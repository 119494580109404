import React from "react";
import "./table.css";

const Table = ({
  headData = [],
  headers,
  body,
  mt,
  nodata,
  totalEntries = 0,
  pageValue,
  setPageValue,
  pageEnteries,
  setPageEnteries,
  generatePageNumbers,
  handlePrev,
  handleNext,
}) => {
  return (
    <div className="table-container">
      {/* {nodata ? (
        <div className="table-nodata">Data Not Found</div>
      ) : ( */}

      {totalEntries > 5 && (
        <div className="AO-2nd">
          <div className="AO-2nd-entries">
            Show
            <select
              className="AO-2nd-ent-select"
              value={pageEnteries}
              onChange={(e) => {
                setPageEnteries(e.target.value);
                setPageValue(1)
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option disabled value="All">All</option>
            </select>
            entries
          </div>
        </div>
      )}
      <div className="tableScroll">
      <table style={{ marginTop: mt || 30 }}>
      {headData?.length > 0 ?  <tr>
          { headData.map((v, i) => (
            <th key={i}>{v}</th>
          ))}
        </tr>
        :headers}
        {body}
      </table>
      </div>
      {nodata && <div className="table-nodata">Data Not Found</div>}
      {totalEntries > 5 && <div className="Page">
        <div>Showing {(pageEnteries * (pageValue - 1)) + 1} to {(pageEnteries * pageValue) > totalEntries ? totalEntries : (pageEnteries * pageValue)} of {totalEntries} entries</div>
        <div className="page-2nd">
          <div className={`page-prev ${pageValue === 1 ? "disabled" : ""}`} onClick={handlePrev}>
            Previous
          </div>
          {generatePageNumbers()}
          <div className={`page-next ${pageValue === 100 ? "disabled" : ""}`} onClick={handleNext}>
            Next
          </div>
        </div>

      </div>}

    </div>
  );
};

export default Table;
