import React, { useState } from "react";
import PageHeading from "../../components/PageHeading";
import { BackButton } from "../../components/btn/Buttons";
import Dropdown from "../../components/inputs/Dropdown";
import "./FinacialReports.css";

const ProAndLoss = () => {
  // State for storing the selected financial year
  const [financialYear, setFinancialYear] = useState("");
  const [open,setOpen]=useState(false);
  const [status, setStatus] = useState("Select a year");

  // Handler for when the financial year changes
  const handleYearChange = (event) => {
    setFinancialYear(event.target.value);
    setStatus("Pending"); 
  };

  return (
    <div className="container">
      <PageHeading
        title={"PROFIT AND LOSS ACCOUNT"}
        right={<BackButton />}
      />
      <div style={{ height: "20px" }}></div>
      <div style={{ width: "180px" }}>
        <Dropdown
          label={"FINANCIAL YEAR"}
          value={financialYear}
          onChange={handleYearChange}
          options={
            <>
              <option value="">SELECT</option>
              <option value="2019-20">2019-20</option>
              <option value="2020-21">2020-21</option>
              <option value="2021-22">2021-22</option>
              <option value="2022-23">2022-23</option>
              <option value="2023-24">2023-24</option>
              <option value="2024-25">2024-25</option>
            </>
          }
        />
      </div>
        <div style={{height:"20px"}}></div>

      {open&&<div className="FinacialYear">
        <span
          style={{
            fontSize: "11px",
            display: "flex",
            alignItems: "center",
          }}
        >
          FINANCIAL YEAR:
        </span>
        <p className="FDATE" style={{ alignItems: "center", display: "flex" }}>
          {financialYear}
        </p>
        <span
          style={{ fontSize: "11px", alignItems: "center", display: "flex" }}
        >
          STATUS :
        </span>
        <p className="FDATE" style={{ alignItems: "center", display: "flex" }}>
          New
        </p>
        <div>
          <button className="GENERATE-BTN">PROCESS</button>
        </div>
      </div>}
      <div style={{height:"20px"}}></div>
      <div>
        <button className="GENERATE-BTN" onClick={()=>setOpen(true)}>FETCH</button>
      </div>
    </div>
  );
};

export default ProAndLoss;
