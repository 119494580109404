import { useEffect, useState } from "react";
import { PiCards } from "react-icons/pi";

export const transition_data = {
  title: "Transition",
  link: "",
  icon: <PiCards size={25} />,
  data: [
    {
      title: "Purchases",
      link: "",

      title: "Sales",
      link: "",
      data: [
        {
          title: "Quotations",
          link: "",
        },
        {
          title: "Purchase Orders",
          link: "",
        },
        {
          title: "Domestic CYL Sales",
          link: "",
        },
        {
          title: "Commercial CYL Sales",
          link: "",
        },
        {
          title: "BLPG/ARB/NFR Sales",
          link: "",
        },
        {
          title: "Delivery Challan",
          link: "",
        },
        {
          title: "Sales Return",
          link: "",
        },
      ],
      title: "NC / DBC / RC / TV",
      link: "",
      data: [
        {
          title: "NC / DBC",
          link: "",
        },
        {
          title: "ITV / RC",
          link: "",
        },
        {
          title: "OTV / TTV /TV",
          link: "",
        },
      ],
      title: "Expenses",
      link: "",
    },
  ],
};

export const Purchase_data = [
  {
    title: "Cylinder Purchases",
    link: "",
  },
  {
    title: "BLPG/ARB/NFR Purchases",
    link: "",
  },
  {
    title: "Other Purchases",
    link: "",
  },
  {
    title: "Defective/Pur return",
    link: "",
  },
];

export const dropdown = [
  {
    label: "Cylinder Purchases",
    value: "",
  },
  {
    label: "BLPG/ARB/NFR Purchases",
    value: "",
  },
  {
    label: "Other Purchases",
    value: "",
  },
  {
    label: "Defective/Pur return",
    value: "",
  },
];

export const MHData = [
  { id: 1, MH: 11, title: "Delivery boy commission", AH: 3 },
  { id: 2, MH: 12, title: "Delivery vehicle rent", AH: 3 },
  { id: 3, MH: 13, title: "Delivery vehicle maintenance", AH: 3 },
  { id: 4, MH: 14, title: "Fuel charges for delivery vehicles", AH: 3 },
  { id: 5, MH: 15, title: "Outward Tolls and Taxes", AH: 3 },
  { id: 6, MH: 21, title: "Transportation charges paid to truck owner", AH: 3 },
  { id: 7, MH: 22, title: "Unloading charges", AH: 3 },
  { id: 8, MH: 23, title: "Truck maintenance", AH: 3 },
  { id: 9, MH: 24, title: "Inward Tolls and Taxes", AH: 3 },
  { id: 10, MH: 25, title: "Fuel charges for delivery vehicles", AH: 3 },
  { id: 11, MH: 31, title: "Electricity bill", AH: 3 },
  { id: 12, MH: 32, title: "Telephone bill", AH: 3 },
  { id: 13, MH: 33, title: "Water bill", AH: 3 },
  { id: 14, MH: 34, title: "Internet bill", AH: 3 },
  { id: 15, MH: 35, title: "Newspaper bill", AH: 3 },
  { id: 16, MH: 41, title: "Renewal charges for licenses", AH: 6 },
  { id: 17, MH: 42, title: "Weighing scale certification charges", AH: 6 },
  { id: 18, MH: 43, title: "Fire extinguisher refilling charges", AH: 6 },
  { id: 19, MH: 44, title: "Annual subscriptions to associations", AH: 6 },
  { id: 20, MH: 51, title: "Expenditure Toward Pujas", AH: 6 },
  { id: 21, MH: 61, title: "Printing cost of Stationery", AH: 6 },
  { id: 22, MH: 62, title: "Postage/courier charges", AH: 6 },
  {
    id: 23,
    MH: 71,
    title: "Travel expense of dealer/staff for official purpose",
    AH: 6,
  },
  {
    id: 24,
    MH: 72,
    title: "Fuel charges for car/bike (Apart from delivery usage)",
    AH: 6,
  },
  { id: 25, MH: 73, title: "Car/bike maintenance costs", AH: 6 },
  { id: 26, MH: 81, title: "Rent paid to Godown", AH: 6 },
  { id: 27, MH: 82, title: "Rent paid to Office", AH: 6 },
  {
    id: 28,
    MH: 91,
    title: "All expenditure towards Business Promotion",
    AH: 3,
  },
  { id: 29, MH: 101, title: "Tea, coffee and Food expenditure", AH: 6 },
  { id: 30, MH: 102, title: "Uniforms", AH: 6 },
  { id: 31, MH: 103, title: "Staff recreation", AH: 6 },
  { id: 32, MH: 104, title: "All staff welfare expenditure", AH: 6 },
  { id: 33, MH: 111, title: "Mastic Flooring", AH: 6 },
  { id: 34, MH: 112, title: "Replacement of wire mesh", AH: 6 },
  {
    id: 35,
    MH: 113,
    title: "Any minor civil works/repairs at godown and Office",
    AH: 6,
  },
  { id: 36, MH: 121, title: "LPG dealer insurance", AH: 6 },
  { id: 37, MH: 131, title: "Salaries & Wages", AH: 6 },
  { id: 38, MH: 132, title: "Auditor fees", AH: 6 },
  { id: 39, MH: 133, title: "Interest paid", AH: 6 },
  { id: 40, MH: 134, title: "All other misc expenditures", AH: 6 },
  { id: 41, MH: 141, title: "Land", AH: 5 },
  { id: 42, MH: 142, title: "Building", AH: 5 },
  { id: 43, MH: 143, title: "Plant and Machinery", AH: 5 },
  { id: 44, MH: 144, title: "Furniture and Fixtures", AH: 5 },
  { id: 45, MH: 145, title: "Motor vehicles", AH: 5 },
  { id: 46, MH: 146, title: "Computer and Printers", AH: 5 },
  { id: 47, MH: 147, title: "Other Movable Assets", AH: 5 },
];
export const AHData = [
  { id: 37, AH: 1, title: "Current Liabilities" },
  { id: 38, AH: 2, title: "Deposited Assets" },
  { id: 39, AH: 3, title: "Direct Expenses" },
  { id: 40, AH: 4, title: "Direct Income" },
  { id: 41, AH: 5, title: "Fixed Assets" },
  { id: 42, AH: 6, title: "Indirect Expenses" },
  { id: 43, AH: 7, title: "Indirect Income" },
  { id: 44, AH: 8, title: "Loans and Advances" },
  { id: 45, AH: 9, title: "Provisions" },
  { id: 46, AH: 10, title: "Reserves and Surplus" },
  { id: 47, AH: 11, title: "Unsecured Loan" },
];

export const UOMData = [
  { id: 48, UOM: 12, title: "BAG-BAGS" },
  { id: 49, UOM: 13, title: "BOX-BOX" },
  { id: 50, UOM: 14, title: "BTL-BOTTLES" },
  { id: 51, UOM: 15, title: "CAN-CANS" },
  { id: 52, UOM: 16, title: "CBM-CUBIC-METERS" },
  { id: 53, UOM: 17, title: "CTN-CARTONS" },
  { id: 54, UOM: 18, title: "DOZ-DOZENS" },
  { id: 55, UOM: 19, title: "GMS-GRAMMES" },
  { id: 56, UOM: 20, title: "KGS-KILOGRAMS" },
  { id: 57, UOM: 21, title: "KLR-KILOLITRE" },
  { id: 58, UOM: 22, title: "KME-KILOMETRE" },
  { id: 59, UOM: 23, title: "MTR-METERS" },
  { id: 60, UOM: 24, title: "MTS-METRIC TON" },
  { id: 61, UOM: 25, title: "NOS-NUMBERS" },
  { id: 62, UOM: 26, title: "PAC-PACKS" },
  { id: 63, UOM: 27, title: "PCS-PIECES" },
  { id: 64, UOM: 28, title: "QTL-QUINTAL" },
  { id: 65, UOM: 29, title: "SET-SETS" },
  { id: 66, UOM: 30, title: "SQF-SQUARE FEET" },
  { id: 67, UOM: 31, title: "SQM-SQUARE METERS" },
  { id: 68, UOM: 32, title: "SQY-SQUARE YARDS" },
  { id: 69, UOM: 33, title: "TON-TONNES" },
  { id: 70, UOM: 34, title: "UNT-UNITS" },
  { id: 71, UOM: 35, title: "YDS-YARDS" },
  { id: 72, UOM: 36, title: "OTH-OTHERS" },
];

export const bankDetails = [
  { value: 1, bankName: "ABHYUDAYA COOPERATIVE BANK LIMITED" },
  { value: 2, bankName: "ABU DHABI COMMERCIAL BANK" },
  { value: 3, bankName: "AHMEDABAD MERCANTILE COOPERATIVE BANK" },
  { value: 4, bankName: "AKOLA JANATA COMMERCIAL COOPERATIVE BANK" },
  { value: 5, bankName: "ALLAHABAD BANK" },
  { value: 6, bankName: "ALMORA URBAN COOPERATIVE BANK LIMITED" },
  { value: 7, bankName: "ANDHRA BANK" },
  { value: 8, bankName: "ANDHRA PRAGATHI GRAMEENA BANK" },
  { value: 9, bankName: "APNA SAHAKARI BANK LIMITED" },
  { value: 10, bankName: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED" },
  { value: 11, bankName: "AXIS BANK" },
  { value: 12, bankName: "B N P PARIBAS" },
  { value: 13, bankName: "BANDHAN BANK LIMITED" },
  { value: 14, bankName: "BANK OF AMERICA" },
  { value: 15, bankName: "BANK OF BAHARAIN AND KUWAIT BSC" },
  { value: 16, bankName: "BANK OF BARODA" },
  { value: 17, bankName: "BANK OF CEYLON" },
  { value: 18, bankName: "BANK OF INDIA" },
  { value: 19, bankName: "BANK OF MAHARASHTRA" },
  { value: 20, bankName: "BANK OF TOKYO MITSUBISHI LIMITED" },
  { value: 21, bankName: "BARCLAYS BANK" },
  { value: 22, bankName: "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED" },
  { value: 23, bankName: "BHARAT COOPERATIVE BANK MUMBAI LIMITED" },
  { value: 24, bankName: "BHARATIYA MAHILA BANK LIMITED" },
  { value: 25, bankName: "CANARA BANK" },
  { value: 26, bankName: "CAPITAL LOCAL AREA BANK LIMITED" },
  { value: 27, bankName: "CATHOLIC SYRIAN BANK LIMITED" },
  { value: 28, bankName: "CENTRAL BANK OF INDIA" },
  { value: 29, bankName: "CHINATRUST COMMERCIAL BANK LIMITED" },
  { value: 30, bankName: "CITI BANK" },
  { value: 31, bankName: "CITIZEN CREDIT COOPERATIVE BANK LIMITED" },
  { value: 32, bankName: "CITY UNION BANK LIMITED" },
  { value: 33, bankName: "COMMONWEALTH BANK OF AUSTRALIA" },
  { value: 34, bankName: "CORPORATION BANK" },
  {
    value: 35,
    bankName: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK CALYON BANK",
  },
  { value: 36, bankName: "CREDIT SUISEE AG" },
  { value: 37, bankName: "DCB BANK LIMITED" },
  { value: 38, bankName: "DENA BANK" },
  { value: 39, bankName: "DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD" },
  { value: 40, bankName: "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION" },
  { value: 41, bankName: "DEUSTCHE BANK" },
  { value: 42, bankName: "DEVELOPMENT BANK OF SINGAPORE" },
  { value: 43, bankName: "DHANALAKSHMI BANK" },
  { value: 44, bankName: "DOHA BANK QSC" },
  { value: 45, bankName: "DOMBIVLI NAGARI SAHAKARI BANK LIMITED" },
  { value: 46, bankName: "EXPORT IMPORT BANK OF INDIA" },
  { value: 47, bankName: "FEDERAL BANK" },
  { value: 48, bankName: "FIRSTRAND BANK LIMITED" },
  { value: 49, bankName: "G P PARSIK BANK" },
  { value: 50, bankName: "HDFC BANK" },
  { value: 51, bankName: "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD" },
  { value: 52, bankName: "HSBC BANK" },
  { value: 53, bankName: "ICICI BANK LIMITED" },
  { value: 54, bankName: "IDBI BANK" },
  { value: 55, bankName: "IDFC BANK LIMITED" },
  { value: 56, bankName: "INDIAN BANK" },
  { value: 57, bankName: "INDIAN OVERSEAS BANK" },
  { value: 58, bankName: "INDUSIND BANK" },
  { value: 59, bankName: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED" },
  { value: 60, bankName: "INDUSTRIAL BANK OF KOREA" },
  { value: 61, bankName: "ING VYSYA BANK" },
  { value: 62, bankName: "JALGAON JANATA SAHAKARI BANK LIMITED" },
  { value: 63, bankName: "JAMMU AND KASHMIR BANK LIMITED" },
  { value: 64, bankName: "JANAKALYAN SAHAKARI BANK LIMITED" },
  { value: 65, bankName: "JANASEVA SAHAKARI BANK BORIVLI LIMITED" },
  { value: 66, bankName: "JANASEVA SAHAKARI BANK LIMITED" },
  { value: 67, bankName: "JANATA SAHAKARI BANK LIMITED" },
  { value: 68, bankName: "JP MORGAN BANK" },
  {
    value: 69,
    bankName: "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LTD",
  },
  { value: 70, bankName: "KALUPUR COMMERCIAL COOPERATIVE BANK" },
  { value: 71, bankName: "KALYAN JANATA SAHAKARI BANK" },
  { value: 72, bankName: "KAPOL COOPERATIVE BANK LIMITED" },
  { value: 73, bankName: "KARNATAKA BANK LIMITED" },
  { value: 74, bankName: "KARNATAKA VIKAS GRAMEENA BANK" },
  { value: 75, bankName: "KARUR VYSYA BANK" },
  { value: 76, bankName: "KEB Hana Bank" },
  { value: 77, bankName: "KERALA GRAMIN BANK" },
  { value: 78, bankName: "KOTAK MAHINDRA BANK LIMITED" },
  { value: 79, bankName: "LAXMI VILAS BANK" },
  { value: 80, bankName: "MAHANAGAR COOPERATIVE BANK" },
  { value: 81, bankName: "MAHARASHTRA STATE COOPERATIVE BANK" },
  { value: 82, bankName: "MASHREQBANK PSC" },
  { value: 83, bankName: "MIZUHO CORPORATE BANK LIMITED" },
  { value: 84, bankName: "NAGAR URBAN CO OPERATIVE BANK" },
  { value: 85, bankName: "NAGPUR NAGARIK SAHAKARI BANK LIMITED" },
  { value: 86, bankName: "NATIONAL AUSTRALIA BANK LIMITED" },
  { value: 87, bankName: "NATIONAL BANK OF ABU DHABI PJSC" },
  { value: 88, bankName: "NEW INDIA COOPERATIVE BANK LIMITED" },
  { value: 89, bankName: "NKGSB COOPERATIVE BANK LIMITED" },
  { value: 90, bankName: "NUTAN NAGARIK SAHAKARI BANK LIMITED" },
  { value: 91, bankName: "ORIENTAL BANK OF COMMERCE" },
  { value: 92, bankName: "PRAGATHI KRISHNA GRAMIN BANK" },
  { value: 93, bankName: "PRATHAMA BANK" },
  { value: 94, bankName: "PRIME COOPERATIVE BANK LIMITED" },
  { value: 95, bankName: "PT BANK MAYBANK INDONESIA TBK" },
  { value: 96, bankName: "PUNJAB AND MAHARSHTRA COOPERATIVE BANK" },
  { value: 97, bankName: "PUNJAB AND SIND BANK" },
  { value: 98, bankName: "PUNJAB NATIONAL BANK" },
  { value: 99, bankName: "RABOBANK INTERNATIONAL" },
  { value: 100, bankName: "RAJGURUNAGAR SAHAKARI BANK LIMITED" },
  { value: 101, bankName: "RAJKOT NAGRIK SAHAKARI BANK LIMITED" },
  { value: 102, bankName: "RBL Bank Limited" },
  { value: 103, bankName: "RESERVE BANK OF INDIA" },
  { value: 104, bankName: "SAHEBRAO DESHMUKH COOPERATIVE BANK LIMITED" },
  { value: 105, bankName: "SAMARTH SAHAKARI BANK LTD" },
  { value: 106, bankName: "SARASWAT COOPERATIVE BANK LIMITED" },
  { value: 107, bankName: "SBER BANK" },
  { value: 108, bankName: "SBM BANK MAURITIUS LIMITED" },
  { value: 109, bankName: "SHIKSHAK SAHAKARI BANK LIMITED" },
  { value: 110, bankName: "SHINHAN BANK" },
  { value: 111, bankName: "SHIVALIK MERCANTILE CO OPERATIVE BANK LTD" },
  {
    value: 112,
    bankName: "SHRI CHHATRAPATI RAJASHRI SHAHU URBAN COOPERATIVE BANK LTD",
  },
  { value: 113, bankName: "SOCIETE GENERALE" },
  { value: 114, bankName: "SOLAPUR JANATA SAHAKARI BANK LIMITED" },
  { value: 115, bankName: "SOUTH INDIAN BANK" },
  { value: 116, bankName: "STANDARD CHARTERED BANK" },
  { value: 117, bankName: "STATE BANK OF BIKANER AND JAIPUR" },
  { value: 118, bankName: "STATE BANK OF HYDERABAD" },
  { value: 119, bankName: "STATE BANK OF INDIA" },
  { value: 120, bankName: "STATE BANK OF MYSORE" },
  { value: 121, bankName: "STATE BANK OF PATIALA" },
  { value: 122, bankName: "STATE BANK OF TRAVANCORE" },
  { value: 123, bankName: "SUMITOMO MITSUI BANKING CORPORATION" },
  { value: 124, bankName: "SURAT NATIONAL COOPERATIVE BANK LIMITED" },
  { value: 125, bankName: "SUTEX COOPERATIVE BANK LIMITED" },
  { value: 126, bankName: "SYNDICATE BANK" },
  { value: 127, bankName: "TAMILNAD MERCANTILE BANK LIMITED" },
  { value: 128, bankName: "THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED" },
  { value: 129, bankName: "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK" },
  { value: 130, bankName: "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED" },
  { value: 131, bankName: "THE BANK OF NOVA SCOTIA" },
  { value: 132, bankName: "THE COSMOS CO OPERATIVE BANK LIMITED" },
  { value: 133, bankName: "THE DELHI STATE COOPERATIVE BANK LIMITED" },
  {
    value: 134,
    bankName: "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  },
  { value: 135, bankName: "THE GREATER BOMBAY COOPERATIVE BANK LIMITED" },
  { value: 136, bankName: "THE GUJARAT STATE COOPERATIVE BANK LIMITED" },
  { value: 137, bankName: "THE HASTI COOP BANK LTD" },
  { value: 138, bankName: "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED" },
  { value: 139, bankName: "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED" },
  { value: 140, bankName: "THE KANGRA COOPERATIVE BANK LIMITED" },
  { value: 141, bankName: "THE KARAD URBAN COOPERATIVE BANK LIMITED" },
  {
    value: 142,
    bankName: "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED",
  },
  { value: 143, bankName: "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED" },
  { value: 144, bankName: "THE MEHSANA URBAN COOPERATIVE BANK" },
  {
    value: 145,
    bankName: "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  },
  { value: 146, bankName: "THE MUNICIPAL COOPERATIVE BANK LIMITED" },
  { value: 147, bankName: "THE NAINITAL BANK LIMITED" },
  { value: 148, bankName: "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED" },
  { value: 149, bankName: "THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR" },
  { value: 150, bankName: "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED" },
  { value: 151, bankName: "THE ROYAL BANK OF SCOTLAND N V" },
  { value: 152, bankName: "THE SEVA VIKAS COOPERATIVE BANK LIMITED" },
  { value: 153, bankName: "THE SHAMRAO VITHAL COOPERATIVE BANK" },
  { value: 154, bankName: "THE SURAT DISTRICT COOPERATIVE BANK LIMITED" },
  { value: 155, bankName: "THE SURATH PEOPLES COOPERATIVE BANK LIMITED" },
  { value: 156, bankName: "THE TAMIL NADU STATE APEX COOPERATIVE BANK" },
  { value: 157, bankName: "THE THANE BHARAT SAHAKARI BANK LIMITED" },
  {
    value: 158,
    bankName: "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
  },
  { value: 159, bankName: "THE VARACHHA COOPERATIVE BANK LIMITED" },
  { value: 160, bankName: "THE VISHWESHWAR SAHAKARI BANK LIMITED" },
  { value: 161, bankName: "THE WEST BENGAL STATE COOPERATIVE BANK" },
  { value: 162, bankName: "THE ZOROASTRIAN COOPERATIVE BANK LIMITED" },
  { value: 163, bankName: "TJSB SAHAKARI BANK LTD" },
  { value: 164, bankName: "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED" },
  { value: 165, bankName: "UCO BANK" },
  { value: 166, bankName: "UNION BANK OF INDIA" },
  { value: 167, bankName: "UNITED BANK OF INDIA" },
  { value: 168, bankName: "UNITED OVERSEAS BANK LIMITED" },
  { value: 169, bankName: "VASAI VIKAS SAHAKARI BANK LIMITED" },
  { value: 170, bankName: "VIJAYA BANK" },
  { value: 171, bankName: "WESTPAC BANKING CORPORATION" },
  { value: 172, bankName: "WOORI BANK" },
  { value: 173, bankName: "YES BANK" },
  { value: 174, bankName: "ZILA SAHAKRI BANK LIMITED GHAZIABAD" },
  { value: 175, bankName: "OTHERS" },
];

export const OMC_data = [
  {
    value: 1,
    label: "NFR PRODUCT",
  },
  {
    value: 2,
    label: "ARB PRODUCT",
  },
  {
    value: 3,
    label: "BLPG PRODUCT",
  },
];

export const creditNoteResons = [
  {
    id: "1",
    res: "Sales Return",
  },
  {
    id: "2",
    res: "Post Sale Discount",
  },
  {
    id: "3",
    res: "Deficiency in services",
  },
  {
    id: "4",
    res: "Correction in Invoice",
  },
  {
    id: "5",
    res: "Change in POS",
  },
  {
    id: "7",
    res: "Finalization of Provisional assessment",
  },
  {
    id: "8",
    res: "Others",
  },
];

export const TransactionType = [
  {
    id: 1,
    label: "PAYMENT",
    show: false,
  },
  {
    id: 2,
    label: "RECEIPT",
    show: false,
  },
  {
    id: 3,
    label: "CASH DEPOSIT",
    show: true,
  },
  {
    id: 4,
    label: "CASH WITHDRAWL",
    show: true,
  },
  {
    id: 5,
    label: "TRANSFER",
    show: true,
  },
  {
    id: 6,
    label: "CHEQUE RETURN",
    show: false,
  },
];

export const ncData = [
  {
    dis_data: "NC",
    label_data: "NC",
    value: "1",
  },
  {
    label_data: "UJWALA LOAN",
    value: "3",
  },
  {
    label_data: "UJWALA CASH",
    value: "5",
  },
  {
    label_data: "BPL",
    value: "4",
  },
  {
    dis_data: "DBC",
    label_data: "DBC",
    value: "2",
  },
  {
    dis_data: "RC",
    label_data: "RC",
    value: "6",
  },
  {
    dis_data: "TV",
    label_data: "TV",
    value: "7",
  },
];

export const LedgerHeaders = [
  {
    id: "1",
    data: [
      "INVOICE DATE",
      "INV NO",
      "GODOWN NAME",
      "TRANSACTION TYPE",
      "QUANTITY",
      "PARTY NAME",
      "BALANCE (FULLS)",
      "BALANCE (EMPTYS)",
      "DISCOUNT",
      "CREATED DATE",
    ],
  },
  {
    id: "2",
    data: [
      "INVOICE DATE",
      "INV NO",
      "TRANSACTION TYPE",
      "DEBIT AMOUNT",
      "CREDIT AMOUNT",
      "CLOSING BALANCE",
      "DISCOUNT",
    ],
  },
  {
    id: "3",
    data: [
      "INVOICE DATE",
      "INV NO",
      "TRANSACTION TYPE",
      "DEBIT AMOUNT",
      "CREDIT AMOUNT",
      "CLOSING BALANCE",
      // "DISCOUNT",
    ],
  },
  {
    id: "4", // Currently not found the data of this Tax Type
    data: [
      "TRANSACTION DATE",
      "TRANSACTION TYPE",
      "DEBIT AMOUNT",
      "CREDIT AMOUNT",
      "BALANCE",
    ],
  },

  {
    id: "5",
    data: [
      "VOUCHER DATE",
      "VOUCHER NO",
      "EXPENSE ITEM",
      "MINOR HEAD",
      "ACCOUNT HEAD",
      "QTY",
      "UOM",
      "UNIT PRICE",
      "PAYMENT MODE",
      "BANK ACCOUNT",
      "TOTAL VALUE",
    ],
  },
  {
    id: "6",
    data: [
      "INVOICE DATE",
      "INV NO",
      "INV REF NO",
      "GODOWN NAME",
      "TRANSACTION TYPE",
      "OMC NAME",
      "PRODUCT",
      "MINOR HEAD",
      "ACCOUNTING HEAD",
      "HSN CODE",
      "UNIT PRICE",
      "QUANTITY",
      "GST%",
      "TAXABLE AMOUNT",
      "IGST AMOUNT",
      "CGST AMOUNT",
      "SGST AMOUNT",
      "OTHER CHARGES",
      "TOTAL AMOUNT",
    ],
  },
];

export const omc_payment_type = [
  { value: "1", lable: "CYLINDER PURCHASES" },
  { value: "2", lable: "BLPG / ARB / NFR PURCHASES" },
  { value: "3", lable: "OTHER PURCHASES" },
  { value: "4", lable: "CREDIT NOTE" },
  { value: "5", lable: "DEBIT NOTE" },
  { value: "6", lable: "BLPG / ARB / NFR SALES" },
];



export const selectPaymentType = [
  {
    id: "1",
    lable: "CYLINDER PURCHASES",
  },
  {
    id: "2",
    lable: "BLPG / ARB / NFR PURCHASES",
  },
  {
    id: "3",
    lable: "OTHER PURCHASES",
  },
  {
    id: "4",
    lable: "CREDIT NOTE",
  },
  {
    id: "5",
    lable: "DEBIT NOTE",
  },
  {
    id: "6",
    lable: "BLPG / ARB / NFR SALES",
  },
];

export const PaymentToParty = [
  {
    id: "1",
    lable: "VENDOR",
  },
  {
    id: "2",
    lable: "CUSTOMER",
  },
  {
    id: "3",
    lable: "GST",
  },
  {
    id: "4",
    lable: "INCOME TAX",
  },
  {
    id: "5",
    lable: "TDS/TCS",
  },
  {
    id: "6",
    lable: "PROFSSIONAL TAX",
  },
  {
    id: "7",
    lable: "LOAN AND ADVANCE",
  },
  {
    id: "8",
    lable: "Others",
  },
];

export const monthData = [
  { id: "1", label: "JAN", fullName: "JANUARY" },
  { id: "2", label: "FEB", fullName: "FEBRUARY" },
  { id: "3", label: "MAR", fullName: "MARCH" },
  { id: "4", label: "APR", fullName: "APRIL" },
  { id: "5", label: "MAY", fullName: "MAY" },
  { id: "6", label: "JUN", fullName: "JUNE" },
  { id: "7", label: "JUL", fullName: "JULY" },
  { id: "8", label: "AUG", fullName: "AUGUST" },
  { id: "9", label: "SEP", fullName: "SEPTEMBER" },
  { id: "10", label: "OCT", fullName: "OCTOBER" },
  { id: "11", label: "NOV", fullName: "NOVEMBER" },
  { id: "12", label: "DEC", fullName: "DECEMBER" },
];

export const YearData = [
  { id: "2019", lable: "2019" },
  { id: "2020", lable: "2020" },
  { id: "2021", lable: "2021" },
  { id: "2022", lable: "2022" },
  { id: "2023", lable: "2023" },
  { id: "2024", lable: "2024" },
  { id: "2025", lable: "2025" },
  { id: "2026", lable: "2026" },
  { id: "2027", lable: "2027" },
  { id: "2028", lable: "2028" },
  { id: "2029", lable: "2029" },
  { id: "2030", lable: "2030" },
];

export const Filter_comp_data = ({ type, omc_data, showData, itc }) => {
  const Day_end_date_Comp_data = [
    {
      title: "CYLINDERS PURCHASE INVOICE ENTRY",
      value: 1,
      table_header: [
        "PRODUCT",
        "ONE WAY/TWO WAY LOAD",
        "TRUCK NUMBER",
        "DRIVER NAME",
        "GODOWN SELECTION",
        "RECIEVED BY",
        "UNIT PRICE	",
        "QUANTITY",
        "GST%",
        "TAXABLE AMOUNT	",
        "TDS/TCS",
        "IGST",
        "SGST",
        "CGST",
        "TOTAL AMOUNT",
      ],
    },
    {
      title:
        (omc_data == 1 && "NFR PURCHASE INVOICES") ||
        (omc_data == 2 && "ARB PURCHASE INVOICES") ||
        (omc_data == 3 && "BLPG PURCHASE INVOICES"),
      value: 2,
      table_header: [
        "PRODUCT",
        "TRUCK NUMBER",
        "DRIVER NAME",
        "RECIEVED BY",
        "GST%",
        "UNIT PRICE	",
        "QUANTITY",
        "TAXABLE AMOUNT	",
        "TDS/TCS",
        "IGST AMOUNT",
        "SGST AMOUNT",
        "CGST AMOUNT",
        "TOTAL AMOUNT",
      ],
    },
    {
      title: `EXPENDITURE INVOICE ENTRY  ${
        showData == "1" && (
          <>{itc == "0" ? "(ITC Eligible)" : "(ITC InEligible)"}</>
        )
      }`,
      value: 3,
      table_header:
        showData == "1"
          ? [
              "PRODUCT",
              "HSN/SAC CODE",
              "GST%",
              "MINOR HEAD",
              "ACCOUNT HEAD",
              "QUANTITY",
              "UOM",
              "UNIT PRICE",
              "TAXABLE VALUE",
              "IGST AMOUNT",
              "SGST AMOUNT",
              "CGST AMOUNT",
              "TOTAL AMOUNT	",
            ]
          : [
              "EXPENSE ITEM",
              "PAYMENT MODE",
              "BANK ACCOUNT",
              "RECIEVED BY",
              "NARRATION",
              "MINOR HEAD",
              "ACCOUNT HEAD",
              "QUANTITY",
              "UOM",
              "UNIT PRICE",
              "TDS",
              "TOTAL VALUE",
            ],
    },

    {
      title: "DEFECTIVE/PURCHASE RETURN DATA",
      value: 4,
      table_header: [
        "PRODUCT",
        "REASON",
        "VEHICLE NUMBER",
        "UNIT RATE",
        "GST %",
        "RETURN QUANTITY",
        "CYL. GROSS WEIGHT",
        "CYL. TARE WEIGHT",
        "CYL. SR. No.",
        "NET WEIGHT(KGS)",
        "TAXABLE AMOUNT",
        "IGST AMOUNT",
        "CGST AMOUNT",
        "SGST AMOUNT",
        "AMOUNT",
      ],
    },
    // Sales Start
    {
      title: "DOMESTIC SALES",
      value: 5,
      table_header: [
        "PRODUCT",
        "DELIVERED BY	",
        "AREA CODE",
        "GST%",
        "UNIT PRICE",
        "DISCOUNT ON UNIT PRICE",
        "QUANTITY",
        "PREPAID CYLINDERS",
        "PSV QUANTITY",
        "TRANSPORT CHARGES",
        "TRANSACTION AMOUNT",
        "TOTAL AMOUNT",
        "ONLINE RCVD AMOUNT",
      ],
    },
    {
      title: "COMMERCIAL SALES",
      value: 6,
      table_header: [
        "PRODUCT",
        "DELIVERED BY",
        // "BANK ACCOUNT",
        "AREA CODE",
        "NARRATION",
        "GST%",
        "UNIT PRICE",
        "DISCOUNT ON UNIT PRICE",
        "QUANTITY",
        "PSV CYLINDERS",
        "EMPTIES RECEIVED",
        "TRANSPORT CHARGES",
        "TDS/TCS",
        "TOTAL AMOUNT",
      ],
    },

    {
      title:
        (OMC_data == 1 && "NFR SALES") ||
        (OMC_data == 2 && "ARB SALES") ||
        (OMC_data == 3 && "BLPG SALES"),
      value: 7,
      table_header: [
        "PRODUCT",
        "UNIT PRICE",
        "GST %",
        "DISCOUNT ON UNIT PRICE	",
        "QUANTITY",
        "TAXABLE AMOUNT",
        "TDS/TCS",
        "IGST AMOUNT	",
        "CGST AMOUNT	",
        "SGST AMOUNT	",
        "TOTAL AMOUNT	",
      ],
    },
    {
      title: "SALES RETURNS INVOICE ENTRY",
      value: 8,
      table_header: [
        "PRODUCT",
        "BANK	ACCOUNT",
        "GST%",
        "return quantity",
        "NET WEIGHT",
        "UNIT PRICE",
        "TAXABLE VALUE",
        "IGST AMOUNT",
        "SGST AMOUNT",
        "CGST AMOUNT",
        "TOTAL AMOUNT	",
      ],
    },

    // NCDBC Start

    {
      title: "NC / DBC DATA",
      value: 9,
      table_header: [
        "PRODUCT",
        "QUANTITY",
        "UNIT PRICE",
        "GST %",
        "DEPOSIT",
        "DISCOUNT ON UNIT RATE",
        "TAXABLE VALUE",
        "CGST AMOUNT",
        "SGST AMOUNT",
        "PRODUCT AMOUNT",
      ],
    },

    {
      title: "ITV / RC",
      value: 10,
      table_header: [
        "PRODUCT",
        "NUMBER OF CYLINDERS",
        "NUMBER OF REGULATORS",
        "CYLINDER DEPOSIT",
        "REGULATOR DEPOSIT	",
        "ADMIN CHARGES	",
        "DGCC AMOUNT",
        "IGST AMOUNT",
        "CGST AMOUNT",
        "SGST AMOUNT",
        "PAYMENT TERMS",
      ],
    },
    {
      title: "OTV / TTV /TV",
      value: 11,
      table_header: [
        "PRODUCT",
        "NUMBER OF CYLINDERS",
        "NUMBER OF REGULATORS",
        "CYLINDER DEPOSIT",
        "REGULATOR DEPOSIT	",
        "IMPREST AMOUNT",
        "ADMIN CHARGES	",
        "IGST AMOUNT",
        "CGST AMOUNT",
        "SGST AMOUNT",
        "PAYMENT TERMS",
      ],
    },
  ];
  const filterData = Day_end_date_Comp_data?.filter((v, i) => v?.value == type);
  return filterData?.length > 0 ? filterData[0] : [];
};
