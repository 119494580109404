// // hooks/useBroadcastChannel.js
// import { useEffect } from 'react';
// import { BroadcastChannel } from 'broadcast-channel';

// const useBroadcastChannel = (channelName, callback) => {
//   useEffect(() => {
//     const channel = new BroadcastChannel(channelName);

//     channel.onmessage = (message) => {
//       callback(message);
//     };

//     return () => {
//       channel.close();
//     };
//   }, [channelName, callback]);
// };

// export default useBroadcastChannel;

// hooks/useBroadcastChannel.js
// import { useEffect } from 'react';
// import { BroadcastChannel } from 'broadcast-channel';

// const useBroadcastChannel = (channelName, callback) => {
//   useEffect(() => {
//     const channel = new BroadcastChannel(channelName);

//     channel.onmessage = (message) => {
//       if (Array.isArray(message)) {
//         message.forEach(callback);
//       } else {
//         callback(message);
//       }
//     };

//     return () => {
//       channel.close();
//     };
//   }, [channelName, callback]);
// };

// export default useBroadcastChannel;

// import { useEffect } from 'react';
// import { BroadcastChannel } from 'broadcast-channel';

// const useBroadcastChannel = (channelName, callback) => {
//   useEffect(() => {
//     const channel = new BroadcastChannel(channelName);

//     channel.onmessage = (event) => {
//       if (event && event.data) {
//         callback(event.data);
//       }
//     };

//     return () => {
//       channel.close();
//     };
//   }, [channelName, callback]);
// };

// export default useBroadcastChannel;

import { BroadcastChannel } from "broadcast-channel";
import { get_user_id } from "../../redux/slice/SlideSlice";
import store from "../../redux/store";

const logoutChannel = new BroadcastChannel("logout");
const loginChannel = new BroadcastChannel("login");

export const login = () => {
  loginChannel.postMessage("login");
  window.location.href = window.location.origin + "/Home";
};

export const loginAllTabs = () => {
  loginChannel.onmessage = () => {
    login();
    loginChannel.close();
  };
};

export const logout = () => {
  logoutChannel.postMessage("Logout");
  localStorage.removeItem("userId", "new");
  localStorage.clear();
  window.location.href = window.location.origin + "/login";
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout();
    logoutChannel.close();
  };
};
