import React, { useState } from "react";
import PageHeading from "../../components/PageHeading";
import { BackButton } from "../../components/btn/Buttons";
import Dropdown from "../../components/inputs/Dropdown";
import SmallModal from "../../components/modal/SmallModal";
import { Alerts } from "../../components/Alerts/Alerts";

const ProfitabilityAnalysis = () => {
  // States to store the selected year and month
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [alerts, setAlerts] = useState(false);
  const [alertsMess, setAlertsMess] = useState("");

  // Handlers for when the year or month changes
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    // Optionally trigger any updates or fetching logic when year changes
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    // Optionally trigger any updates or fetching logic when month changes
  };

  const valiDate = () => {
    const data = [];
    if (selectedMonth === "" || selectedMonth === "SELECT") {
      data.push("Please select the corresponding month");
    }
    if (selectedYear === "" || selectedYear === "SELECT") {
      data.push("Please select the corresponding year");
    }
    return data;
  };

  const handleSave = () => {
    const data = valiDate();
    if (data?.length > 0) {
      setAlerts(true);
      setAlertsMess(<Alerts data={data} />);
    }
  };

  return (
    <div className="container">
      <PageHeading title={"PROFITABILITY ANALYSIS"} right={<BackButton />} />
      <div style={{ height: "20px" }}></div>
      <div className="df mt10 gap10">
        <div style={{ width: "120px" }}>
          <Dropdown
            label={"YEAR"}
            value={selectedYear}
            onChange={handleYearChange}
            options={
              <>
                <option value="">SELECT</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </>
            }
          />
        </div>
        <div style={{ width: "120px" }}>
          <Dropdown
            label={"MONTH"}
            value={selectedMonth}
            onChange={handleMonthChange}
            options={
              <>
                <option value="">SELECT</option>
                <option value="JAN">JAN</option>
                <option value="FEB">FEB</option>
                <option value="MAR">MAR</option>
                <option value="APR">APR</option>
                <option value="MAY">MAY</option>
                <option value="JUN">JUN</option>
                <option value="JUL">JUL</option>
                <option value="AUG">AUG</option>
                <option value="SEP">SEP</option>
                <option value="OCT">OCT</option>
                <option value="NOV">NOV</option>
                <option value="DEC">DEC</option>
              </>
            }
          />
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <button
            className="GENERATE-BTN"
            onClick={() => {
              handleSave();
            }}
          >
            FETCH
          </button>
        </div>
      </div>
      {alerts && (
        <SmallModal
          isOpen={alerts}
          setIsOpen={setAlerts}
          title={"alert"}
          mess={alertsMess}
          okbtn={setAlertsMess}
        />
      )}
    </div>
  );
};

export default ProfitabilityAnalysis;
