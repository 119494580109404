import React, { useState } from 'react'
import { AddButton, BackButton, SaveButton } from "../../../components/btn/Buttons";
import PageHeading from "../../../components/PageHeading";
import Table from "../../../components/table/Table";
import { DeleteIcon } from '../../../utils/utils';
import Input from '../../../components/inputs/Input';
import { DateValidator } from '../../../utils/validation';
import moment from 'moment';
import Dropdown from '../../../components/inputs/Dropdown';
import SmallModal from '../../../components/modal/SmallModal';

const PartnersTransaction = () => {
  const [open, setOpen] = useState(false);
  const [addProductData, setAddProductData] = useState([]);
  const [alerts, setAlerts] = useState(false);
  const [alertsMess, setAlertsMess] = useState("");

  const eighteenYearsAgo = moment().subtract(18, "years").format("DD/MM/YYYY");
  const eighteenYearsAgo2 = moment().subtract(18, "years").format("YYYY-MM-DD");

  const valiDate = () => {
    const errors = [];
    let count = 1;
    for (let v of addProductData) {
      if (!v?.TRANSACTION_DATE) {
        errors.push(`Please select Transaction Date in Row ${count}`);
      }
      if (!v?.PARTNER_NAME) {
        errors.push(`Please select Partner Name in Row ${count}`);
      }
      if (!v?.TRANSACTION_TYPE) {
        errors.push(`Please select Transaction Type in Row ${count}`);
      }
      if (!v?.TRANSACTION_AMOUNT) {
        errors.push(`Please enter Transaction Amount in Row ${count}`);
      }
      if (!v?.BANK) {
        errors.push(`Please enter Bank in Row ${count}`);
      }
      if (!v?.NARRATION) {
        errors.push(`Please enter Narration in Row ${count}`);
      }
      count++;
    }
    return errors;
  };

  const handleAddProduct = () => {
    const defaultProduct = {
      id: addProductData.length + 1,
      TRANSACTION_DATE: "",
      PARTNER_NAME: "",
      TRANSACTION_TYPE: "",
      TRANSACTION_AMOUNT: "",
      BANK: "",
      NARRATION: "",
      ACTIONS: "",
    };

    if (!addProductData.length || valiDate().length === 0) {
      setAddProductData((prevData) => [...prevData, defaultProduct]);
    } else {
      // setAddProductData((prevData) => [...prevData, defaultProduct]);
      const validationErrors = valiDate();
      setAlerts(true);
      setAlertsMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {validationErrors.map((item, i) => (
            <p style={{ textAlign: "left" }} key={i}>
              {item}
            </p>
          ))}
        </div>
      );
    }
  };

  const handleSaveProduct = async () => {
    const validationErrors = valiDate();
    setAlerts(true);
    setAlertsMess(
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {validationErrors.map((item, i) => (
          <p style={{ textAlign: "left" }} key={i}>
            {item}
          </p>
        ))}
      </div>
    );
  };

  const handleAddDelete = (deleteId) => {
    const filterData = addProductData.filter((item) => item.id !== deleteId);
    setAddProductData(filterData);
  };
  return (
    <div className="container">
      <PageHeading
        title={"PARTNERS TRANSACTIONS"}
        right={
          <>
            <AddButton
              onClick={() => {
                setOpen(true);
                handleAddProduct();
              }}
            />
            <SaveButton title={"SAVE"} onClick={handleSaveProduct} />
          </>
        }
      />
      {open && (
        <>
          <Table
            mt={30}
            headData={[
              "TRANSACTION DATE",
              "PARTNER NAME",
              "TRANSACTION TYPE",
              "TRANSACTION AMOUNT",
              "BANK",
              "NARRATION",
              "ACTIONS",
            ]}
            body={
              <>
                {addProductData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Input
                        type={"date"}
                        max={eighteenYearsAgo2}
                        onChange={(event) => {
                          if (event.target.value.length <= 10) {
                            const numericValue = event.target.value;
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                TRANSACTION_DATE: numericValue,
                              };
                              return updatedData;
                            });
                          }
                        }}
                        onBlur={() => {
                          const lastProduct =
                            addProductData[addProductData.length - 1];
                          if (lastProduct?.dob.length == 10) {
                            if (
                              DateValidator(
                                moment(lastProduct?.dob).format("DD/MM/YYYY"),
                                eighteenYearsAgo,
                                "01/01/1970",
                                "DATE OF BIRTH",
                                `Please enter valid data for DATE OF BIRTH field (before ${eighteenYearsAgo} `
                              ) != true
                            ) {
                              setAlertsMess(
                                ...DateValidator(
                                  moment(lastProduct?.dob).format("DD/MM/YYYY"),
                                  eighteenYearsAgo,
                                  "01/01/1970",
                                  "DATE OF BIRTH",
                                  `Please enter valid data for DATE OF BIRTH field (before ${eighteenYearsAgo} `
                                )
                              );
                              setAlerts(true);
                              setAddProductData((prevData) => {
                                const updatedData = [...prevData];
                                updatedData[index] = {
                                  ...updatedData[index],
                                  dob: "",
                                };
                                return updatedData;
                              });
                            }
                          }
                        }}
                        value={item.dob}
                      />
                    </td>
                    <td>
                      <Dropdown
                        value={item.PARTNER_NAME}
                        onChange={(e) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              PARTNER_NAME: e.target.value,
                            };
                            return updatedData;
                          });
                        }}
                        options={
                          <>
                            <option hidden value="-1">
                              SELECT
                            </option>
                            <option value="0">Abhishek</option>
                            <option value="1">Naveen sir</option>
                          </>
                        }
                      />
                    </td>
                    <td>
                      <Dropdown
                        value={item.TRANSACTION_TYPE}
                        onChange={(e) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              TRANSACTION_TYPE: e.target.value,
                            };
                            return updatedData;
                          });
                        }}
                        options={
                          <>
                            <option hidden value="-1">
                              SELECT
                            </option>
                            <option value="0">Abhishek</option>
                            <option value="1">Naveen sir</option>
                          </>
                        }
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"TRANSACTION AMOUNT"}
                        value={item.PAN}
                        onChange={(event) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              PAN: event.target.value,
                            };
                            return updatedData;
                          });
                        }
                        }
                      />
                    </td>
                    <td>
                      <Dropdown
                        value={item.BANK}
                        onChange={(e) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              BANK: e.target.value,
                            };
                            return updatedData;
                          });
                        }}
                        options={
                          <>
                            <option hidden value="-1">
                              SELECT
                            </option>
                            <option value="0">Abhishek</option>
                            <option value="1">Naveen sir</option>
                          </>
                        }
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"TRANSACTION TYPE"}
                        value={item.PERC_OF_SHARE}
                        onChange={(event) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              PERC_OF_SHARE: event.target.value,
                            };
                            return updatedData;
                          });
                        }
                        }
                      />
                    </td>
                    <td>
                      <DeleteIcon onClick={() => handleAddDelete(item.id)} />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </>
      )}
      <div style={{ height: "10px" }}></div>
      <div>
        <Table
          headData={[
            " TRANSACTION DATE",
            "PARTNER NAME",
            " TRANSACTION TYPE",
            "TRANSACTION AMOUNT",
            "BANK", "NARRATION",
            "ACTIONS",
          ]}
          body={
            <>
              <tr>
                <td>cust yes</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>53500</td>
                <td>53500</td>
                <td>53500</td>
                <td><DeleteIcon onClick={() => handleAddDelete(0)} /></td>
              </tr>
            </>
          }
        />
      </div>
      {alerts && (
        <SmallModal
          isOpen={alerts}
          setIsOpen={setAlerts}
          title={"alert"}
          mess={alertsMess}
          okbtn={setAlertsMess}
        />
      )}
    </div>
  );
}

export default PartnersTransaction