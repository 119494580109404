import React, { useEffect } from "react";
import { CgMenuLeftAlt } from "react-icons/cg";
import "./header.css";
import store from "../../redux/store";
import { setSidebarWidth } from "../../redux/slice/actionSlice";
import { useSelector } from "react-redux";
import { IoNotificationsOutline, IoNotificationsSharp } from "react-icons/io5";
import MenuBar from "../menu/MenuBar";
import Notification from "../notification";
import { useLocation, useNavigate } from "react-router-dom";
import {
  get_effective_date,
  get_omc,
  get_omc_name,
  get_today_date,
  get_user_id,
} from "../../redux/slice/SlideSlice";
import {
  get_arb_list,
  get_areacode_master_list,
  get_bank_master_list,
  get_customer_vender_list,
  get_equipment_list,
  get_refill_price_list,
  get_staff_list,
  get_vehical_list,
} from "../../screens/MasterData/masterdataSlice";
import {
  get_customer_purchase_order_list,
  get_transition_complete_list,
} from "../../screens/transactions/trasactionsSlice";
import {
  get_home_data,
  get_profile_data,
  get_profile_img_url,
  get_profile_name,
  get_profile_pin,
} from "../../screens/profile/UsersSlice";

const data = [
  {
    title: "CP",
    desc: "CYLINDERS PURCHASE INVOICE ENTRY",
    nav: "/transactions/Cylinder-Purchases",
  },
  {
    title: "OP",
    desc: "EXPENDITURE / OTHER PURCHASES INVOICE ENTRY",
    nav: "/transactions/ExpenditureOtherPurchase",
    nav2: "/transactions/OtherPurchase",
  },
  {
    title: "DS",
    desc: "DOMESTIC CYLINDER SALES",
    nav: "/transactions/DomestiCylSale",
  },
  {
    title: "CS",
    desc: "COMMERCIAL CYLINDER SALES",
    nav: "/transactions/CommercialCylSale",
  },
  {
    title: "DC",
    desc: "DELIVERY CHALLAN",
    nav: "/transactions/DeliveryChallan",
  },
  {
    title: "NC",
    desc: "NC / DBC DATA",
    nav: "/transactions/ncdbctrans",
  },
  {
    title: "RC",
    desc: "ITV / RC",
    nav: "/transactions/itvrctrans",
  },
  {
    title: "TV",
    desc: "OTV / TTV /TV",
    nav: "/transactions/otvttvtvtrans",
  },
  {
    title: "RT",
    desc: "RECEIPTS",
    nav: "/Cash&Bank/Receipts",
  },
  {
    title: "PT",
    desc: "PAYMENTS",
    nav: "/Cash&Bank/Payments",
  },
  {
    title: "BT",
    desc: "BANK TRANSACTIONS",
    nav: "/Cash&Bank/BankTransactions",
  },
  {
    title: "CVO",
    desc: "CUSTOMER / VENDOR MASTER",
    nav: "/MasterData/custVendMaster",
  },
];

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { smallSidebarWidth } = useSelector((state) => state.actions);
  const navigate = useNavigate();
  const { user_id, today } = useSelector((state) => state.other);
  
  const { alert_count } = useSelector((state) => state.profile);

  useEffect(() => {
    store.dispatch(get_omc_name());
    store.dispatch(get_user_id());
    store.dispatch(get_omc());
    store.dispatch(get_today_date());
    store.dispatch(get_profile_img_url());
    store.dispatch(get_profile_name());
    store.dispatch(get_profile_pin());
  }, []);
  useEffect(() => {
    if (user_id) {
      store.dispatch(get_profile_data(user_id));
      store.dispatch(get_home_data(user_id));
    }
  }, [user_id]);

  return (
    <div className="header-main">
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <CgMenuLeftAlt
          size={30}
          className="cursor"
          color="#9e72ff"
          onClick={() => {
            store.dispatch(setSidebarWidth(!smallSidebarWidth));
          }}
        />
        <div className="nav-btn">
          {data?.map((v, i) => (
            <div
              className={`nav-btn-1 ${
                v?.nav == currentPath ? "nav-btn-1-focus" : ""
              }`}
              style={{
                backgroundColor:
                  v?.title == "OP" && currentPath == v?.nav2 ? "#007bff" : "",
                color:
                  v?.title == "OP" && currentPath == v?.nav2 ? "white" : "",
              }}
              key={i}
              onClick={() => navigate(v?.nav)}
            >
              {v?.title}
            </div>
          ))}
        </div>
      </div>
      <div className="header-right">
        <div style={{ position: "relative" }}>
          <Notification />
          {alert_count > 0 && (
            <div
              style={{
                position: "absolute",
                height: "20px",
                width: "20px",
                fontSize:"12px",
                backgroundColor: "#007bff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                color:"#fff",
                top:"-10px",right:"-8px"
              }}
            >
              <span>{alert_count}</span>
            </div>
          )}
        </div>
        <div>
          <MenuBar />
        </div>
      </div>
    </div>
  );
};

export default Header;
