import React, { useEffect, useRef, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Button from "../../../components/btn/Button";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { motion } from "framer-motion";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import { useSelector } from "react-redux";
import PageLoading from "../../../components/PageLoading";
import store from "../../../redux/store";
import {
  get_bank_master_list,
  get_equipment_list,
  get_staff_list,
  get_vehical_list,
} from "../../MasterData/masterdataSlice";
import { DeleteIcon, Radio } from "../../../utils/utils";
import {
  get_blpg_arb_sales_list,
  get_commercial_sales_list,
  get_delivery_challan_list,
  get_domestic_sales_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import moment from "moment";
import Table from "../../../components/table/Table";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { delivery_challan_api } from "../../../utils/apis";
import { toast } from "react-toastify";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
  AreaCodeList,
  CVOList,
  EquipmentList,
  PriceList,
  StaffList,
} from "../../OtherComponent/StaticLists/staticLists";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import NoData from "../../../components/NoData";
import { DropInput } from "../../../components/inputs/DropInput";
import {
  DateValidator2,
  gst_calculate,
  UnitFunction,
  UnitFunctionAsPerTitile,
} from "../../../utils/validation";
import { MobileValidation } from "../../../utils/validator";
import { TaxInvoice } from "../../OtherComponent/TaxInvoice/TaxInvoice";
import ReactToPrint from "react-to-print";
import useUniqueEquipmentList from "../../../utils/Hook/useUniqueEquipmentList";
import { Alerts } from "../../../components/Alerts/Alerts";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
import { UOMData } from "../../../utils/data";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import { EmptyDataHook } from "../../../utils/Hook/EmptyDataHook";
import TaxInvoice5 from "../../OtherComponent/TaxInvoice/TaxInvoice5";
import TaxInvoice3 from "../../OtherComponent/TaxInvoice/TaxInvoice3";
const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  // { title:"Disc.% Cash",cols:false},
  { title: "Discount", cols: false },
  // { title: "Transport Charge" ,cols:false},
  { title: "Amount", cols: false },
];
export const DeliveryChallan = () => {
  const previewRef = useRef(null);
  const [EquipClick, setEquipClick] = useState(false);
  const [PriceClick, setPriceClick] = useState(false);
  const [StaffClick, setStaffClick] = useState(false);
  const [AreaClick, setAreaClick] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cvoClick, setCvoClick] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const { day_end_date } = useSelector((state) => state.profile);
  const { godown_address_list } = useSelector((state) => state.profile);

  console.log("viewItem ===", viewItem);

  const navigate = useNavigate();
  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };
  const { eff_date, user_id, today } = useSelector((state) => state.other);
  const { equipment_list, vehical_list, staff_list } = useSelector(
    (state) => state.masterdata
  );
  const uniqueEquipmentList = useUniqueEquipmentList(equipment_list);
  const {
    delivery_challan_list,
    delivery_challan_loading,
    transition_complete_list,
    commercial_sales_list,
    domestic_sales_list,
    blpg_sales_list,
  } = useSelector((state) => state.transactions);
  useEffect(() => {
    store.dispatch(get_equipment_list());
    store.dispatch(get_staff_list());
    store.dispatch(get_vehical_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_commercial_sales_list());
    store.dispatch(get_domestic_sales_list());
    store.dispatch(get_blpg_arb_sales_list());
    store.dispatch(get_bank_master_list());
  }, []);

  const fetchData = () => {
    store.dispatch(get_delivery_challan_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const delete_item = () => {
    try {
      setOpen(false);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", id);
      delivery_challan_api(body, setLoading).then((v) => {
        if (v.success) {
          if (delivery_challan_list?.length < 2) {
            setPageNo(pageNo - 1);
            store.dispatch(
              get_delivery_challan_list({
                page: pageNo - 1,
                limit: entries,
                setTotalCount: setTotalCount,
              })
            );
          } else {
            store.dispatch(get_delivery_challan_list(obj));
          }
          setOpen(false);
          toast(v.message);
        } else {
          setOpen1(true);
          setMess(v?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validation_check = () => {
    if (addDataHead?.dc_type == "2") {
      if (
        addData.find(
          (v) =>
            !v?.product_code ||
            (v?.product_code && v?.product_code?.length == 0) ||
            !v?.ship_quantity ||
            (v?.ship_quantity && v?.ship_quantity?.length == 0) ||
            v?.ship_quantity == 0
        )
      ) {
        return true;
      }
      return false;
    } else if (addDataHead?.dc_type == "1") {
      if (
        addData.find(
          (v) =>
            !v?.product_code ||
            (v?.product_code && v?.product_code?.length == 0) ||
            !v?.ship_quantity ||
            (v?.ship_quantity && v?.ship_quantity?.length == 0) ||
            v?.ship_quantity == 0
        )
      ) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  const valiDate = () => {
    const data = [];
    const data1 = DateValidator2(
      moment(addDataHead?.delivery_challan_date).format("DD/MM/YYYY"),
      today,
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      `Delivery Challan Date cant be before ${moment(
        addDataHead?.inv_date
      ).format("DD-MMM-YYYY")} `,
      `Delivery Challan Date cant be a future date`
    );
    // const data2 = DateValidator2(
    //   moment(addDataHead?.delivery_challan_date).format("DD/MM/YYYY"),
    //   moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
    //   today,
    //   `Delivery Challan Date cant be before ${day_end_date} `,
    //   `Delivery Challan Date cant be a future date`
    // );
    if (
      !addDataHead?.delivery_challan_date ||
      (addDataHead?.delivery_challan_date &&
        addDataHead?.delivery_challan_date?.length === 0)
    ) {
      data.push("Please Enter Delivery Challan Date");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name?.length === 0)
    ) {
      data.push("Please Select COUSTOMER NAME");
    }
    if (
      !addDataHead?.staff_name ||
      (addDataHead?.staff_name && addDataHead?.staff_name?.length === 0)
    ) {
      data.push("Please Select STAFF NAME");
    }
    if (
      !addDataHead?.vahicle_number ||
      (addDataHead?.vahicle_number &&
        addDataHead?.vahicle_number?.trim()?.length === 0)
    ) {
      data.push("Please Select VEHICLE NUMBER");
    }
    if (
      !addDataHead?.driver_name ||
      (addDataHead?.driver_name &&
        addDataHead?.driver_name?.trim()?.length === 0)
    ) {
      data.push("Please Enter Driver Name");
    }
    if (
      addDataHead?.contact &&
      (addDataHead?.contact?.length != 10 ||
        !MobileValidation.test(addDataHead?.contact))
    ) {
      data.push("Please Enter Valid Mobile Number");
    }
    if (
      addDataHead?.dc_type == "2" &&
      (!addDataHead?.ref_no ||
        (addDataHead?.ref_no && addDataHead?.ref_no?.trim()?.length === 0))
    ) {
      data.push("Please Enter INVOICE NUMBER");
    }
    if (
      !addDataHead?.delivery_mode ||
      (addDataHead?.delivery_mode &&
        addDataHead?.delivery_mode?.trim()?.length === 0)
    ) {
      data.push("Please Enter DELIVERY MODE");
    }
    if (
      !addDataHead?.delivery_instruction ||
      (addDataHead?.delivery_instruction &&
        addDataHead?.delivery_instruction?.trim()?.length === 0)
    ) {
      data.push("Please Enter DELIVERY INSTRUCTION");
    }
    if (
      godown_address_list?.length > 1 &&
      (!addDataHead?.godown_id ||
        (addDataHead?.godown_id && addDataHead?.godown_id?.length === 0))
    ) {
      data.push("Please Select Godown Address");
    }
    if (
      addDataHead?.dc_type == "2" &&
      (!addDataHead?.delivery_type ||
        (addDataHead?.delivery_type &&
          addDataHead?.delivery_type?.length === 0))
    ) {
      data.push("Please Select Delivery Type");
    }

    for (let v of addData) {
      if (
        !v?.product_code ||
        (v?.product_code && v?.product_code?.length === 0)
      ) {
        data.push("Please Select PRODUCT");
      }

      if (
        !v?.ship_quantity ||
        (v?.ship_quantity && v?.ship_quantity?.length === 0)
      ) {
        data.push("Please Enter Shipping Quantity");
      } else if (v?.ship_quantity == 0) {
        data.push("Please Enter Valid Shipping Quantity");
      }

      if (addDataHead?.dc_type == "2") {
        // if (
        //   !v?.truck_number ||
        //   (v?.truck_number && v?.truck_number?.length === 0)
        // ) {
        //   data.push("Please Select Vehicle Number");
        // }
        // if (
        //   v?.contact &&
        //   (v?.contact?.length != 10 || !MobileValidation.test(v?.contact))
        // ) {
        //   data.push("Please Enter Valid Mobile Number");
        // }
        if (addDataHead?.delivery_type == "1") {
          if (Number(v?.ship_quantity) >= Number(v?.quantity)) {
            data.push(
              `${
                addDataHead?.delivery_type == "1"
                  ? "If you select Partial Delivery, Then"
                  : ""
              } Shipping Quantity cant be Greaterthen or Equal to Billed Quantity `
            );
          }
        }

        // if (!v?.narration || (v?.narration && v?.narration?.length === 0)) {
        //   data.push("Please Enter Narration");
        // }
        // if (!v?.amount || (v?.amount && v?.amount?.length === 0)) {
        //   data.push("Please Click on Calculate Button");
        // }
        // if (!v?.amount || (v?.amount && v?.amount?.length === 0)) {
        //   data.push("Please Click on Calculate Button");
        // }
      }
    }

    // if (
    //   addDataHead?.dc_type == "2" &&
    //   (!addDataHead?.total_amount ||
    //     (addDataHead?.total_amount && addDataHead?.total_amount?.length === 0))
    // ) {
    //   data.push("Please Click on Calculate Button");
    // }
    return data;
  };

  const add_items = async () => {
    const data = valiDate();
    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        // po_no,po_date,driver_name,driver_contact,vehicle_no,uom[],hsn[]
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("dc_type", addDataHead?.dc_type);
        body.append("dc_Date", addDataHead?.delivery_challan_date);
        body.append("customer_id", addDataHead?.customer_name);
        body.append(
          "inv_no",
          addDataHead?.dc_type == "2" ? addDataHead?.ref_no : ""
        );
        body.append("po_no", addDataHead?.po_num);
        body.append("po_date", addDataHead?.po_date);
        body.append("driver_name", addDataHead?.driver_name);
        body.append("driver_contact", addDataHead?.contact);
        // body.append(
        //   "dc_amt",
        //   addDataHead?.dc_type == "2" ? addDataHead?.total_amount : ""
        // );
        body.append("staff_id", addDataHead?.staff_name);
        body.append("fleet_id", addDataHead?.vahicle_number);
        body.append("vehicle_no", addDataHead?.vahicle_number);

        body.append("del_mode", addDataHead?.delivery_mode);
        body.append("del_instruction", addDataHead?.delivery_instruction);
        body.append(
          "godown_id",
          addDataHead?.godown_id || godown_address_list?.[0]?.ID || 0
        );
        body.append("shipp_status", addDataHead?.delivery_type || "");

        addData.forEach((item) => {
          if (item) {
            body.append("pcode[]", item?.product_code);
            body.append("uom[]", item?.uom_name);
            body.append("uom_id[]", item?.uom_code);
            body.append("hsn[]", item?.hsn_code);
            body.append("shipp_qty[]", item?.ship_quantity || "");
            if (addDataHead?.dc_type == "2") {
              body.append("bill_qty[]", item?.quantity || "");
            } else {
              body.append("quantity[]", item?.quantity || "");
            }
          }
        });

        const response = await delivery_challan_api(body);
        setLoading(false);
        if (response.success) {
          setAddDataHead({});
          setAddData([]);
          setIsAdd(false);
          store.dispatch(get_delivery_challan_list(obj));
          toast.success(response?.message);
        } else if (!response?.success) {
          setMess(response?.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const calculate = () => {
    if (!addDataHead?.ref_no) {
      setMess("Please select Invoice Number");
      setOpen1(true);
      return;
    }
    if (!addDataHead?.customer_name) {
      setMess("Please select Customer Name");
      setOpen1(true);
      return;
    }
    if (
      addData.find(
        (v) =>
          !v?.ship_quantity ||
          (v?.ship_quantity && v?.ship_quantity.length == 0) ||
          v?.ship_quantity == 0
      )
    ) {
      setMess(`Please Enter SHIPPING QUANTITY.`);
      setOpen1(true);
      return;
    }

    var total = 0;
    addData.forEach((data, index) => {
      const unit_rate = Number(data?.unit_rate);
      const quantity = Number(data?.ship_quantity);
      const p_gst = Number(data?.gstp);
      const discount = Number(data?.discount);
      const tr_charges = Number(data?.tr_charges);
      const tax_amount = (unit_rate - discount) * quantity + tr_charges;
      const gst_amount = tax_amount * (p_gst / 100);
      const amount = tax_amount + gst_amount;

      setAddData((prevData) => {
        return prevData.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              amount: amount.toFixed(2),
              igst:
                addDataHead?.c_type == "isp"
                  ? Number(gst_amount).toFixed(2)
                  : 0,
              sgst:
                addDataHead?.c_type != "isp"
                  ? Number(gst_amount / 2).toFixed(2)
                  : 0,
              cgst:
                addDataHead?.c_type != "isp"
                  ? Number(gst_amount / 2).toFixed(2)
                  : 0,
            };
          }
          return item;
        });
      });
      total = total + amount;
    });
    setAddDataHead({ ...addDataHead, total_amount: Number(total).toFixed(2) });
  };

  return (
    <div className="container">
      <PageHeading
        title={"DELIVERY CHALLAN"}
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData((prevData) => [...prevData, {}]);
                setAddDataHead({
                  dc_type: "2",
                  delivery_type: "2",
                  delivery_mode: "ROAD",
                });
                setIsAdd(true);
              }}
            />
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/oEzSuwSIkRg",
                "https://youtu.be/rrX-pqA7h68",
              ]}
            />
          </>
        }
      />
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {delivery_challan_loading ? (
          <PageLoading />
        ) : delivery_challan_list?.length == 0 ? (
          <NoData />
        ) : (
          <>
            <div className="grid">
              {delivery_challan_list?.map((v, i) => (
                <div className="card" key={i}>
                  <div>
                    <div className="card-heading">{v?.CVO_NAME}</div>
                    <RowTxt
                      title={"DC No."}
                      value={
                        <ReactToPrint
                          trigger={() => (
                            <div
                              onMouseEnter={() => {
                                setViewItem(v);
                              }}
                              onTouchStart={() => {
                                setViewItem(v);
                              }}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setViewItem(v);
                              }}
                            >
                              {v?.SR_NO}
                            </div>
                          )}
                          content={() => {
                            if (v?.ID === viewItem?.ID) {
                              return previewRef.current;
                            } else {
                              setViewItem(v);
                              return null;
                            }
                          }}
                        />
                      }
                    />
                    <RowTxt
                      title={"DC Date"}
                      value={moment(v?.DC_DATE).format("DD-MMM-YYYY")}
                    />
                    <RowTxt
                      title={"DC Type."}
                      value={
                        v?.DC_TYPE == "2"
                          ? "After Invoice"
                          : v?.DC_TYPE == "1"
                          ? "Before Invoice "
                          : "" || "NA"
                      }
                    />

                    {v?.DC_TYPE == "2" && (
                      <>
                        {/* <RowTxt title={"DC Value"} value={v?.DC_AMOUNT} /> */}
                        <RowTxt title={"Ref Invoice No."} value={v?.INV_NO} />
                        <RowTxt
                          title={"Ref Invoice Date"}
                          value={moment(v?.DC_REF_DATE).format("DD-MMM-YYYY")}
                        />
                        {v?.PO_NO && (
                          <>
                            <RowTxt title={"PO No."} value={v?.PO_NO} />
                            <RowTxt
                              title={"PO Date"}
                              value={moment(v?.PO_DATE).format("DD-MMM-YYYY")}
                            />
                          </>
                        )}
                      </>
                    )}

                    {/* <RowTxt
                      title={" Delivery Instructions"}
                      value={v?.DELIVERY_INSTRUCTIONS}
                    /> */}
                    {/* <RowTxt title={"Delivery Mode"} value={v?.DELIVERY_MODE} /> */}
                    {/* <RowTxt title={"Employee"} value={v?.EMP_NAME} /> */}
                    {/* <RowTxt title={"Godown Address"} value={v?.GODOWN_NAME} /> */}
                    <RowTxt title={"Vehicle No."} value={v?.VEHICLE_NO} />
                    <RowTxt title={"Driver Name"} value={v?.DRIVER_NAME} />
                    <RowTxt
                      title={"Driver Contact"}
                      value={v?.DRIVER_CONTACT}
                    />
                  </div>
                  <div className="df jcsb mt10">
                    <DeleteIcon
                      onClick={() => {
                        setId(v?.ID);
                        setOpen(true);
                      }}
                    />
                    {v?.DC_TYPE == "1" && (
                      <Button
                        title={"GEN INV"}
                        onClick={() => setOpen2(true)}
                      />
                    )}
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 1.05 }}
                      className="flex aic gap10 jce cursor-pointer"
                    >
                      <div
                        className="view-btn"
                        onClick={() => {
                          setOpenView(true);
                          setViewItem(v);
                        }}
                      >
                        View
                      </div>
                      <HiOutlineArrowRight
                        size={20}
                        color="#1b64af"
                        onClick={() => {
                          setOpenView(true);
                          setViewItem(v);
                        }}
                      />
                    </motion.div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Pagination3>
      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData([]);
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button
                title={"CVO"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"EQUIPMENT"}
                back
                onClick={() => setEquipClick(!EquipClick)}
              />
              <Button
                title={"PRICE"}
                back
                onClick={() => setPriceClick(!PriceClick)}
              />
              <Button
                title={"BANK"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"STAFF"}
                back
                onClick={() => setStaffClick(!StaffClick)}
              />
              <Button
                title={"AREACODE"}
                back
                onClick={() => setAreaClick(!AreaClick)}
              />
            </div>
          }
          title={"DELIVERY CHALLAN"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Dropdown
                  width2=""
                  label={"Type of DC"}
                  value={addDataHead?.dc_type}
                  onChange={(e) => {
                    setAddDataHead({
                      dc_type: e.target.value,
                      dis: false,
                      customer_name: "",
                      ref_no: "",
                      delivery_challan_date: "",
                      staff_name: "",
                      vahicle_number: "",
                      delivery_mode: "ROAD",
                      delivery_instruction: "",
                      shipping_status: "",
                      delivery_type: e.target.value == "2" ? "2" : "",
                      driver_name: "",
                      contact: "",
                      vahicle_number: "",
                      po_date: "",
                      po_num: "",
                      godown_id: "",
                    });
                    // setAddData([
                    //   {
                    //     product_code: "",
                    //     ship_quantity: "",
                    //     hsn_code: "",
                    //     uom_code: "",
                    //     uom_name: "",
                    //   },
                    // ]);
                    const resetData1 = EmptyDataHook(addData?.[0]);
                    setAddData([resetData1]);
                  }}
                  options={
                    <>
                      <option value={"1"}>BEFORE INVOICE</option>
                      <option value={"2"}>AFTER INVOICE</option>
                    </>
                  }
                />
                <Input
                  label={"DELIVERY CHALLAN DATE"}
                  type={"date"}
                  value={addDataHead?.delivery_challan_date}
                  onChange={(e) => {
                    if (e.target.value?.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        delivery_challan_date: e.target.value,
                      });
                    }
                  }}
                />

                <Dropdown
                  width2=""
                  label={"CUSTOMER NAME"}
                  // disabled={addDataHead?.dis}
                  value={addDataHead?.customer_name}
                  onChange={(e) => {
                    const data = transition_complete_list?.Customer?.filter(
                      (val) => val?.ID == e.target.value
                    );
                    setAddDataHead({
                      ...addDataHead,
                      customer_name: e.target.value,
                      c_type: data?.length > 0 ? data[0]?.connection_type : "",
                      ref_no: "",
                      po_num: "",
                      po_date: "",
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {transition_complete_list?.Customer?.filter(
                        (v) => v?.cvo_name != "UJWALA"
                      ).map((v, i) => (
                        <option key={i} value={v?.ID}>
                          {v?.cvo_name}
                        </option>
                      ))}
                    </>
                  }
                />

                {addDataHead?.dc_type == "2" && (
                  <Dropdown
                    value={addDataHead?.ref_no}
                    width2=""
                    label={"INVOICE NUMBER"}
                    onChange={(e) => {
                      const data = [
                        ...domestic_sales_list,
                        ...commercial_sales_list,
                        ...blpg_sales_list,
                      ]?.filter((v) => v?.SR_NO == e.target.value)?.[0];

                      setAddDataHead({
                        ...addDataHead,
                        ref_no: e.target.value,
                        inv_date: data
                          ? data?.SI_DATE || data?.INVOICE_DATE
                          : "",
                        godown_id: data?.DISPATCH_GODOWN
                          ? data?.DISPATCH_GODOWN
                          : "",
                        godown_id_dis: data?.DISPATCH_GODOWN ? true : false,
                        po_num: data?.PO_NO ? data?.PO_NO : "",
                        po_date: data?.PO_NO
                          ? moment(data?.PO_DATE || new Date()).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                      });
                      setAddData([
                        {
                          product_code: "",
                          ship_quantity: "",
                          hsn_code: "",
                          uom_code: "",
                          uom_name: "",
                        },
                      ]);
                    }}
                    options={
                      <>
                        <option value={"0"}>SELECT</option>
                        {addDataHead?.customer_name
                          ? [
                              ...domestic_sales_list,
                              ...commercial_sales_list,
                              ...blpg_sales_list,
                            ]
                              // blpg_sales_list
                              ?.filter(
                                (v) =>
                                  v?.CUSTOMER_ID == addDataHead?.customer_name
                              )
                              ?.map((v, i) => (
                                <option key={i} value={v?.SR_NO}>
                                  {/* {v?.cvo_name || v?.CUSTOMER} - */}
                                  {v?.SR_NO}
                                </option>
                              ))
                          : ""}
                        {/* {blpg_sales_list
                          ?.filter(
                            (v) => v?.CUSTOMER_ID == addDataHead?.customer_name
                          )
                          ?.map((v, i) => (
                            <option key={i} value={v?.SR_NO}>
                              {v?.cvo_name} - {v?.SR_NO}
                            </option>
                          ))} */}
                      </>
                    }
                  />
                )}

                {addDataHead?.po_num && (
                  <>
                    <Input
                      label={"PO NUMBER"}
                      disabled={true}
                      value={addDataHead?.po_num}
                    />
                    <Input
                      label={"PO DATE"}
                      type={"date"}
                      disabled={true}
                      value={addDataHead?.po_date}
                    />
                  </>
                )}
                <Dropdown
                  width2=""
                  value={addDataHead?.staff_name}
                  label={"STAFF NAME"}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      staff_name: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {staff_list?.map((v, i) => (
                        <option key={i} value={v?.ID}>
                          {v?.EMP_NAME}
                        </option>
                      ))}
                    </>
                  }
                />
                <DropInput
                  id={"truck"}
                  label={"VEHICLE NUMBER"}
                  placeholder={"VEHICLE NUMBER"}
                  value={addDataHead?.vahicle_number}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      vahicle_number: e.target.value,
                    });
                  }}
                  list={vehical_list?.map((v, i) => (
                    <option key={i} value={v?.VEHICLE_NO}>
                      {v?.VEHICLE_NO}
                    </option>
                  ))}
                />

                <Input
                  value={addDataHead?.driver_name}
                  label={"DRIVER NAME"}
                  placeholder={"Driver Name"}
                  onChange={(e) => {
                    const input = e.target.value;
                    setAddDataHead({
                      ...addDataHead,
                      driver_name: input,
                    });
                  }}
                />
                <Input
                  label={"CONTACT NO"}
                  placeholder={"CONTACT NO"}
                  value={addDataHead?.contact}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (
                      (/^\d+$/.test(input) || input === "") &&
                      input?.length <= 10
                    ) {
                      setAddDataHead({
                        ...addDataHead,
                        contact: input,
                      });
                    }
                  }}
                />
                <Input
                  label={"DELIVERY MODE"}
                  placeholder={"DELIVERY MODE"}
                  disabled={true}
                  value={addDataHead?.delivery_mode}
                />
                <Input
                  label={"DELIVERY INSTRUCTIONS"}
                  placeholder={"DELIVERY INSTRUCTIONS"}
                  value={addDataHead?.delivery_instruction}
                  onChange={(e) =>
                    setAddDataHead({
                      ...addDataHead,
                      delivery_instruction: e.target.value,
                    })
                  }
                />
                {/* <Dropdown
                  value={addDataHead?.shipping_status}
                  width2=""
                  label={"SHIPPING STATUS"}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      shipping_status: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"0"}>Not-Delivered</option>
                      <option value={"1"}>Partial Delivered</option>
                      <option value={"2"}>Full Delivered</option>
                    </>
                  }
                /> */}
                {godown_address_list?.length > 1 && (
                  <Dropdown
                    width2=""
                    disabled={addDataHead?.godown_id_dis}
                    label={"DISPATCH GODOWN"}
                    value={addDataHead?.godown_id}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        godown_id: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        {godown_address_list?.map((s, i) => (
                          <option key={i} value={s?.ID}>
                            {s?.UNIT_NAME}
                          </option>
                        ))}
                      </>
                    }
                  />
                )}

                {/* {addDataHead?.dc_type == "2" && (
                  <Input
                    label={"DC VALUE"}
                    placeholder={"DELIVERY CHALLAN VALUE"}
                    value={addDataHead?.total_amount}
                    disabled={true}
                  />
                )} */}
              </div>
              {addDataHead?.dc_type == "2" && (
                <div
                  style={{ marginTop: "1.5rem" }}
                  className="flex aic gap10 mt10"
                >
                  <Radio
                    title={"PARTIAL DELIVERY"}
                    show={addDataHead?.delivery_type == "1" ? true : false}
                    setShow={() =>
                      setAddDataHead({ ...addDataHead, delivery_type: "1" })
                    }
                  />
                  <Radio
                    title={"COMPLETE DELIVERY"}
                    show={addDataHead?.delivery_type == "2" ? true : false}
                    setShow={() => {
                      setAddDataHead({ ...addDataHead, delivery_type: "2" });
                      addData?.forEach((item, index) => {
                        setAddData((prevData) =>
                          prevData.map((v, i) =>
                            i === index
                              ? {
                                  ...v,
                                  ship_quantity:
                                    item?.quantity == "NA"
                                      ? 0
                                      : item?.quantity || "",
                                  amount: "",
                                }
                              : v
                          )
                        );
                      });
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={
                    addDataHead?.dc_type == "2"
                      ? [
                          "PRODUCT",
                          "HSN CODE",
                          "UOM",
                          // "GST %	",
                          // "UNIT PRICE",
                          // "Vehicle No",
                          // "Driver Name",
                          // "Driver Contact",
                          "BILLED QUANTITY",
                          "Shipped Quantity",
                          // "TOTAL AMOUNT",
                          // "Narration",
                          "ACTIONS",
                        ]
                      : [
                          "PRODUCT",
                          "HSN CODE",
                          "UOM",
                          "Shipped Quantity",
                          "ACTIONS",
                        ]
                  }
                  body={
                    addDataHead?.dc_type != "" &&
                    addData?.map((val, ind) => (
                      <tr key={ind}>
                        <td>
                          {
                            <Dropdown
                              options={
                                <>
                                  <option value={""}>SELECT</option>
                                  {addDataHead?.dc_type == "2"
                                    ? [
                                        ...domestic_sales_list,
                                        ...commercial_sales_list,
                                        ...blpg_sales_list,
                                      ]
                                        ?.filter(
                                          (v) => v?.SR_NO == addDataHead?.ref_no
                                        )?.[0]
                                        ?.products?.map((v, i) =>
                                          v?.CAT_NAME == "DOMESTIC" ||
                                          v?.CAT_NAME == "COMMERCIAL" ? (
                                            <React.Fragment key={i}>
                                              <option
                                                disabled={
                                                  addData?.filter(
                                                    (d) =>
                                                      d?.product_code == v?.CODE
                                                  )?.length > 0
                                                }
                                                value={v?.CODE}
                                              >
                                                {v?.CAT_NAME}-{v?.CAT_DESC}
                                              </option>
                                              <option
                                                disabled={
                                                  addData?.filter(
                                                    (d) =>
                                                      d?.product_code ==
                                                      `${v?.CODE}e`
                                                  )?.length > 0
                                                }
                                                value={`${v?.CODE}e`}
                                              >
                                                {v?.CAT_NAME}-{v?.CAT_DESC}
                                                (Empties)
                                              </option>
                                            </React.Fragment>
                                          ) : v?.CAT_NAME == "REGULATOR" ? (
                                            <option
                                              disabled={
                                                addData?.filter(
                                                  (d) =>
                                                    d?.product_code == v?.CODE
                                                )?.length > 0
                                              }
                                              value={v?.CODE}
                                            >
                                              {v?.CAT_NAME}-{v?.CAT_DESC}
                                            </option>
                                          ) : (
                                            <option
                                              disabled={
                                                addData?.filter(
                                                  (d) =>
                                                    d?.product_code == v?.CODE
                                                )?.length > 0
                                              }
                                              value={v?.CODE}
                                            >
                                              {v?.CAT_DESC}-{v?.PROD_BRAND}-
                                              {v?.PROD_NAME}
                                            </option>
                                          )
                                        )
                                    : uniqueEquipmentList?.map((v, i) =>
                                        v?.CAT_NAME == "DOMESTIC" ||
                                        v?.CAT_NAME == "COMMERCIAL" ? (
                                          <React.Fragment key={i}>
                                            <option
                                              disabled={
                                                addData?.filter(
                                                  (d) =>
                                                    d?.product_code ==
                                                    v?.PROD_CODE
                                                )?.length > 0
                                              }
                                              value={v?.PROD_CODE}
                                            >
                                              {v?.CAT_NAME}-{v?.product_name}
                                            </option>
                                            <option
                                              disabled={
                                                addData?.filter(
                                                  (d) =>
                                                    d?.product_code ==
                                                    `${v?.PROD_CODE}e`
                                                )?.length > 0
                                              }
                                              value={`${v?.PROD_CODE}e`}
                                            >
                                              {v?.CAT_NAME}-{v?.product_name}
                                              (Empties)
                                            </option>
                                          </React.Fragment>
                                        ) : v?.CAT_NAME == "REGULATOR" ? (
                                          <option
                                            disabled={
                                              addData?.filter(
                                                (d) =>
                                                  d?.product_code == v?.CODE
                                              )?.length > 0
                                            }
                                            value={v?.PROD_CODE}
                                          >
                                            {v?.CAT_NAME}-{v?.product_name}
                                          </option>
                                        ) : (
                                          ""
                                        )
                                      )}
                                </>
                              }
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const ifEmpty = inputValue.includes("e");

                                const data = [
                                  ...domestic_sales_list,
                                  ...commercial_sales_list,
                                  ...blpg_sales_list,
                                ]
                                  ?.filter(
                                    (val) => val?.SR_NO == addDataHead?.ref_no
                                  )?.[0]
                                  ?.products?.filter(
                                    (v) => v?.CODE == inputValue
                                  )?.[0];
                                const cleanedValue = inputValue.replace(
                                  /e/g,
                                  ""
                                ); // Removes all 'e' characters

                                // const data1 = [
                                //   ...domestic_sales_list,
                                //   ...commercial_sales_list,
                                //   ...blpg_sales_list,
                                // ]
                                //   ?.filter(
                                //     (val) => val?.SR_NO == addDataHead?.ref_no
                                //   )?.[0]
                                //   ?.products?.filter(
                                //     (v) => v?.CODE == cleanedValue
                                //   )?.[0];
                                const data1 = uniqueEquipmentList?.filter(
                                  (v, i) => v?.PROD_CODE == cleanedValue
                                )?.[0];

                                setAddData((prevData) => {
                                  if (addDataHead?.dc_type === "2") {
                                    return prevData.map((v, i) =>
                                      i === ind
                                        ? {
                                            ...v,
                                            product_code: e.target.value,
                                            empty_prod: ifEmpty ? true : false,
                                            gstp: ifEmpty ? 0 : data?.GSTP,
                                            unit_rate: ifEmpty
                                              ? "0.00"
                                              : data?.UNIT_RATE,
                                            ship_quantity: ifEmpty
                                              ? 0
                                              : data?.QUANTITY,
                                            quantity:
                                              addDataHead?.dc_type == "2"
                                                ? data?.QUANTITY || "0"
                                                : ifEmpty
                                                ? "NA"
                                                : "0",
                                            amount:
                                              !ifEmpty &&
                                              addDataHead?.delivery_type ==
                                                "2" &&
                                              addDataHead?.dc_type == "2"
                                                ? data?.SALE_AMOUNT
                                                : ifEmpty
                                                ? "NA"
                                                : "",
                                            tr_charges:
                                              addDataHead?.dc_type == "2"
                                                ? data?.TRANSPORT_CHARGES || 0
                                                : ifEmpty
                                                ? "0"
                                                : "",
                                            discount:
                                              addDataHead?.dc_type == "2"
                                                ? data?.DISC_UNIT_RATE
                                                : ifEmpty
                                                ? "0"
                                                : "",
                                            uom_name: data
                                              ? UnitFunction(
                                                  data?.UOM || data?.UNITS,
                                                  "title"
                                                )
                                              : data1
                                              ? UnitFunction(
                                                  data1?.UNITS,
                                                  "title"
                                                )
                                              : "",
                                            uom_code: data
                                              ? UnitFunction(
                                                  data?.UOM || data?.UNITS,
                                                  "UOM"
                                                )
                                              : data1
                                              ? UnitFunction(
                                                  data1?.UNITS,
                                                  "UOM"
                                                )
                                              : "",
                                            hsn_code: data
                                              ? data?.HSNCODE || data?.CSTEH_NO
                                              : data1
                                              ? data1?.CSTEH_NO
                                              : "",
                                          }
                                        : { ...v }
                                    );
                                  } else if (addDataHead?.dc_type === "1") {
                                    return prevData.map((v, i) =>
                                      i === ind
                                        ? {
                                            ...v,
                                            product_code: e.target.value,
                                            uom_code: data1
                                              ? UnitFunction(
                                                  data1?.UNITS,
                                                  "UOM"
                                                )
                                              : "",
                                            uom_name: data1
                                              ? UnitFunction(
                                                  data1?.UNITS,
                                                  "title"
                                                )
                                              : "",
                                            hsn_code: data1
                                              ? data1?.CSTEH_NO
                                              : "",
                                          }
                                        : { ...v }
                                    );
                                  } else {
                                    return prevData;
                                  }
                                });
                              }}
                              value={val.product_code}
                            />
                          }
                        </td>
                        <td>
                          {
                            <Input
                              value={val?.hsn_code}
                              placeholder={"HSN CODE"}
                              disabled={true}
                            />
                          }
                        </td>
                        <td>
                          {
                            <Input
                              value={val?.uom_name}
                              placeholder={"UOM"}
                              disabled={true}
                            />
                          }
                        </td>
                        {addDataHead?.dc_type == "2" && (
                          <>
                            {/* <td>
                              {
                                <Input
                                  value={val?.gstp}
                                  placeholder={"GST %	"}
                                  disabled={true}
                                />
                              }
                            </td>
                            <td>
                              {
                                <Input
                                  value={val?.unit_rate}
                                  placeholder={"UNIT PRICE"}
                                  disabled={true}
                                />
                              }
                            </td>

                            <td>
                              <DropInput
                                placeholder={"TRUCK NUMBER"}
                                value={val?.truck_number}
                                id={"truck"}
                                onChange={(event) => {
                                  const input =
                                    event.target.value.toUpperCase();
                                  if (
                                    /^[A-Z]{0,2}\d{0,2}[A-Z]{0,2}\d{0,4}$/.test(
                                      input
                                    ) ||
                                    /^[0-9]{0,4}$/.test(input) ||
                                    input === ""
                                  ) {
                                    setAddData((pre) =>
                                      pre.map((v, i) =>
                                        i == ind
                                          ? { ...v, truck_number: input }
                                          : { ...v }
                                      )
                                    );
                                  }
                                }}
                                list={vehical_list?.map((v, i) => (
                                  <option key={i} value={v?.VEHICLE_NO}>
                                    {v?.VEHICLE_NO}
                                  </option>
                                ))}
                              />
                            </td>
                            <td>
                              {
                                <Input
                                  value={val?.driver_name}
                                  placeholder={"Driver Name"}
                                  onChange={(e) => {
                                    const numericValue = e.target.value;
                                    setAddData((pre) =>
                                      pre.map((v, i) =>
                                        i == ind
                                          ? {
                                              ...v,
                                              driver_name: numericValue,
                                            }
                                          : { ...v }
                                      )
                                    );
                                  }}
                                />
                              }
                            </td>
                            <td>
                              <Input
                                placeholder={"CONTACT NO"}
                                value={val?.contact}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  if (
                                    (/^\d+$/.test(input) || input === "") &&
                                    input?.length <= 10
                                  ) {
                                    setAddData((pre) =>
                                      pre.map((v, i) =>
                                        i == ind
                                          ? {
                                              ...v,
                                              contact: input,
                                            }
                                          : { ...v }
                                      )
                                    );
                                  }
                                }}
                              />
                            </td> */}
                            <td>
                              {
                                <Input
                                  value={val?.quantity}
                                  placeholder={"Quantity"}
                                  disabled={true}
                                />
                              }
                            </td>
                          </>
                        )}

                        <td>
                          {
                            <Input
                              value={val?.ship_quantity}
                              placeholder={"Shipped Quantity"}
                              disabled={
                                addDataHead?.delivery_type == "2" &&
                                addDataHead?.dc_type == "2" &&
                                !val?.product_code?.includes("e")
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                const numericValue = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                if (numericValue?.length < 5) {
                                  setAddData((pre) =>
                                    pre.map((v, i) =>
                                      i == ind
                                        ? {
                                            ...v,
                                            ship_quantity: numericValue,
                                          }
                                        : { ...v }
                                    )
                                  );
                                }
                              }}
                            />
                          }
                        </td>
                        {/* {addDataHead?.dc_type == "2" && (
                          <>
                            <td>
                              {
                                <Input
                                  value={val?.amount}
                                  placeholder={"Sale Amount"}
                                  disabled={true}
                                />
                              }
                            </td>
                            <td>
                              {
                                <Input
                                  value={val?.narration}
                                  placeholder={"Narration"}
                                  onChange={(e) => {
                                    if (e.target.value?.length <= 100) {
                                      setAddData((pre) =>
                                        pre.map((v, i) =>
                                          i == ind
                                            ? {
                                                ...v,
                                                narration: e.target.value,
                                              }
                                            : { ...v }
                                        )
                                      );
                                    }
                                  }}
                                />
                              }
                            </td>
                          </>
                        )} */}
                        <td>
                          {
                            <DeleteIcon
                              onClick={() =>
                                setAddData((pre) =>
                                  pre.filter((v, i) => i != ind)
                                )
                              }
                            />
                          }
                        </td>
                      </tr>
                    ))
                  }
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <AddButton
                  onClick={() => {
                    validation_check() &&
                      setMess(
                        "Please enter all the values in current row,calculate and then add next row"
                      );
                    validation_check()
                      ? setOpen1(true)
                      : setAddData((pre) => [...pre, {}]);
                  }}
                />
                <SaveButton onClick={() => add_items()} />
              </div>
            </div>
          }
        ></Modal>
      )}
      <div style={{ display: "" }}>
        <TaxInvoice3
          doc_name={"Delivery Challan"}
          previewRef={previewRef}
          doc_type="DC"
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.DC_DATE,
            REF_NUMBER: viewItem?.INV_NO || "NA",
            REF_DATE: viewItem?.DC_REF_DATE,
            PO_REF:viewItem?.PO_NO,
            PO_REF_DATE:viewItem?.PO_DATE,
            // EWAY_NO:viewItem?.PO_NO,
            // EWAY_DATE:viewItem?.PO_DATE,
            DRIVER_NAME:viewItem?.DRIVER_NAME,
            CVO_NAME: viewItem?.CVO_NAME,
            CVO_ADDRESS: viewItem?.PLACE_OF_SUPPLY || viewItem?.BILLING_ADDRESS,
            CVO_DISTRICT: viewItem?.B_DISTRICT,
            CVO_LOCATION: viewItem?.B_LOCATION,
            B_STATE_CODE: viewItem?.STATE_CODE,
            B_STATE_NAME: viewItem?.B_STATE_NAME,
            CVO_PINCODE: viewItem?.B_PINCODE,
            CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            SHIP_CVO_NAME: viewItem?.CVO_NAME,
            SHIP_CVO_ADDRESS: viewItem?.S_ADDRESS || viewItem?.BILLING_ADDRESS,
            SHIP_CVO_DISTRICT: viewItem?.S_DISTRICT,
            SHIP_CVO_LOCATION: viewItem?.S_LOCATION,
            SHIP_B_STATE: viewItem?.S_STATE,
            SHIP_B_STATE_NAME: viewItem?.S_STATE_NAME,
            SHIP_CVO_PINCODE: viewItem?.S_PINCODE,
            SHIP_CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
          }}
          empty_arr_length={4}
          table_header={
            <tr>
              <td colSpan={1}> SL NO</td>
              <td colSpan={1}>DESPATCH ITEM DESCRIPTION </td>
              <td colSpan={1}> HSN</td>
              <td colSpan={1}>QTY </td>
            </tr>
          }
          table_body={viewItem?.products?.map((v, i) => (
            <tr key={i} className="both" style={{ border: "none" }}>
              <td colSpan={1} className="both">
                {i + 1}
              </td>
              <td
                colSpan={1}
                className="both"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  minWidth: "280px",
                }}
              >
                {`${v?.CAT_NAME}-${v?.PRODUCT_NAME}`}
              </td>
              <td colSpan={1} className="both" style={{ border: "none" }}>
                {v?.HSN_CODE || "NA"}
              </td>
              <td colSpan={1} className="both" style={{ border: "none" }}>
                {v?.SHIPP_QTY} {UnitFunction(v?.UNITS || v?.UOM, "title")}
              </td>
            </tr>
          ))}
          other_data={
            <table>
              <tr>
                <td colSpan={1}> SL NO</td>
                <td colSpan={5}>
                  WE CONFIRM THAT FOLLOWING NUMBER OF EMPTY/DEFECTIVE CYLINDERS
                  RECEIVED
                </td>
                <td colSpan={1}> EMPTY</td>
                <td colSpan={1}>DEFECTIVE </td>
              </tr>
              <tr>
                <td colSpan={1}>1</td>
                <td colSpan={5}></td>
                <td colSpan={1}> </td>
                <td colSpan={1}> </td>
              </tr>
              <tr>
                <td colSpan={1}>2</td>
                <td colSpan={5}></td>
                <td colSpan={1}> </td>
                <td colSpan={1}> </td>
              </tr>
              <tr>
                <td colSpan={1}>3</td>
                <td colSpan={5}></td>
                <td colSpan={1}> </td>
                <td colSpan={1}> </td>
              </tr>
              <tr>
                <td style={{ borderBottom: "none" }} colSpan={6}></td>
                <td style={{ borderBottom: "none" }} colSpan={1}>
                  {" "}
                </td>
                <td style={{ borderBottom: "none" }} colSpan={1}>
                  {" "}
                </td>
              </tr>
            </table>
          }
        />
      </div>

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => delete_item()}
        />
      )}
      {open2 && (
        <SmallModal
          bname={"SUBMIT"}
          title={"Confirm"}
          isOpen={open2}
          setIsOpen={setOpen2}
          mess={"Are you sure? You want to create a Invoice for This Challan"}
          onClick={() => {
            alert("Invoice Created");
            setOpen2(false);
          }}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}

      {cvoClick && <CVOList cvoClick={cvoClick} setCvoClick={setCvoClick} />}
      {EquipClick && (
        <EquipmentList EquipClick={EquipClick} setEquipClick={setEquipClick} />
      )}
      {StaffClick && (
        <StaffList StaffClick={StaffClick} setStaffClick={setStaffClick} />
      )}
      {PriceClick && (
        <PriceList PriceClick={PriceClick} setPriceClick={setPriceClick} />
      )}
      {AreaClick && (
        <AreaCodeList AreaClick={AreaClick} setAreaClick={setAreaClick} />
      )}

      {openView && (
        <Modal
          onCancel={() => {
            setViewItem({});
          }}
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
            />
          }
          isOpen={openView}
          title={viewItem?.CVO_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={
                  viewItem?.DC_TYPE == "1"
                    ? ["PRODUCT", "HSN CODE", "UOM", "SHIPPED QUANTITY"]
                    : [
                        "PRODUCT",
                        "HSN CODE",
                        "UOM",
                        "BILLED QUANTITY",
                        "SHIPPED QUANTITY",
                      ]
                }
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        {viewItem?.DC_TYPE == "1" ? (
                          <>
                            <td>{`${v?.CAT_NAME}-${v?.PRODUCT_NAME} ${
                              v?.PROD_CODE.includes("e") ? "(Empties)" : ""
                            }`}</td>
                            <td>{v?.HSN_CODE}</td>
                            <td>{UnitFunction(v?.UNITS)}</td>
                            <td>{v?.SHIPP_QTY}</td>
                          </>
                        ) : (
                          <>
                            <td>{`${v?.CAT_NAME}-${v?.PRODUCT_NAME} ${
                              v?.PROD_CODE.includes("e") ? "(Empties)" : ""
                            }`}</td>
                            <td>{v?.HSN_CODE}</td>
                            <td>{UnitFunction(v?.UNITS)}</td>
                            <td>{v?.BILL_QTY}</td>
                            <td>{v?.SHIPP_QTY}</td>
                          </>
                        )}
                      </tr>
                    ))}
                    <tr>
                      {viewItem?.DC_TYPE == "1" ? (
                        <>
                          <td
                            colSpan={3}
                            style={{ fontWeight: "bold", fontSize: "15px" }}
                          >
                            TOTAL
                          </td>

                          <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                            {viewItem?.products
                              .map((v) => v.SHIPP_QTY)
                              .reduce((a, b) => Number(a) + Number(b), 0)
                              ?.toFixed(0)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            colSpan={3}
                            style={{ fontWeight: "bold", fontSize: "15px" }}
                          >
                            TOTAL
                          </td>
                          <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                            {viewItem?.products
                              .map((v) => v.BILL_QTY)
                              .reduce((a, b) => Number(a) + Number(b), 0)
                              ?.toFixed(0)}
                          </td>
                          <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                            {viewItem?.products
                              .map((v) => v.SHIPP_QTY)
                              .reduce((a, b) => Number(a) + Number(b), 0)
                              ?.toFixed(0)}
                          </td>
                          {/* <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                            {Number(viewItem?.DC_AMOUNT)?.toFixed(2)}
                          </td> */}
                        </>
                      )}
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}

      {loading && <Loader />}
    </div>
  );
};
