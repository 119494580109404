import React, { useEffect } from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
import Button from "../btn/Button";
import { AnimatePresence, motion } from "framer-motion";

const Modal = ({
  setIsOpen,
  title,
  del,
  body,
  onClick,
  bname,
  isOpen,
  header_right,
  width = "80vh",
  hideClose = false,
  onCancel,
  bg = "",
  style, 
  preference = 1,
}) => {
  return (
    <>
      <div className={styles.darkBG} style={{ zIndex: preference }} />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.centered}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              delay: 0.2,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            style={{ maxHeight: style ? "" : width, ...style }}
          >
            <div className={styles.modal}>
              <div className={styles.modalHeader}>
                <h5 className={styles.heading}>{title}</h5>
                {header_right}
                {!hideClose && (
                  <button
                    className={styles.closeBtn}
                    onClick={() => {
                      setIsOpen(false);
                      if (onCancel) {
                        onCancel();
                      }
                    }}
                  >
                    <RiCloseLine size={25} color="#2c3e50" />
                  </button>
                )}
              </div>
              <div
                className={styles.modalContent}
                style={{ backgroundColor: bg }}
              >
                {body}
              </div>
              {del && (
                <div className={styles.modalActions}>
                  <div className={styles.actionsContainer}>
                    <Button
                      className={styles.deleteBtn}
                      onClick={onClick}
                      title={bname || "Delete"}
                    />
                    <button
                      className={styles.cancelBtn}
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Modal;
