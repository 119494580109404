import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

export const get_staff_list_payroll = createAsyncThunk(
  "staff_list_payroll",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = {
      AGENCY_ID: userId,
      action: "fetchStaffData",
      fetchtype: "1",
    };

    const response = await fetch(base_url + "DOMSALES/PayRuns.php", {
      method: "POST",
      body: JSON.stringify(body),
    });

    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);

const initialState = {
  staff_payroll_list: [],
  staff_payroll_loading: false,
};

const payrollSlice = createSlice({
  name: "payrollSlice",
  initialState: initialState,
  extraReducers: (builder) => {
    // Payroll Staff List
    builder.addCase(get_staff_list_payroll.fulfilled, (state, action) => {
      const data = action.payload;
      state.staff_payroll_loading = false;
      if (data.success) {
        state.staff_payroll_list = data.data;
      } else {
        state.staff_payroll_list = [];
      }
    });

    builder.addCase(get_staff_list_payroll.pending, (state, action) => {
      state.staff_payroll_loading = true;
    });

    builder.addCase(get_staff_list_payroll.rejected, (state, action) => {
      state.staff_payroll_loading = false;
    });
  },
});

export default payrollSlice.reducer;
