import React, { useState } from "react";
import "./radio.css";
import { AnimatePresence, motion } from "framer-motion";

const RadioBtn = ({ label, icon, bcolor, value, setValue }) => {
  return (
    <div className="radio-main">
      {icon &&
        (!value ? (
          <AnimatePresence presenceAffectsLayout>
            <motion.div
              onClick={() => setValue(!value)}
              className="radio-circle"
              style={{ borderColor: bcolor }}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              whileTap={{ opacity: 0, scale: 0.5 }}
              transition={{
                duration: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                  type: "spring",
                  damping: 5,
                  stiffness: 100,
                  restDelta: 0.001,
                },
              }}
            />
          </AnimatePresence>
        ) : (
          <AnimatePresence>
            <motion.div
              onClick={() => setValue(!value)}
              className="radio-circle1"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              whileTap={{ opacity: 0, scale: 0.5 }}
              transition={{
                duration: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                  type: "spring",
                  damping: 5,
                  stiffness: 100,
                  restDelta: 0.001,
                },
              }}
            >
              <div className="radio-inner" />
            </motion.div>
          </AnimatePresence>
        ))}
      <div className="radio-label">{label}</div>
    </div>
  );
};

export default RadioBtn;
