import { configureStore } from "@reduxjs/toolkit";
import actionSlice from "./slice/actionSlice";
import masterdataSlice from "../screens/MasterData/masterdataSlice";
import completeListSlice from "./slice/completeListSlice";
import transactionsSlice from "../screens/transactions/trasactionsSlice";
import profileSlice from "../screens/profile/UsersSlice.js";
import otherSlice from "./slice/SlideSlice.js";
import transactionReportSlice from "../screens/transactionReport/transactionReportSlice.js";
import cashbankslice from "../screens/cash&bank/cashBankSlice.js";
import payrollSlice from "../screens/Payroll/payrollSlice.js";
import dayEndDateSlice from "../screens/DayEnd/DayendDaySlice.js";

const store = configureStore({
  reducer: {
    actions: actionSlice,
    profile: profileSlice,
    other: otherSlice,
    completeList: completeListSlice,
    masterdata: masterdataSlice,
    transactions: transactionsSlice,
    transactionReport: transactionReportSlice,
    cashbanks: cashbankslice,
    payroll: payrollSlice,
    day_end: dayEndDateSlice,
  },
});

export default store;
