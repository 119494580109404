import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import CustomList14 from "./CustomList14";
import { FiChevronRight } from "react-icons/fi";
import { IoReceiptOutline } from "react-icons/io5";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { TbFileDescription } from "react-icons/tb";
import { HiOutlineCreditCard } from "react-icons/hi";
import { GoNote } from "react-icons/go";
import { BsBank } from "react-icons/bs";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";

const CashnBankList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="list-main mt10"
        onClick={() =>
          setFocused((pre) =>
            pre.find((v) => v == "Transactions")
              ? pre.filter((v) => v != "Transactions")
              : ["Transactions"]
          )
        }
      >
        <div className="list-icon">
          <BsBank color="#6e82a5" size={16} />
        </div>
        <div className="list-title fs15">Cash & Bank</div>
        <div className="list-arrow">
          <FiChevronRight
            size={18}
            className={
              focused.includes("Transactions") ? "rotate-down" : "rotate-right"
            }
          />
        </div>
      </div>
      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <CustomList14
              title={"Receipts"}
              icon={<IoReceiptOutline color="#6e82a5" size={15} />}
              style={{
                backgroundColor:
                  path == "/Cash&Bank/Receipts" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Cash&Bank/Receipts");
                close();
              }}
            />
            <CustomList14
              title={"Payments"}
              icon={<MdOutlineCurrencyRupee color="#6e82a5" size={15} />}
              style={{
                backgroundColor:
                  path == "/Cash&Bank/Payments" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Cash&Bank/Payments");
                close();
              }}
            />
            <CustomList14
              title={"Cheque Clearance"}
              icon={<LiaMoneyCheckAltSolid color="#6e82a5" size={15} />}
              style={{
                backgroundColor:
                  path == "/Cash&Bank/cheque" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Cash&Bank/cheque");
                close();
              }}
            />
            <CustomList14
              title={"Bank Transactions"}
              icon={<TbFileDescription color="#6e82a5" size={15} />}
              style={{
                backgroundColor:
                  path == "/Cash&Bank/BankTransactions" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Cash&Bank/BankTransactions");
                close();
              }}
            />
            <CustomList14
              title={"Credit Note"}
              icon={<HiOutlineCreditCard color="#6e82a5" size={15} />}
              style={{
                backgroundColor:
                  path == "/Cash&Bank/CreditNote" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Cash&Bank/CreditNote");
                close();
              }}
            />
            <CustomList14
              title={"Debit Note"}
              icon={<GoNote color="#6e82a5" size={15} />}
              style={{
                backgroundColor:
                  path == "/Cash&Bank/DebitNote" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Cash&Bank/DebitNote");
                close();
              }}
            />
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default CashnBankList;
