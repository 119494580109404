import React from "react";

export const Alerts = ({ data }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {[...(new Set(data))]
        .filter((v) => v !== "")
        .map((item, i) => (
          <p style={{ textAlign: "left" }} key={i}>
            {item}
          </p>
        ))}
    </div>
  );
};
