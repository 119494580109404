import { createSlice } from "@reduxjs/toolkit";

const actionSlice = createSlice({
  name: "action",
  initialState: {
    smallSidebarWidth: false,
    smallSidebarProfileWidth: false,
    focused: [],
  },
  reducers: {
    setSidebarWidth(state, action) {
      state.smallSidebarWidth = action.payload;
    },
    setSidebarProfileWidth(state, action) {
      state.smallSidebarProfileWidth = action.payload;
    },
    setFocused(state, action) {
      state.focused = action.payload;
    },
  },
});

export const { setSidebarWidth, setSidebarProfileWidth, setFocused } =
  actionSlice.actions;
export default actionSlice.reducer;
