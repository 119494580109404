import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import CustomList14 from "./CustomList14";
import { FiChevronRight } from "react-icons/fi";
import { BsPercent } from "react-icons/bs";

const GstReturnList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="list-main mt10"
        onClick={() =>
          setFocused((pre) =>
            pre.find((v) => v == "Transactions")
              ? pre.filter((v) => v != "Transactions")
              : ["Transactions"]
          )
        }
      >
        <div className="list-icon">
          <BsPercent color="#6e82a5" size={16} />
        </div>
        <div className="list-title fs15">GST Returns</div>
        <div className="list-arrow">
          <FiChevronRight
            size={18}
            className={
              focused.includes("Transactions") ? "rotate-down" : "rotate-right"
            }
          />
        </div>
      </div>
      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <CustomList14
              title={"GSTR1"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/GSTReportS/GST1" ? "white" : "",
              }}
              onClick={() => {
                navigate("/GSTReportS/GST1");
                close();
              }}
            />
            <CustomList14
              title={"GSTR2"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/GSTReportS/GST2" ? "white" : "",
              }}
              onClick={() => {
                navigate("/GSTReportS/GST2");
                close();
              }}
            />
            <CustomList14
              title={"GSTR3"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/GSTReportS/GST3" ? "white" : "",
              }}
              onClick={() => {
                navigate("/GSTReportS/GST3");
                close();
              }}
            />
            <CustomList14
              title={"GSTR9"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/GSTReportS/GST9" ? "white" : "",
              }}
              onClick={() => {
                navigate("/GSTReportS/GST9");
                close();
              }}
            />
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default GstReturnList;
