import React, { useState } from "react";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import Dropdown from "../../../components/inputs/Dropdown";
import Table from "../../../components/table/Table";
import { Input } from "../../../components/inputs/Inputs";
import { RiDeleteBin5Fill } from "react-icons/ri";
import SmallModal from "../../../components/modal/SmallModal";

const AssetsManagement = () => {
  const [flag, setFlag] = useState(false);
  const [alerts, setAlerts] = useState(false);
  const [alertsMess, setAlertsMess] = useState("");
  const [addProductData, setAddProductData] = useState([]);
  const [transactionData, setTransactionData] = useState({
    transactionDate: '',
    transactionType: '',
    itemDesc: '',
    accountHead: '',
    value: '',
    modeOfPayment: '',
    bank: '',
    staff: ''
  });

  const [errors, setErrors] = useState({});

  const handleAddProduct = () => {
    const defaultProduct = {
      id: addProductData.length + 1,
      NAME: "",
      PAN: "",
      PERC_OF_SHARE: "",
      OPENING_BALANCE: "",
      STATUS: "",
      ACTIONS: "",
    };
    if (!addProductData.length || valiDate().length === 0) {
      setAddProductData((prevData) => [...prevData, defaultProduct]);
    } else {
      // setAddProductData((prevData) => [...prevData, defaultProduct]);
      const validationErrors = valiDate();
      setAlerts(true);
      setAlertsMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {validationErrors.map((item, i) => (
            <p style={{ textAlign: "left" }} key={i}>
              {item}
            </p>
          ))}
        </div>
      );
    }
  };


  const handleInputChange = (field, value) => {
    setTransactionData(prev => ({
      ...prev,
      [field]: value
    }));
    // Remove errors as user types
    setErrors(prev => ({ ...prev, [field]: null }));
  };

  const valiDate = () => {
    const errors = [];

    // Validate transactionDate
    if (!transactionData.transactionDate.trim()) {
      errors.push("Transaction date is required.");
    }

    // Validate transactionType
    if (!transactionData.transactionType.trim()) {
      errors.push("Transaction type is required.");
    }

    // Validate itemDesc
    if (!transactionData.itemDesc.trim()) {
      errors.push("Item description is required.");
    }

    // Validate accountHead
    if (!transactionData.accountHead.trim()) {
      errors.push("Account head is required.");
    }

    // Validate value
    if (!transactionData.value.trim()) {
      errors.push("Value is required.");
    } else if (isNaN(Number(transactionData.value))) {
      errors.push("Value must be a number.");
    }

    // Validate modeOfPayment
    if (!transactionData.modeOfPayment.trim()) {
      errors.push("Mode of payment is required.");
    }

    // Validate bank
    if (!transactionData.bank.trim()) {
      errors.push("Bank is required.");
    }

    // Validate staff
    if (!transactionData.staff.trim()) {
      errors.push("Staff is required.");
    }

    return errors;
  };

  const handleSave = () => {
    const validationErrors = valiDate();
    setAlerts(true);
    setAlertsMess(
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {validationErrors.map((item, i) => (
          <p style={{ textAlign: "left" }} key={i}>
            {item}
          </p>
        ))}
      </div>
    );
  };

  return (
    <div className="container">
      <PageHeading
        title={"AGENCY ASSETS"}
        right={
          <>
            <AddButton onClick={()=>{
              setFlag(true);
              handleAddProduct()
            }} />
            <SaveButton onClick={handleSave} />
            <BackButton />
          </>
        }
      />
      <div style={{ height: "20px" }}></div>

      {addProductData.length>0&&<Table
        mt={5}
        headData={[
          "TRANSACTION DATE",
          "TRANSACTION TYPE",
          "ITEM DESC",
          "ACCOUNT HEAD",
          "VALUE",
          "MODE OF PAYMENT",
          "BANK",
          "STAFF",
          "ACTIONS",
        ]}
        body={
          <>
            {addProductData.map((item) => (
              <tr key={item.id}>
                <td>
                  <Input
                    placeholder={"Select Date"}
                    type={"date"}
                    value={transactionData.transactionDate}
                    onChange={(e) => handleInputChange('transactionDate', e.target.value)}
                    style={errors.transactionDate ? { borderColor: 'red' } : {}}
                  />
                  {errors.transactionDate && <div style={{ color: 'red', marginTop: 5 }}>{errors.transactionDate}</div>}
                </td>
                <td>
                  <Dropdown
                    value={transactionData.transactionType}
                    onChange={(e) => handleInputChange('transactionType', e.target.value)}
                    options={
                      <>
                        <option value="">SELECT</option>
                        <option value="Sale">Sale</option>
                        <option value="Purchase">Purchase</option>
                        <option value="Transfer">Transfer</option>
                      </>
                    }
                    style={errors.transactionType ? { borderColor: 'red' } : {}}
                  />
                  {errors.transactionType && <div style={{ color: 'red', marginTop: 5 }}>{errors.transactionType}</div>}
                </td>
                <td>
                  <Input
                    placeholder={"ITEM DESCRIPTION"}
                    type={"text"}
                    value={transactionData.itemDesc}
                    onChange={(e) => handleInputChange('itemDesc', e.target.value)}
                    style={errors.itemDesc ? { borderColor: 'red' } : {}}
                  />
                  {errors.itemDesc && <div style={{ color: 'red', marginTop: 5 }}>{errors.itemDesc}</div>}
                </td>
                <td>
                  <Dropdown
                    value={transactionData.accountHead}
                    onChange={(e) => handleInputChange('accountHead', e.target.value)}
                    options={
                      <>
                        <option value="">SELECT</option>
                        <option value="Assets">Assets</option>
                        <option value="Liabilities">Liabilities</option>
                      </>
                    }
                    style={errors.accountHead ? { borderColor: 'red' } : {}}
                  />
                  {errors.accountHead && <div style={{ color: 'red', marginTop: 5 }}>{errors.accountHead}</div>}
                </td>
                <td>
                  <Input
                    type="number"
                    placeholder="Value"
                    value={transactionData.value}
                    onChange={(e) => handleInputChange('value', e.target.value)}
                    style={errors.value ? { borderColor: 'red' } : {}}
                  />
                  {errors.value && <div style={{ color: 'red', marginTop: 5 }}>{errors.value}</div>}
                </td>
                <td>
                  <Dropdown
                    value={transactionData.modeOfPayment}
                    onChange={(e) => handleInputChange('modeOfPayment', e.target.value)}
                    options={
                      <>
                        <option value="">SELECT</option>
                        <option value="Cash">Cash</option>
                        <option value="Cheque">Cheque</option>
                        <option value="Transfer">Transfer</option>
                      </>
                    }
                    style={errors.modeOfPayment ? { borderColor: 'red' } : {}}
                  />
                  {errors.modeOfPayment && <div style={{ color: 'red', marginTop: 5 }}>{errors.modeOfPayment}</div>}
                </td>
                <td>
                  <Dropdown
                    value={transactionData.bank}
                    onChange={(e) => handleInputChange('staff', e.target.value)}
                    options={
                      <>
                        <option value="">BANK</option>
                        <option value="John Doe">SBI</option>
                        <option value="Jane Doe">PNB</option>
                      </>
                    }
                    style={errors.bank ? { borderColor: 'red' } : {}}
                  />
                  {errors.staff && <div style={{ color: 'red', marginTop: 5 }}>{errors.staff}</div>}
                </td>
                <td>
                  <Dropdown
                    value={transactionData.staff}
                    onChange={(e) => handleInputChange('staff', e.target.value)}
                    options={
                      <>
                        <option value="">SELECT</option>
                        <option value="John Doe">John Doe</option>
                        <option value="Jane Doe">Jane Doe</option>
                      </>
                    }
                    style={errors.staff ? { borderColor: 'red' } : {}}
                  />
                  {errors.staff && <div style={{ color: 'red', marginTop: 5 }}>{errors.staff}</div>}
                </td>
                <td>
                  <RiDeleteBin5Fill style={{ cursor: 'pointer' }} />
                </td>
              </tr>
            ))}
          </>
        }
      />}
      <Table
        mt={5}
        headData={[
          "TRANSACTION DATE",
          "TRANSACTION TYPE",
          "ITEM DESC",
          "ACCOUNT HEAD",
          "VALUE",
          "MODE OF PAYMENT",
          "BANK",
          "STAFF",
          "ACTIONS",
        ]}
        body={
          <>
            <tr>
              <td>cust yes</td>
              <td>0.00</td>
              <td>0.00</td>
              <td>0.00</td>
              <td>0.00</td>
              <td>0.00</td>
              <td>0.00</td>
            </tr>
          </>
        }
      />
      {alerts && (
        <SmallModal
          isOpen={alerts}
          setIsOpen={setAlerts}
          title={"alert"}
          mess={alertsMess}
          okbtn={setAlertsMess}
        />
      )}
    </div>
  );
};

export default AssetsManagement;
