import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

export const get_purchase_report_list = createAsyncThunk(
  "purchase_report",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    const response = await fetch(base_url + "reports/purchase_reports.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_sales_report_list = createAsyncThunk(
  "sales_report",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    const response = await fetch(base_url + "reports/sales_reports.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);
export const get_nc_dbc_report_list = createAsyncThunk(
  "nc_dbc_report",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    const response = await fetch(base_url + "reports/ncdbc_rctv_reports.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_stock_report_list = createAsyncThunk(
  "stock_report",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    const response = await fetch(base_url + "reports/stock_report.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_payment_receipt_report_list = createAsyncThunk(
  "payment_receipt_report",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "listdata");
    body.append("agencyid", userId);
    const response = await fetch(
      base_url + "reports/payments_receipts_reports.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await response.json();
    return res;
  }
);

const initialState = {
  purchase_report_list: {
    productlist: [],
    vendorlist: [],
  },
  sales_report_list: {
    productlist: [],
    customerlist: [],
  },
  nc_dbc_report_list: {
    productlist: [],
    connectionType: [],
  },

  payments_reciept_list: {
    reciepts_list: [],
    payments_list: [],
  },
  // stock_report_list: [],
  stock_report_list: {
    productlist: [],
    godownlist: [],
  },

};

const transactionsReportSlice = createSlice({
  name: "purchase_reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(get_purchase_report_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data?.success) {
        state.purchase_report_list.productlist = data.productlist;
        state.purchase_report_list.vendorlist = data.vendorlist;
      } else {
        state.purchase_report_list.productlist = [];
        state.purchase_report_list.vendorlist = [];
      }
    });

    builder.addCase(get_sales_report_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data?.success) {
        state.sales_report_list.productlist = data.productlist;
        state.sales_report_list.customerlist = data.customerlist;
      } else {
        state.sales_report_list.productlist = [];
        state.sales_report_list.customerlist = [];
      }
    });

    builder.addCase(get_nc_dbc_report_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data?.success) {
        state.nc_dbc_report_list.productlist = data.productlist;
      } else {
        state.nc_dbc_report_list.productlist = [];
      }
    });

    builder.addCase(get_stock_report_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data?.success) {
        state.stock_report_list.productlist = data.productlist;
        state.stock_report_list.godownlist = data.godownlist;
      } else {
        state.stock_report_list.productlist = [];
        state.stock_report_list.godownlist = [];
      }
    });

    builder.addCase(
      get_payment_receipt_report_list.fulfilled,
      (state, action) => {
        const data = action.payload;
        if (data?.success) {
          state.payments_reciept_list.payments_list =
            data?.data?.payments_list_data;
          state.payments_reciept_list.reciepts_list =
            data?.data?.receipts_list_data;
        } else {
          state.payments_reciept_list.payments_list = [];
          state.payments_reciept_list.reciepts_list = [];
        }
      }
    );
  },
});

export default transactionsReportSlice.reducer;
