import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/inputs/Input";
import Button from "../../components/btn/Button";
import "./login.css";
import logoicon from "../../assets/logo512.png";
import logotxt from "../../assets/mylpgtxt.png";
import { BiLogIn } from "react-icons/bi";
import AuthBg from "../../components/authbg/AuthBg";
import { toast } from "react-toastify";
import { base_url } from "../../utils/utils";
import Loader from "../../components/Loader";
import { login } from "../../utils/Hook/useBroadcastChannel";
// import { BsEye, BsEyeSlash } from "react-icons/bs";
// import store from "../../redux/store";
// import {
//   get_profile_img_url,
//   get_profile_name,
//   get_profile_pin,
// } from "../profile/UsersSlice";

const Login = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const onLogin = async () => {
    if (
      !userData.id ||
      !userData.pass ||
      userData?.id?.trim().length == 0 ||
      userData.pass.trim().length == 0 ||
      userData?.id?.trim()?.length < 6 || 
      userData.pass?.trim()?.length < 8
    ) {
      setUserData({
        ...userData,
        idmess:
          !userData.id || userData?.id?.trim().length == 0
            ? "User ID is required"
            : userData?.id?.length < 6
            ? "User ID must Contain 6 digit"
            : "",
        idval: !userData.id || userData?.id?.trim().length == 0 ? true : false,
        passmess:
          !userData.pass || userData.pass.trim().length == 0
            ? "Password is required"
            : userData.pass?.trim()?.length < 8 ? "Password must be min 8 charector":"",
        passval:
          !userData.pas || userData.pass.trim().length == 0 ? true : false,
      });
      return;
    }
    setLoading(true);
    const body = new FormData();
    body.append("action", "agency");
    body.append("agencyid", userData?.id?.trim());
    body.append("password", userData?.pass?.trim());
    const responce = await fetch(base_url + "agency.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      localStorage.setItem("userId", JSON.stringify(res));
      localStorage.setItem("IS_FTL", res.IS_FTL);
      localStorage.setItem("eff_date", res.date);
      // login()

      // store.dispatch(get_profile_img_url(res?.image_url));
      // store.dispatch(get_profile_name(res?.Name));
      // store.dispatch(get_profile_pin(res?.PIN));
    } else {
      toast.error(res.message);
    }
  };
  const uid = localStorage.getItem("userId");
  const ID = JSON.parse(uid);
  useEffect(() => {
    if (ID != null) {
      navigate("/Home");
    }
  }, [ID]);
  return (
    <AuthBg>
      {loading && <Loader loading={loading} />}

      <div className="login-inner-div">
        <div className="sidebar-logo aic jcc ">
          <img src={logoicon} className="s_logo" />
          <img src={logotxt} className="s_logotxt" />
        </div>
        <div className="login-text mt10">Log in to your account</div>

        <Input
          label={"Agency Id"}
          placeholder={"Enter Agency Id"}
          value={userData.id}
          onChange={(e) => {
            if (e.target.value.length <= 10) {
              setUserData({
                ...userData,
                id: e.target.value,
                idmess: "",
                idval: false,
              });
            }
          }}
          error={userData.idmess}
          bcolor={userData.idval}
        />
        <Input
          label={"Password"}
          type={showPass ? "text" : "password"}
          placeholder={"Enter Password"}
          value={userData.pass}
          onChange={(e) =>
            setUserData({
              ...userData,
              pass: e.target.value,
              passmess: "",
              passval: false,
            })
          }
          error={userData.passmess}
          bcolor={userData.passval}
          pass={true}
          setShowPass={setShowPass}
          showPass={showPass}
        />
        <div
          style={{
            width: "100%",
            marginTop: "1rem",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "1rem",
            fontSize: "14px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/forgot")}
        >
          Forgot Your Password?
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            title={"Login"}
            icon={<BiLogIn size={20} />}
            onClick={onLogin}
          />
        </div>
        <div
          style={{
            justifyContent: "center",
            marginTop: 10,
            display: "flex",
            gap: 5,
          }}
          onClick={() => navigate("/register")}
        >
          <div style={{ fontSize: 14, color: "#6f6f6f", fontWeight: "500" }}>
            Don't have an account? -
          </div>
          <div
            style={{
              cursor: "pointer",
              fontSize: 15,
              color: "#6f6f6f",
              fontWeight: "500",
            }}
          >
            Sign Up
          </div>
        </div>
      </div>
    </AuthBg>
  );
};

export default Login;
