import React from "react";
import styles from "./tax.module.css";
import { CheckBox } from "../../../components/inputs/CheckBox";
import { useSelector } from "react-redux";
import Indanegas from "../../../assets/Indane.png";
import hpgas from "../../../assets/Hp.png";
import bharatgas from "../../../assets/Bharatgas.png";
import useAmountToWords from "../../../utils/Hook/useAmountToWords";
import moment from "moment";

const TaxInvoice5 = ({
  doc_name,
  previewRef,
  listdata,
  net_total,
  table_body,
  table_header,
  empty_arr_length,
  empty_row_height,
  taxable_amt,
  igst,
  cgst,
  sgst,
  other,
  Total_tax,
  deposite,
  total_amt,
}) => {
  const { profile_data, seal_image, signature_image, upi_qr_image, upi_id } =
    useSelector((state) => state.profile);
  const { omc_name, omc_data } = useSelector((state) => state.other);
  const convertAmountToWords = useAmountToWords(
    Number(total_amt || 0).toFixed(0)
  );

  return (
    <div ref={previewRef}>
      {Array(2)
        .fill()
        .map((v, i) => (
          <div
            key={i}
            className={styles.invoice_main}
            style={{ marginLeft: "60px", marginBottom: 0,paddingBottom:"0", }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",

                padding: "0 0",
                marginBottom: ".24rem",
              }}
            >
              {doc_name}
            </div>
            <div
              style={{
                width: "100%",
                fontSize: "14px",
                textAlign: "right",
                padding: "0 2rem 5px 0",
                display: "flex",
                justifyContent: "flex-end",
                gap: "0.5rem", // Add some space between items
              }}
            >
              <label style={{ display: "flex", alignItems: "center" }}>
                <CheckBox /> Original
              </label>
              /
              <label style={{ display: "flex", alignItems: "center" }}>
                <CheckBox /> Duplicate
              </label>
              /
              <label style={{ display: "flex", alignItems: "center" }}>
                <CheckBox /> Triplicate
              </label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: ".24rem",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  id="Aname"
                  style={{ width: "170px" }}
                  src={
                    omc_name == "IOCL"
                      ? Indanegas
                      : omc_name == "HPCL"
                      ? hpgas
                      : omc_name == "BPCL"
                      ? bharatgas
                      : ""
                  }
                />
              </div>

              <div
                style={{
                  flex: 3,
                  gap: ".25rem",
                  marginLeft: "8rem",
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  fontSize: "14px",
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {profile_data?.name}
                </div>
                <div>
                  {profile_data.ADDRESS}, {profile_data?.LOCATION},
                </div>
                <div> GSTIN/UIN: {profile_data?.gst}</div>
              </div>
            </div>
            <table className={styles.table_1}>
              <tr>
                <td style={{ borderBottom: "none" }} colSpan={2}>
                  INV NO. : {listdata?.SR_NO}
                </td>
                <td style={{ borderBottom: "none" }} colSpan={2}>
                  INV DATE :{" "}
                  {moment(listdata?.SR_NO_DATE).format("DD-MMM-YYYY")}
                </td>
                <td style={{ borderBottom: "none" }} colSpan={2}>
                  Customer Name : <b>{listdata?.CVO_NAME || "NA"}</b>{" "}
                </td>
              </tr>
            </table>
            <table>
              {table_header}
              {table_body}
              <tr style={{ height: `${empty_row_height}px` }}>
                {Array(empty_arr_length)
                  .fill()
                  .map(() => (
                    <td style={{height:`${empty_row_height}px`}} className="both"></td>
                  ))}
              </tr>
            </table>
            <table>
              <tr>
                <td colSpan={5}>
                  TOTAL INV AMOUNT:
                  <span style={{ fontWeight: "bold" }}>{net_total}</span>
                </td>
                <td>AMOUNT BEFORE TAX</td>
                <td style={{ textAlign: "right" }}>{taxable_amt}</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  TOTAL SECURITY DEPOSIT AMOUNT :{" "}
                  <span style={{ fontWeight: "bold" }}>{deposite}</span>
                </td>
                <td>IGST</td>
                <td style={{ textAlign: "right" }}>{igst}</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  TOTAL AMOUNT :{" "}
                  <span style={{ fontWeight: "bold" }}>{total_amt}</span>
                </td>
                <td>CGST</td>
                <td style={{ textAlign: "right" }}>{cgst}</td>
              </tr>
              <tr>
                <td colSpan={5} rowSpan={2}>
                  TOTAL AMOUNT (In Words) :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {convertAmountToWords}
                  </span>
                </td>
                <td>SGST</td>
                <td style={{ textAlign: "right" }}>{sgst}</td>
              </tr>
              <tr>
                <td>TOTA GST</td>
                <td style={{ textAlign: "right" }}>{Total_tax}</td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  rowSpan={3}
                  style={{ verticalAlign: "top", textAlign: "left" }}
                >
                  DECLARATION: THIS IS THE SYSTEM GENERATED INVOICE AND WE
                  DECLARE THAT THIS INVOICE SHOWS THE ACTUAL PRICE OF THE GOODS
                  DESCRIBED ANDTHAT ALL PARTICULARS ARE TRUE AND CORRECT
                </td>
                <td>ADDITIONAL CHARGES</td>
                <td style={{ textAlign: "right" }}>{other}</td>
              </tr>
              <tr>
                <td>TOTAL INV AMOUNT</td>
                <td style={{ textAlign: "right" }}>{net_total}</td>
              </tr>
              <tr>
                <td>ROUNDED OFF</td>
                <td style={{ textAlign: "right" }}>
                  {(Number(net_total).toFixed(0) - Number(net_total)).toFixed(
                    2
                  )}
                </td>
              </tr>

              <tr>
                <td colSpan={7} style={{ height: "100px" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        textAlign: "left",
                        fontWeight: "bold",
                        padding: "0 20px 5px 20px",
                        fontSize: "13px",
                      }}
                    >
                      CUSTOMER SIGNATURE
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "right",
                        fontWeight: "bold",
                        padding: "0 20px 5px 20px",
                        fontSize: "13px",
                      }}
                    >
                      STAMP AND SIGNATURE
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        ))}
    </div>
  );
};

export default TaxInvoice5;
