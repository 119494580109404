import React, { useState } from "react";
import styles from "./profile.module.css";
import Button from "../../components/btn/Button";
import SmallModal from "../../components/modal/SmallModal";
import Input from "../../components/inputs/Input";
import { Alerts } from "../../components/Alerts/Alerts";
import { profile_update_api } from "../../utils/apis";
import { toast } from "react-toastify";
import store from "../../redux/store";
import { get_profile_data } from "./UsersSlice";
import { useSelector } from "react-redux";
const InvoiceBillInfo = () => {
  const [open, setOpen] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [addData, setAddData] = useState({});
  const [Loading, setLoading] = useState(false);
  const { user_id, today } = useSelector((state) => state.other);

  const update_details = async () => {
    const data = validate();
    if (data?.length === 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "gstinupdate");
        body.append("agencyid", user_id);
        body.append("username", addData?.user);
        body.append("password", addData?.password);
        const res = await profile_update_api(body);
        setLoading(false);
        if (res.success) {
          setOpen(false);
          toast.success(res.message);
          setAddData({});
          store.dispatch(get_profile_data(user_id));
        } else if (!res.success) {
          toast.error(res.message);
        }
      } catch (error) {
        console.log("error ===", error);
      }
    }
  };

  const validate = () => {
    const data = [];
    const obj = {
      userErr: "",
      passwordErr: "",
    };
    if (!addData?.user || addData?.user?.length === 0) {
      data.push("User Details Required");
      obj["userErr"] = "User Details Required";
    }
    if (!addData?.password || addData?.password?.length === 0) {
      data.push("User Password Details Required");
      obj["passwordErr"] = "User Password Details Required";
    }

    setAddData({ ...addData, ...obj });

    return data; // Return the array of validation error messages
  };

  return (
    <div className={styles.p_card_div}>
      <div className={styles.card_heading}>
        E-Invoice and E-Way Bill Enabling
      </div>
      <div className={styles.invoice_card}>
        <div className={styles.invoive_txt}>
          Please do GSP Registration as MastersIndia in E-Way bill portal.
          Please Update Registered User Name and password for enabling E-Invoice
          and EWay Bill Generation as given in GSP Registration.
        </div>
        <div className={styles.invoice_btn_wrap}>
          <div>Help</div>
          <Button title={"ENABLE"} onClick={() => setOpen(true)} />
        </div>
      </div>
      {open && (
        <SmallModal
          isOpen={open}
          onCancle={() => {
            setAddData({});
          }}
          setIsOpen={setOpen}
          title={"E-Invoice Authetication"}
          ok_btn_click={update_details}
          okBtnText={"AUTHENTICATE"}
          mess={
            <div
              className="flex aic gap10"
              style={{
                flexDirection: "column",
              }}
            >
              <Input
                value={addData?.user}
                error={addData?.userErr}
                onChange={(e) => {
                  setAddData({ ...addData, user: e.target.value, userErr: "" });
                }}
                title="User"
                placeholder={"User"}
              />
              <Input
                value={addData?.password}
                error={addData?.passwordErr}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    password: e.target.value,
                    passwordErr: "",
                  });
                }}
                pass={true}
                setShowPass={setShowPass}
                showPass={showPass}
                title="Password"
                placeholder={"Password"}
                type={showPass ? "text" : "password"}
              />
            </div>
          }
          okbtn={setOpen}
        />
      )}
    </div>
  );
};

export default InvoiceBillInfo;
