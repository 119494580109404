import React from "react";
import "./list.css";
import { BsCircle } from "react-icons/bs";

const CustomList14 = ({ icon, title, onClick,style }) => {
  return (
    <div className="list-main-single" onClick={onClick} style={style}>
      <div className="list-icon">
        {/* <BsCircle size={10} color="#6e82a5" />
         */}
        {icon}
      </div>
      <div className="list-title fs14">{title}</div>
    </div>
  );
};

export default CustomList14;
