import React from "react";
import "./authBg.css";

const AuthBg = ({ children }) => {
  return (
    <div className="bg-container">
      {children}
      {/* <div className="bg-inner">{children}</div> */}
    </div>
  );
};

export default AuthBg;
