import React from "react";
import "./input.css";
const Dropdown = ({
  label,
  value,
  onChange,
  data = [],
  placeholder,
  onFocus,
  mess,
  error,
  options,
  mw,
  w,
  onBlur,
  disabled = false,
  width2 = "100%",
  fontSize = "14px",
}) => {
  return (
    <div style={{ width: width2 }}>
      <div className="inp-main">
        {label && <div className="inp-label">{label?.toUpperCase()} </div>}
      </div>
      <select
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        className={"drop-select"}
        style={{
          minWidth: mw || "120px",
          width: w || "100%",
          fontSize: fontSize,
        }}
        value={value}
        onChange={onChange}
      >
        {options ||
          data.map((v, i) => (
            <option key={i} value={v.value}>
              {v.label}
            </option>
          ))}
        {/* {data1.length > 0 &&
          data1.map((v, i) => (
            <option key={i} value={v}>
              {v}
            </option>
          ))} */}
      </select>

      {mess && <div className="inp-mess">{mess}</div>}
      {error && <div className="inp-error">{error}</div>}
    </div>
  );
};

export default Dropdown;
