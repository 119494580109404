import React, { useEffect, useState } from "react";
import Dropdown from "../../components/inputs/Dropdown";
import PageHeading from "../../components/PageHeading";
import { Input } from "../../components/inputs/Inputs";
import { base_url, } from "../../utils/utils";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { get_bank_master_list } from "../MasterData/masterdataSlice";
import moment from "moment";
import TableNew from "../../components/table/TableWithPagination";
import PageLoading from "../../components/PageLoading";
import Loader from "../../components/Loader";
import SmallModal from "../../components/modal/SmallModal";
import { Alerts } from "../../components/Alerts/Alerts";

const Reports = () => {
  const [buttonClick, setButtonClick] = useState("Staff-Salary-Ledger");
  const [staff_loading, setStaffLoading] = useState(false);
  const [alerts, setAlerts] = useState(false);
  const [alertsMess, setAlertsMess] = useState("");
  const [staff_ledger_list, setStaffLedgerList] = useState([]);
  const [formData, setFormData] = useState({
    selectedMonth: "",
    selectedYear: "",
    selectedPaymentMode: "",
    selectedBank: "",
    fromDate: "",
    toDate: "",
    selectedStaff: "",
  });
  const { user_id } = useSelector((state) => state.other);

  const { bank_master_list } = useSelector((state) => state.masterdata);

  const valiDate = () => {
    const data = [];
    if (buttonClick === "Staff-Salary-Ledger") {
      data.push(...validateStaffSalaryLedger());
    } else if (buttonClick === "Staff-Loan-Ledger") {
      data.push(...validateStaffLoanLedger());
    } else if (buttonClick === "Es1-REPORT") {
      data.push(...validateES1Report());
    } else {
      data.push(...validateDefault());
    }

    return data;
  };

  const validateStaffSalaryLedger = () => {
    const errors = [];
    if (!user_id) errors.push("User ID is required.");
    if (!formData.selectedBank) errors.push("Selected bank is required.");
    if (!formData.selectedMonth) errors.push("Selected month is required.");
    if (!formData.selectedYear) errors.push("Selected year is required.");
    if (!formData.selectedPaymentMode)
      errors.push("Selected payment mode is required.");
    return errors;
  };

  const validateStaffLoanLedger = () => {
    const errors = [];
    if (!user_id) errors.push("User ID is required.");
    if (!formData.fromDate) errors.push("From date is required.");
    if (!formData.toDate) errors.push("To date is required.");
    return errors;
  };

  const validateES1Report = () => {
    const errors = [];
    if (!user_id) errors.push("User ID is required.");
    if (!formData.selectedMonth) errors.push("Selected month is required.");
    if (!formData.selectedYear) errors.push("Selected year is required.");
    return errors;
  };

  const validateDefault = () => {
    const errors = [];
    if (!formData.selectedMonth) errors.push("Selected month is required.");
    if (!formData.selectedYear) errors.push("Selected year is required.");
    return errors;
  };

  const funcSelectLedgers = (buttonClick) => {
    if (buttonClick === "Staff-Salary-Ledger")
      return JSON.stringify({
        AGENCY_ID: user_id,
        action: "fetchStaffMonthlySalaryReports",
        bid: formData.selectedBank,
        month: formData.selectedMonth,
        year: formData.selectedYear,
        pt: formData.selectedPaymentMode,
      });
    else if (buttonClick === "Staff-Loan-Ledger")
      return JSON.stringify({
        AGENCY_ID: user_id,
        action: "fetchStaffLoanReport",
        fd: formData.fromDate,
        td: formData.toDate,
        staffid: user_id,
      });
    else if (buttonClick === "Es1-REPORT") {
      return JSON.stringify({
        AGENCY_ID: user_id,
        action: "fetchStaffESIandPFReportData",
        year: formData.selectedYear,
        month: formData.selectedMonth,
        staffid: user_id,
      });
    } else
      return JSON.stringify({
        AGENCY_ID: user_id,
        action: "fetchStaffESIandPFReportData",
        year: formData.selectedYear,
        month: formData.selectedMonth,
      });
  };

  const handleFetchData = async () => {
    try {
      const data1 = valiDate();
      if (data1.length === 0) {
        setStaffLoading(true);
        const body = funcSelectLedgers(buttonClick);
        const response = await fetch(
          `${base_url}DOMSALES/staffSalaryLedger.php`,
          {
            method: "POST",
            body: body,
          }
        );
        const responseText = await response.text();
        const cleanedResponse = responseText.replace(/\r?\n|\r/g, "");
        const jsonResponse = cleanedResponse.substring(
          cleanedResponse.indexOf("{")
        );
        const resJson = await JSON.parse(jsonResponse);
        setStaffLedgerList(resJson?.data?.STAFF_DATA);
        setStaffLoading(false);
      } else {
        setAlerts(true);
        setAlertsMess(<Alerts data={data1} />);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleInputChange = (key) => (event) => {
    setFormData({
      ...formData,
      [key]: event.target.value,
    });
  };

  useEffect(() => {
    store.dispatch(get_bank_master_list());
  }, []);

  return (
    <div className="container">
      <div className="ReportsMain">
        <PageHeading
          title={buttonClick.toUpperCase()}
          right={
            <div className="ReportMenu-btn">
              <div>
                <button
                  style={{
                    backgroundColor:
                      buttonClick == "Staff-Salary-Ledger" && " #2196f3",
                  }}
                  onClick={() => {
                    setStaffLedgerList([]);
                    setFormData({});
                    setButtonClick("Staff-Salary-Ledger");
                  }}
                  className="ReportButtons"
                >
                  Staff Salary Ledger
                </button>
              </div>
              <div>
                <button
                  className="ReportButtons"
                  onClick={() => {
                    setStaffLedgerList([]);
                    setFormData({});
                    setButtonClick("Staff-Loan-Ledger");
                  }}
                  style={{
                    backgroundColor:
                      buttonClick == "Staff-Loan-Ledger" && " #2196f3",
                  }}
                >
                  Staff Loan Ledger
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setStaffLedgerList([]);
                    setFormData({});
                    setButtonClick("Es1-REPORT");
                  }}
                  className="ReportButtons"
                  style={{
                    backgroundColor: buttonClick == "Es1-REPORT" && " #2196f3",
                  }}
                >
                  ES1 REPORT
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setStaffLedgerList([]);
                    setFormData({});
                    setButtonClick("PF-REPORT");
                  }}
                  className="ReportButtons"
                  style={{
                    backgroundColor: buttonClick == "PF-REPORT" && " #2196f3",
                  }}
                >
                  PF REPORT
                </button>
              </div>
            </div>
          }
        />
      </div>
      {buttonClick == "Staff-Salary-Ledger" && (
        <>
          <div className="tb1">
            <div className="MonYear">
              <p style={{ fontSize: "11px" }} className="mt10">
                MONTH:
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedMonth}
                  onChange={handleInputChange("selectedMonth")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value={"1"}>JAN</option>
                      <option value={"2"}>FEB</option>
                      <option value={"3"}>MAR</option>
                      <option value={"4"}>APR</option>
                      <option value={"5"}>MAY</option>
                      <option value={"6"}>JUN</option>
                      <option value={"7"}>JUL</option>
                      <option value={"8"}>AUG</option>
                      <option value={"9"}>SEP</option>
                      <option value={"10"}>OCT</option>
                      <option value={"11"}>NOV</option>
                      <option value={"12"}>DEC</option>
                    </>
                  }
                />
              </div>
              <p style={{ fontSize: "11px" }} className="mt10">
                Year:
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedYear}
                  onChange={handleInputChange("selectedYear")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                    </>
                  }
                />
              </div>
              <p style={{ fontSize: "11px" }} className="mt10">
                PAYMENT MODE:
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedPaymentMode}
                  onChange={handleInputChange("selectedPaymentMode")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value={"1"}>CASH</option>
                      <option value={"2"}>CHEQUE</option>
                      <option value={"3"}>ONLINE</option>
                    </>
                  }
                />
              </div>
              <p style={{ fontSize: "11px" }} className="mt10">
                BANK :
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedBank}
                  onChange={handleInputChange("selectedBank")}
                  options={
                    <>
                      <option>SELECT</option>
                      {bank_master_list?.map((v, i) => (
                        <option key={i} value={v?.ID}>
                          {v?.BANK_CODE} - {v?.BANK_ACC_NO}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>
              <div className="mt10">
                <button className="EXECUTE-btn" onClick={handleFetchData}>
                  SEARCH
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* condition2 */}

      {buttonClick == "Staff-Loan-Ledger" && (
        <>
          <div className="tb1">
            <div className="MonYear">
              <p style={{ fontSize: "11px" }} className="mt10">
                FROM DATE:
              </p>
              <div className="DR">
                <div style={{ width: "180px" }}>
                  <Input
                    type={"date"}
                    value={formData.fromDate}
                    onChange={handleInputChange("fromDate")}
                  />
                </div>
              </div>
              <p style={{ fontSize: "11px" }} className="mt10">
                TO DATE:
              </p>
              <div className="DR">
                <div style={{ width: "180px" }}>
                  <Input
                    type={"date"}
                    value={formData.toDate}
                    onChange={handleInputChange("toDate")}
                  />
                </div>
              </div>
              <p style={{ fontSize: "11px" }} className="mt10">
                STAFF NAME :
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedStaff}
                  onChange={handleInputChange("selectedStaff")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option>Devi Employer</option>
                    </>
                  }
                />
              </div>
              <div className="mt10">
                <button className="SEARCH-btn" onClick={handleFetchData}>
                  SEARCH
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* condition3 */}

      {buttonClick == "Es1-REPORT" && (
        <>
          <div className="tb1">
            <div className="MonYear">
              <p style={{ fontSize: "11px" }} className="mt10">
                MONTH:
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedMonth}
                  onChange={handleInputChange("selectedMonth")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value={"1"}>JAN</option>
                      <option value={"2"}>FEB</option>
                      <option value={"3"}>MAR</option>
                      <option value={"4"}>APR</option>
                      <option value={"5"}>MAY</option>
                      <option value={"6"}>JUN</option>
                      <option value={"7"}>JUL</option>
                      <option value={"8"}>AUG</option>
                      <option value={"9"}>SEP</option>
                      <option value={"10"}>OCT</option>
                      <option value={"11"}>NOV</option>
                      <option value={"12"}>DEC</option>
                    </>
                  }
                />
              </div>
              <p style={{ fontSize: "11px" }} className="mt10">
                Year:
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedYear}
                  onChange={handleInputChange("selectedYear")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                    </>
                  }
                />
              </div>
              <div className="mt10">
                <button className="EXECUTE-btn" onClick={handleFetchData}>
                  {" "}
                  EXECUTE
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* condition3 */}

      {buttonClick == "PF-REPORT" && (
        <>
          <div className="tb1">
            <div className="MonYear">
              <p style={{ fontSize: "11px" }} className="mt10">
                MONTH:
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedMonth}
                  onChange={handleInputChange("selectedMonth")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value={"1"}>JAN</option>
                      <option value={"2"}>FEB</option>
                      <option value={"3"}>MAR</option>
                      <option value={"4"}>APR</option>
                      <option value={"5"}>MAY</option>
                      <option value={"6"}>JUN</option>
                      <option value={"7"}>JUL</option>
                      <option value={"8"}>AUG</option>
                      <option value={"9"}>SEP</option>
                      <option value={"10"}>OCT</option>
                      <option value={"11"}>NOV</option>
                      <option value={"12"}>DEC</option>
                    </>
                  }
                />
              </div>
              <p style={{ fontSize: "11px" }} className="mt10">
                Year:
              </p>
              <div className="DR">
                <Dropdown
                  value={formData.selectedYear}
                  onChange={handleInputChange("selectedYear")}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                    </>
                  }
                />
              </div>
              <div className="mt10">
                <button className="EXECUTE-btn" onClick={handleFetchData}>
                  {" "}
                  EXECUTE
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {staff_ledger_list?.length > 0 && (
        <>
          {staff_loading ? (
            <PageLoading />
          ) : (
            <TableNew
              mt={5}
              nodata={staff_ledger_list?.length !== 0 ? false : true}
              headData={[
                "EMPLOYEE CODE",
                "EMPLOYEE NAME",
                "DATE OF BIRTH",
                "DESIGNATION",
                "ROLE",
                "DATE OF JOINING",
              ]}
              body={
                <>
                  {staff_ledger_list?.length != 0 &&
                    staff_ledger_list?.map((item, i) => (
                      <tr key={i}>
                        <td
                          // style={{ cursor: "pointer" }}
                          onClick={() => {
                            // setEditItem(item);
                            // setShowEdit(true);
                          }}
                        >
                          {item?.EMP_CODE}
                        </td>
                        <td>{item?.EMP_NAME}</td>
                        <td>{moment(item?.DOB).format("DD/MM/YYYY")}</td>
                        <td>{item?.DESIGNATION}</td>
                        <td>{item?.ROLE}</td>
                        <td>{moment(item?.DOJ).format("DD/MM/YYYY")}</td>
                      </tr>
                    ))}
                </>
              }
            />
          )}
        </>
      )}
      {staff_ledger_list?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0%",
          }}
          className="mt10"
        >
          <div>
            <p style={{ fontSize: "12px" }}> Showing 1 to 1 entries</p>
          </div>
          <div className="df">
            <button
              style={{
                padding: "10px",
                fontSize: "10px",
                borderRadius: "5px",
                backgroundColor: "white",
                border: "0.5px solid  #2cbfca",
              }}
            >
              previous
            </button>
            <button
              style={{
                backgroundColor: "#0971fe ",
                padding: "10px",
                fontSize: "10px",
                border: "0.5px solid",
              }}
            >
              1
            </button>
            <button
              style={{
                padding: "10px",
                fontSize: "10px",
                borderRadius: "5px",
                backgroundColor: "white",
                border: "0.5px solid  #2cbfca",
              }}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {alerts && (
        <SmallModal
          isOpen={alerts}
          setIsOpen={setAlerts}
          title={"alert"}
          mess={alertsMess}
          okbtn={setAlertsMess}
        />
      )}
      {staff_loading && <Loader />}
    </div>
  );
};

export default Reports;
