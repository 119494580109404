import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import { DateValidator2 } from "../../utils/validation";
import SmallModal from "../../components/modal/SmallModal";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import NPInput from "../../components/inputs/NPInput";
import { HelpButton } from "../../components/btn/Buttons";
import moment from "moment";
import Button from "../../components/btn/Button";
import { is_ftl_api } from "../../utils/apis";
import { toast } from "react-toastify";
import { get_home_data } from "../profile/UsersSlice";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import Loader from "../../components/Loader";
import {
  get_eff_date,
  get_effective_date,
  get_omc,
  get_user_id,
} from "../../redux/slice/SlideSlice";
import { Alerts } from "../../components/Alerts/Alerts";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const userIdString = localStorage.getItem("userId");
  const userName = JSON.parse(userIdString)?.Name;
  const [isFTL, setIsFTL] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const { home_data } = useSelector((state) => state.profile);
  const IS_FTL = localStorage.getItem("IS_FTL");
  const navigate = useNavigate();

  const [isFTLData, setIsFTLData] = useState({
    FY: getCurrentFinancialYear(),
    b2b: "0001",
    b2c: "0001",
    payment_voucher: "0001",
    reciept_voucher: "0001",
    delivery_challan: "0001",
    quotation: "0001",
    purchase_order: "0001",
    expenses_voucher: "0001",
    cash_balance: "0.00",
    load_balance: "0.00",
    svtv_balance: "0.00",
  });
  const { today, user_id } = useSelector((state) => state.other);

  useEffect(() => {
    store.dispatch(get_effective_date());
    store.dispatch(get_user_id());
    store.dispatch(get_omc());
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if (IS_FTL == 0) {
        setIsFTL(true);
      } else {
        setIsFTL(false);
        store.dispatch(get_home_data(user_id));
      }
    };
    fetchData();
  }, [IS_FTL]);

  function getCurrentFinancialYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const fiscalStartMonth = 3;
    const fiscalYear =
      today.getMonth() < fiscalStartMonth ? currentYear - 1 : currentYear;

    const fiscalYearStart = new Date(fiscalYear, fiscalStartMonth, 1);
    const fiscalYearEnd = new Date(fiscalYear + 1, fiscalStartMonth - 1, 31);

    const formattedStartYear = fiscalYearStart.getFullYear();
    const formattedEndYear = fiscalYearEnd.getFullYear();

    return `${formattedStartYear.toString().slice(2)}`;
  }

  const HandleSubmitFTL = async () => {
    const arr = [];

    arr.push(!isFTLData.FY ? "Please Select FY" : "");
    const currentDate = moment();
    const currentFYStart =
      currentDate.month() < 3
        ? moment().subtract(1, "years").startOf("year").add(3, "months")
        : moment().startOf("year").add(3, "months");
    const formattedFYStart = currentFYStart.format("DD/MM/YYYY");
    const data_valid = await DateValidator2(
      moment(isFTLData?.effe_date).format("DD/MM/YYYY"),
      today,
      formattedFYStart,
      `Effective Date cant be before ${moment("01/04/2020").format(
        "DD/MM/YYYY"
      )} `,
      `Effective Date cant be a future date`
    );

    arr.push(
      !isFTLData?.effe_date ||
        (isFTLData?.effe_date && isFTLData?.effe_date?.length != 10)
        ? "Please Select Effective Date"
        : data_valid != true
        ? data_valid?.[0]
        : ""
    );
    arr.push(
      !isFTLData?.b2b || (isFTLData?.b2b && isFTLData?.b2b.length == 0)
        ? "Please Enter B2B INVOICE COUNTER"
        : ""
    );
    arr.push(
      !isFTLData?.b2c || (isFTLData?.b2c && isFTLData?.b2c.length == 0)
        ? "Please Enter B2C INVOICE COUNTER"
        : ""
    );
    arr.push(
      !isFTLData?.payment_voucher ||
        (isFTLData?.payment_voucher && isFTLData?.payment_voucher.length == 0)
        ? "Please Enter PAYMENT VOUCHER"
        : ""
    );
    arr.push(
      !isFTLData?.reciept_voucher ||
        (isFTLData?.reciept_voucher && isFTLData?.reciept_voucher.length == 0)
        ? "Please Enter RECIEPTS VOUCHER"
        : ""
    );

    arr.push(
      !isFTLData?.delivery_challan ||
        (isFTLData?.delivery_challan && isFTLData?.delivery_challan.length == 0)
        ? "Please Enter DELIVERY CHALLAN"
        : ""
    );
    arr.push(
      !isFTLData?.quotation ||
        (isFTLData?.quotation && isFTLData?.quotation.length == 0)
        ? "Please Enter QUOTATION"
        : ""
    );

    arr.push(
      !isFTLData?.purchase_order ||
        (isFTLData?.purchase_order && isFTLData?.purchase_order.length == 0)
        ? "Please Enter PURCHASE ORDER"
        : ""
    );
    arr.push(
      !isFTLData?.expenses_voucher ||
        (isFTLData?.expenses_voucher && isFTLData?.expenses_voucher.length == 0)
        ? "Please Enter EXPENSES VOUCHER"
        : ""
    );
    arr.push(
      !isFTLData?.cash_balance ||
        (isFTLData?.cash_balance && isFTLData?.cash_balance.length == 0)
        ? "Please Enter CASH BALANCE"
        : isFTLData?.cash_balance <= 0
        ? "CASH BALANCE must be greaterthen zero"
        : ""
    );
    arr.push(
      !isFTLData?.load_balance ||
        (isFTLData?.load_balance && isFTLData?.load_balance.length == 0)
        ? "Please Enter LOAD ACCOUNT OPEN BALANCE"
        : isFTLData?.load_balance <= 0
        ? "LOAD BALANCE must be greaterthen zero"
        : ""
    );
    arr.push(
      !isFTLData?.svtv_balance ||
        (isFTLData?.svtv_balance && isFTLData?.svtv_balance.length == 0)
        ? "Please Enter SVTV ACCOUNT BALANCE"
        : isFTLData?.svtv_balance <= 0
        ? "SVTV BALANCE must be greaterthen zero"
        : ""
    );
    if (arr.filter((v) => v !== "")?.length > 0) {
      {
        setOpen1(true);
        setMess(<Alerts data={arr} />);
      }
    } else if (arr.filter((v) => v !== "")?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "ftl");
        body.append("agencyid", user_id);
        body.append("fin_year", isFTLData?.FY);
        body.append("eff_date", isFTLData?.effe_date);
        body.append("sino", isFTLData?.b2b);
        body.append("csno", isFTLData?.b2c);
        body.append("payno", isFTLData?.payment_voucher);
        body.append("recno", isFTLData?.reciept_voucher);
        body.append("delno", isFTLData?.delivery_challan);
        body.append("quono", isFTLData?.quotation);
        body.append("pono", isFTLData?.purchase_order);
        body.append("expno", isFTLData?.expenses_voucher);
        body.append("cash", isFTLData?.cash_balance);
        body.append("load", isFTLData?.load_balance);
        body.append("svtv", isFTLData?.svtv_balance);
        const responce = await is_ftl_api(body);
        setShowLoader(false);
        if (responce.success) {
          toast(responce.message);
          localStorage.removeItem("IS_FTL");
          localStorage.setItem("IS_FTL", 1);
          store.dispatch(get_home_data(user_id));
          // store.dispatch(get_eff_date(responce?.date));
          localStorage.setItem("eff_date", responce?.date);
          navigate("/profile/Ainfo");
          setIsFTL(false);
        } else if (!responce.success) {
          setOpen1(true);
          setMess(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i > currentYear - 1; i--) {
    years.push(i + "-" + (i + 1).toString().substring(2, 4));
  }

  // end home page content

  return (
    <div className="container">
      <div className={styles.home_title_container}>
        <div className={styles.wel}>Welcome !</div>
        <div className={styles.name_data}>{userName}</div>
        <div className={styles.desc}>At a glance summary of your account.</div>
      </div>
      <div className={styles.home_2nd}>
        <div className={styles.title_over}>Overview</div>
        <div className={styles.home_content}>
          <div
            className={styles.cards}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              flex: "2",
              gap: "0",
            }}
          >
            <div className={styles.card_1}>
              <div>CASH BALANCE</div>
              <div>{Number(home_data?.cash_balance).toFixed(2)}</div>
            </div>
            <div className={styles.card_1}>
              <div>LAST DAYEND DATE</div>
              <div>{moment(home_data?.dayend).format("DD-MMM-YYYY")}</div>
            </div>
          </div>
          <div className={styles.cards}>
            <div
              className={styles.card_1}
              style={{
                backgroundColor: "#edf1f5",
                color: "#6f6f6f",
                borderRadius: ".5rem",
              }}
            >
              <div>LOAD ACCOUNT</div>
              <div className={styles.progress}>
                <div
                  className={styles.progress_bar}
                  style={{
                    borderRadius: "1.25rem",
                    width: "70%",
                    backgroundColor: "rgb(0, 123, 255)",
                    height: "6px",
                  }}
                ></div>
              </div>
              <div>
                {home_data?.bank?.length > 0
                  ? Number(home_data?.bank[0]?.ACC_CB).toFixed(2)
                  : 0}
                {/* {home_data?.bank[0]?.ACC_CB} */}
              </div>
            </div>
            <div
              className={styles.card_1}
              style={{
                backgroundColor: "#edf1f5",
                color: "#6f6f6f",
                borderRadius: ".5rem",
              }}
            >
              <div>RECEIVABLES</div>
              <div>{Number(home_data?.receivables).toFixed(2)}</div>
            </div>
          </div>
          <div className={styles.cards}>
            <div
              className={styles.card_1}
              style={{
                backgroundColor: "#edf1f5",
                color: "#6f6f6f",
                borderRadius: ".5rem",
              }}
            >
              <div>SV ACCOUNT</div>
              <div>
                {home_data?.bank?.length > 0
                  ? Number(home_data?.bank[1]?.ACC_CB).toFixed(2)
                  : 0}
                {/* {home_data?.bank[1]?.ACC_CB} */}
              </div>
            </div>
            <div
              className={styles.card_1}
              style={{
                backgroundColor: "#edf1f5",
                color: "#6f6f6f",
                borderRadius: ".5rem",
              }}
            >
              <div>PAYABLES</div>
              <div>{Number(home_data?.payables).toFixed(2) || 0}</div>
            </div>
          </div>
        </div>
      </div>
      {isFTL && (
        <SmallModal
          closeBtnHide={false}
          okBtnHide={false}
          isOpen={isFTL}
          setIsOpen={setIsFTL}
          style={{ minWidth: "40rem" }}
          okbtn={setIsFTL}
          header_right={
            <HelpButton
              link={[
                "https://youtu.be/A971FsmllM4",
                "https://youtu.be/v5AAVwSlxqs",
              ]}
            />
          }
          title={"PLEASE ENTER THE FOLLOWING DETAILS"}
          mess={
            <div className="pin-ver" style={{ margin: "2rem 0" }}>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  FINANCIAL YEAR
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  <Dropdown
                    fontSize={"15px"}
                    value={isFTLData.FY}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        FY: e.target.value,
                      }));
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        {/* <option value={"23"}>2023-24</option>
                        <option value={"22"}>2022-23</option>
                        <option value={"21"}>2021-22</option>
                        <option value={"20"}>2020-21</option> */}
                        {years.map((year, index) => (
                          <option key={index} value={year?.slice(2, 4)}>
                            {year}
                          </option>
                        ))}
                      </>
                    }
                  />
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  EFFECTIVE DATE
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  <Input
                    type={"date"}
                    value={isFTLData?.effe_date}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setIsFTLData({
                          ...isFTLData,
                          effe_date: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  margin: "2rem 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  B2B INVOICE COUNTER
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  <NPInput
                    type={"text"}
                    prefixItem={`SI-${isFTLData.FY}-`}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        b2b: e.target.value,
                      }));
                    }}
                    value={isFTLData.b2b}
                  />
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  B2C INVOICE COUNTER
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  <NPInput
                    prefixItem={`CS-${isFTLData.FY}-`}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        b2c: e.target.value,
                      }));
                    }}
                    value={isFTLData.b2c}
                    type={"text"}
                  />
                </div>
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  margin: "2rem 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  PAYMENT VOUCHER
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  <NPInput
                    type={"text"}
                    prefixItem={`PT-${isFTLData.FY}-`}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        payment_voucher: e.target.value,
                      }));
                    }}
                    value={isFTLData.payment_voucher}
                  />
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  RECIEPTS VOUCHER
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  <NPInput
                    type={"text"}
                    prefixItem={`RT-${isFTLData.FY}-`}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        reciept_voucher: e.target.value,
                      }));
                    }}
                    value={isFTLData.reciept_voucher}
                  />
                </div>
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  margin: "2rem 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  DELIVERY CHALLAN
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  <NPInput
                    prefixItem={`DC-${isFTLData.FY}-`}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        delivery_challan: e.target.value,
                      }));
                    }}
                    value={isFTLData.delivery_challan}
                    type={"text"}
                  />
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  QUOTATION
                </div>
                <div
                  style={{ width: "calc(150px - .5rem)", padding: "0 .25rem" }}
                >
                  <NPInput
                    prefixItem={`QT-${isFTLData.FY}-`}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        quotation: e.target.value,
                      }));
                    }}
                    value={isFTLData.quotation}
                    type={"text"}
                  />
                </div>
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  margin: "2rem 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  PURCHASE ORDER
                </div>
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  <NPInput
                    prefixItem={`PO-${isFTLData.FY}-`}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        purchase_order: e.target.value,
                      }));
                    }}
                    value={isFTLData.purchase_order}
                    type={"text"}
                  />
                </div>
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  EXPENSES VOUCHER
                </div>
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  <NPInput
                    prefixItem={`EX-${isFTLData.FY}-`}
                    onChange={(e) => {
                      setIsFTLData((prevData) => ({
                        ...prevData,
                        expenses_voucher: e.target.value,
                      }));
                    }}
                    value={isFTLData.expenses_voucher}
                    type={"text"}
                  />
                </div>
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  margin: "2rem 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  CASH BALANCE
                </div>
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  <Input
                    placeholder={"CASH BALANCE"}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (inputValue?.length < 12 &&
                          /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(inputValue)) ||
                        e.target.value == 0
                      ) {
                        setIsFTLData((prevData) => ({
                          ...prevData,
                          cash_balance: /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                            inputValue
                          )
                            ? inputValue
                            : "",
                        }));
                      }
                    }}
                    value={isFTLData.cash_balance}
                    type={"text"}
                  />
                </div>
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  LOAD ACC OPEN BALANCE
                </div>
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  <Input
                    placeholder={"LOAD ACC BALANCE"}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (inputValue?.length < 12 &&
                          /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(inputValue)) ||
                        e.target.value == 0
                      ) {
                        setIsFTLData((prevData) => ({
                          ...prevData,
                          load_balance: /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                            inputValue
                          )
                            ? inputValue
                            : "",
                        }));
                      }
                    }}
                    value={isFTLData.load_balance}
                    type={"text"}
                  />
                </div>
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  margin: "2rem 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  SVTV BALANCE
                </div>
                <div style={{ width: "150px", padding: "0 .25rem" }}>
                  <Input
                    placeholder={"SVTV BALANCE"}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (inputValue?.length < 12 &&
                          /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(inputValue)) ||
                        e.target.value == 0
                      ) {
                        setIsFTLData((prevData) => ({
                          ...prevData,
                          svtv_balance: /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                            inputValue
                          )
                            ? inputValue
                            : "",
                        }));
                      }
                    }}
                    value={isFTLData.svtv_balance}
                    type={"text"}
                  />
                </div>
                <div style={{ width: "150px", padding: "0 .25rem" }}></div>
                <div style={{ width: "150px", padding: "0 .25rem" }}></div>
              </div>

              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  margin: "2rem 0",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "30%", padding: "0 5% " }}>
                  <Button onClick={HandleSubmitFTL} title={"SUBMIT DATA"} />
                </div>
              </div>
            </div>
          }
        />
      )}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};
