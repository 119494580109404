import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

export const get_complete_list = createAsyncThunk("list", async (data) => {
  const response = await fetch(base_url + "completelist.php", {
    method: "POST",
  });
  const res = await response.json();
  return res;
});
const completeListSlice = createSlice({
  name: "list",
  initialState: {
    loading: false,
    stateList: [],
    firmList: [],
    omcList: [],
    refillList: [],
    turnoverList: [],
    payrollList: [],
    terms: "",
  },
  extraReducers: (builder) => {
    builder.addCase(get_complete_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;
      if (data.success) {
        state.stateList = data.data.reponselist.states;
        state.firmList = data.data.reponselist.firmtype;
        state.omcList = data.data.reponselist.omc;
        state.refillList = data.data.reponselist.refill;
        state.turnoverList = data.data.reponselist.trunover;
        state.payrollList = data.data.reponselist.payroll;
        state.terms = data.data.reponselist.terms;
      } else {
        state.stateList = [];
        state.firmList = [];
        state.omcList = [];
        state.refillList = [];
        state.turnoverList = [];
        state.payrollList = [];
        state.terms = [];
      }
    });
    builder.addCase(get_complete_list.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_complete_list.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default completeListSlice.reducer;