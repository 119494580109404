import React from "react";
import "./input.css";

const NPInput = ({
  label,
  placeholder,
  error,
  mess,
  type,
  onChange,
  value,
  disabled,
  onBlur,
  mw,
  w,
  onFocus, onKeyDown,prefixItem
}) => {
  return (
    <div className="inp-main">
      {label && <div className="inp-label">{label}</div>}
      <div className="inp-wrap" style={{ display: 'flex' }}>
    {prefixItem && (
        <div style={{
            paddingLeft: ".25rem",
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
            color: "black",
            fontWeight: "400",
            wordWrap: "nowrap",  // This ensures that words won't break
            whiteSpace: "nowrap",  // This ensures that the entire content won't wrap to the next line
            width: "auto"
        }}>
            {prefixItem}
        </div>
    )}
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={{paddingInline:"0px"}}
          className="inp"
          type={type}
          disabled={disabled}
          onBlur={onBlur} // it is used for input type date only
          onFocus={onFocus} // it is Used for input type data and select both
          onKeyDown={onKeyDown} // it is Used for input type data and select both
        />
      </div>
      {mess && <div className="inp-mess">{mess}</div>}
      {error && <div className="inp-error">{error}</div>}
    </div>
  );
};

export default NPInput;
