import React from "react";
import "./payrunsmodal.css";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
import { SaveButton } from "../btn/Buttons";
import { base_url } from "../../utils/utils";

const PayRunsModal = ({ setIsOpen, modalData }) => {
  const handleSaveData = async () => {
    const body = {
      AGENCY_ID: "12345678",
      action: "saveStaffSalaryAndLopData",
      esmonth: 1,
      esyear: 2024,
      fetchtype: "2",
      month: 1,
      year: 2024,
      empsidebarid: 1000002,
      staffrole: ["1"],
      stafftype: ["0"],
      cloutstandloan: 100,
      epdays: 31,
      elopdays: 0,
      eactualdays: 31,
      ebasicpay: 20000,
      ehrapa: 1000,
      ecallowpay: 200,
      emallowpay: 300,
      efallowpay: 150,
      eitaxpay: 0,
      eptaxpay: 0,
      elopamount: 0,
      epaidloanamount: 0,
      enetpay: 18811.68,
      eesiamount: 162.38,
      epfamount: 2675.94,
      loanmonths: 0,
      emipm: 0,
    };
    try {
      const response = await fetch(
        `${base_url}DOMSALES/PayRuns.php`,
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );

      const resJson = await response.json();
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="payruns_modal_main_container">
      <div className="payruns_modal_heading_container">
        <div className="payruns_modal_upper_heading">
          <p className="payruns_modal_description">{modalData.EMP_NAME}</p>
          <p className="payruns_modal_description">Net pay</p>
          <button
            // className={styles.closeBtn}
            style={{ marginLeft: "auto", background: "white", border: "none" }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <RiCloseLine size={25} color="#2c3e50" />
          </button>
        </div>
        <div className="payruns_modal_upper_heading">
          <p className="payruns_modal_description">11111</p>
          <p className="payruns_modal_description">1234</p>
        </div>
      </div>
      <div className="payruns_modal_middle_container">
        <div className="payruns_modal_middle_container_inner_div">
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Payable Days</p>
            <p className="payruns_modal_description_right">30</p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">LOP Days</p>
            <p className="payruns_modal_description_right">0</p>
          </div>
          <div style={{ height: "15px", overflow: "hidden" }}>
            ---------------------------------------------------------
          </div>
        </div>
        <div className="payruns_modal_middle_container_inner_div">
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Actual Payable Days</p>
            <p className="payruns_modal_description_right">30</p>
          </div>
          <div style={{ height: "15px", overflow: "hidden" }}>
            ---------------------------------------------------------
          </div>
        </div>
        <div className="payruns_modal_middle_container_inner_div">
          <div className="payruns_modal_inner_inner_div">
            <p
              className="payruns_modal_description"
              style={{
                color: "rgb(0, 176, 80)",
                fontWeight: "700",
                fontSize: "16px",
              }}
            >
              (+) EARNINGS
            </p>
            <p className="payruns_modal_description_right">AMOUNT</p>
          </div>

          <div style={{ height: "15px", overflow: "hidden" }}>
            ---------------------------------------------------------
          </div>
        </div>
        <div className="payruns_modal_middle_container_inner_div">
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Basic</p>
            <p className="payruns_modal_description_right">
              {modalData?.BASIC_PAY}
            </p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">House Rent Allowance</p>
            <p className="payruns_modal_description_right">{modalData?.HRA}</p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Conveyance Allowance</p>
            <p className="payruns_modal_description_right">
              {modalData?.CONVEYANCE_ALLOWANCE}
            </p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Medical Allowances</p>
            <p className="payruns_modal_description_right">
              {modalData?.MEDICAL_ALLOWANCE}
            </p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Other Allowance</p>
            <p className="payruns_modal_description_right">
              {modalData?.OTHER_ALLOWANCE}
            </p>
          </div>

          <div style={{ height: "15px", overflow: "hidden" }}>
            ---------------------------------------------------------
          </div>
        </div>
        <div className="payruns_modal_middle_container_inner_div">
          <div className="payruns_modal_inner_inner_div">
            <p
              className="payruns_modal_description"
              style={{
                color: "rgb(255, 76, 76)",
                fontWeight: "700",
                fontSize: "16px",
              }}
            >
              (-) DEDUCTIONS
            </p>
            <p className="payruns_modal_description_right">AMOUNT</p>
          </div>
          <div style={{ height: "15px", overflow: "hidden" }}>
            ---------------------------------------------------------
          </div>
        </div>
        <div className="payruns_modal_middle_container_inner_div">
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Taxes</p>
            <p className="payruns_modal_description_right"></p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Income Tax </p>
            <p className="payruns_modal_description_right">0</p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Professional Tax </p>
            <p className="payruns_modal_description_right">0</p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">ESI Amount </p>
            <p className="payruns_modal_description_right">0</p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">PF Amount </p>
            <p className="payruns_modal_description_right">0</p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">LOP Amount </p>
            <p className="payruns_modal_description_right">0.00</p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">Loan Refund </p>
            <p className="payruns_modal_description_right">0.00</p>
          </div>
          <div className="payruns_modal_inner_inner_div">
            <p className="payruns_modal_description">NET PAY </p>
            <p className="payruns_modal_description_right">13726.00</p>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", padding: "30px" }}
      >
        <SaveButton onClick={handleSaveData} />
      </div>
    </div>
  );
};

export default PayRunsModal;
