import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import Button from "../../components/btn/Button";
import Input from "../../components/inputs/Input";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import { forgot_pin_email_api, profile_update_api } from "../../utils/apis";
import { toast } from "react-toastify";
import { passwordRegex } from "../auth/Signup";
import { EmailValidation } from "../../utils/validator";
import moment from "moment";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { get_pin_data, get_profile_data, get_profile_pin } from "./UsersSlice";
import { get_complete_list } from "../../redux/slice/completeListSlice";
import { FaRegEdit } from "react-icons/fa";

const Settinginfo = () => {
  const [openChangePass, setOpenChangePass] = useState(false);
  const [openUpdatePin, setOpenUpdatePin] = useState(false);
  const [openForgotPin, setOpenForgotPin] = useState(false);
  const [openSetPin, setOpenSetPin] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState(false);
  const [showForPinInputs, setShowForPinInputs] = useState(false);
  const [obj, setObj] = useState({});
  const { pin_data } = useSelector((state) => state.profile);
  const { user_id } = useSelector((state) => state.other);
  const { profile_data } = useSelector((state) => state.profile);


  useEffect(() => {
    // store.dispatch(get_complete_list());
  }, [profile_data]);
  
  const handle_update = async () => {
    const arr = [];
    if (
      !obj?.old_password ||
      obj?.old_password.trim().length === 0 ||
      // !passwordRegex.test(obj?.old_password) ||
      !obj?.new_password ||
      obj?.new_password.trim().length === 0 ||
      !passwordRegex.test(obj?.new_password) ||
      !obj?.confirm_password ||
      obj?.confirm_password.trim().length === 0 ||
      obj?.confirm_password != obj?.new_password
    ) {
      arr.push(
        !obj?.old_password
          ? "Please Enter Old Password"
          : obj?.old_password.trim().length === 0
          ? "Please Enter Old Password"
          : // !passwordRegex.test(obj?.old_password) ? "Old Password must be a combination of @,A,a,1.":"")
            ""
      );
      arr.push(
        !obj?.new_password
          ? "Please Enter New Password"
          : obj?.new_password.trim().length === 0
          ? "Please Enter New Password"
          : !passwordRegex.test(obj?.new_password)
          ? "New Password must be a combination of @,A,a,1."
          : ""
      );
      arr.push(
        !obj?.confirm_password
          ? "Please Enter Confirm Password"
          : obj?.confirm_password.trim().length === 0
          ? "Please Enter Confirm Password"
          : !passwordRegex.test(obj?.confirm_password)
          ? "Confirm Password must be a combination of @,A,a,1."
          : obj?.new_password != obj?.confirm_password
          ? "Please Enter Same Value For PASSWORD and CONFIRM PASSWORD."
          : ""
      );
    }

    if (arr.length === 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "passwordupdate");
        body.append("agencyid", user_id);
        body.append("oldpassword", obj?.old_password);
        body.append("password", obj?.new_password);

        const responce = await profile_update_api(body);
        setShowLoader(false);
        if (responce.success) {
          setOpenChangePass(false);
          toast.success(responce.message);
          setObj({});
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen(true);
      setMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {arr
            .filter((v) => v !== "")
            .map((item, i) => (
              <p style={{ textAlign: "left" }} key={i}>
                {item}
              </p>
            ))}
        </div>
      );
    }
  };
  const handle_pin_update = async () => {
    const arr = [];
    if (
      !obj?.old_pin ||
      obj?.old_pin.trim().length !== 4 ||
      !obj?.new_pin ||
      obj?.new_pin.trim().length !== 4 ||
      !obj?.conf_pin ||
      obj?.conf_pin.trim().length !== 4 ||
      obj?.new_pin != obj?.conf_pin
    ) {
      arr.push(
        !obj?.old_pin
          ? "Please Enter Old Pin"
          : obj?.old_pin.trim().length === 0
          ? "Please Enter Old Pin"
          : ""
      );
      arr.push(
        !obj?.new_pin
          ? "Please Enter New Pin"
          : obj?.new_pin.trim().length === 0
          ? "Please Enter New Pin"
          : ""
      );

      arr.push(
        !obj?.conf_pin
          ? "Please Enter Confirm Pin"
          : obj?.conf_pin.trim().length === 0
          ? "Please Enter Confirm Pin"
          : obj?.new_pin != obj?.conf_pin
          ? "Pin not Matched"
          : obj?.old_pin.length !== 4 ||
            obj?.new_pin.length !== 4 ||
            obj?.conf_pin.length !== 4
          ? "Pin must be 4 digit only"
          : ""
      );
    }

    if (arr.length === 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "pinupdate");
        body.append("agencyid", user_id);
        body.append("oldpin", obj?.old_pin);
        body.append("pin", obj?.new_pin);

        const responce = await profile_update_api(body);
        setShowLoader(false);
        if (responce.success) {
          setOpenUpdatePin(false);
          toast.success(responce.message);
          setObj({});
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen(true);
      setMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {arr
            .filter((v) => v !== "")
            .map((item, i) => (
              <p style={{ textAlign: "left" }} key={i}>
                {item}
              </p>
            ))}
        </div>
      );
    }
  };

  const handle_send_mail = async () => {
    if (!EmailValidation.test(obj?.email)) {
      setOpen(true);
      setMess("Please Enter Valid Mail");
    } else if (obj?.email != profile_data?.email) {
      setOpen(true);
      setMess("Please Enter Registered Mail");
    } else {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("agencyid", user_id);
        body.append("email", obj?.email);
        const responce = await forgot_pin_email_api(body);
        setShowLoader(false);
        if (responce.success) {
          setShowForPinInputs(true);
          toast.success(responce.message);
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handle_forgot_pin = async () => {
    const arr = [];
    if (
      !obj?.email_otp ||
      obj?.email_otp.trim().length != 6 ||
      !obj?.new_for_pin ||
      obj?.new_for_pin.trim().length != 4
    ) {
      arr.push(
        !obj?.email_otp
          ? "Please Enter Email OTP"
          : obj?.email_otp.trim().length != 6
          ? "Please Enter valid Email OTP"
          : ""
      );
      arr.push(
        !obj?.new_for_pin
          ? "Please Enter Pin"
          : obj?.new_for_pin.trim().length != 4
          ? "Please Enter valid Pin"
          : ""
      );
    }

    if (arr.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "forgotpin");
        body.append("agencyid", user_id);
        body.append("code", obj?.email_otp);
        body.append("newpin", obj?.new_for_pin);
        body.append("reenter", obj?.new_for_pin);
        const responce = await profile_update_api(body);
        setShowLoader(false);
        if (responce.success) {
          setOpenForgotPin(false);
          setShowForPinInputs(false);
          toast.success(responce.message);
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen(true);
      setMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {arr
            .filter((v) => v !== "")
            .map((item, i) => (
              <p style={{ textAlign: "left" }} key={i}>
                {item}
              </p>
            ))}
        </div>
      );
    }
  };

  const handle_set_pin = async () => {
    if (!obj?.set_pin) {
      setOpen(true);
      setMess("Please Enter Pin");
    } else if (obj?.set_pin?.length != 4) {
      setOpen(true);
      setMess("Pin Must be 4 digit only");
    } else {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "setpin");
        body.append("agencyid", user_id);
        body.append("pinnumber", obj?.set_pin);
        const responce = await profile_update_api(body);
        setShowLoader(false);
        if (responce.success) {
          store.dispatch(get_pin_data(1));
          setOpenSetPin(false);
          toast.success(responce.message);
          // store.dispatch(get_profile_pin("4"));
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className={styles.p_card_div}>
      <div className={styles.card_heading}>Security Settings</div>
      <div className={styles.p_card}>
        <div className={styles.setting_inner_row}>
          <div style={{ flex: 1 }}>
            <div className={styles.setting_heading}>Change Password</div>
            <div className={styles.setting_sub_heading}>
              Set a unique password to protect your account.
            </div>
          </div>
          <FaRegEdit
                        size={20}
            onClick={() => setOpenChangePass(true)}
                        cursor="pointer"
                        />
          {/* <Button
            title={"CHANGE PASSWORD"}
            onClick={() => setOpenChangePass(true)}
          /> */}
        </div>
        <div className={styles.p_row_line} />
        <div className={styles.setting_inner_row}>
          <div style={{ flex: 1 }}>
            <div className={styles.setting_heading}>Security PIN</div>
            <div className={styles.setting_sub_heading}>
              Update Security PIN for Refil, ARB and Credit Limit/ Set New PIN
              for Refil, ARB and Credit Limit
            </div>
          </div>
          <div className={styles.setting_btn_wrap}>
            {pin_data == 0 ? (
              <Button title={"SET PIN"} onClick={() => setOpenSetPin(true)} />
            ) : (
              <>
                <Button
                  title={"UPDATE"}
                  onClick={() => setOpenUpdatePin(true)}
                />
                <Button
                  title={"FORGOT"}
                  onClick={() => {
                    setOpenForgotPin(true);
                    setShowForPinInputs(false);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.p_row_line} />
        <div className={styles.setting_inner_row}>
          <div style={{ flex: 1 }}>
            <div className={styles.setting_heading}>Subscription</div>
            <div className={styles.setting_sub_heading}>
              Service A Valid UpTo:{" "}
              {moment(profile_data?.serviceA).format("DD MMM YYYY")}
              <div style={{ color: "red" }}>
                Your serviceA is expired, Please Renewal it.
              </div>
            </div>
            <div className={styles.setting_sub_heading}>
              Service B Valid UpTo:{" "}
              {moment(profile_data?.serviceA).format("DD MMM YYYY")}
              <div style={{ color: "red" }}>
                Your serviceB is expired, Please Renewal it.
              </div>
            </div>
            <div className={styles.setting_sub_heading}>
              Service C Valid UpTo:{" "}
              {moment(profile_data?.serviceA).format("DD MMM YYYY")}
              <div style={{ color: "red" }}>
                Your serviceC is expired, Please Renewal it.
              </div>
            </div>
            <div className={styles.setting_sub_heading}>
              Service D Valid UpTo:{" "}
              {moment(profile_data?.serviceA).format("DD MMM YYYY")}
              <div style={{ color: "red" }}>
                Your serviceD is expired, Please Renewal it.
              </div>
            </div>
          </div>
          <div className={styles.setting_btn_wrap}>
            <Button
              title={"RENEWAL"}
              onClick={() => console.log("this Functionality is under working")}
            />
          </div>
        </div>

        {openChangePass && (
          <SmallModal
            okBtnHide={false}
            isOpen={openChangePass}
            setIsOpen={setOpenChangePass}
            style={{ minWidth: "30rem" }}
            okbtn={setOpenChangePass}
            title={"Change Password"}
            mess={
              <div className="pin-ver" style={{ margin: "2rem 0" }}>
                <div
                  className="pin-ver-1st"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <Input
                    w={"90%"}
                    placeholder={"Old Password"}
                    type={showPass1 ? "text" : "password"}
                    pass={true}
                    setShowPass={setShowPass1}
                    showPass={showPass1}
                    value={obj?.old_password}
                    onChange={(e) => {
                      if (e.target.value.length <= 12) {
                        setObj({
                          ...obj,
                          old_password: e.target.value,
                        });
                      }
                    }}
                  />
                  <Input
                    w={"90%"}
                    placeholder={"New Password"}
                    type={showPass2 ? "text" : "password"}
                    pass={true}
                    setShowPass={setShowPass2}
                    showPass={showPass2}
                    value={obj?.new_password}
                    onChange={(e) => {
                      if (e.target.value.length <= 12) {
                        setObj({
                          ...obj,
                          new_password: e.target.value,
                        });
                      }
                    }}
                  />
                  <Input
                    w={"90%"}
                    type={showPass3 ? "text" : "password"}
                    placeholder={"Confirm Password"}
                    pass={true}
                    setShowPass={setShowPass3}
                    showPass={showPass3}
                    value={obj?.confirm_password}
                    onChange={(e) => {
                      if (e.target.value.length <= 12) {
                        setObj({
                          ...obj,
                          confirm_password: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <div
                  className="pin-ver-1st"
                  style={{
                    display: "flex",
                    margin: "2rem 0",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ padding: "0 5% " }}>
                    <Button onClick={handle_update} title={"SUBMIT"} />
                  </div>
                </div>
              </div>
            }
          />
        )}
        {openUpdatePin && (
          <SmallModal
            okBtnHide={false}
            isOpen={openUpdatePin}
            setIsOpen={setOpenUpdatePin}
            style={{ minWidth: "30rem" }}
            okbtn={setOpenUpdatePin}
            title={"Change Password"}
            mess={
              <div className="pin-ver" style={{ margin: "2rem 0" }}>
                <div
                  className="pin-ver-1st"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <Input
                    w={"90%"}
                    placeholder={"Old Pin"}
                    type={"password"}
                    setShowPass={setShowPass1}
                    showPass={showPass1}
                    value={obj?.old_pin}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (/^\d{0,4}$/.test(inputValue) &&
                          inputValue?.length <= 4) ||
                        !obj?.old_pin
                      ) {
                        setObj({
                          ...obj,
                          old_pin: /^\d{0,4}$/.test(inputValue)
                            ? inputValue
                            : "",
                        });
                      }
                    }}
                  />
                  <Input
                    w={"90%"}
                    placeholder={"New Pin"}
                    type={"password"}
                    setShowPass={setShowPass2}
                    value={obj?.new_pin}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (/^\d{0,4}$/.test(inputValue) &&
                          inputValue?.length <= 4) ||
                        !obj?.new_pin
                      ) {
                        setObj({
                          ...obj,
                          new_pin: /^\d{0,4}$/.test(inputValue)
                            ? inputValue
                            : "",
                        });
                      }
                    }}
                  />
                  <Input
                    w={"90%"}
                    type={"password"}
                    placeholder={"Confirm Pin"}
                    setShowPass={setShowPass3}
                    showPass={showPass3}
                    value={obj?.conf_pin}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (/^\d{0,4}$/.test(inputValue) &&
                          inputValue?.length <= 4) ||
                        !obj?.conf_pin
                      ) {
                        setObj({
                          ...obj,
                          conf_pin: /^\d{0,4}$/.test(inputValue)
                            ? inputValue
                            : "",
                        });
                      }
                    }}
                  />
                </div>
                <div
                  className="pin-ver-1st"
                  style={{
                    display: "flex",
                    margin: "2rem 0",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ padding: "0 5% " }}>
                    <Button onClick={handle_pin_update} title={"SUBMIT"} />
                  </div>
                </div>
              </div>
            }
          />
        )}
        {openForgotPin && (
          <SmallModal
            okBtnHide={false}
            isOpen={openForgotPin}
            setIsOpen={setOpenForgotPin}
            style={{ minWidth: "30rem" }}
            okbtn={setOpenForgotPin}
            title={"Forgot Pin"}
            mess={
              showForPinInputs ? (
                <div className="pin-ver" style={{ margin: "2rem 0" }}>
                  <div
                    className="pin-ver-1st"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <Input w={"90%"} disabled={true} value={user_id} />
                    <Input
                      w={"90%"}
                      placeholder={"Email OTP"}
                      type={"password"}
                      value={obj?.email_otp}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d{0,6}$/.test(inputValue) || !obj?.email_otp) {
                          setObj({
                            ...obj,
                            email_otp: /^\d{0,6}$/.test(inputValue)
                              ? inputValue
                              : "",
                          });
                        }
                      }}
                    />
                    <Input
                      w={"90%"}
                      type={"password"}
                      placeholder={"new Pin"}
                      setShowPass={setShowPass3}
                      showPass={showPass3}
                      value={obj?.new_for_pin}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d{0,4}$/.test(inputValue) || !obj?.new_for_pin) {
                          setObj({
                            ...obj,
                            new_for_pin: /^\d{0,4}$/.test(inputValue)
                              ? inputValue
                              : "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div
                    className="pin-ver-1st"
                    style={{
                      display: "flex",
                      margin: "2rem 0",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ padding: "0 5% " }}>
                      <Button onClick={handle_forgot_pin} title={"SUBMIT"} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pin-ver" style={{ margin: "2rem 0" }}>
                  <div style={{ color: "red", padding: "0rem 0 2rem 0" }}>
                    Please enter your email address & We will send a
                    confirmation code
                  </div>
                  <div
                    className="pin-ver-1st"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <Input
                      w={"90%"}
                      placeholder={"Email"}
                      value={obj?.email}
                      onChange={(e) => {
                        setObj({
                          ...obj,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div
                    className="pin-ver-1st"
                    style={{
                      display: "flex",
                      margin: "2rem 0",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ padding: "0 5% " }}>
                      <Button onClick={handle_send_mail} title={"SUBMIT"} />
                    </div>
                  </div>
                </div>
              )
            }
          />
        )}
        {openSetPin && (
          <SmallModal
            okBtnHide={false}
            isOpen={openSetPin}
            setIsOpen={setOpenSetPin}
            style={{ minWidth: "30rem" }}
            okbtn={setOpenSetPin}
            title={"Set Pin"}
            mess={
              <>
                <div className="pin-ver">
                  <div
                    className="pin-ver-1st"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "2rem",
                    }}
                  >
                    <div
                      style={{
                        width: "70%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <label style={{ width: "30%", fontSize: "14px" }}>
                        Pin Number:
                      </label>
                      <div style={{ width: "70%" }}>
                        <Input
                          type={"password"}
                          placeholder={"Enter Pin Here"}
                          value={obj?.set_pin}
                          onChange={(event) => {
                            const numericValue = event.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            if (numericValue.length <= 4) {
                              setObj({ ...obj, set_pin: numericValue });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ width: "30%", padding: "0 5% " }}>
                      <Button onClick={handle_set_pin} title={"SUBMIT"} />
                    </div>
                  </div>
                  {/* <div style={{ width: "100px", margin: "1.5rem 0" }}>
                    <BackButton onClick={() => navigate(-1)} />
                  </div> */}
                </div>
              </>
            }
          />
        )}

        {open && (
          <SmallModal
            isOpen={open}
            setIsOpen={setOpen}
            title={"alerts"}
            mess={mess}
            okbtn={setOpen}
          />
        )}
        {showLoader && <Loader />}
      </div>
    </div>
  );
};

export default Settinginfo;
