import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  EditButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import PageLoading from "../../../components/PageLoading";
import Table from "../../../components/table/Table";
import { DeleteIcon } from "../../../utils/utils";
import { set_credit_limit_API } from "../../../utils/apis";
import { toast } from "react-toastify";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import Input from "../../../components/inputs/Input";
import axios from "axios";
import Dropdown from "../../../components/inputs/Dropdown";
import TableNew from "../../../components/table/TableWithPagination";
import store from "../../../redux/store";
import { FaRegEye } from "react-icons/fa";
import {
  get_credit_limit_list,
  get_customer_vender_list,
  get_equipment_list,
  get_service_master_list,
} from "../masterdataSlice";
import { useSelector } from "react-redux";
import Modal from "../../../components/modal/Modal";
import { Pagination2 } from "../../../components/pagination/pagination";
import { PinVerify } from "../../OtherComponent/PinVerify";
import NoData from "../../../components/NoData";
import { DateValidator2 } from "../../../utils/validation";
import moment from "moment";
import { Alerts } from "../../../components/Alerts/Alerts";
import useUniqueEquipmentList from "../../../utils/Hook/useUniqueEquipmentList";

const headData = [
  "CUSTOMER",
  "CREDIT LIMIT",
  "CREDIT LIMIT CONTROL ",
  "CREDIT DAYS",
  "CREDIT DAYS CONTROL",
  "VIEW",
  "ACIONS",
];

export const SetCreditLimit = () => {
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [addHeadData, setAddHeadData] = useState({});
  const [showPinVerify, setShowPinVerify] = useState(true);
  const [pinALert, setPinAlert] = useState(false);
  const [pinAlertMess, setPinAlertMess] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [addData, setAddData] = useState([]);
  const [editItem, setEditItem] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [showAddProd, setShowAddProd] = useState(false);
  const navigate = useNavigate();
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  const {
    cust_vend_list,
    equipment_list,
    service_master_list,
    credit_limit_list,
    credit_limit_loading,
  } = useSelector((state) => state.masterdata);
  const { user_id, eff_date, today } = useSelector((state) => state.other);

  useEffect(() => {
    const fetchData = () => {
      setAddData([]);
      setAddHeadData({});
    };
    if (!isAdd) {
      fetchData();
    }
  }, [isAdd]);

  useEffect(() => {
    store.dispatch(get_equipment_list());
    store.dispatch(get_service_master_list());
    store.dispatch(get_customer_vender_list());
  }, []);

  useEffect(() => {
    store.dispatch(get_credit_limit_list(obj));
  }, [pageNo, entries]);

  // Product Update Function

  const handleChangeSubmit = async () => {
    const data = valiDateChange();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "update");
        body.append("agencyid", user_id);
        body.append("customer_id", editItem?.id);
        body.append("credit_limit", editItem?.credit_limit);
        body.append("ctyp_limit", editItem?.cc_limit == "WARN" ? "2" : "1");
        body.append("credit_days", editItem?.credit_days);
        body.append("ctyp_day", editItem?.cc_days == "WARN" ? "2" : "1");
        editItem?.items?.forEach((v) =>
          body.append("productcode[]", v?.product_id)
        );
        editItem?.items?.forEach((v) => body.append("did[]", v?.id || ""));
        editItem?.items?.forEach((v) => body.append("discount[]", v?.discount));
        editItem?.items?.forEach((v) =>
          body.append("effectivedate[]", v?.effective_date)
        );
        const res = await set_credit_limit_API(body);
        setShowLoader(false);
        if (res.success) {
          setOpenEdit(false);
          toast(res.message);
          store.dispatch(get_credit_limit_list(obj));
        } else if (!res.success) {
          setPinAlertMess(res?.message);
          setPinAlert(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setPinAlertMess(<Alerts data={data} />);
      setPinAlert(true);
    }
  };

  // Product Update Validation Function

  const valiDateChange = (type) => {
    const data = [];
    if (type != "add") {
      if (
        !editItem?.credit_limit ||
        (editItem?.credit_limit && editItem?.credit_limit?.length === 0)
      ) {
        data.push("Please Enter Credit Limit.");
      }
      if (
        !editItem?.credit_days ||
        (editItem?.credit_days && editItem?.credit_days?.length === 0)
      ) {
        data.push("Please Enter Credit Days.");
      } else if (editItem?.credit_days > 180) {
        data.push("Credit Days must be less then 180.");
      }
      if (
        !editItem?.cc_limit ||
        (editItem?.cc_limit && editItem?.cc_limit === 0)
      ) {
        data.push("Please Select Credit Limit Control");
      }

      if (
        !editItem?.cc_days ||
        (editItem?.cc_days && editItem?.cc_days === 0)
      ) {
        data.push("Please Select Credit Days Control");
      }
    }
    for (let v of editItem?.items) {
      if (!v.product_id || (v.product_id && v.product_id?.length === 0)) {
        data.push("Please Select Product.");
      }
      if (!v.discount || (v.discount && v.discount?.length === 0)) {
        data.push("Please Enter Discount.");
      } else if (Number(v.discount) > Number(v?.rsp)) {
        data.push("Discount cant be greaterthen RSP");
      }
      const data1 = DateValidator2(
        moment(v?.effective_date).format("DD/MM/YYYY"),
        today,
        eff_date,
        `Effective Date cant be before ${eff_date} `,
        `Effective Date cant be a future date`
      );
      if (
        !v.effective_date ||
        (v.effective_date && v.effective_date?.length === 0)
      ) {
        data.push("Please Select Effective Date.");
      } else if (data1 != true) {
        data.push(...data1);
      }
    }
    return data;
  };

  // Product Add Validation Function

  const valiDate = (type) => {
    const data = [];
    if (type != "add") {
      if (
        !addHeadData?.cname ||
        (addHeadData?.cname && addHeadData?.cname?.length === 0)
      ) {
        data.push("Please Select Customer.");
      }
      if (
        !addHeadData?.climit ||
        (addHeadData?.climit && addHeadData?.climit?.length === 0)
      ) {
        data.push("Please Enter Credit Limit.");
      }
      if (
        !addHeadData?.day ||
        (addHeadData?.day && addHeadData?.day?.length === 0)
      ) {
        data.push("Please Enter Credit Days.");
      } else if (addHeadData?.day > 180) {
        data.push("Credit Days must be less then 180.");
      }
      if (
        !addHeadData?.control_lim ||
        (addHeadData?.control_lim && addHeadData?.control_lim === 0)
      ) {
        data.push("Please Select Credit Limit Control");
      }

      if (
        !addHeadData?.control ||
        (addHeadData?.control && addHeadData?.control === 0)
      ) {
        data.push("Please Select Credit Days Control");
      }
    }

    if (addData?.length > 0) {
      for (let v of addData) {
        if (
          !v.product_code ||
          (v.product_code && v.product_code?.length === 0)
        ) {
          data.push("Please Select Product.");
        }
        if (!v.discount || (v.discount && v.discount?.length === 0)) {
          data.push("Please Enter Discount.");
        }

        if (Number(v.discount) > Number(v?.rsp)) {
          data.push("Discount cant be greaterthen RSP");
        }
        const data1 = DateValidator2(
          moment(v?.effe_date).format("DD/MM/YYYY"),
          today,
          eff_date,
          `Effective Date cant be before ${eff_date} `,
          `Effective Date cant be a future date`
        );
        if (!v.effe_date || (v.effe_date && v.effe_date?.length === 0)) {
          data.push("Please Select Effective date.");
        } else if (data1 != true) {
          data.push(...data1);
        }
      }
    }
    return data;
  };

  // Product Add Function

  const add_items = async () => {
    const data = valiDate();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("cname", addHeadData?.cname);
        body.append("climit", addHeadData?.climit);
        body.append("day", addHeadData?.day);
        body.append("cc_limit", addHeadData?.control_lim);
        body.append("cc_days", addHeadData?.control);
        // body.append("effective_dat", addHeadData?.control);
        if (addData?.length > 0) {
          addData.forEach((v) => body.append("productcode[]", v.product_code));
          addData.forEach((v) => body.append("discount[]", v.discount));
          addData.forEach((v) => body.append("effectivedate[]", v.effe_date));
        }
        const responce = await set_credit_limit_API(body);
        setShowLoader(false);
        if (responce.success) {
          store.dispatch(get_credit_limit_list(obj));
          setAddData([]);
          setAddHeadData({});
          setIsAdd(false);
          toast(responce?.message);
        } else {
          setPinAlert(true);
          setPinAlertMess(responce?.message);
        }
      } catch (error) {
        setShowLoader(false);
        console.log(error);
      }
    } else {
      setPinAlertMess(<Alerts data={data} />);
      setPinAlert(true);
    }
  };

  // Product Delete Function

  const handleDeleteSetLimit = async (deleteId) => {
    setShowLoader(true);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", deleteId);
    const response = await set_credit_limit_API(body);
    setShowLoader(false);

    if (response.success) {
      setShowDeleteAlert(false);
      toast(response.message, {
        position: "top-center",
      });
      if (credit_limit_list?.length < 2) {
        setPageNo(pageNo - 1);
        store.dispatch(
          get_credit_limit_list({
            pageNo: pageNo - 1,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
      } else {
        store.dispatch(get_credit_limit_list(obj));
      }
    }
  };

  // const handleGetProduct = (item) => {
  //   const equipmentProduct = equipment_list.find(
  //     (v) => v?.PROD_CODE == item?.product_code
  //   );
  //   const serviceProduct = service_master_list.find(
  //     (v) => v?.PROD_CODE === item?.product_code
  //   );

  //   if (equipmentProduct) {
  //     return `${equipmentProduct.CAT_NAME} - ${equipmentProduct.product_name}`;
  //   } else if (serviceProduct) {
  //     return serviceProduct.CAT_DESC;
  //   }

  //   return "";
  // };

  const uniqueEquipmentList = useUniqueEquipmentList(equipment_list);

  return (
    <div className="container">
      <PageHeading
        title={"DISCOUNT/CREDIT LIMIT MASTER"}
        right={
          <>
            <AddButton
              onClick={() => {
                setIsAdd(true);
              }}
            />
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              Hindi={false}
              link={["https://www.youtube.com/watch?v=Yo9jZmdpzrQ"]}
            />
          </>
        }
      />

      {/*Product Add Component */}

      {isAdd > 0 && (
        <Modal
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          title={"DISCOUNT/CREDIT LIMIT MASTER"}
          body={
            <div>
              <div
                className="flex aic gap10 credit-limit"
                style={{ flexWrap: "wrap" }}
              >
                <Dropdown
                  width2=""
                  label={"CUSTOMER"}
                  value={addHeadData?.cname}
                  onChange={(e) => {
                    setAddHeadData({ ...addHeadData, cname: e.target.value });
                  }}
                  options={[<option value={""}>{"SELECT"}</option>].concat(
                    cust_vend_list
                      ?.filter(
                        (v) => v?.CVO_CAT == "1" && v?.cvo_name != "UJWALA"
                      )
                      ?.map((v, i) => (
                        <option value={v?.ID} key={i}>
                          {v?.cvo_name.toUpperCase()}
                        </option>
                      ))
                  )}
                />
                <Input
                  label={"CREDIT LIMIT"}
                  placeholder={"Credit Limit"}
                  value={addHeadData?.climit}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      (inputValue?.length < 11 &&
                        /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(inputValue)) ||
                      inputValue == 0 ||
                      !addHeadData?.climit
                    ) {
                      setAddHeadData({
                        ...addHeadData,
                        climit: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                          inputValue
                        )
                          ? inputValue
                          : "",
                      });
                    }
                  }}
                />
                <Dropdown
                  width2=""
                  label={"CREDIT LIMIT CONTROL"}
                  value={addHeadData?.control_lim}
                  onChange={(e) => {
                    setAddHeadData({
                      ...addHeadData,
                      control_lim: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"2"}>WARN</option>
                      <option value={"1"}>BLOCK</option>
                    </>
                  }
                />
                <Input
                  label={"CREDIT DAY "}
                  placeholder={"Credit Day"}
                  value={addHeadData.day}
                  onChange={(e) => {
                    const validInput = e.target.value.replace(/[^0-9]/g, "");
                    if (validInput?.length < 4) {
                      setAddHeadData({ ...addHeadData, day: validInput });
                    }
                  }}
                />
                <Dropdown
                  width2=""
                  label={"CREDIT DAYS CONTROL"}
                  value={addHeadData.control}
                  onChange={(e) => {
                    setAddHeadData({
                      ...addHeadData,
                      control: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"2"}>WARN</option>
                      <option value={"1"}>BLOCK</option>
                    </>
                  }
                />
              </div>

              {addData?.length > 0 && (
                <div>
                  <Table
                    mt={5}
                    headData={[
                      "PRODUCT",
                      "RSP(PER CYL.)",
                      "RSP(PER KG)",
                      "DISCOUNT ON RSP(PER CYL.)",
                      "EFFECTIVE DATE",
                      "ACTIONS",
                    ]}
                    body={
                      addData?.length > 0 && (
                        <>
                          {addData.map((item, i) => (
                            <tr className="MD-tr" key={i}>
                              <td>
                                <Dropdown
                                  width2=""
                                  value={item?.product_code}
                                  onChange={async (e) => {
                                    const input = e.target.value;
                                    try {
                                      setShowLoader(true);
                                      const body = new FormData();
                                      body.append("action", "fetchrsp");
                                      body.append("agencyid", user_id);
                                      body.append("pcode", input);
                                      const res = await set_credit_limit_API(
                                        body
                                      );
                                      setShowLoader(false);
                                      if (res?.success) {
                                        const capacity =
                                          uniqueEquipmentList?.filter(
                                            (v) => v?.PROD_CODE == input
                                          );
                                        const newProduct = {
                                          capacity: capacity[0]?.CAPACITY,
                                          rsp: res?.RSP,
                                          id: addData?.length - 1,
                                          product_code: input,
                                          discount: "0.00",
                                          effe_date: "",
                                        };
                                        const isProductExist = addData.some(
                                          (item) => item.product_code == input
                                        );

                                        if (!isProductExist && input != "") {
                                          setAddData((prevData) =>
                                            prevData.map((item, ind) =>
                                              ind === i
                                                ? { ...item, ...newProduct }
                                                : item
                                            )
                                          );
                                        }
                                      } else {
                                        setPinAlert(true);
                                        setPinAlertMess(res?.message);
                                      }
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                  options={[
                                    <option value={""}>{"SELECT"}</option>,
                                    ...uniqueEquipmentList
                                      ?.filter(
                                        (s, i) => s?.CAT_NAME == "COMMERCIAL"
                                      )
                                      ?.map((v, i) => (
                                        <option
                                          key={i}
                                          value={v?.PROD_CODE}
                                          disabled={addData.some(
                                            (item) =>
                                              item?.product_code == v?.PROD_CODE
                                          )}
                                        >
                                          {v.CAT_NAME + " - " + v.product_name}
                                        </option>
                                      )),
                                  ]}
                                />
                              </td>
                              <td>
                                <Input
                                  value={Number(item?.rsp)}
                                  disabled={true}
                                />
                              </td>
                              <td>
                                <Input
                                  value={(item?.rsp > 0
                                    ? Number(item?.rsp) / Number(item?.capacity)
                                    : 0
                                  )?.toFixed(2)}
                                  disabled={true}
                                />
                              </td>
                              <td>
                                <Input
                                  value={item.discount}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;

                                    if (
                                      (inputValue?.length < 10 &&
                                        /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(
                                          inputValue
                                        )) ||
                                      e.target.value == 0
                                    ) {
                                      setAddData((pre) =>
                                        pre.map((v, index) =>
                                          i === index
                                            ? {
                                                ...v,
                                                discount:
                                                  /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(
                                                    inputValue
                                                  )
                                                    ? inputValue
                                                    : "",
                                              }
                                            : { ...v }
                                        )
                                      );
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <Input
                                  value={item?.effe_date}
                                  type={"date"}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (e.target.value.length <= 10) {
                                      setAddData((pre) =>
                                        pre.map((v, index) =>
                                          i === index
                                            ? {
                                                ...v,
                                                effe_date: inputValue,
                                              }
                                            : { ...v }
                                        )
                                      );
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <DeleteIcon
                                  onClick={() => {
                                    const filtData = addData.filter(
                                      (product, ind) => i !== ind
                                    );
                                    setAddData(filtData);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      )
                    }
                  />
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  margin: "10px 0",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ width: "auto", display: "flex", gap: "1rem" }}>
                  <AddButton
                    onClick={() => {
                      const data = valiDate("add");
                      if (data?.length == 0) {
                        setAddData([...addData, { rsp: 0, discount: "0.00" }]);
                      } else {
                        setPinAlertMess(<Alerts data={data} />);
                        setPinAlert(true);
                      }
                    }}
                  />
                  <SaveButton onClick={add_items} />
                </div>
              </div>
            </div>
          }
        />
      )}

      {/*Product List Component */}

      {totalCount > 5 && (
        <Pagination2
          top={true}
          search={false}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {credit_limit_loading ? (
        <PageLoading />
      ) : credit_limit_list?.length == 0 ? (
        <NoData mt={30} />
      ) : (
        <TableNew
          mt={5}
          nodata={credit_limit_list?.length != 0 ? false : true}
          headData={headData}
          body={credit_limit_list?.map((item, i) => (
            <tr key={i}>
              <td>{item.CVO_name}</td>
              <td>{item.credit_limit}</td>
              <td>{item.cc_limit}</td>
              <td>{item.credit_days}</td>
              <td>{item.cc_days}</td>
              <td
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                {item?.items?.length > 0 ? (
                  <FaRegEye
                    onClick={() => {
                      setViewItem(item);
                      setOpenView(true);
                    }}
                  />
                ) : (
                  "-"
                )}
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <DeleteIcon
                  onClick={() => {
                    setDeleteId(item.id);
                    setShowDeleteAlert(true);
                  }}
                />
                <EditButton
                  onClick={() => {
                    setEditItem(item);
                    setOpenEdit(true);
                    setShowAddProd(false);
                  }}
                />
              </td>
            </tr>
          ))}
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {/* View Product Component */}

      {openView && (
        <Modal
          isOpen={openView}
          setIsOpen={setOpenView}
          title={viewItem.CVO_name}
          body={
            <Table
              mt={5}
              headData={[
                "PRODUCT",
                "RSP(PER CYL.)",
                "RSP(PER KG)",
                "DISCOUNT ON RSP(PER CYL.)",
                "EFFECTIVE DATE",
              ]}
              body={
                viewItem?.items?.length > 0 ? (
                  <>
                    {viewItem?.items.map((item, i) => (
                      <tr className="MD-tr" key={i}>
                        <td>
                          {/* <Input
                            value={(() => {
                              const equipmentProduct = equipment_list.find(
                                (v) => v?.PROD_CODE == item?.product_id
                              );
                              const serviceProduct = service_master_list.find(
                                (v) => v?.PROD_CODE == item?.product_id
                              );
                              if (equipmentProduct) {
                                return `${equipmentProduct.CAT_NAME} - ${equipmentProduct.product_name}`;
                              } else if (serviceProduct) {
                                return serviceProduct.CAT_DESC;
                              }

                              return "";
                            })()}
                            disabled={true}
                          /> */}
                          {(() => {
                            const equipmentProduct = equipment_list.find(
                              (v) => v?.PROD_CODE == item?.product_id
                            );
                            const serviceProduct = service_master_list.find(
                              (v) => v?.PROD_CODE == item?.product_id
                            );
                            if (equipmentProduct) {
                              return `${equipmentProduct.CAT_NAME} - ${equipmentProduct.product_name}`;
                            } else if (serviceProduct) {
                              return serviceProduct.CAT_DESC;
                            }

                            return "";
                          })()}
                        </td>
                        <td>{Number(item?.rsp)}</td>
                        <td>
                          {(Number(item?.rsp) / Number(item?.capacity)).toFixed(
                            2
                          )}
                        </td>
                        <td>{item.discount}</td>
                        <td>
                          {moment(item?.effective_date).format("DD-MMM-YYYY")}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    NO DATA FOUND, PLEASE ADD SOME PRODUCT
                  </div>
                )
              }
            />
          }
        />
      )}

      {/* Edit Product Component */}

      {openEdit && (
        <Modal
          isOpen={openEdit}
          setIsOpen={setOpenEdit}
          title={"UPDATE DISCOUNT/CREDIT LIMIT DISCOUNT"}
          body={
            <>
              <div
                className="flex aic gap10 credit-limit"
                style={{ flexWrap: "wrap", paddingBottom: "1rem" }}
              >
                <Dropdown
                  width2=""
                  label={"CUSTOMER"}
                  value={editItem?.CVO_name}
                  disabled={true}
                  options={[<option value={""}>{"SELECT"}</option>].concat(
                    cust_vend_list
                      ?.filter((v) => v?.CVO_CAT == "1")
                      ?.map((v, i) => (
                        <option value={v.cvo_name} key={i}>
                          {v.cvo_name.toUpperCase()}
                        </option>
                      ))
                  )}
                />
                <Input
                  label={"CREDIT LIMIT"}
                  placeholder={"Credit Limit"}
                  value={editItem?.credit_limit}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      (inputValue?.length < 11 &&
                        /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(inputValue)) ||
                      e.target.value == 0
                    ) {
                      setEditItem({
                        ...editItem,
                        credit_limit: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                          inputValue
                        )
                          ? inputValue
                          : "",
                      });
                    }
                  }}
                />
                <Dropdown
                  width2=""
                  label={"CREDIT LIMIT CONTROL"}
                  value={editItem?.cc_limit}
                  onChange={(e) => {
                    setEditItem({
                      ...editItem,
                      cc_limit: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"WARN"}>WARN</option>
                      <option value={"BLOCK"}>BLOCK</option>
                    </>
                  }
                />
                <Input
                  label={"CREDIT DAY "}
                  placeholder={"Credit Day"}
                  value={editItem?.credit_days}
                  onChange={(e) => {
                    const validInput = e.target.value.replace(/[^0-9]/g, "");
                    if (validInput?.length < 4) {
                      setEditItem({ ...editItem, credit_days: validInput });
                    }
                  }}
                />
                <Dropdown
                  width2=""
                  label={"CREDIT DAYS CONTROL"}
                  value={editItem.cc_days}
                  onChange={(e) => {
                    setEditItem({
                      ...editItem,
                      cc_days: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"WARN"}>WARN</option>
                      <option value={"BLOCK"}>BLOCK</option>
                    </>
                  }
                />
              </div>

              {editItem?.items?.length > 0 ? (
                <Table
                  mt={5}
                  headData={[
                    "PRODUCT",
                    "RSP(PER CYL.)",
                    "RSP(PER KG)",
                    "DISCOUNT ON RSP(PER CYL.)",
                    "EFFECTIVE DATE",
                    "ACTIONS",
                  ]}
                  body={
                    <>
                      {editItem.items.map((item, i) => (
                        <tr className="MD-tr" key={i}>
                          <td>
                            {/* <Input
                              value={(() => {
                                const equipmentProduct = equipment_list.find(
                                  (v) => v?.PROD_CODE == item?.product_id
                                );
                                // const serviceProduct = service_master_list.find(
                                //   (v) => v?.PROD_CODE == item?.product_id
                                // );
                                if (equipmentProduct) {
                                  return `${equipmentProduct.CAT_NAME} - ${equipmentProduct.product_name}`;
                                }
                                //  else if (serviceProduct) {
                                //   return serviceProduct.CAT_DESC;
                                // }

                                return "";
                              })()}
                              disabled={true}
                            /> */}
                            <Dropdown
                              width2=""
                              w={"300px"}
                              value={item?.product_id}
                              onChange={async (e) => {
                                if (e.target.value !== "") {
                                  const input = e.target.value;
                                  try {
                                    setShowLoader(true);
                                    const body = new FormData();
                                    body.append("action", "fetchrsp");
                                    body.append("agencyid", user_id);
                                    body.append("pcode", input);
                                    const res = await set_credit_limit_API(
                                      body
                                    );
                                    setShowLoader(false);
                                    if (res?.success) {
                                      const capacity =
                                        uniqueEquipmentList?.filter(
                                          (v) => v?.PROD_CODE == input
                                        );
                                      const newProduct = {
                                        rsp: res?.RSP,
                                        product_id: input,
                                        discount: "0.00",
                                        capacity: capacity[0]?.CAPACITY || 1,
                                        effective_date: "",
                                      };
                                      const isProductExist =
                                        editItem.items.some(
                                          (item) => item?.product_id === input
                                        );
                                      if (!isProductExist && input !== "") {
                                        setEditItem((prevEditItem) => ({
                                          ...prevEditItem,
                                          items: prevEditItem.items.map(
                                            (item, index) =>
                                              index === i // Replace the product at the specific index
                                                ? { ...item, ...newProduct } // Update with new product details
                                                : item // Keep the other items unchanged
                                          ),
                                        }));
                                      }
                                    } else {
                                      setPinAlert(true);
                                      setPinAlertMess(res?.message);
                                    }
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }
                              }}
                              options={[
                                <option key={""} value={""}>
                                  {"SELECT"}
                                </option>,
                                ...uniqueEquipmentList
                                  ?.filter(
                                    (s, i) => s?.CAT_NAME == "COMMERCIAL"
                                  )
                                  ?.map((v, i) => (
                                    <option
                                      key={i}
                                      value={v?.PROD_CODE}
                                      disabled={editItem?.items.some(
                                        (item) =>
                                          item?.product_id === v?.PROD_CODE
                                      )}
                                    >
                                      {v.CAT_NAME + " - " + v.product_name}
                                    </option>
                                  )),
                              ]}
                            />
                          </td>
                          <td>
                            <Input value={Number(item?.rsp)} disabled={true} />
                          </td>
                          <td>
                            <Input
                              value={(item?.rsp > 0
                                ? Number(item?.rsp) /
                                  Number(item?.capacity || 1)
                                : 0
                              ).toFixed(2)}
                              disabled={true}
                            />
                          </td>
                          <td>
                            <Input
                              value={item?.discount}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  (inputValue?.length < 10 &&
                                    /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )) ||
                                  e.target.value == 0
                                ) {
                                  setEditItem((prev) => {
                                    return {
                                      ...prev,
                                      items: prev?.items.map((v, index) =>
                                        index === i
                                          ? {
                                              ...v,
                                              discount:
                                                /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(
                                                  inputValue
                                                )
                                                  ? inputValue
                                                  : "",
                                            }
                                          : v
                                      ),
                                    };
                                  });
                                }
                              }}
                            />
                          </td>
                          <td>
                            <Input
                              value={moment(item?.effective_date).format(
                                "YYYY-MM-DD"
                              )}
                              type={"date"}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (e.target.value.length <= 10) {
                                  setEditItem((prev) => {
                                    return {
                                      ...prev,
                                      items: prev?.items.map((v, index) =>
                                        index === i
                                          ? { ...v, effective_date: inputValue }
                                          : v
                                      ),
                                    };
                                  });
                                }
                              }}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              onClick={async () => {
                                if (item?.id) {
                                  try {
                                    setShowLoader(true);
                                    const body = new FormData();
                                    body.append("action", "delete_product");
                                    body.append("id", item?.id);
                                    body.append(
                                      "productcode",
                                      item?.product_id
                                    );
                                    const res = await set_credit_limit_API(
                                      body
                                    );
                                    setShowLoader(false);
                                    if (res.success) {
                                      const filtData = editItem.items.filter(
                                        (product) => product?.id !== item?.id
                                      );
                                      setEditItem((prevEditItem) => ({
                                        ...prevEditItem,
                                        items: filtData,
                                      }));
                                    }
                                  } catch (error) {
                                    console.log(error);
                                  }
                                } else {
                                  const filtData = editItem.items.filter(
                                    (product) =>
                                      product.product_id !== item.product_id
                                  );
                                  setEditItem((prevEditItem) => ({
                                    ...prevEditItem,
                                    items: filtData,
                                  }));
                                }
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              ) : showAddProd ? (
                ""
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  NO DATA FOUND, PLEASE ADD SOME PRODUCT
                </div>
              )}

              <div
                style={{
                  width: "100%",
                  margin: "10px 0",
                  display: "flex",
                  gap: ".5rem",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ width: "auto" }}>
                  <AddButton
                    onClick={() => {
                      const data = valiDateChange("add");
                      if (data?.length == 0) {
                        setEditItem((prev) => {
                          return {
                            ...prev,
                            items: [
                              ...prev?.items,
                              {
                                rsp: 0,
                                discount: "0.00",
                                effective_date: "",
                              },
                            ],
                          };
                        });
                      } else {
                        setPinAlertMess(<Alerts data={data} />);
                        setPinAlert(true);
                      }
                    }}
                  />
                </div>
                <div style={{ width: "auto" }}>
                  <SaveButton onClick={() => handleChangeSubmit()} />
                </div>
              </div>
            </>
          }
        />
      )}

      {/* Product Delete Component */}

      {showDeleteAlert && (
        <SmallModal
          isOpen={showDeleteAlert}
          setIsOpen={setShowDeleteAlert}
          onClick={() => handleDeleteSetLimit(deleteId)}
          title={"Confirm"}
          mess={"Are you sure? You want to delete"}
        />
      )}

      {/* Pin Verify Component */}

      {showPinVerify && (
        <PinVerify
          showPinVerify={showPinVerify}
          setShowPinVerify={setShowPinVerify}
          setShowLoader={setShowLoader}
          setAlertOpen={setPinAlert}
          setAlertMess={setPinAlertMess}
        />
      )}

      {/* Alerts Component */}

      {pinALert && (
        <SmallModal
          isOpen={pinALert}
          setIsOpen={setPinAlert}
          title={"alert"}
          mess={pinAlertMess}
          okbtn={setPinAlert}
        />
      )}

      {showLoader && <Loader />}
    </div>
  );
};
