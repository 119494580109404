import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

export const get_reciept_list = createAsyncThunk("reciept", async (data) => {
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "");
  body.append("limit", data?.entries || "");
  body.append("search", data?.search || "");

  const response = await fetch(base_url + "cash_bank_receipts.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  data?.setSearchCount(res?.searchcount);
  return res;
});

export const get_payment_list = createAsyncThunk("payment", async (data) => {
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "");
  body.append("limit", data?.entries || "");
  body.append("search", data?.search || "");

  const response = await fetch(base_url + "payment.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  data?.setSearchCount(res?.searchcount);
  return res;
});

export const get_bank_transaction_list = createAsyncThunk(
  "bank_transaction",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "");
    body.append("limit", data?.entries || "");
    body.append("search", data?.search || "");

    const response = await fetch(base_url + "Bank_transaction.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    data?.setSearchCount(res?.searchcount);
    return res;
  }
);

export const get_credit_note_list = createAsyncThunk("credit", async (data) => {
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "");
  body.append("limit", data?.entries || "");
  body.append("search", data?.search || "");

  const response = await fetch(base_url + "credit_notes.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  data?.setSearchCount(res?.searchcount);
  return res;
});

export const get_debit_note_list = createAsyncThunk("debit", async (data) => {
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "");
  body.append("limit", data?.entries || "");
  body.append("search", data?.search || "");

  const response = await fetch(base_url + "debit_notes.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  data?.setSearchCount(res?.searchcount);
  return res;
});

export const get_cheque_list = createAsyncThunk("cheque", async (data) => {
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "");
  body.append("limit", data?.entries || "");
  body.append("search", data?.search || "");

  const response = await fetch(base_url + "cheque_clearance.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  data?.setSearchCount(res?.searchcount);
  return res;
});

const initialState = {
  reciept_list: [],
  reciept_loading: false,
  payment_list: [],
  payment_loading: false,
  bank_transaction_list: [],
  bank_transaction_loading: false,
  credit_note_list: [],
  credit_note_loading: false,
  debit_node_list: [],
  debit_node_loading: false,
  cheque_list: [],
  cheque_loading: false,
};
const cashbankslice = createSlice({
  name: "cash_and_bank",
  initialState: initialState,
  extraReducers: (builder) => {
    // Reciept List

    builder.addCase(get_reciept_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.reciept_loading = false;

      if (data.success) {
        state.reciept_list = data.data;
      } else {
        state.reciept_list = [];
      }
    });
    builder.addCase(get_reciept_list.pending, (state, action) => {
      state.reciept_loading = true;
    });
    builder.addCase(get_reciept_list.rejected, (state, action) => {
      state.reciept_loading = false;
    });

    // Payment List

    builder.addCase(get_payment_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.payment_loading = false;

      if (data.success) {
        state.payment_list = data.data;
      } else {
        state.payment_list = [];
      }
    });
    builder.addCase(get_payment_list.pending, (state, action) => {
      state.payment_loading = true;
    });
    builder.addCase(get_payment_list.rejected, (state, action) => {
      state.payment_loading = false;
    });

    // Bank Transaction List

    builder.addCase(get_bank_transaction_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.bank_transaction_loading = false;

      if (data.success) {
        state.bank_transaction_list = data.data;
      } else {
        state.bank_transaction_list = [];
      }
    });
    builder.addCase(get_bank_transaction_list.pending, (state, action) => {
      state.bank_transaction_loading = true;
    });
    builder.addCase(get_bank_transaction_list.rejected, (state, action) => {
      state.bank_transaction_loading = false;
    });

    // Credit Note List

    builder.addCase(get_credit_note_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.credit_note_loading = false;

      if (data.success) {
        state.credit_note_list = data.data;
      } else {
        state.credit_note_list = [];
      }
    });
    builder.addCase(get_credit_note_list.pending, (state, action) => {
      state.credit_note_loading = true;
    });
    builder.addCase(get_credit_note_list.rejected, (state, action) => {
      state.credit_note_loading = false;
    });

    // Debit Note List

    builder.addCase(get_debit_note_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.debit_node_loading = false;

      if (data.success) {
        state.debit_node_list = data.data;
      } else {
        state.debit_node_list = [];
      }
    });
    builder.addCase(get_debit_note_list.pending, (state, action) => {
      state.debit_node_loading = true;
    });
    builder.addCase(get_debit_note_list.rejected, (state, action) => {
      state.debit_node_loading = false;
    });

    // Cheque List

    builder.addCase(get_cheque_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.cheque_loading = false;

      if (data.success) {
        state.cheque_list = data.cheque;
      } else {
        state.cheque_list = [];
      }
    });
    builder.addCase(get_cheque_list.pending, (state, action) => {
      state.cheque_loading = true;
    });
    builder.addCase(get_cheque_list.rejected, (state, action) => {
      state.cheque_loading = false;
    });
  },
});

export default cashbankslice.reducer;
