import React from "react";
import "./layout.css";
import Header from "../header/Header";
import { useSelector } from "react-redux";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { setSidebarWidth } from "../../redux/slice/actionSlice";
import store from "../../redux/store";
import { Drawer } from "antd";

const Layout = () => {
  const { smallSidebarWidth } = useSelector((state) => state.actions);

  return (
    <div className="layout-main">
      <div
        className="layout-sidebar"
        style={{
          width: smallSidebarWidth ? "4rem" : "20rem",
          transition: "width ease 1s",
        }}
      >
        <Sidebar />
      </div>
      <div className="sidebar-new">
        <Drawer
        width={"16rem"}
          placement={"left"}
          destroyOnClose={true}
          rootClassName={"sidebar-new"}
          Header={false}
          onClose={() => store.dispatch(setSidebarWidth(!smallSidebarWidth))}
          open={smallSidebarWidth}
        >
          <Sidebar />
        </Drawer>
      </div>

      <div className="layout-body">
        <div className="layout-header">
          <Header />
        </div>
        <div className="layout-outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
