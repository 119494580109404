import React from "react";
import "./input.css";

export const Input = ({
  label,
  placeholder,
  error,
  mess,
  type,
  onChange,
  value,
  disabled = false,
  readOnly = false, // changes by Naveen, for enable input readonly property
  onBlur, // changes by Naveen, for enable input onBlur Event
  w,
}) => {
  return (
    <div className="inp-main" style={{maxWidth:w}}>
      {label && <div className="inp-label" style={{maxWidth:w}}>{label}</div>}
      <div
        className="inp-wrap"
        style={{
          backgroundColor:
            readOnly == true || disabled == true ? "#f9f6fa" : "",
        }}
      >
        <input
          disabled={disabled}
          readOnly={readOnly}
          style={{
            backgroundColor:
              readOnly == true || disabled == true ? "#f9f6fa" : "",
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className="inp"
          type={type}
        />
      </div>
      {mess && <div className="inp-mess">{mess}</div>}
      {error && <div className="inp-error">{error}</div>}
    </div>
  );
};

export const RowInput = ({
  label,
  placeholder,
  error,
  mess,
  type,
  onChange,
  value,
}) => {
  return (
    <div className="inp-main2">
      <div className="inp-label">{label}</div>
      <div className="inp-wrap">
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="inp"
          type={type}
        />
      </div>
      {mess && <div className="inp-mess">{mess}</div>}
      {error && <div className="inp-error">{error}</div>}
    </div>
  );
};


