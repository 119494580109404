import React, { useEffect, useState } from "react";
import { BackButton, HelpButton } from "../../components/btn/Buttons";
import { useNavigate } from "react-router-dom";
import { Radio, RadioNP } from "../../utils/utils";
import PageHeading from "../../components/PageHeading";
import RadioBtn from "../../components/radio/RadioBtn";

export const ExpenditureOtherPurchase = ({}) => {
  
  const [showData,setShowData] = useState("")
  const [itc, setItc] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    if (showData === "2") {
      navigate('/transactions/OtherPurchase', {
        state: {
          itc: "",
          showData: showData,
        }
      });
    } else if (itc !== "") {
      navigate('/transactions/OtherPurchase', {
        state: {
          itc: itc,
          showData: showData,
        }
      });
    }
  }, [itc, showData]);
  

  return (
    <div className="container">
      <PageHeading
        title={"EXPENDITURE / OTHER PURCHASES INVOICE ENTRY"}
        right={
          <>
            <HelpButton
          
              link={["","https://www.youtube.com/watch?v=C4JmI9QRwG0"]}
              Hindi={false}
            />
          </>
        }
      />
     <div className="EOP-main" style={{fontSize:"14px"}}>
     <div className="EOP">
        <div className="EOP-1st">
          <div>Is your Expenses or Other Purchases Taxable?</div>
          <div>
            <Radio show={showData == "1" ? true : false}
                  setShow={() =>
                    setShowData("1")
                  } />
            <p>Yes</p>
          </div>
          <div>
            <Radio show={showData == "2" ? true : false}
                  setShow={() =>
                    setShowData("2")
                  }  />
            <p>No</p>
          </div>
        </div>
        {showData == "1" && <div className="EOP-1st">
            <div className="itc-div"> 
            <Radio show={itc == "0" ? true : false}
                  setShow={() =>
                    setItc("0")
                  } />
            <p>ITC Eligible</p>
          </div>
          <div className="itc-div">
            <Radio show={itc == "1" ? true : false}
                  setShow={() =>
                    setItc("1")
                  }  />
            <p>ITC InEligible</p>
          </div>
            </div>}
        <div className="EOP-2nd">
          <BackButton onClick={() => navigate(-1)} />
        </div>
      </div>
     </div>
    </div>
  );
};
