import React, { useState } from "react";
import SmallModal from "../../components/modal/SmallModal";
import { pinverifyAPI } from "../../utils/apis";
import axios from "axios";
import Input from "../../components/inputs/Input";
import { BackButton } from "../../components/btn/Buttons";
import Button from "../../components/btn/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const PinVerify = ({
  showPinVerify,
  setShowPinVerify,
  setShowLoader,
  setAlertOpen,
  setAlertMess,
  closeBTN = false
}) => {
  const [pin, setPin] = useState("");
  const { pin_data } = useSelector((state) => state.profile);
  const { user_id } = useSelector((state) => state.other);

  const navigate = useNavigate();

  const handlePinSubmit = async () => {
    if (pin.length == 4) {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "pinverify");
      body.append("agencyid", user_id);
      body.append("pin", pin);
      const response = await axios.post(pinverifyAPI, body);
      setShowLoader(false);
      if (response.data.success) {
        setShowPinVerify(false);
        setPin("");
      } else {
        setAlertOpen(true);
        setAlertMess(response.data.message);
        setPin("");
      }
    } else {
      if (pin.length == 0) {
        setAlertMess("Please Enter Pin");
      } else if (pin.length >= 3 && pin.length <= 5) {
        setAlertMess("Pin contain 4 digit only");
      }
      setAlertOpen(true);
    }
  };
  return (
    <SmallModal
      closeBtnHide={closeBTN}
      okBtnHide={false}
      isOpen={showPinVerify}
      setIsOpen={setShowPinVerify}
      style={{ minWidth: "30rem" }}
      okbtn={setShowPinVerify}
      title={"ENTER SECURITY PIN DETAILS"}
      mess={
        pin_data == 0 ? (
          <div className="pin-ver">
            <div
              className="pin-ver-1st"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                Please Set Pin First
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "fit-content", margin: "1.5rem 0" }}>
                <BackButton onClick={() => navigate(-1)} />
              </div>
              <div style={{ width: "fit-content", margin: "1.5rem 0" }}>
                <Button
                  onClick={() => navigate("/profile/Settinginfo")}
                  title={"PROFILE"}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="pin-ver">
            <div
              className="pin-ver-1st"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <label
                  className="pin-lable"
                  style={{ width: "30%", fontSize: "14px" }}
                >
                  Pin Number:
                </label>
                <div className={"pin-input-div"} style={{ width: "70%" }}>
                  <Input
                    type={"password"}
                    placeholder={"Enter Pin Here"}
                    value={pin}
                    onChange={(event) => {
                      const numericValue = event.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      if (numericValue?.length <= 4 || numericValue == "") {
                        setPin(numericValue);
                      }
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "fit-content", padding: "0 5% " }}>
                <Button onClick={handlePinSubmit} title={"SUBMIT"} />
              </div>
            </div>
            <div style={{ width: "fit-content", margin: "1.5rem 0" }}>
              <BackButton onClick={() => navigate(-1)} />
            </div>
          </div>
        )
      }
    />
  );
};
