import React, { useState } from "react";
import { BackButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";
import Table from "../../components/table/Table";
import { downloadExcel, exportPDF } from "../../utils/utils";
import moment from "moment";
import { Pagination2 } from "../../components/pagination/pagination";
import NoData from "../../components/NoData";
import { useLocation } from "react-router-dom";

const ReceivablesPaymentsDetails = () => {
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [serchInput, setSerchInput] = useState("");
  const { state } = useLocation();

  const table_data = (addData) => {
    const data = [
      ...addData?.map((v, i) => [
        i + 1,
        v?.INV_DATE,
        v?.INV_REF_NO,
        v?.TRANS_TYPE == "2" ? "Sales" : v?.TRANS_TYPE == "1" ? "Purchase" : "",
        Number(v?.AMOUNT).toFixed(2),
        Number(v?.AMOUNT_RECEIVED).toFixed(2),
        Number(v?.AMOUNT_REMAINING).toFixed(2),
        Number(v?.TOTAL_AMOUNT_RECEIVED).toFixed(2),
        v?.DEDUCTED_RECEIPTS?.map((val) => val?.SR_NO).join(", "),
        v?.STATUS,
      ]),
    ];

    return data;
  };
  const table_header = [
    "Invoice Date",
    "Inovice Number",
    "Transaction Type",
    "Invoice Amount",
    "Cleared Amount",
    "Remaining AMount",
    `${state?.comp_type == "1" ? "Received" : "Payment"} Amount`,
    "Voucher Number",
    "Status",
  ];

  const identifier_data = [
    { id: "LT_30_DAYS", value: "< 30 DAYS DATE" },
    { id: "DAYS_31_TO_60", value: "31-60 DAYS" },
    { id: "GT_60_DAYS", value: "> 60 DAYS	" },
    { id: "BALANCE", value: "BALANCE" },
  ];
  const export_pdf = (data) => {
    exportPDF({
      table_data: table_data(data),
      table_headers: ["S.No.", ...table_header],
      fileName: `MyLPGBooks DEALER APPLICATION -  RECEIVABLES REPORTS - ${
        state?.name
      } - ${
        identifier_data?.filter((v, i) => v?.id == state?.type)?.[0]?.value
      }`,
      tableName: `MyLPGBooks DEALER APPLICATION -  RECEIVABLES REPORTS  - ${
        state?.name
      }  - ${
        identifier_data?.filter((v, i) => v?.id == state?.type)?.[0]?.value
      }`,
    });
  };

  const export_excel = (data, total) => {
    downloadExcel(
      table_data(data, total),
      ["S.No.", ...table_header],
      `MyLPGBooks DEALER APPLICATION -  RECEIVABLES REPORT  - ${
        state?.name
      }  - ${
        identifier_data?.filter((v, i) => v?.id == state?.type)?.[0]?.value
      }S`
    );
  };

  return (
    <div className="container  ">
      <PageHeading
        title={`${state?.name} - ${
          identifier_data?.filter((v, i) => v?.id == state?.type)?.[0]?.value
        }`}
        right={<BackButton />}
      />
      <div style={{ height: "10px" }}></div>

      {state?.data?.length > 0 ? (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "11PX" }}>DATE :</span>
            <p className="FDATE">{moment(state?.date).format("DD/MM/YY")}</p>
          </div>

          <Pagination2
            count={0}
            download={true}
            PDFDown={() => export_pdf(state?.data)}
            excelDown={() => export_excel(state?.data)}
            top={true}
            totalCount={state?.data?.length}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          {state?.data?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <Table
              headData={table_header}
              body={
                <>
                  {state?.data
                    ?.slice(pageNo * entries - entries, pageNo * entries)
                    ?.map((v, i) => (
                      <tr key={i}>
                        <td>{v?.INV_DATE}</td>
                        <td>{v?.INV_REF_NO}</td>
                        <td>
                          {v?.TRANS_TYPE == "2"
                            ? "Sales"
                            : v?.TRANS_TYPE == "1"
                            ? "Purchase"
                            : ""}
                        </td>
                        <td>{Number(v?.AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.AMOUNT_RECEIVED).toFixed(2)}</td>
                        <td>{Number(v?.AMOUNT_REMAINING).toFixed(2)}</td>
                        <td>{Number(v?.TOTAL_AMOUNT_RECEIVED).toFixed(2)}</td>
                        <td>
                          {v?.DEDUCTED_RECEIPTS?.map((val, ind) => (
                            <div key={ind}>{val?.SR_NO}</div>
                          ))}
                        </td>

                        <td>{v?.STATUS}</td>
                      </tr>
                    ))}
                </>
              }
            />
          )}
          <Pagination2
            count={0}
            bottom={true}
            totalCount={state?.data?.length}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReceivablesPaymentsDetails;
