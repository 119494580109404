import React, { useState } from "react";
import { AddButton, BackButton, SaveButton } from "../../../components/btn/Buttons";
import PageHeading from "../../../components/PageHeading";
import Table from "../../../components/table/Table";
import { DeleteIcon } from "../../../utils/utils";
import Input from "../../../components/inputs/Input";
import moment from "moment";
import validatePAN from "../../../utils/validation";
import SmallModal from "../../../components/modal/SmallModal";

const PartnersData = () => {
  const [open, setOpen] = useState(false);
  const [addProductData, setAddProductData] = useState([]);
  const [alerts, setAlerts] = useState(false);
  const [alertsMess, setAlertsMess] = useState("");

  const valiDate = () => {
    const errors = [];
    var count = 1;  // Use let for consistency if modifying inside the loop
    for (let v of addProductData) {
      if (!v?.name || v?.name.trim().length === 0) {
        errors.push(`Please enter Partner Name in Row ${count}`);
      }
      if (!v?.PAN || v?.PAN.trim().length === 0) {
        errors.push(`Please enter PAN in Row ${count}`);
      } else if (!validatePAN(v?.PAN)) {
        errors.push(`Please enter a valid PAN in Row ${count}`);
      }
      if (!v?.PERC_OF_SHARE || v?.PERC_OF_SHARE.trim().length === 0) {
        errors.push(`Please enter % of Share in Row ${count}`);
      }
      if (!v?.OPENING_BALANCE || v?.OPENING_BALANCE.trim().length === 0) {
        errors.push(`Please enter Opening Balance in Row ${count}`);
      }
      count++;
    }
    return errors;
  };


  const handleAddProduct = () => {
    const defaultProduct = {
      id: addProductData.length + 1,
      NAME: "",
      PAN: "",
      PERC_OF_SHARE: "",
      OPENING_BALANCE: "",
      STATUS: "",
      ACTIONS: "",
    };

    if (!addProductData.length || valiDate().length === 0) {
      setAddProductData((prevData) => [...prevData, defaultProduct]);
    } else {
      // setAddProductData((prevData) => [...prevData, defaultProduct]);
      const validationErrors = valiDate();
      setAlerts(true);
      setAlertsMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {validationErrors.map((item, i) => (
            <p style={{ textAlign: "left" }} key={i}>
              {item}
            </p>
          ))}
        </div>
      );
    }
  };

  const handleSaveProduct = async () => {
    const validationErrors = valiDate();
    setAlerts(true);
    setAlertsMess(
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {validationErrors.map((item, i) => (
          <p style={{ textAlign: "left" }} key={i}>
            {item}
          </p>
        ))}
      </div>
    );
  };

  const handleAddDelete = (deleteId) => {
    const filterData = addProductData.filter((item) => item.id !== deleteId);
    setAddProductData(filterData);
  };
  return (
    <div className="container">
      <PageHeading
        title={"PARTNERS DATA"}
        right={
          <>
            <AddButton
              onClick={() => {
                setOpen(true);
                handleAddProduct();
              }}
            />
            <SaveButton title={"SAVE"} onClick={handleSaveProduct} />
          </>
        }
      />
      {open && (
        <>
          <Table
            mt={30}
            headData={[
              "NAME",
              "PAN",
              "% OF SHARE",
              "OPENING BALANCE",
              "STATUS",
              "ACTIONS",
            ]}
            body={
              <>
                {addProductData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Input
                        placeholder={"Partner Name"}
                        value={item.name}
                        onChange={(event) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              name: event.target.value,
                            };
                            return updatedData;
                          });
                        }
                        }
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"PAN"}
                        value={item.PAN}
                        onChange={(event) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              PAN: event.target.value,
                            };
                            return updatedData;
                          });
                        }
                        }
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"% OF SHARE"}
                        value={item.PERC_OF_SHARE}
                        onChange={(event) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              PERC_OF_SHARE: event.target.value,
                            };
                            return updatedData;
                          });
                        }
                        }
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"OPENING BALANCE"}
                        value={item.OPENING_BALANCE}
                        onChange={(event) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              OPENING_BALANCE: event.target.value,
                            };
                            return updatedData;
                          });
                        }
                        }
                      />
                    </td>
                    <td></td>
                    <td>
                      <DeleteIcon onClick={() => handleAddDelete(item.id)} />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </>
      )}
      <div style={{ height: "10px" }}></div>
      <div>
        <Table
          headData={[
            " NAME",
            "PAN",
            " % OF SHARE",
            "OPENING BALANCE",
            "STATUS",
            "ACTIONS",
          ]}
          body={
            <>
              <tr>
                <td>cust yes</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>53500</td>
                <td>53500</td>
                <td>
                  <DeleteIcon onClick={() => handleAddDelete(0)} />
                </td>
              </tr>
            </>
          }
        />
      </div>
      {alerts && (
        <SmallModal
          isOpen={alerts}
          setIsOpen={setAlerts}
          title={"alert"}
          mess={alertsMess}
          okbtn={setAlertsMess}
        />
      )}
    </div>
  );
};

export default PartnersData;