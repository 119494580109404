import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import PageHeading from "../../../components/PageHeading";
import Dropdown from "../../../components/inputs/Dropdown";
import Input from "../../../components/inputs/Input";
import Table from "../../../components/table/Table";
import SmallModal from "../../../components/modal/SmallModal";
import axios from "axios";
import {
  excel_upload_download_api,
  vehicalMasterAPI,
} from "../../../utils/apis";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import PageLoading from "../../../components/PageLoading";
import { base_url, DeleteIcon } from "../../../utils/utils";
import TableNew from "../../../components/table/TableWithPagination";
import { Pagination2 } from "../../../components/pagination/pagination";
import { useSelector } from "react-redux";
import { get_vehical_list } from "../masterdataSlice";
import store from "../../../redux/store";
import { downloadFile } from "../../../utils/Hook/DownloadFile";
import { UploadFile } from "../../../components/UploadFile";

export const VehicalMaster = () => {
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [addProductData, setAddProductData] = useState([]);
  const [alerts, setAlerts] = useState(false);
  const [alertsMess, setAlertsMess] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState("");

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const { vehical_list, vehical_loading } = useSelector(
    (state) => state.masterdata
  );
  const { user_id, today } = useSelector((state) => state.other);

  const navigate = useNavigate();

  const handleDeleteVehicalMaster = async (deleteId) => {
    setShowLoader(true);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", deleteId);
    const response = await axios.post(vehicalMasterAPI, body);
    setShowLoader(false);
    setShowDeleteAlert(false);
    if (response.data.success) {
      toast(response.data.message, {
        position: "top-center",
      });
      if (vehical_list?.length < 2) {
        setPageNo(pageNo - 1);
        store.dispatch(
          get_vehical_list({
            pageNo: pageNo - 1,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
      } else {
        store.dispatch(get_vehical_list(obj));
      }
    }
  };

  const handleAddProduct = () => {
    if (addProductData.length === 0) {
      setAddProductData((prevData) => [
        ...prevData,
        {
          id: addProductData?.length + 1,
          VEHICLE_NUMBER: "",
          VEHICLE_MAKE: "",
          VEHICLE_TYPE: "",
          VEHICLE_USAGE: "",
        },
      ]);
    } else {
      if (
        addProductData[addProductData.length - 1].VEHICLE_NUMBER != "" &&
        addProductData[addProductData.length - 1].VEHICLE_NUMBER.length > 8 &&
        addProductData[addProductData.length - 1].VEHICLE_NUMBER.length < 11 &&
        addProductData[addProductData.length - 1].VEHICLE_MAKE.length > 0 &&
        addProductData[addProductData.length - 1].VEHICLE_TYPE.length > 0 &&
        addProductData[addProductData.length - 1].VEHICLE_USAGE.length > 0
      ) {
        setAddProductData((prevData) => [
          ...prevData,
          {
            id: addProductData.length + 1,
            VEHICLE_NUMBER: "",
            VEHICLE_MAKE: "",
            VEHICLE_TYPE: "",
            VEHICLE_USAGE: "",
          },
        ]);
      } else {
        setAlerts(true);
        setAlertsMess(
          "Please enter all the values in current row,calculate and then add next row"
        );
      }
    }
  };

  const handleAddDelete = (deleteId) => {
    const filterData = addProductData.filter((item) => item.id !== deleteId);
    setAddProductData(filterData);
  };

  const valiDate = () => {
    const data = [];

    if (addProductData?.length > 0) {
      const vehicleNumbers = new Set();
      var arr = [];
      for (let v of addProductData) {
        if (
          !v?.VEHICLE_NUMBER ||
          (v?.VEHICLE_NUMBER && v?.VEHICLE_NUMBER.length === 0)
        ) {
          data.push("Please Select VEHICLE NUMBER.");
        } else if (
          /^[A-Z]{0,2}\d{0,2}[A-Z]{0,2}\d{3,4}$/.test(v?.VEHICLE_NUMBER) !=
            true ||
          v?.VEHICLE_NUMBER.length <= 8
        ) {
          data.push("Please enter valid VEHICLE NO.");
        } else if (v?.VEHICLE_NUMBER) {
          arr.push(v?.VEHICLE_NUMBER);
        }
        if (
          !v?.VEHICLE_MAKE ||
          (v?.VEHICLE_MAKE && v?.VEHICLE_MAKE.length === 0)
        ) {
          data.push("Please Enter VEHICLE MAKE.");
        } else if (v?.VEHICLE_MAKE?.trim()?.length == 0) {
          data.push("Please Enter valid VEHICLE MAKE.");
        }

        if (
          !v?.VEHICLE_TYPE ||
          (v?.VEHICLE_TYPE && v?.VEHICLE_TYPE.length === 0)
        ) {
          data.push("Please Select VEHICLE TYPE.");
        }
        if (
          !v?.VEHICLE_USAGE ||
          (v?.VEHICLE_USAGE && v?.VEHICLE_USAGE.length === 0)
        ) {
          data.push("Please Select VEHICLE USAGE.");
        }
      }
      const uniqueArray = arr.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      if (uniqueArray?.length != arr?.length) {
        data.push("Duplicate Vehicle no. not allowed");
      }
    } else {
      data.push("Please Add Product First");
    }
    return data;
  };

  const handleSaveProduct = async () => {
    const alertArr = valiDate();

    if (alertArr?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        addProductData.forEach((item) => {
          if (item) {
            body.append("vnumber[]", item?.VEHICLE_NUMBER);
            body.append("vmake[]", item?.VEHICLE_MAKE?.trim());
            body.append("vtype[]", item?.VEHICLE_TYPE);
            body.append("vusage[]", item?.VEHICLE_USAGE);
          }
        });
        const response = await axios.post(vehicalMasterAPI, body);
        setShowLoader(false);
        if (response.data.success) {
          setAddProductData([]);
          toast(response.data.message, {
            position: "top-center",
          });
          store.dispatch(get_vehical_list(obj));
        } else {
          setAlerts(true);
          setAlertsMess(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setAlertsMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {alertArr.map((item, i) => (
            <p style={{ textAlign: "left" }} key={i}>
              {item}
            </p>
          ))}
        </div>
      );
      setAlerts(true);
    }
  };

  useEffect(() => {
    store.dispatch(get_vehical_list(obj));
  }, [pageNo, entries]);

  const download_excel = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("agencyid", user_id);
      body.append("action", "downloadVehicle");
      const res = await excel_upload_download_api(body);

      setShowLoader(false);
      if (res.success) {
        await downloadFile(base_url + res?.file);
      } else if (!res.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const upload_excel = async (file) => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("agencyid", user_id);
      body.append("action", "uploadVehicle");
      body.append("excelFile", file);
      const res = await excel_upload_download_api(body);

      setShowLoader(false);
      if (res.success) {
        setOpenUpload(false);
        toast.success(res.message);
        store.dispatch(get_vehical_list(obj));
      } else if (!res.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"VEHICLES MASTER"}
        right={
          <>
            <AddButton
              onClick={() => {
                setOpen(true);
                handleAddProduct();
              }}
            />
            <SaveButton title={"SAVE"} onClick={handleSaveProduct} />
            <Button title={"DOWNLOAD EXCEL"} onClick={download_excel} />
            <Button
              onClick={() => setOpenUpload(true)}
              title={"UPLOAD EXCEL"}
            />

            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/utbfPI8ahAg",
                "https://youtu.be/13mGz-V_ls4",
              ]}
            />
          </>
        }
      />
      {open && addProductData.length != 0 && (
        <>
          <Table
            mt={30}
            headData={[
              "VEHICLE NUMBER",
              "VEHICLE MAKE",
              "VEHICLE TYPE",
              "VEHICLE USAGE",
              "ACTIONS",
            ]}
            body={
              <>
                {addProductData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Input
                        placeholder={"VEHICLE NUMBER"}
                        value={item.VEHICLE_NUMBER}
                        onChange={(event) => {
                          const input = event.target.value.toUpperCase();
                          if (
                            /^[A-Z]{0,2}\d{0,2}[A-Z]{0,2}\d{0,4}$/.test(
                              input
                            ) ||
                            /^[0-9]{0,4}$/.test(input) ||
                            input === ""
                          ) {
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                VEHICLE_NUMBER: input,
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"VEHICLE MAKE"}
                        onChange={(event) => {
                          const numericValue = event.target.value.toUpperCase();
                          if (numericValue.length <= 20) {
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                VEHICLE_MAKE: numericValue,
                              };
                              return updatedData;
                            });
                          }
                        }}
                        value={item.VEHICLE_MAKE}
                      />
                    </td>
                    <td>
                      <Dropdown
                        value={item.VEHICLE_TYPE}
                        onChange={(e) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              VEHICLE_TYPE: e.target.value,
                            };
                            return updatedData;
                          });
                        }}
                        options={
                          <>
                            <option hidden value="-1">
                              SELECT
                            </option>
                            <option value="0">TWO WHEELER</option>
                            <option value="1">THREE WHEELER</option>
                            <option value="2">FOUR WHEELER</option>
                            <option value="3">TRUCK</option>
                            <option value="4">OTHERS</option>
                          </>
                        }
                      />
                    </td>
                    <td>
                      <Dropdown
                        value={item.VEHICLE_USAGE}
                        onChange={(e) => {
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              VEHICLE_USAGE: e.target.value,
                            };
                            return updatedData;
                          });
                        }}
                        options={
                          <>
                            <option hidden value="-1">
                              SELECT
                            </option>
                            <option value="0">INWARD DELIVERY</option>
                            <option value="1">OUTWARD DELIVERY</option>
                            <option value="2">INWARD/OUTWARD DELIVERY</option>
                            <option value="3">CONVEYANCE</option>
                            <option value="4">OTHERS</option>
                          </>
                        }
                      />
                    </td>
                    <td>
                      <DeleteIcon onClick={() => handleAddDelete(item.id)} />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </>
      )}
      {totalCount > 5 && (
        <Pagination2
          top={true}
          search={false}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {vehical_loading ? (
        <PageLoading />
      ) : (
        <TableNew
          nodata={vehical_list.length != 0 && !vehical_loading ? false : true}
          mt={5}
          headData={[
            "VEHICLE NUMBER",
            "VEHICLE MAKE",
            "VEHICLE TYPE",
            "VEHICLE USAGE",
            "ACTIONS",
          ]}
          body={
            <>
              {vehical_list.length > 0 &&
                vehical_list.map((item, i) => (
                  <tr key={i}>
                    <td>{item?.VEHICLE_NO}</td>
                    <td>{item?.VEHICLE_MAKE}</td>
                    <td>{item?.VEHICLE_TYPE}</td>
                    <td>{item?.VEHICLE_USUAGE}</td>
                    <td>
                      <DeleteIcon
                        onClick={() => {
                          setDeleteId(item.ID);
                          setShowDeleteAlert(true);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
            </>
          }
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {openUpload && (
        <SmallModal
          okBtnHide={false}
          isOpen={openUpload}
          setIsOpen={setOpenUpload}
          style={{ minWidth: "30rem" }}
          okbtn={setOpenUpload}
          title={"UPLOAD MASTER DATA"}
          mess={
            <UploadFile handle_click={(file) => upload_excel(file)} />
          }
        />
      )}
      {showDeleteAlert && (
        <SmallModal
          isOpen={showDeleteAlert}
          setIsOpen={setShowDeleteAlert}
          onClick={() => handleDeleteVehicalMaster(deleteId)}
          title={"Confirm"}
          mess={"Are you sure? You want to delete"}
        />
      )}
      {alerts && (
        <SmallModal
          isOpen={alerts}
          setIsOpen={setAlerts}
          title={"alert"}
          mess={alertsMess}
          okbtn={setAlertsMess}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};