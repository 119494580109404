import { useMemo } from 'react';

const useUniqueEquipmentList = (equipmentList) => {
  const uniqueEquipmentList = useMemo(() => {
    return equipmentList.reduce((acc, current) => {
      const duplicate = acc.find(item => item.CAT_NAME === current.CAT_NAME && item.product_name === current.product_name);
      if (!duplicate) {
        acc.push(current);
      }
      return acc;
    }, []);
  }, [equipmentList]);

  return uniqueEquipmentList;
};

export default useUniqueEquipmentList;
