import React from "react";

const NoData = ({ mt, title, color = "black" }) => {
  return (
    <div
      className="table-nodata"
      style={{ marginTop: mt || "2rem", color: color }}
    >
      {title ? title : "Data Not Found"}
    </div>
  );
};

export default NoData;
