import React, { useEffect, useState } from "react";
import "./table.css";
import { Pagination2 } from "../pagination/pagination";

const TableNew = ({
  totalCount,entries,pageNo,SerchInput,setSearchInput,setEntries,setPageNo,
  headData = [],
  body,
  mt,
  nodata,
  data= false
}) => {
  return (
    <div className="table-container">
      {totalCount > 5 && data && (
        <Pagination2
          top={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      <div className="tableScroll">
      <table style={{ marginTop: mt || 30 }}>
        <tr>
          {headData.map((v, i) => (
            <th key={i}>{v}</th>
          ))}
        </tr>
      {body}
      </table>
      </div>
      {nodata && <div className="table-nodata">Data Not Found</div>}
      {totalCount > 5  && data && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {/* {totalEntries > 5 && (
        <div className="Page">
          <div>
            Showing {pageEnteries * (pageValue - 1) + 1} to{" "}
            {pageEnteries * pageValue > totalEntries
              ? totalEntries
              : pageEnteries * pageValue}{" "}
            of {totalEntries} entries
          </div>
          <div className="page-2nd">
            <div
              className={`page-prev ${pageValue === 1 ? "disabled" : ""}`}
              onClick={handlePrev}
            >
              Previous
            </div>
            {generatePageNumbers()}
            <div
              className={`page-next ${pageValue === 100 ? "disabled" : ""}`}
              onClick={handleNext}
            >
              Next
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default TableNew;
