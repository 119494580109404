import React, { useState } from "react";
import PageHeading from "../../components/PageHeading";
import { BackButton } from "../../components/btn/Buttons";
import Dropdown from "../../components/inputs/Dropdown";
import "./FinacialReports.css";
import { setFocused } from "../../redux/slice/actionSlice";
const BalanceSheet = () => {
  const [financialYear, setFinancialYear] = useState("");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [year, setYear] = useState('')

  const handleYearChange = (event) => {
    setFinancialYear(event.target.value);
    setStatus("PROCESS");
  };

  const trimYear = (str) => {
    let res = "";
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '-') break;
      res += str[i];
    }
    return res;
  }

  return (
    <div className="container" mt={5}>
      <PageHeading
        title={"AGENCY BALANCE SHEET"}
        right={
          <>
            <BackButton />
          </>
        }
      />
      <div style={{ height: "20px" }}></div>
      <div
        style={{
          width: "280px",
          display: "flex",
          alignItems: "flex-end",
          gap: "10px",
        }}
      >
        <Dropdown
          label={"FINANCIAL YEAR"}
          value={financialYear}
          onChange={handleYearChange}
          options={
            <>
              <option value="">SELECT</option>
              <option value="2019-20">2019-20</option>
              <option value="2020-21">2020-21</option>
              <option value="2021-22">2021-22</option>
              <option value="2022-23">2022-23</option>
              <option value="2023-24">2023-24</option>
              <option value="2024-25">2024-25</option>
            </>
          }
        />

        <div>
          <button className="GENERATE-BTN" onClick={() => {
            setYear(financialYear);
            setOpen(true)
          }}>
            FETCH</button>
        </div>
      </div>
      <div style={{ height: "20px" }}></div>

      {open && <div className="FinacialYear">
      <div className="inp-main" style={{width:'fit-content'}}>
        <div className="inp-label" style={{width:'fit-content'}}>FINANCIAL YEAR :</div>
      </div>
        <p className="FDATE" style={{ alignItems: "center", display: "flex" }}>
          {trimYear(year)}
        </p>
        <span
          style={{ fontSize: "11px", alignItems: "center", display: "flex" }}
        >
          STATUS :
        </span>
        <p className="FDATE" style={{ alignItems: "center", display: "flex" }}>
          New
        </p>
        <div>
          <button className="GENERATE-BTN" onClick={()=>setStatus('IN-PROGRESS')}>{status.length===0?'PROCESS':status}</button>
        </div>
      </div>}
      <div style={{ height: "20px" }}></div>
    </div>
  );
};

export default BalanceSheet;
