import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";
import { Input } from "../../components/inputs/Inputs";
import Dropdown from "../../components/inputs/Dropdown";
import Button from "../../components/btn/Button";
import Table from "../../components/table/Table";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { get_transition_complete_list } from "../transactions/trasactionsSlice";
import { downloadExcel, exportPDF } from "../../utils/utils";
import { receivables_report_api } from "../../utils/apis";
import { DateValidator2 } from "../../utils/validation";
import moment from "moment";
import {
  Pagination2,
  Pagination3,
} from "../../components/pagination/pagination";
import NoData from "../../components/NoData";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import { Alerts } from "../../components/Alerts/Alerts";
import { useNavigate } from "react-router-dom";
import SearchApiHook from "../../CustomHooks/SearchApiHook";

const Receivables = () => {
  const [addDataPre, setAddDataPre] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [serchInput, setSerchInput] = useState("");
  const [showData, setShowData] = useState(false);
  const [receivables_report_item_list, setReceivables_report_item_list] =
    useState([]);
  const [totalData, setTotalData] = useState({});
  const { transition_complete_list } = useSelector(
    (state) => state.transactions
  );
  const navigate = useNavigate();
  const { eff_date, user_id, today } = useSelector((state) => state.other);
  const [addData, setAddData] = useState({
    date: moment().subtract(0, "days").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    store.dispatch(get_transition_complete_list());
  }, []);

  // useEffect(() => {
  //   if (showData) {
  //     get_list(addDataPre);
  //   }
  // }, [pageNo, entries]);
  const fetchData = () => {
    if (showData) {
      get_list(addDataPre);
    }
  };
  SearchApiHook(fetchData, serchInput?.trim(), prevSearch, [entries, pageNo]);

  const table_data = (addData, total) => {
    const data = [
      ...addData?.map((v, i) => [
        i + 1,
        v?.CUSTOMER_NAME,
        v?.LT_30_DAYS,

        v?.DAYS_31_TO_60,
        v?.GT_60_DAYS,
        v?.BALANCE,
      ]),
    ];

    return data;
  };
  const table_header = [
    "CUSTOMER NAME",
    "< 30 DAYS DATE",
    "31-60 DAYS",
    "> 60 DAYS	",
    "BALANCE",
  ];
  const export_pdf = (addData, data, total) => {
    exportPDF({
      table_data: table_data(data, total),
      table_headers: ["S.No.", ...table_header],
      fileName: `MyLPGBooks DEALER APPLICATION - RECEIVABLES REPORTS`,
      tableName: `MyLPGBooks DEALER APPLICATION - RECEIVABLES REPORTS`,
    });
  };

  const export_excel = (addData, data, total) => {
    downloadExcel(
      table_data(data, total),
      ["S.No.", ...table_header],
      `MyLPGBooks DEALER APPLICATION - RECEIVABLES REPORTS`
    );
  };

  const get_list = async (addData, data1 = "") => {
    const data = validate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", addData?.action || "fetchagencyreceivables");
        body.append("agencyid", user_id);
        body.append("as_on_date", addData?.date);
        body.append("customerid", addData?.customer_id || "");
        // body.append("page", data1?.length > 1 ? 1 : pageNo);
        // body.append("limit", data1?.length > 1 ? totalCount : entries);
        // body.append("search", data1?.length > 1 ? "" : serchInput);
        body.append("page", data1?.length > 1 || data1 == "main" ? 1 : pageNo);
        body.append(
          "limit",
          data1?.length > 1 ? (data1 == "main" ? entries : totalCount) : entries
        );
        body.append("search", data1 == "main"? "" :serchInput);
        const response = await receivables_report_api(body);
        setShowLoader(false);
        if (response.success) {
          if (data1 == "excel") {
            export_excel(
              addData,
              response?.data || response?.productlist,
              response?.total
            );
          } else if (data1 == "pdf") {
            export_pdf(
              addData,
              response?.data || response?.productlist,
              response?.total
            );
          } else {
            setReceivables_report_item_list(
              response?.data || response?.productlist
            );
            setTotalCount(response?.totalcount);
            setSearchCount(response?.searchcount);
            setShowData(true);
            setAddDataPre(addData);
            setTotalData(response?.total);
          }
        } else if (!response.success) {
          setMess(response?.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = (addData) => {
    const data = [];
    if (!addData?.date || (addData?.date && addData?.date?.length != 10)) {
      data.push("Please Enter As on Date");
    } else if (
      DateValidator2(
        moment(addData?.date).format("DD/MM/YYYY"),
        today,
        eff_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.date).format("DD/MM/YYYY"),
          today,
          eff_date,
          `As On Date cant be before ${moment(eff_date).format("DD/MM/YYYY")} `,
          `As On Date cant be a future date`
        )
      );
    }
    // if (
    //   !addData?.customer_id ||
    //   (addData?.customer_id && addData?.customer_id?.length == 0)
    // ) {
    //   data.push("Please Select CUSTOMER NAME / OMC NAME");
    // }
    return data;
  };

  // const navi_click = (para1,type,data)=>{
  //   if (Number(v?.BALANCE) > 0) {
  //     navigate("/transactionReport/ReceivablesDetails", {
  //       state: {
  //         type: "BALANCE",
  //         data: v?.BALANCE_DETAILS || [],
  //         name: v?.CUSTOMER_NAME,
  //         comp_type: "1",
  //         date: addDataPre?.date,
  //       },
  //     });
  //   }
  // }

  return (
    <div className="container  ">
      <PageHeading
        title={" AGENCY RECEIVABLES REPORT"}
        right={<BackButton />}
      />
      <div style={{ height: "10px" }}></div>

      <div className="transactionMain">
        <div style={{ width: "220px" }}>
          <Dropdown
            label={"CUSTOMER NAME "}
            value={addData?.customer_id}
            onChange={(e) => {
              setAddData({
                ...addData,
                customer_id: e.target.value,
                action: e.target.value != "" ? "fetchagencyreceivables" : "",
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                {(transition_complete_list?.Customer?.length > 0
                //  ||                  transition_complete_list?.OMC?.length > 0
                ) &&
                  [
                    ...transition_complete_list?.Customer,
                    // ...transition_complete_list?.OMC,
                  ]?.map((v, i) => (
                    <option key={i} value={v?.ID}>
                      {v?.cvo_name?.toUpperCase()}
                    </option>
                  ))}
              </>
            }
          />
        </div>

        <div style={{ width: "220px" }}>
          <Input
            label={"AS ON"}
            type={"date"}
            value={addData?.date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, date: e.target.value });
              }
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            title={"SEARCH"}
            onClick={async () => {
              await setShowData(false);
              await setSerchInput("");
              await setPageNo(1);
              await setEntries(5);
              get_list(addData,'main');
            }}
          />
        </div>
      </div>
      {showData && totalCount != 0 ? (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "11PX" }}>DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.date).format("DD/MM/YY")}
            </p>
          </div>

          <Pagination3
            count={0}
            download={true}
            PDFDown={() => get_list(addDataPre, "pdf")}
            excelDown={() => get_list(addDataPre, "excel")}
            top={true}
            totalCount={totalCount > SearchCount ? SearchCount : totalCount}
            SerchInput={serchInput}
            serchChange={(e) => {
              setSerchInput(e.target.value);
              setPrevSearch(serchInput);
              setPageNo(1);
            }}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          >
            {receivables_report_item_list?.length == 0 ? (
              <NoData mt={30} />
            ) : (
              <Table
                headData={table_header}
                body={
                  <>
                    {receivables_report_item_list?.map((v, i) => (
                      <tr key={i}>
                        <td>{v?.CUSTOMER_NAME}</td>
                        <td
                        // className={`${
                        //   Number(v?.LT_30_DAYS) > 0 ? "link_days" : ""
                        // }`}
                        // onClick={() => {
                        //   if (Number(v?.LT_30_DAYS) > 0) {
                        //     navigate("/transactionReport/ReceivablesDetails", {
                        //       state: {
                        //         type: "LT_30_DAYS",
                        //         data: v?.LT_30_DAYS_DETAILS,
                        //         name: v?.CUSTOMER_NAME,
                        //         comp_type: "1",
                        //         date: addDataPre?.date,
                        //       },
                        //     });
                        //   }
                        // }}
                        >
                          {Number(v?.LT_30_DAYS).toFixed(2)}
                        </td>
                        <td
                        // className={`${
                        //   Number(v?.DAYS_31_TO_60) > 0 ? "link_days" : ""
                        // }`}
                        // onClick={() => {
                        //   if (Number(v?.DAYS_31_TO_60) > 0) {
                        //     navigate("/transactionReport/ReceivablesDetails", {
                        //       state: {
                        //         type: "DAYS_31_TO_60",
                        //         data: v?.DAYS_31_TO_60_DETAILS,
                        //         name: v?.CUSTOMER_NAME,
                        //         comp_type: "1",
                        //         date: addDataPre?.date,
                        //       },
                        //     });
                        //   }
                        // }}
                        >
                          {Number(v?.DAYS_31_TO_60).toFixed(2)}
                        </td>
                        <td
                        // className={`${
                        //   Number(v?.GT_60_DAYS) > 0 ? "link_days" : ""
                        // }`}
                        // onClick={() => {
                        //   if (Number(v?.GT_60_DAYS) > 0) {
                        //     navigate("/transactionReport/ReceivablesDetails", {
                        //       state: {
                        //         type: "GT_60_DAYS",
                        //         data: v?.GT_60_DAYS_DETAILS,
                        //         name: v?.CUSTOMER_NAME,
                        //         comp_type: "1",
                        //         date: addDataPre?.date,
                        //       },
                        //     });
                        //   }
                        // }}
                        >
                          {Number(v?.GT_60_DAYS).toFixed(2)}
                        </td>
                        <td
                        // className={`${
                        //   Number(v?.BALANCE) > 0 ? "link_days" : ""
                        // }`}
                        // onClick={() => {
                        //   if (Number(v?.BALANCE) > 0) {
                        //     navigate("/transactionReport/ReceivablesDetails", {
                        //       state: {
                        //         type: "BALANCE",
                        //         data: v?.BALANCE_DETAILS || [],
                        //         name: v?.CUSTOMER_NAME,
                        //         comp_type: "1",
                        //         date: addDataPre?.date,
                        //       },
                        //     });
                        //   }
                        // }}
                        >
                          {Number(v?.BALANCE).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      TOTAL
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {totalData?.inv_amount}
                    </td>
                  </tr> */}
                  </>
                }
              />
            )}
          </Pagination3>
        </>
      ) : showData ? (
        <NoData title={"NO RECORDS FOUND"} color="red" mt={30} />
      ) : (
        ""
      )}

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default Receivables;
