import React from "react";
import { BackButton, Button, HelpButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";

const GSTR2 = () => {
  return (
    <div className="container">
      <PageHeading
        title={"GSTR2 REPORT"}
        right={
          <div className="ReportMenu-btn">
            <div>
              <BackButton />
            </div>
            <div>
              
              <HelpButton
            link={[
              "https://youtu.be/oEzSuwSIkRg",
              "https://youtu.be/rrX-pqA7h68",
            ]}
            />
            </div>
          </div>
        }
      />
      <div style={{ height: "20px" }}></div>
      <p style={{ fontSize: "11px" }}>Under Development</p>
    </div>
  );
};

export default GSTR2;
