import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import Button from "../../components/btn/Button";
import CardDataRow from "./CardDataRow";
import Dropdown from "../../components/inputs/Dropdown";
import SmallModal from "../../components/modal/SmallModal";
import Input from "../../components/inputs/Input";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import {
  GST_data_api,
  distrct_data_api,
  distrct_data_api2,
  email_update_api,
  pincode_verify_api,
  profile_update_api,
} from "../../utils/apis";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { get_profile_data } from "./UsersSlice";
import { PinVerify } from "../OtherComponent/PinVerify";
import { EmailValidation, MobileValidation } from "../../utils/validator";
import { Radio } from "../../utils/utils";
import RadioBtn from "../../components/radio/RadioBtn";
import Modal from "../../components/modal/Modal";
import { SaveButton } from "../../components/btn/Buttons";
import { CheckBox } from "../../components/inputs/CheckBox";
import { Alerts } from "../../components/Alerts/Alerts";
import { get_complete_list } from "../../redux/slice/completeListSlice";
import { FaEdit, FaRegEdit } from "react-icons/fa";
import { CardDataRowProfile } from "../../components/list/ProfileList";

const Pinfo = ({ data }) => {
  const [obj, setObj] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [mess, setMess] = useState("");
  const [openEmailEdit, setOpenEmailEdit] = useState(false);
  const [showPinVerify, setShowPinVerify] = useState(false);
  const [addData, setAddData] = useState({});
  const [showAdd, setShowAdd] = useState(false);
  const [districtList2, setDistrictList2] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [locationList2, setLocationList2] = useState([]);
  const { showroom_address_list, godown_address_list, profile_data } =
    useSelector((state) => state.profile);
  const { stateList } = useSelector((state) => state.completeList);

  const { user_id } = useSelector((state) => state.other);

  useEffect(() => {
    update_profile_data();
  }, [user_id]);
  const update_profile_data = () => {
    if (user_id) {
      store.dispatch(get_profile_data(user_id));
    }
  };

  useEffect(() => {
    // store.dispatch(get_complete_list());
  }, [profile_data]);

  useEffect(() => {
    if (!showAdd) {
      // store.dispatch(get_profile_data(userid));
      update_profile_data();
    }
  }, [showAdd]);

  useEffect(() => {
    fetchDistList();
    setAddData({
      ...addData,
      showroom_address: profile_data?.showroom_address,
      godown_address: profile_data?.godown_address,
    });
  }, [profile_data]);

  const fetchDistList = async () => {
    if (profile_data?.state) {
      try {
        const responce = await distrct_data_api2(profile_data?.state);
        setDistrictList(responce);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handle_update = async () => {
    const arr = [];
    if (
      !obj?.person_name ||
      obj?.person_name.trim().length === 0 ||
      !obj?.mobile ||
      obj?.mobile.length !== 10 ||
      !obj?.address ||
      obj?.address.trim().length <= 25 ||
      // !obj?.std_code ||
      !obj?.land_line ||
      obj?.land_line.length != 10 ||
      !obj?.district ||
      !obj?.location ||
      obj?.location.trim().length === 0 ||
      !obj?.pin_code ||
      obj?.pin_code?.toString()?.length !== 6
    ) {
      arr.push(!obj.person_name ? "Please Enter PERSON NAME" : "");
      arr.push(
        !obj?.mobile
          ? "Please Enter Your MOBILE NUMBER"
          : obj?.mobile.length !== 10
          ? "MOBILE NUMBER Must Be Valid Number."
          : ""
      );
      arr.push(
        !obj?.address
          ? "Agency Address is required"
          : obj?.address.trim().length <= 25
          ? "AGENCY OFFICE ADDRESS Must Contain At Least 25 Characters"
          : ""
      );
      // arr.push(
      //   !obj?.std_code
      //     ? "STD CODE Must Be Valid Number"
      //     : obj?.std_code.length != 4
      //     ? "STD CODE Must Be Valid Number"
      //     : ""
      // );
      arr.push(
        !obj?.land_line
          ? "Alt. Mobile Must Be Valid Number"
          : obj?.land_line.length != 10
          ? "Alt. Mobile Must Be Valid Number"
          : ""
      );
      arr.push(!obj?.district ? "Please Select DISTRICT" : "");
      arr.push(!obj?.location ? "Please Enter LOCATION" : "");
      console.log("obj?.pin_code =====", obj?.pin_code);

      arr.push(
        !obj?.pin_code
          ? "Please Enter PIN CODE"
          : obj?.pin_code?.toString().length != 6
          ? "PIN CODE must be 6 digits"
          : ""
      );
    }

    if (arr.length === 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "profileupdate");
        body.append("agencyid", user_id);
        body.append("name", obj?.person_name?.trim());
        body.append("mobile", obj?.mobile);
        // body.append("landline", `${obj?.std_code}${obj?.land_line}`);
        body.append("landline", obj?.land_line);
        body.append("address", obj?.address.trim());
        body.append("district", obj?.district);
        body.append("location", obj?.location.trim());
        body.append("pincode", obj?.pin_code);

        const responce = await profile_update_api(body);
        setShowLoader(false);
        if (responce.success) {
          setOpenEdit(false);
          toast.success(responce.message);
          setObj({});
          // store.dispatch(get_profile_data(userid));
          update_profile_data();
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen(true);
      setMess(<Alerts data={arr} />);
    }
  };
  const handleDistrict3 = async (data) => {
    try {
      const body = new FormData();
      body.append("stateid", data || addData?.state);
      const response = await distrct_data_api(body);
      if (response.success) {
        setDistrictList2(response?.data?.reponselist?.districts);
      } else {
        setOpen(true);
        setMess(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handle_email_update = async () => {
    const arr = [];
    if (
      !obj?.old_email ||
      obj?.old_email.trim().length === 0 ||
      !EmailValidation.test(obj?.old_email) ||
      !obj?.new_email ||
      obj?.new_email.length === 0 ||
      !EmailValidation.test(obj?.new_email)
    ) {
      arr.push(
        !obj?.old_email
          ? "Please Enter old Email"
          : obj?.old_email.trim().length === 0
          ? "Please Enter old Email"
          : !EmailValidation.test(obj?.old_email)
          ? "Please Enter Valid old Email "
          : ""
      );
      arr.push(
        !obj?.new_email
          ? "Please Enter New Email"
          : obj?.new_email.trim().length === 0
          ? "Please Enter New Email"
          : !EmailValidation.test(obj?.new_email)
          ? "Please Enter Valid New Email "
          : ""
      );
    }
    if (arr.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("agencyid", user_id);
        body.append("new_email", obj?.new_email);
        body.append("old_email", obj?.old_email);
        const responce = await email_update_api(body);
        setShowLoader(false);
        if (responce.success) {
          toast.success(responce.message);
          setOpenEmailEdit(false);
          setObj({});
          // store.dispatch(get_profile_data(userid));
          update_profile_data();
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen(true);
      setMess(<Alerts data={arr} />);
    }
  };

  const validate_add_address = () => {
    const arr = [];

    console.log("addData?.addr_type ===", addData?.addr_type);

    if (
      !addData?.addr_type ||
      addData?.addr_type?.toString()?.trim().length === 0 ||
      // (addData?.gst &&
      //   (addData?.gst?.length !== 15 || !GSTValidation.test(addData?.gst))) ||
      (addData?.addr_type == "2" &&
        (!addData?.unit_name || addData?.unit_name.length == 0)) ||
      (addData?.addr_type == "1" &&
        (!addData?.cnt_name ||
          addData?.cnt_name.length == 0 ||
          !addData?.cnt_designation ||
          addData?.cnt_designation.length == 0 ||
          !addData?.cnt_mobile ||
          addData?.cnt_mobile.length !== 10 ||
          !MobileValidation?.test(addData?.cnt_mobile))) ||
      //  ||
      !addData?.address1 ||
      addData?.address1.trim().length <= 25 ||
      !addData?.district ||
      !addData?.state ||
      !addData?.pincode ||
      addData?.pincode.length !== 6 ||
      !addData?.location
      // addData?.location.length === 0
    ) {
      arr.push(!addData.addr_type ? "Please Select ADDRESS TYPE" : "");
      // arr.push(
      //   addData?.gst &&
      //     (addData?.gst?.length !== 15 || !GSTValidation.test(addData?.gst))
      //     ? "Please Enter Valid GST"
      //     : ""
      // );
      arr.push(
        addData?.addr_type == "2" &&
          (!addData?.unit_name || addData?.unit_name.length == 0)
          ? "Please Enter GODOWN NAME"
          : ""
      );
      arr.push(
        addData?.addr_type == "1" &&
          (!addData?.cnt_name || addData?.cnt_name.length == 0)
          ? "Please Enter CONTACT PERSON NAME"
          : ""
      );
      arr.push(
        addData?.addr_type == "1" &&
          (!addData?.cnt_designation || addData?.cnt_designation.length == 0)
          ? "Please Enter CONTACT PERSON DESIGNATION"
          : ""
      );

      arr.push(
        addData?.addr_type == "1"
          ? !addData?.cnt_mobile
            ? "Please Enter Your CONTACT MOBILE NUMBER"
            : addData?.cnt_mobile?.length !== 10
            ? "CONTACT MOBILE NUMBER Must Be Valid Number."
            : !MobileValidation?.test(addData?.cnt_mobile)
            ? "Please Enter Valid Mobile Number"
            : ""
          : ""
      );
      arr.push(!addData?.state ? "Please Enter STATE" : "");
      arr.push(
        !addData?.address1
          ? "Please Enter ADDRESS LINE 1"
          : addData?.address1.trim().length <= 25
          ? "ADDRESS should be minimum 25 characters."
          : ""
      );
      arr.push(!addData?.district ? "Please Select DISTRICT" : "");
      arr.push(
        !addData?.pincode
          ? "Please Enter PIN CODE"
          : addData?.pincode.length !== 6
          ? "PIN CODE must be 6 digits"
          : ""
      );
      arr.push(!addData.location ? "Please Select LOCATION" : "");
    }
    return arr;
  };

  const add_address = async () => {
    const data = validate_add_address();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "addAddress");
        body.append("agencyid", user_id);
        body.append("id", addData?.id || "");
        body.append("addr_type", addData?.addr_type);
        // body.append("addr_type", "1");
        // body.append("gst", addData?.gst || "");
        body.append(
          "unit_name",
          addData?.addr_type == "1" ? "" : addData?.unit_name.trim()
        );
        body.append(
          "cnt_name",
          addData?.addr_type == "2" ? "" : addData?.cnt_name
        );
        body.append(
          "cnt_designation",
          addData?.addr_type == "2" ? "" : addData?.cnt_designation
        );
        body.append(
          "cnt_mobile",
          addData?.addr_type == "2" ? "" : addData?.cnt_mobile
        );
        body.append("address1", addData?.address1 || "");
        body.append("address2", addData?.address2 || "");
        body.append("district", addData?.district);
        body.append("state", addData?.state);
        body.append("pincode", addData?.pincode);
        body.append("location", addData?.location);
        const responce = await profile_update_api(body);
        setShowLoader(false);
        if (responce.success) {
          setAddData({});
          setShowAdd(false);
          toast.success(responce.message);
          // store.dispatch(get_profile_data(userid));
          update_profile_data();
        } else if (!responce.success) {
          setMess(responce.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen(true);
      setMess(<Alerts data={data} />);
    }
  };

  const get_location = async (data, stateData, savetype) => {
    if ((stateData || stateData?.length > 0) && (data || data?.length === 6)) {
      try {
        const body = new FormData();
        body?.append("pincode", data);
        body?.append("state", stateData);
        const responce = await pincode_verify_api(body);
        if (responce.success) {
          if (savetype == "add") {
            setLocationList(responce?.locations);
          } else if (savetype == "obj") {
            setLocationList2(responce?.locations);
          }
        } else if (!responce.success) {
          // setMess(responce.message || "");
          // setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const default_address = async (id) => {
    if (id) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "defaultupdate");
        body.append("godown_id", id);
        body.append("agencyid", user_id);
        body.append("default_status", 1);
        const responce = await profile_update_api(body);
        setShowLoader(false);
        if (responce.success) {
          toast.success(responce.message, {
            toastId: "1",
          });
          update_profile_data();
        } else if (!responce.success) {
          setMess(responce.message);
          setOpen(true);
        }
      } catch (error) {
        console.log("err =====", error);
      }
    }
  };

  return (
    <div className={styles.p_card_div}>
      <div className={styles.p_card_header}>
        <div className={styles.card_heading}>Personal Information</div>
        <FaRegEdit
          size={20}
          cursor="pointer"
          onClick={() => {
            setOpenEdit(true);
            setObj({
              ...obj,
              state: profile_data?.state,
              person_name: profile_data?.contact_person_name,
              mobile: profile_data?.mobile,
              address: profile_data?.ADDRESS,
              location: profile_data?.LOCATION,
              pin_code: profile_data?.PINCODE,
              // land_line: profile_data?.landline?.slice(4, 11),
              land_line: profile_data?.landline,
              // std_code: profile_data?.landline?.slice(0, 4),
              district: profile_data?.DISTRICT?.toUpperCase(),
            });
            if (profile_data?.state && profile_data?.PINCODE) {
              get_location(profile_data?.PINCODE, profile_data?.state, "obj");
            }
          }}
        />
      </div>
      <div className={styles.p_card}>
        <CardDataRowProfile
          title={"NAME"}
          value={profile_data?.contact_person_name || "-"}
        />
        <CardDataRowProfile
          title={"MOBILE NUMBER"}
          value={profile_data?.mobile}
        />
        <CardDataRowProfile
          title={"ALT. NUMBER"}
          value={profile_data?.landline || "-"}
        />
        <div>
          <CardDataRowProfile
            title={"EMAIL ID"}
            value={
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="profile-card"
                >
                  <div className="profile-card-1">{profile_data?.email}</div>
                  <div
                    className="profile-card-2"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <FaRegEdit
                      size={20}
                      cursor="pointer"
                      onClick={() => {
                        setOpenEmailEdit(true);
                        setShowPinVerify(true);
                      }}
                    />
                    {/* <Button
                      onClick={() => {
                        setOpenEmailEdit(true);
                        setShowPinVerify(true);
                      }}
                      title={"UPDATE"}
                    /> */}
                  </div>
                </div>
              </>
            }
          />
          <div></div>
        </div>
        <CardDataRowProfile
          title={"REGISTERED OFFICE ADDRESS"}
          value={
            profile_data?.ADDRESS
              ? `${profile_data?.ADDRESS}, ${profile_data?.LOCATION} ,${profile_data?.DISTRICT}, ${profile_data?.state}, ${profile_data?.PINCODE}  `
              : "-"
          }
        />
        {profile_data?.showroom_address == "1" && (
          <>
            <CardDataRowProfile
              title={"SHOWROOM ADDRESS"}
              value={
                <div className="flex aic gap10 ">
                  <RadioBtn label={"SAME"} />
                  <Radio
                    title={"Yes"}
                    show={addData?.showroom_address == "1" ? true : false}
                    setShow={() => {
                      setAddData({ ...addData, showroom_address: "1" });
                      setShowAdd(false);
                    }}
                  />
                  <Radio
                    title={"NO"}
                    show={addData?.showroom_address == "1" ? false : true}
                    setShow={() => {
                      setAddData({
                        ...addData,
                        showroom_address: "0",
                        addr_type: "1",
                        id: "",
                      });
                      setShowAdd(true);
                    }}
                  />
                </div>
              }
            />
          </>
        )}
        {/* {profile_data?.godown_address == "1" && (
          <>
            <CardDataRowProfile
              title={"GODOWN ADDRESS"}
              value={
                <div className="flex aic gap10 ">
                  <RadioBtn label={"SAME"} />
                  <Radio
                    title={"Yes"}
                    show={addData?.godown_address == "1" ? true : false}
                    setShow={() => {
                      setAddData({ ...addData, godown_address: "1" });
                      setShowAdd(false);
                    }}
                  />
                  <Radio
                    title={"NO"}
                    show={addData?.godown_address == "1" ? false : true}
                    setShow={() => {
                      setAddData({
                        ...addData,
                        godown_address: "0",
                        addr_type: "2",
                        id: "",
                      });
                      setShowAdd(true);
                    }}
                  />
                </div>
              }
            />
          </>
        )} */}
        {showroom_address_list?.map((v, i) => (
          <div key={i}>
            <CardDataRowProfile
              title={"SHOWROOM ADDRESS"}
              value={
                <div className="profile-card">
                  <div
                    className="profile-card-1"
                    style={{ padding: ".5rem 0", flex: 12 }}
                  >
                    {v?.CNT_NAME} {v?.ADDRESS1} ,{v?.DISTRICT},
                    {
                      stateList?.filter((item) => item?.code == v?.STATE)[0]
                        ?.name
                    }
                    , {v?.PINCODE}
                  </div>
                  <div className="profile-card-2" style={{ flex: 2 }}>
                    <FaRegEdit
                      size={20}
                      cursor="pointer"
                      onClick={() => {
                        handleDistrict3(v?.STATE);
                        get_location(v?.PINCODE, v?.STATE, "add");
                        setAddData({
                          ...addData,
                          addr_type: v?.ADDRESS_TYPE,
                          unit_name: v?.ADDRESS_TYPE == "1" ? "" : v?.UNIT_NAME,
                          cnt_name: v?.CNT_NAME,
                          cnt_designation: v?.CNT_DESIGNATION,
                          cnt_mobile: v?.CNT_NUMBER,
                          address1: v?.ADDRESS1,
                          address2: v?.ADDRESS2,
                          district: v?.DISTRICT,
                          state: v?.STATE,
                          pincode: v?.PINCODE,
                          location: v?.LOCATION,
                          id: v?.ID,
                          update_data: true,
                        });
                        setShowAdd(true);
                      }}
                    />
                    {/* <Button
                      width={"fit-content"}
                      title={"EDIT"}
                      onClick={() => {
                        handleDistrict3(v?.STATE);
                        get_location(v?.PINCODE, v?.STATE, "add");
                        setAddData({
                          ...addData,
                          addr_type: v?.ADDRESS_TYPE,
                          unit_name: v?.ADDRESS_TYPE == "1" ? "" : v?.UNIT_NAME,
                          cnt_name: v?.CNT_NAME,
                          cnt_designation: v?.CNT_DESIGNATION,
                          cnt_mobile: v?.CNT_NUMBER,
                          address1: v?.ADDRESS1,
                          address2: v?.ADDRESS2,
                          district: v?.DISTRICT,
                          state: v?.STATE,
                          pincode: v?.PINCODE,
                          location: v?.LOCATION,
                          id: v?.ID,
                          update_data: true,
                        });
                        setShowAdd(true);
                      }}
                    /> */}
                  </div>
                </div>
              }
            />
          </div>
        ))}
        {godown_address_list?.length > 0 && (
          <>
            <CardDataRowProfile
              title={"GODOWN ADDRESS"}
              value={
                <>
                  {godown_address_list?.map((v, i) => (
                    <div className="profile-card" key={i}>
                      <div
                        style={{ padding: ".5rem 0", flex: 12 }}
                        className="profile-card-1"
                      >
                        {v?.UNIT_NAME}, {v?.ADDRESS1} ,{v?.DISTRICT},
                        {
                          stateList?.filter((item) => item?.code == v?.STATE)[0]
                            ?.name
                        }
                        , {v?.PINCODE}
                      </div>
                      <FaRegEdit
                        size={20}
                        cursor="pointer"
                        onClick={() => {
                          handleDistrict3(v?.STATE);
                          get_location(v?.PINCODE, v?.STATE, "add");
                          setAddData({
                            ...addData,
                            addr_type: v?.ADDRESS_TYPE,
                            unit_name:
                              v?.ADDRESS_TYPE == "1" ? "" : v?.UNIT_NAME,
                            address1: v?.ADDRESS1,
                            address2: v?.ADDRESS2,
                            district: v?.DISTRICT,
                            location: v?.LOCATION,
                            state: v?.STATE,
                            pincode: v?.PINCODE,
                            id: v?.ID,
                            update_data: true,
                          });
                          setShowAdd(true);
                        }}
                      />
                      {/* <Button
                        width={"fit-content"}
                        title={"EDIT"}
                        onClick={() => {
                          handleDistrict3(v?.STATE);
                          get_location(v?.PINCODE, v?.STATE, "add");
                          setAddData({
                            ...addData,
                            addr_type: v?.ADDRESS_TYPE,
                            unit_name:
                              v?.ADDRESS_TYPE == "1" ? "" : v?.UNIT_NAME,
                            address1: v?.ADDRESS1,
                            address2: v?.ADDRESS2,
                            district: v?.DISTRICT,
                            location: v?.LOCATION,
                            state: v?.STATE,
                            pincode: v?.PINCODE,
                            id: v?.ID,
                            update_data: true,
                          });
                          setShowAdd(true);
                        }}
                      /> */}
                      {/* </div> */}
                    </div>
                  ))}
                </>
              }
            />
          </>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            title={"ADD GODOWN"}
            onClick={() => {
              setAddData({
                ...addData,
                addr_type: "2",
                state: profile_data?.state,
                district: profile_data?.DISTRICT,
              });
              handleDistrict3(profile_data?.state);
              setShowAdd(true);
            }}
          />
        </div>

        {/* {profile_data?.godown_address != "1" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ width: "fit-content" }}>
              <Button
                title={"ADD GODOWN"}
                onClick={() => {
                  setAddData({
                    ...addData,
                    addr_type: "2",
                    state: profile_data?.state,
                    district: profile_data?.DISTRICT,
                  });
                  handleDistrict3(profile_data?.state);
                  setShowAdd(true);
                }}
              />
            </div>
          </div>
        )} */}
      </div>

      {openEdit && (
        <SmallModal
          okBtnHide={false}
          isOpen={openEdit}
          setIsOpen={setOpenEdit}
          style={{ width: "fit-content", maxWidth: "fit-content" }}
          okbtn={setOpenEdit}
          title={"CHANGE PROFILE DETAILS"}
          mess={
            <div className="pin-ver" style={{ margin: "2rem 0" }}>
              <div
                className="credit-limit"
                style={{
                  gap: "1rem",
                }}
              >
                <div style={{ width: "250px", padding: " .15rem .5rem" }}>
                  <Input
                    label={"PERSON NAME:"}
                    placeholder={"PERSON NAME"}
                    value={obj?.person_name}
                    onChange={(e) => {
                      const input = e.target.value;
                      const regex = /^[a-zA-Z ]+$/;
                      if (
                        regex.test(input) ||
                        input === "" ||
                        !obj?.person_name
                      ) {
                        setObj({
                          ...obj,
                          person_name: regex.test(input) ? input : "",
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "250px", padding: " .15rem .5rem" }}>
                  <Input
                    label={"MOBILE:"}
                    placeholder={"MOBILE NUMBER"}
                    value={obj?.mobile}
                    onChange={(e) => {
                      const input = e.target.value;
                      const regex = /^[0-9]+$/;
                      if (
                        (regex.test(input) || input === "" || !obj?.mobile) &&
                        input.length <= 10
                      ) {
                        setObj({
                          ...obj,
                          mobile: regex.test(input) ? input : "",
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "250px", padding: " .15rem .5rem" }}>
                  <Input
                    label={"ALT. MOBILE:"}
                    placeholder={"ALT. MOBILE NUMBER"}
                    value={obj?.land_line}
                    onChange={(e) => {
                      const input = e.target.value;
                      const regex = /^[0-9]+$/;
                      if (
                        (regex.test(input) ||
                          input === "" ||
                          !obj?.land_line) &&
                        input.length <= 10
                      ) {
                        setObj({
                          ...obj,
                          land_line: regex.test(input) ? input : "",
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "250px", padding: " .15rem .5rem" }}>
                  <Input
                    label={"ADDRESS:"}
                    placeholder={"ADDRESS"}
                    value={obj?.address}
                    onChange={(e) => {
                      setObj({ ...obj, address: e.target.value });
                    }}
                  />
                </div>

                <div style={{ width: "250px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label={"DISTRICT"}
                    placeholder={"DISTRICT"}
                    value={obj?.district.toUpperCase()}
                    onChange={(e) => {
                      setObj({ ...obj, district: e.target.value });
                    }}
                    options={[<option value={""}>{"SELECT"}</option>].concat(
                      districtList?.map((v, i) => (
                        <option key={i} value={v.name.toUpperCase()}>
                          {v.name}
                        </option>
                      ))
                    )}
                  />
                </div>
                <div style={{ width: "250px", padding: " .15rem .5rem" }}>
                  <Input
                    label={"PINCODE:"}
                    placeholder={"PINCODE"}
                    value={obj?.pin_code}
                    onChange={(e) => {
                      const input = e.target.value;
                      const regex = /^[0-9]+$/;
                      if (
                        (regex.test(input) || input === "" || !obj?.pin_code) &&
                        input.length <= 6
                      ) {
                        setObj({
                          ...obj,
                          pin_code: regex.test(input) ? input : "",
                          location: "",
                        });
                      }
                      if (
                        e.target.value.length == 6 &&
                        obj?.state?.length > 0
                      ) {
                        get_location(e.target.value, obj?.state, "obj");
                      }
                    }}
                  />
                </div>
                <div style={{ width: "250px", padding: " .15rem .5rem" }}>
                  {/* <Input
                    label={"LOCATION:"}
                    placeholder={"LOCATION"}
                    value={obj?.location}
                    onChange={(e) => {
                      setObj({ ...obj, location: e.target.value });
                    }}
                  /> */}
                  <Dropdown
                    label="LOCATION"
                    value={obj?.location}
                    options={
                      <>
                        <option>
                          {obj?.location?.length === 0 &&
                          locationList2?.length > 0
                            ? "SELECT"
                            : locationList2?.filter((v) => v === v?.location)[0]
                                ?.name || "SELECT"}
                        </option>
                        {locationList2?.length > 0 &&
                          locationList2?.map((v, i) => (
                            <option key={i} value={v}>
                              {v}
                            </option>
                          ))}
                      </>
                    }
                    onChange={(e) => {
                      setObj({ ...obj, location: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  margin: "2rem 0",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ padding: "0 5% " }}>
                  <Button onClick={handle_update} title={"SUBMIT"} />
                </div>
              </div>
            </div>
          }
        />
      )}

      {openEmailEdit &&
        (showPinVerify ? (
          <PinVerify
            // closeBTN={true}
            showPinVerify={showPinVerify}
            setShowPinVerify={setShowPinVerify}
            setShowLoader={setShowLoader}
            setAlertOpen={setOpen}
            setAlertMess={setMess}
          />
        ) : (
          <SmallModal
            okBtnHide={false}
            isOpen={openEmailEdit}
            setIsOpen={setOpenEmailEdit}
            style={{ minWidth: "40rem" }}
            okbtn={setOpenEmailEdit}
            title={"UPDATE REGISTERED EMAIL ID"}
            mess={
              <div className="pin-ver" style={{ margin: "2rem 0" }}>
                <p>
                  Confirmation Emails will be sent to your both existing and new
                  Email Id's. Please contact us if you don't receive
                  confirmation mail.
                </p>
                <div
                  className="pin-ver-1st"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <Input
                    w={"90%"}
                    placeholder={"Old Email"}
                    value={obj?.old_email}
                    onChange={(e) => {
                      setObj({
                        ...obj,
                        old_email: e.target.value,
                      });
                    }}
                  />
                  <Input
                    w={"90%"}
                    placeholder={"New Email"}
                    value={obj?.new_email}
                    onChange={(e) => {
                      setObj({
                        ...obj,
                        new_email: e.target.value,
                      });
                    }}
                  />
                </div>
                <div
                  className="pin-ver-1st"
                  style={{
                    display: "flex",
                    margin: "2rem 0",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ padding: "0 5% " }}>
                    <Button onClick={handle_email_update} title={"SUBMIT"} />
                  </div>
                </div>
              </div>
            }
          />
        ))}

      {showAdd && (
        <Modal
          isOpen={showAdd}
          setIsOpen={setShowAdd}
          title={addData?.id == "" ? "ADD ADDRESS" : "UPDATE ADDRESS"}
          body={
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div className="credit-limit">
                {addData?.addr_type == "2" ? (
                  <>
                    {/* <div style={{ width: "250px", padding: " .15rem .5rem" }}>
                      <Input
                        label="GST"
                        placeholder={"GSTIN"}
                        value={addData?.gst}
                        onChange={handleGSTData}
                        disabled={addData.addr_type == "1" ? true : false}
                        onBlur={() => {
                          if (
                            (addData?.gst?.length !== 15 &&
                              addData?.gst !== "") ||
                            !addData?.gst
                          ) {
                            setOpen(true);
                            setMess("Please enter valid GSTIN");
                          }
                        }}
                      />
                    </div> */}
                    <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                      <Input
                        label="GODOWN NAME"
                        placeholder={"GODOWN NAME"}
                        value={addData?.unit_name}
                        // disabled={addData.addr_type == "1" ? true : false}
                        onChange={(e) => {
                          setAddData({ ...addData, unit_name: e.target.value });
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                      <Input
                        label="CONTACT PERSON NAME"
                        placeholder={"CONTACT PERSON NAME"}
                        value={addData?.cnt_name}
                        onChange={(e) => {
                          setAddData({ ...addData, cnt_name: e.target.value });
                        }}
                      />
                    </div>
                    <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                      <Input
                        label="CONTACT PERSON DESIGNATION"
                        placeholder={"CONTACT PERSON DESIGNATION"}
                        value={addData?.cnt_designation}
                        onChange={(e) => {
                          setAddData({
                            ...addData,
                            cnt_designation: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                      <Input
                        label="CONTACT PERSON MOBILE"
                        placeholder={"CONTACT PERSON MOBILE"}
                        value={addData?.cnt_mobile}
                        onChange={(e) => {
                          const input = e.target.value;
                          const regex = /^[0-9]+$/;
                          if (
                            (regex.test(input) ||
                              input === "" ||
                              !obj?.mobile) &&
                            input.length <= 10
                          ) {
                            setAddData({
                              ...addData,
                              cnt_mobile: regex.test(input) ? input : "",
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}

                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    label="ADDRESS LINE 1"
                    placeholder={"ADDRESS"}
                    value={addData?.address1}
                    onChange={(e) => {
                      setAddData({ ...addData, address1: e.target.value });
                    }}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    label="ADDRESS LINE 2"
                    placeholder={"ADDRESS"}
                    value={addData?.address2}
                    onChange={(e) => {
                      setAddData({ ...addData, address2: e.target.value });
                    }}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="STATE"
                    value={addData?.state}
                    options={
                      <>
                        <option>
                          {addData?.state?.length === 0 && stateList.length > 0
                            ? "SELECT"
                            : stateList.filter((v) => v.code === v.state)[0]
                                ?.name || "SELECT"}
                        </option>
                        {stateList?.length > 0 &&
                          stateList?.map((v, i) => (
                            <option key={i} value={v.code}>
                              {v.name}
                            </option>
                          ))}
                      </>
                    }
                    onChange={(e) => {
                      setAddData({
                        ...addData,
                        state: e.target.value,
                        district: "",
                        location: "",
                        pincode: "",
                      });
                      if (addData?.pincode?.length == 6) {
                        get_location(addData?.pincode, addData?.state);
                      }
                    }}
                    onBlur={() => handleDistrict3(addData?.state)}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="DISTRICT"
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        district: e.target.value,
                        location: "",
                        pincode: "",
                      })
                    }
                    value={addData?.district}
                    options={
                      <>
                        <option>
                          {addData?.district?.length === 0 || !addData?.district
                            ? "SELECT"
                            : addData?.district}
                        </option>
                        {Array.isArray(districtList2) &&
                          districtList2?.length > 0 &&
                          districtList2?.map((item, i) => (
                            <option key={i} value={item.name.toUpperCase()}>
                              {item.name}
                            </option>
                          ))}
                      </>
                    }
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Input
                    label="PINCODE"
                    placeholder={"PINCODE"}
                    value={addData?.pincode}
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        let inputData = e.target.value;
                        const validInput = inputData.replace(/[^0-9]/g, "");
                        setAddData({
                          ...addData,
                          pincode: validInput,
                          location: "",
                        });
                      }
                      if (
                        e.target.value.length == 6 &&
                        addData?.state?.length > 0
                      ) {
                        get_location(e.target.value, addData?.state, "add");
                      }
                    }}
                  />
                </div>
                <div style={{ width: "240px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="LOCATION"
                    value={addData?.location}
                    options={
                      <>
                        <option>
                          {addData?.location?.length === 0 &&
                          locationList?.length > 0
                            ? "SELECT"
                            : locationList?.filter((v) => v === v?.location)[0]
                                ?.name || "SELECT"}
                        </option>
                        {locationList?.length > 0 &&
                          locationList?.map((v, i) => (
                            <option key={i} value={v}>
                              {v}
                            </option>
                          ))}
                      </>
                    }
                    onChange={(e) => {
                      setAddData({ ...addData, location: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <SaveButton onClick={() => add_address()} back />
              </div>
            </div>
          }
        ></Modal>
      )}

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alert"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default Pinfo;

// profile(agencyid),
// profileupdate(agencyid,name,mobile,landline,address,district,location,pincode)
// emailupdate(agencyid,email)
// passwordupdate(agencyid,oldpassword,password)
// pinupdate(agencyid,oldpin,pin)
// pinverify(agencyid,pin)
