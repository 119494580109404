import React, { useState } from "react";
import Dropdown from "../../../components/inputs/Dropdown";
import { BackButton } from "../../../components/btn/Buttons";
import PageHeading from "../../../components/PageHeading";
import Table from "../../../components/table/Table";

const CapitalAccReport = () => {
  // State variable for the selected financial year
  const [selectedYear, setSelectedYear] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // Example handler for when the financial year changes
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    // Here you could add logic to fetch new data based on the selected year
  };

  return (
    <div className="container">
      <PageHeading
        title={"CAPITAL REPORT"}
        right={
          <>
            <BackButton />
          </>
        }
      />
      <div style={{ height: "10px" }}></div>
      <div className="df mt10 gap10">
        <div style={{ width: "180px" }}>
          <Dropdown
            label={"FINANCIAL YEAR"}
            value={selectedYear}
            onChange={handleYearChange}
            options={
              <>
                <option value="">SELECT</option>
                <option value="2019-20">2019-20</option>
                <option value="2020-21">2020-21</option>
                <option value="2021-22">2021-22</option>
                <option value="2022-23">2022-23</option>
                <option value="2023-24">2023-24</option>
                <option value="2024-25">2024-25</option>
              </>
            }
          />
        </div>

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <button className="GENERATE-BTN" onClick={() => setModalOpen(true)}>SEARCH</button>
        </div>
      </div>
      <div style={{ height: "10px" }}></div>
      {modalOpen&&<Table
        mt={5}
        headData={[
          "FINANCIAL YEAR",
          "CREATED ON",
          "LAST UPDATED ON",
          "OPENING BALANCE",
          "NET INFUSION",
          "NET WITHDRAWAL / WRITE-OFF",
          "CLOSING BALANCE",
          "STATUS",
          "ACTIONS",
        ]}
        body={
          <>
            <tr>
              <td>{selectedYear}</td>
              <td>01/01/2021</td>
              <td>01/01/2022</td>
              <td>$10,000</td>
              <td>$2,000</td>
              <td>-$500</td>
              <td>$11,500</td>
              <td>Active</td>
              <td>
                <button className="GENERATE-BTN">PROCESS</button>
              </td>
            </tr>
            {/* More rows can be added similarly */}
          </>
        }
      />}
    </div>
  );
};

export default CapitalAccReport;
