import React from "react";
import PageHeading from "../../../components/PageHeading";
import { BackButton, Button, HelpButton } from "../../../components/btn/Buttons";

const TDSTCSReturn = () => {
  return (
    <div className="container">
      <PageHeading
        title={"TDS/TCS"}
        right={
          <div className="ReportMenu-btn">
            <div>
              <BackButton />
            </div>
            <div>
              
              <HelpButton
            link={[
              "https://youtu.be/oEzSuwSIkRg",
              "https://youtu.be/rrX-pqA7h68",
            ]}
            />
            </div>
          </div>
        }
      />
      <div style={{ height: "20px" }}></div>
      <p style={{ fontSize: "11px" }}>Under Development</p>
    </div>
  );
};

export default TDSTCSReturn;
