import React from "react";
import styles from "./profile.module.css";
import CardDataRow from "./CardDataRow";
import { CardDataRowProfile } from "../../components/list/ProfileList";

const PPinfo = () => {
  return (
    <div className={styles.p_card_div}>
      <div className={styles.card_heading}>Personal Preferences</div>
      <div className={styles.p_card}>
        <CardDataRowProfile title={"Language"} value={"English (United State)"} />
        <CardDataRowProfile title={"Date Format"} value={"DD-MM-YYYY"} />
        <CardDataRowProfile title={"Currency"} value={"INR"} />
        <CardDataRowProfile title={"Time Zone"} value={"Asia/Kolkata"} />
        <CardDataRowProfile title={"Time Format"} value={"24-hour"} />
        <CardDataRowProfile title={"Week Start Day"} value={"Monday"} />
        <CardDataRowProfile title={"Number Format"} value={"1,234.56"} />
      </div>
    </div>
  );
};

export default PPinfo;
