import React, { useState } from "react";
import { FiChevronRight, FiShoppingBag } from "react-icons/fi";
import { LuCalculator } from "react-icons/lu";
import { VscFiles } from "react-icons/vsc";
import { AnimatePresence, motion } from "framer-motion";
import CustomList from "./CustomList";
import { useNavigate } from "react-router-dom";
import { BsGraphUp } from "react-icons/bs";
import { MdOutlineDesktopWindows } from "react-icons/md";

const TransactionList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  const userIdString = localStorage.getItem("userId");
  const OMC = JSON.parse(userIdString)?.OMC;
  return (
    <>
      <div
        className="list-main mt10"
        onClick={() =>
          setFocused((pre) =>
            pre.find((v) => v == "Transactions")
              ? pre.filter((v) => v != "Transactions")
              : ["Transactions"]
          )
        }
      >
        <div className="list-icon">
          <VscFiles size={18} color="#6e82a5" />
        </div>
        <div className="list-title fs15">Transactions</div>
        <div className="list-arrow">
          <FiChevronRight
            size={18}
            className={
              focused.includes("Transactions") ? "rotate-down" : "rotate-right"
            }
          />
        </div>
      </div>

      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <>
              <div
                className="list-main"
                onClick={() =>
                  setFocused((pre) =>
                    pre.find((v) => v == "Purchases")
                      ? pre.filter((v) => v != "Purchases")
                      : [...pre, "Purchases"]
                  )
                }
              >
                <div className="list-icon">
                  <FiShoppingBag size={15} color="#6e82a5" />
                </div>
                <div className="list-title fs14">Purchases</div>
                <div className="list-arrow">
                  <FiChevronRight
                    size={18}
                    className={
                      focused.includes("Purchases")
                        ? "rotate-down"
                        : "rotate-right"
                    }
                  />
                </div>
              </div>
              <AnimatePresence>
                {focused.includes("Purchases") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <CustomList
                      title={"Vendor PO"}
                      style={{
                        backgroundColor:
                          path == "/transactions/venderpurchase" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/venderpurchase");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Cylinder Purchases"}
                      style={{
                        backgroundColor:
                          path == "/transactions/Cylinder-Purchases"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/Cylinder-Purchases");
                        close();
                      }}
                    />
                    <CustomList
                      title={
                        (OMC == 1 && "NFR Purchase") ||
                        (OMC == 2 && "ARB Purchase") ||
                        (OMC == 3 && "BLPG Purchase")
                      }
                      style={{
                        backgroundColor:
                          path == "/transactions/BLPG/ARB/NFR-Purchases"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/BLPG/ARB/NFR-Purchases");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Other Purchases"}
                      style={{
                        backgroundColor:
                          path == "/transactions/ExpenditureOtherPurchase" ||
                          path == "/transactions/OtherPurchase"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/ExpenditureOtherPurchase");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Defective/Pur return"}
                      style={{
                        backgroundColor:
                          path == "/transactions/Defective/Pur/Return"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/Defective/Pur/Return");
                        close();
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <>
              <div
                className="list-main"
                onClick={() =>
                  setFocused((pre) =>
                    pre.find((v) => v == "Sales")
                      ? pre.filter((v) => v != "Sales")
                      : [...pre, "Sales"]
                  )
                }
              >
                <div className="list-icon">
                  <BsGraphUp size={15} color="#6e82a5" />
                </div>
                <div className="list-title fs14">Sales</div>
                <div className="list-arrow">
                  <FiChevronRight
                    size={18}
                    className={
                      focused.includes("Sales") ? "rotate-down" : "rotate-right"
                    }
                  />
                </div>
              </div>
              <AnimatePresence>
                {focused.includes("Sales") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <CustomList
                      title={"Quotations"}
                      style={{
                        backgroundColor:
                          path == "/transactions/Quotations" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/Quotations");
                        close();
                      }}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/PurchaseSales" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/PurchaseSales");
                        close();
                      }}
                      title={"Customer PO"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/DomestiCylSale" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/DomestiCylSale");
                        close();
                      }}
                      title={"Domestic CYL Sales"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/CommercialCylSale"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/CommercialCylSale");
                        close();
                      }}
                      title={"Commercial CYL Sales"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/ARBSale" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/ARBSale");
                        close();
                      }}
                      title={
                        (OMC == 1 && "NFR Sales") ||
                        (OMC == 2 && "ARB Sales") ||
                        (OMC == 3 && "BLPG Sales")
                      }
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/DeliveryChallan"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/DeliveryChallan");
                        close();
                      }}
                      title={"Delivery Challan"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/SalesReturn" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/SalesReturn");
                        close();
                      }}
                      title={"Sales Return"}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <>
              <div
                className="list-main"
                onClick={() =>
                  setFocused((pre) =>
                    pre.find((v) => v == "NC / DBC / RC / TV")
                      ? pre.filter((v) => v != "NC / DBC / RC / TV")
                      : [...pre, "NC / DBC / RC / TV"]
                  )
                }
              >
                <div className="list-icon">
                  <MdOutlineDesktopWindows size={15} color="#6e82a5" />
                </div>
                <div className="list-title fs14">NC / DBC / RC / TV</div>
                <div className="list-arrow">
                  <FiChevronRight
                    size={18}
                    className={
                      focused.includes("NC / DBC / RC / TV")
                        ? "rotate-down"
                        : "rotate-right"
                    }
                  />
                </div>
              </div>
              <AnimatePresence>
                {focused.includes("NC / DBC / RC / TV") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/ncdbctrans" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/ncdbctrans");
                        close();
                      }}
                      title={"NC / DBC"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/itvrctrans" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/itvrctrans");
                        close();
                      }}
                      title={"ITV / RC"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/otvttvtvtrans" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/otvttvtvtrans");
                        close();
                      }}
                      title={"OTV / TTV /TV"}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/transactions/OtherPurchase" ? "white" : "",
              }}
            >
              <div className="list-icon">
                <LuCalculator size={15} color="#6e82a5" />
              </div>
              <div
                className="list-title fs14"
                onClick={() => {
                  // navigate("/transactions/ExpenditureOtherPurchase");
                  navigate("/transactions/OtherPurchase", {
                    state: {
                      itc: "",
                      showData: 2,
                    },
                  });
                  close();
                }}
              >
                Expenses
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default TransactionList;
