import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import CustomList from "./CustomList";
import { useNavigate } from "react-router-dom";
import { GoChecklist } from "react-icons/go";
import { FiChevronRight } from "react-icons/fi";

const FinacialReportsList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="list-main mt10"
        onClick={() =>
          setFocused((pre) =>
            pre.find((v) => v == "Transactions")
              ? pre.filter((v) => v != "Transactions")
              : ["Transactions"]
          )
        }
      >
        <div className="list-icon">
          <GoChecklist size={18} color="#6e82a5" />
        </div>
        <div className="list-title fs15">Finacial Reports</div>
        <div className="list-arrow">
          <FiChevronRight
            size={18}
            className={
              focused.includes("Transactions") ? "rotate-down" : "rotate-right"
            }
          />
        </div>
      </div>

      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <>
              <div
                className="list-main"
                onClick={() =>
                  setFocused((pre) =>
                    pre.find((v) => v == "Product Master")
                      ? pre.filter((v) => v != "Product Master")
                      : [...pre, "Product Master"]
                  )
                }
              >
                <div className="list-icon"></div>
                <div className="list-title fs14">Capital Account</div>
                <div className="list-arrow">
                  <FiChevronRight
                    size={18}
                    className={
                      focused.includes("Product Master")
                        ? "rotate-down"
                        : "rotate-right"
                    }
                  />
                </div>
              </div>
              <AnimatePresence>
                {focused.includes("Product Master") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <CustomList
                      title={"Partners Data"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/PartnersData" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/PartnersData");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Partners Transaction"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/PartnersTransaction"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/PartnersTransaction");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Capital Acc Search"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/CapitalAccSearch"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/CapitalAccSearch");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Capital Acc Report"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/CapitalAccReport"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/CapitalAccReport");
                        close();
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <>
              <div
                className="list-main"
                onClick={() =>
                  setFocused((pre) =>
                    pre.find((v) => v == "Price Master")
                      ? pre.filter((v) => v != "Price Master")
                      : [...pre, "Price Master"]
                  )
                }
              >
                <div className="list-icon"></div>
                <div className="list-title fs14">Depreciation Account</div>
                <div className="list-arrow">
                  <FiChevronRight
                    size={18}
                    className={
                      focused.includes("Price Master")
                        ? "rotate-down"
                        : "rotate-right"
                    }
                  />
                </div>
              </div>
              <AnimatePresence>
                {focused.includes("Price Master") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <CustomList
                      title={"A ssets Management"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/AssetsManagement"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/AssetsManagement");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Depreciaton Report"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/DepreciationReport"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/DepreciationReport");
                        close();
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/FinacialReport/ProLossAccount" ? "white" : "",
              }}
              onClick={() => {
                navigate("/FinacialReport/ProLossAccount");
                close();
              }}
            >
              <div className="list-icon"></div>
              <div className="list-title fs14">Profile and Loass Acoount</div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/FinacialReport/BalanceSheet" ? "white" : "",
              }}
              onClick={() => {
                navigate("/FinacialReport/BalanceSheet");
                close();
              }}
            >
              <div className="list-icon"></div>
              <div className="list-title fs14">Balance Sheet</div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/FinacialReport/ProfitabilityAnalysis"
                    ? "white"
                    : "",
              }}
              onClick={() => {
                navigate("/FinacialReport/ProfitabilityAnalysis");
                close();
              }}
            >
              <div className="list-icon"></div>
              <div className="list-title fs14">Profitability Analysis</div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default FinacialReportsList;
