import React, { useEffect, useMemo, useRef, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Button from "../../../components/btn/Button";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { motion } from "framer-motion";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import { useSelector } from "react-redux";
import PageLoading from "../../../components/PageLoading";
import store from "../../../redux/store";
import {
  get_areacode_master_list,
  get_bank_master_list,
  get_credit_limit_list,
  get_customer_vender_list,
  get_equipment_list,
  get_refill_price_list,
  get_staff_list,
} from "../../MasterData/masterdataSlice";
import { BankCodeFinder, DeleteIcon } from "../../../utils/utils";
import moment from "moment";
import Table from "../../../components/table/Table";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { domestic_cylinder_sales_API } from "../../../utils/apis";
import { toast } from "react-toastify";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
  AreaCodeList,
  CVOList,
  EquipmentList,
  PriceList,
  StaffList,
} from "../../OtherComponent/StaticLists/staticLists";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import NoData from "../../../components/NoData";
import {
  get_domestic_sales_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { alfaNumericWithoutSpace } from "../../../utils/Rejex";
import ReactToPrint from "react-to-print";
import { Alerts } from "../../../components/Alerts/Alerts";
import { UOMData } from "../../../utils/data";
import {
  DateValidator2,
  gst_calculate,
  isInvoiceDateWithinCreditPeriod,
  UnitFunction,
} from "../../../utils/validation";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import TaxInvoice3 from "../../OtherComponent/TaxInvoice/TaxInvoice3";
import { ReduceFunction } from "../../../utils/Hook/ReduceFunction";
import { EmptyDataHook } from "../../../utils/Hook/EmptyDataHook";
// const tax_invoice_table_header = [
//   { title: "Sl No.", cols: false },
//   { title: "Description of Goods", cols: true },
//   { title: "HSN/SAC", cols: false },
//   { title: "GST Rate", cols: false },
//   { title: "Quantity", cols: false },
//   { title: "Rate Incl. GST", cols: false },
//   { title: "Rate", cols: false },
//   { title: "per", cols: false },
//   { title: "Discount", cols: false },
//   { title: "Amount", cols: false },
// ];

export const DomestiCylSale = () => {
  const previewRef = useRef(null);
  const [EquipClick, setEquipClick] = useState(false);
  const [PriceClick, setPriceClick] = useState(false);
  const [StaffClick, setStaffClick] = useState(false);
  const [AreaClick, setAreaClick] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cvoClick, setCvoClick] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [SerchInput, setSearchInput] = useState("");
  const [open4, setOpen4] = useState(false);
  const {
    area_code_list,
    refill_price_list,
    staff_list,
    bank_master_list,
    equipment_list,
    credit_limit_list,
    cust_vend_list,
  } = useSelector((state) => state.masterdata);
  const { user_id, today } = useSelector((state) => state.other);
  const navigate = useNavigate();
  const { profile_data, day_end_date } = useSelector((state) => state.profile);
  const { godown_address_list } = useSelector((state) => state.profile);
  const {
    transition_complete_list,
    domestic_sales_list,
    domestic_sales_loading,
  } = useSelector((state) => state.transactions);

  useEffect(() => {
    store.dispatch(get_equipment_list());
    store.dispatch(get_bank_master_list());
    store.dispatch(get_staff_list());
    store.dispatch(get_areacode_master_list());
    store.dispatch(get_refill_price_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_credit_limit_list());
    store.dispatch(get_customer_vender_list());
  }, []);

  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };
  const fetchData = () => {
    store.dispatch(get_domestic_sales_list(obj));
  };
  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const onDelete_item = async () => {
    try {
      setOpen(false);
      setLoading(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", id);
      const responce = await domestic_cylinder_sales_API(body);
      setLoading(false);
      if (responce.success) {
        setPageNo(domestic_sales_list?.length < 2 ? pageNo - 1 : pageNo);
        store.dispatch(
          get_domestic_sales_list({
            page: domestic_sales_list?.length < 2 ? pageNo - 1 : pageNo,
            limit: entries,
            setTotalCount: setTotalCount,
            setSearchCount: setSearchCount,
            search: SerchInput,
          })
        );
        toast.success(responce.message);
      } else if (!responce.success) {
        setMess(responce.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculate = () => {
    var total = 0;
    var online = 0;
    addData?.forEach((lastItem, i) => {
      const fieldsToCheck = [
        "area_code",
        "unit_rate",
        "quantity",
        "p_gst",
        // "discount_price",
        "psv_quantity",
        "prepaid_cylinder",
      ];

      const missingField = fieldsToCheck.find(
        (field) =>
          !lastItem[field] || (lastItem[field] && lastItem[field].length === 0)
      );

      if (missingField) {
        setOpen1(true);
        setMess("Please Fill All fields first");
        return;
      }
      if (addDataHead?.payment_terms == "2" && lastItem?.prepaid_cylinder > 0) {
        // data.push("PREPAID CYLINDERS	must be 0 for CREDIT type payment");
        setOpen1(true);
        setMess("PREPAID CYLINDERS	must be 0 for CREDIT type payment");
        return;
      }
      const data =
        Number(lastItem?.psv_quantity) + Number(lastItem?.prepaid_cylinder);
      if (Number(lastItem?.quantity) < data) {
        setOpen1(true);
        setMess(
          "The Quantity is always greater than or equal to the sum of Prepaid Cylinders and PSV Cylinders."
        );
        return;
      }

      const unit_rate = Number(lastItem.unit_rate);
      const quantity = Number(lastItem.quantity);
      const p_gst = Number(lastItem.p_gst);
      const discount_price = Number(lastItem.discount_price);
      const psv_quantity = Number(lastItem.psv_quantity);
      const prepaid_cylinder = Number(lastItem.prepaid_cylinder);
      const transport_charges = Number(lastItem.transport_charges) || 0;

      // Normal Quantity Calculation
      const tax_amount = (unit_rate - discount_price) * quantity;
      const gst_amount = tax_amount * (p_gst / 100);
      const tr_charges = transport_charges + transport_charges * (p_gst / 100);
      const amount = gst_amount + tax_amount + tr_charges;

      // PSV Quantity Calculation
      const psv_amount = (unit_rate - discount_price) * psv_quantity;
      const psv_gst_amount = psv_amount * (p_gst / 100);
      const psv_total_amount = psv_gst_amount + psv_amount;

      // Digi Quantity Calculation
      const prepaid_amount = (unit_rate - discount_price) * prepaid_cylinder;
      const prepaid_gst_amount = prepaid_amount * (p_gst / 100);
      const prepaid_total_amount = prepaid_gst_amount + prepaid_amount;

      // Finalize
      const total_amount = amount - psv_total_amount;
      const transiction_total_amount = (
        amount -
        psv_total_amount -
        prepaid_total_amount -
        tr_charges
      ).toFixed(2);

      const online_rcvd_total_amount =
        prepaid_total_amount === 0 ? 0 : prepaid_total_amount;

      setAddData((previousData) =>
        previousData.map((item, index) => {
          if (index === i) {
            return {
              ...item,
              amount: Number(total_amount).toFixed(2),
              transaction_amount: Number(transiction_total_amount || 0).toFixed(
                2
              ),
              online_rcvd_amount: Number(online_rcvd_total_amount || 0).toFixed(
                2
              ),
              igst:
                addDataHead?.c_type == "isp"
                  ? Number(gst_amount).toFixed(2)
                  : 0,
              sgst:
                addDataHead?.c_type != "isp"
                  ? Number(gst_amount / 2).toFixed(2)
                  : 0,
              cgst:
                addDataHead?.c_type != "isp"
                  ? Number(gst_amount / 2).toFixed(2)
                  : 0,
            };
          }
          return { ...item };
        })
      );
      online += online_rcvd_total_amount;
      total += total_amount;
    });

    setAddDataHead({
      ...addDataHead,
      inv_amount: Number(total).toFixed(2),
      total_new: Number(total - online).toFixed(2),
      cash_recieved: Number(
        total -
          online -
          (addDataHead?.online_recieved > 0 ? addDataHead?.online_recieved : 0)
      ).toFixed(2),
      online_recieved: Number(addDataHead?.online_recieved || 0).toFixed(2),
    });
  };

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.product_code ||
          (v.product_code && v.product_code.length == 0) ||
          !v.p_gst ||
          (v.p_gst && v.p_gst.length == 0) ||
          !v.unit_rate ||
          (v.unit_rate && v.unit_rate.length == 0) ||
          v?.discount_price === undefined ||
          v?.discount_price === null ||
          v?.discount_price === "" ||
          !v.quantity ||
          (v.quantity && v.quantity.length == 0) ||
          !v.delivered_by ||
          (v.delivered_by && v.delivered_by.length == 0) ||
          !v.area_code ||
          (v.area_code && v.area_code.length == 0) ||
          v?.transport_charges === undefined ||
          v?.transport_charges === null ||
          v?.transport_charges === "" ||
          !v.transaction_amount ||
          (v.transaction_amount && v.transaction_amount.length == 0) ||
          !v.amount ||
          (v.amount && v.amount.length == 0)
      )
    ) {
      return true;
    }
  };

  const add_items = async (type) => {
    const data1 = valiDate();
    const data = [...new Set(data1)];
    if (data.length == 0) {
      if (type == "first") {
        const data3 = credit_limit_list?.filter(
          (v) => v?.customer_id == addDataHead?.customer_name
        )?.[0];
        const data2 = cust_vend_list?.filter(
          (v) => v?.ID == addDataHead?.customer_name
        )?.[0];

        const dateValid = isInvoiceDateWithinCreditPeriod(
          data3?.modified_date,
          addDataHead?.inv_date,
          data3?.credit_days
        );
        if (
          addDataHead?.payment_terms == "1" &&
          addDataHead?.inv_amount > 250000
        ) {
          setMess(
            "YOUR INVOICE AMOUNT EXCEEDS RS.2,50,000/- .IF YOU WANT TO ADD FURTHER,ADD IN NEXT INVOICE"
          );
          setOpen1(true);
          return;
        } else if (addDataHead?.payment_terms == "2") {
          if (data3?.cc_days == "BLOCK" && !dateValid) {
            setMess(
              "THIS TRANSACTION CANNOT BE PROCEEDED FURTHER AS THIS CUSTOMER HAS EXCEEDED CREDIT Days."
            );
            setOpen1(true);
            return;
          } else if (data3?.cc_days == "WARN" && !dateValid) {
            setMess(
              "THIS CUSTOMER HAS EXCEEDED CREDIT Days. DO YOU WANT TO CONTINUE?"
            );
            setOpen4(true);
            return;
          } else if (
            data3?.cc_limit == "BLOCK" &&
            (Number(data3.credit_limit) < Number(addDataHead?.inv_amount) ||
              Number(data3.credit_limit) < Number(data2.CBAL) ||
              Number(data3.credit_limit) <
                Number(addDataHead?.inv_amount) + Number(data2.CBAL))
          ) {
            setMess(
              "THIS TRANSACTION CANNOT BE PROCEEDED FURTHER AS THIS CUSTOMER HAS EXCEEDED CREDIT LIMIT."
            );
            setOpen1(true);
            return;
          } else if (
            data3?.cc_limit == "WARN" &&
            (Number(data3.credit_limit) < Number(addDataHead?.inv_amount) ||
              Number(data3.credit_limit) < Number(data2.CBAL) ||
              Number(data3.credit_limit) <
                Number(addDataHead?.inv_amount) + Number(data2.CBAL))
          ) {
            setMess(
              "THIS CUSTOMER HAS EXCEEDED CREDIT LIMIT. DO YOU WANT TO CONTINUE?"
            );
            setOpen4(true);
            return;
          }
        }
      }
      setOpen4(false);
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("number", addDataHead?.ref_no);
        body.append("date", addDataHead?.inv_date);
        body.append("customer", addDataHead?.customer_name);
        body.append("payment_terms", addDataHead?.payment_terms);
        body.append("amount", addDataHead?.inv_amount);
        body.append("cash_amount", addDataHead?.cash_recieved);
        body.append("cash_received", addDataHead?.cash_recieved_from);
        body.append("cash_received_online", addDataHead?.cash_recieved_from);
        body.append("amount_received_online", addDataHead?.online_recieved);
        body.append("bank_account", addDataHead?.bank_account);
        body.append(
          "cust_name",
          addDataHead?.customer_name == "CASH SALES / HOUSEHOLDS"
            ? addDataHead?.customer_name
            : ""
        );
        body.append("cust_addr", "");
        body.append("balance", "");
        body.append("gstin", "");
        body.append("transaction_id", addDataHead?.transaction_id || "");
        body.append("godown_id", addDataHead?.godown_id);
        // body.append("received_from", addDataHead?.ref_no);

        addData?.forEach((v) => {
          if (v) {
            body.append("product[]", v?.product_code);
            body.append("gst[]", v?.p_gst);
            body.append("dis_uprice[]", v?.discount_price);
            body.append("quantity[]", v?.quantity);
            body.append("unit_rate[]", v?.unit_rate);
            body.append("transport_charges[]", v?.transport_charges);
            body.append("rcvd_amount[]", v?.online_rcvd_amount);
            body.append("tamount[]", v?.transaction_amount);
            body.append("total_amount[]", v?.amount);
            body.append("psv_cylinder[]", v?.psv_quantity);
            body.append("pre_cylinder[]", v?.prepaid_cylinder);
            body.append("delivered[]", v?.delivered_by);
            body.append("area_code[]", v?.area_code);
            body.append("bank_id[]", v?.bank_account);
            body.append("igst[]", v?.igst);
            body.append("cgst[]", v?.cgst);
            body.append("sgst[]", v?.sgst);
            // body.append("narration[]", v?.);
          }
        });

        const data = await domestic_cylinder_sales_API(body);
        setLoading(false);
        if (data.success) {
          toast(data.message);
          setIsAdd(false);
          store.dispatch(get_domestic_sales_list(obj));
          setAddData([]);
          setAddDataHead({});
        } else if (!data.success) {
          setMess(data?.message);
          setOpen1(true);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const FetchGST = async () => {
    const data = [];
    const data1 = DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice date cant be before as day end Date ${day_end_date}`,
      `Invoice date cant be a future date`
    );
    if (
      !addDataHead.inv_date ||
      (addDataHead.inv_date && addDataHead.inv_date.length == 0)
    ) {
      data.push("Please Enter Invoice Date First");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      !addDataHead.customer_name ||
      (addDataHead.customer_name && addDataHead.customer_name.length == 0)
    ) {
      data.push("Please Enter Customer Name First");
    }
    if (
      !addData[addData.length - 1]?.product_code ||
      (addData[addData.length - 1]?.product_code &&
        addData[addData.length - 1]?.product_code.length === 0)
    ) {
      data.push("please select product first ");
    }
    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "fetchgst");
        body.append("agencyid", user_id);
        body.append("procode", addData[addData.length - 1]?.product_code);
        body.append("invdate", addDataHead?.inv_date);
        const response = await domestic_cylinder_sales_API(body);
        setLoading(false);
        if (response.success) {
          setAddData((pre) =>
            pre.map((v, i) =>
              i === addData.length - 1
                ? {
                    ...v,
                    p_gst: response.GSTP,
                    unit_rate: response.BASE_PRICE,
                    discount_price: 0,
                    transaction_amount: "",
                    amount: "",
                    igst: "",
                    sgst: "",
                    cgst: "",
                  }
                : { ...v }
            )
          );
        } else if (!response.success) {
          setOpen1(true);
          setMess(response?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen1(true);
      setMess(<Alerts data={data} />);
    }
  };

  const fetchTransCharge = async (ind, area_code) => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "fetchareacode");
      body.append("agencyid", user_id);
      body.append("areacode", area_code);
      const response = await domestic_cylinder_sales_API(body);
      setLoading(false);
      if (response?.success) {
        setAddData((pre) =>
          pre.map((v, i) =>
            i === ind
              ? {
                  ...v,
                  transport_charges: addData?.[ind]?.quantity
                    ? Number(response?.TRANSPORT_CHARGES) *
                        Number(
                          (addData?.[ind]?.quantity || 1) -
                            (addData?.[ind]?.psv_quantity || 0) -
                            (addData?.[ind]?.prepaid_cylinder || 0)
                        ) || 0
                    : "",
                  unit_transport_charges: response?.TRANSPORT_CHARGES || 0,
                  amount: "",
                  transaction_amount: "",
                  online_rcvd_amount: "",
                }
              : { ...v }
          )
        );
      } else if (!response.success) {
        setOpen1(true);
        setMess(response?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const FetchInvoiceNumber = async () => {
    const data = [];
    const data1 = DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice date cant be before as day end Date ${day_end_date}`,
      `Invoice date cant be a future date`
    );
    if (
      !addDataHead.inv_date ||
      (addDataHead.inv_date && addDataHead.inv_date.length == 0)
    ) {
      data.push("Please Enter Invoice Date First");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      !addDataHead.customer_name ||
      (addDataHead.customer_name && addDataHead.customer_name.length == 0)
    ) {
      data.push("Please Enter Customer Name First");
    }
    if (data?.length == 0) {
      try {
        const body = new FormData();
        body.append("action", "invoicenumber");
        body.append("agencyid", user_id);
        body.append("date", addDataHead?.inv_date);
        body.append("is_gst_reg", addDataHead?.cust_reg);
        body.append("cust_id", addDataHead?.customer_name || "");
        const response = await domestic_cylinder_sales_API(body);
        if (response.success) {
          setAddDataHead({ ...addDataHead, ref_no: response.invoice_number });
        } else if (!response.success) {
          setOpen1(true);
          setMess(response?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen1(true);
      setMess(<Alerts data={data} />);
    }
  };

  const valiDate = () => {
    const data = [];
    const data1 = DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice date cant be before as day end Date ${day_end_date}`,
      `Invoice date cant be a future date`
    );
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date.length === 0)
    ) {
      data.push("Please Enter Invoice Date");
    } else if (data1 != true) {
      data.push(...data1);
    } else if (
      !addDataHead?.ref_no ||
      (addDataHead?.ref_no && addDataHead?.ref_no.length === 0)
    ) {
      data.push("Please Click Fetch Invoice Number button");
    }

    if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name.length === 0)
    ) {
      data.push("Please Select Costomer Name");
    }
    if (
      !addDataHead?.payment_terms ||
      (addDataHead?.payment_terms && addDataHead?.payment_terms.length === 0)
    ) {
      data.push("Please Select Payment Terms");
    }

    if (addDataHead?.payment_terms == "1") {
      // const sumData =
      //   Number(addDataHead?.cash_recieved) +
      //   Number(addDataHead?.online_recieved);
      // const invAmount = addData
      //   .map((v) => v.amount)
      //   .reduce((a, b) => Number(a) + Number(b), 0);
      const tableOnlineRecivedAmmount = addData
        .map((v) => v.online_rcvd_amount)
        .reduce((a, b) => Number(a) + Number(b), 0);
      if (
        (!addDataHead?.cash_recieved || addDataHead?.cash_recieved == 0) &&
        (!addDataHead?.online_recieved || addDataHead?.online_recieved == 0)
      ) {
        data.push("Please Enter Cash and Online Recieved Amount");
      }
      // else if (tableOnlineRecivedAmmount > addDataHead?.online_recieved) {
      //   data.push(
      //     "Online Recieved Amount Should be Greater then or equal to Table Online RCVD amounts Sum"
      //   );
      // }
      else if (
        Number(addDataHead?.cash_recieved) < 0 ||
        Number(addDataHead?.online_recieved) < 0
      ) {
        if (Number(addDataHead?.cash_recieved) < 0) {
          data.push("Cash Received amount cant be lessthen Zero");
        } else if (Number(addDataHead?.online_recieved) < 0) {
          data.push("Online Received amount cant be lessthen Zero");
        }
      }
      if (addDataHead?.online_recieved > 0) {
        // if (
        //   !addDataHead?.transaction_id &&
        //   (addDataHead?.transaction_id || addDataHead?.transaction_id == "")
        // ) {
        //   data.push("Please Enter Transaction Id");
        // } else if (addDataHead?.transaction_id?.length != 15) {
        //   data.push("Please Enter valid Transaction Id");
        // }
        if (
          (!addDataHead?.cash_recieved_from ||
            addDataHead?.cash_recieved_from == "") &&
          addDataHead?.cash_recieved > 0
        ) {
          data.push("Please Select a Cash Recieved From");
        }
        if (
          (!addDataHead?.bank_account || addDataHead?.bank_account == "") &&
          addDataHead?.online_recieved > 0
        ) {
          data.push("Please Select a Bank Account");
        }
      }
    }
    if (
      !addDataHead?.godown_id ||
      (addDataHead?.godown_id && addDataHead?.godown_id.length === 0)
    ) {
      data.push("Please Select Godown First");
    }

    for (let v of addData) {
      if (
        !v?.product_code ||
        (v?.product_code && v?.product_code.length === 0)
      ) {
        data.push("Please Select PRODUCT ");
      } else if (
        !v?.p_gst ||
        (v?.p_gst && v?.p_gst.length === 0) ||
        !v?.unit_rate ||
        (v?.unit_rate && v?.unit_rate.length === 0)
      ) {
        data.push("Please Click Fetch Price button");
      }
      if (
        v?.discount_price === undefined ||
        v?.discount_price === null ||
        v?.discount_price === ""
      ) {
        data.push("Please Enter Discount Price");
      } else if (v?.discount_price < 0) {
        data.push("Discount cant be a negetive value");
      }
      if (!v?.quantity || (v?.quantity && v?.quantity.length === 0)) {
        data.push("Please Enter Quantity");
      } else if (v?.quantity == 0) {
        data.push("Please Enter valid Quantity");
      } else if (
        equipment_list?.filter((s) => s?.PROD_CODE == v?.product_code)?.[0]
          ?.CS_FULLS < v?.quantity
      ) {
        data.push(
          "Given Quantity is not available in our stock, please Check Stocks and Try again"
        );
      }
      if (
        v?.psv_quantity === undefined ||
        v?.psv_quantity === null ||
        v?.psv_quantity === ""
      ) {
        data.push("Please Enter PSV Quantity");
      } else if (v?.psv_quantity < 0) {
        data.push("PSV Quantity cant be a negetive value");
      }
      if (
        !v?.prepaid_cylinder ||
        (v?.prepaid_cylinder && v?.prepaid_cylinder.length === 0)
      ) {
        data.push("Please Enter Prepaid Quantity");
      } else if (addDataHead?.payment_terms == "2" && v?.prepaid_cylinder > 0) {
        data.push("PREPAID CYLINDERS	must be 0 for CREDIT type payment");
      }
      if (
        !v?.delivered_by ||
        (v?.delivered_by && v?.delivered_by.length === 0)
      ) {
        data.push("Please Select Delivered By");
      }
      if (!v?.area_code || (v?.area_code && v?.area_code.length === 0)) {
        data.push("Please Select Area Code");
      } else if (
        v?.transport_charges === undefined ||
        v?.transport_charges === null ||
        v?.transport_charges === ""
      ) {
        data.push("Please Click on Calculate");
      }
      if (
        !v?.transaction_amount ||
        (v?.transaction_amount && v?.transaction_amount.length === 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (!v?.amount || (v?.amount && v?.amount.length === 0)) {
        data.push("Please Click on Calculate");
      } else if (
        !addDataHead?.inv_amount ||
        (addDataHead?.inv_amount && addDataHead?.inv_amount.length === 0)
      ) {
        data.push("Please Click on Calculate");
      }
    }
    return data;
  };

  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);
  return (
    <div className="container">
      <PageHeading
        title={"DOMESTIC CYLINDER SALES"}
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData((prevData) => [...prevData, {}]);
                setIsAdd(true);
              }}
            />
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/WMuWtnlI8zc",
                "https://youtu.be/l8xqCNiWcQw",
              ]}
            />
          </>
        }
      />
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {domestic_sales_loading ? (
          <PageLoading />
        ) : domestic_sales_list?.length == 0 ? (
          <NoData />
        ) : (
          <div className="grid">
            {domestic_sales_list?.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v?.cvo_name || "CASH SALES / HOUSEHOLDS"}
                  </div>
                  <RowTxt
                    title={"Invoice No."}
                    value={
                      <ReactToPrint
                        trigger={() => (
                          <div
                            onMouseEnter={() => {
                              setViewItem(v);
                            }}
                            onTouchStart={() => {
                              setViewItem(v);
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setViewItem(v);
                            }}
                          >
                            {v?.SR_NO}
                          </div>
                        )}
                        content={() => previewRef.current}
                        documentTitle={`${
                          v?.cvo_name || "CASH SALES / HOUSEHOLDS"
                        }-${v?.SR_NO}`}
                      />
                    }
                  />
                  <RowTxt
                    title={"Invoice Date"}
                    value={moment(v?.SI_DATE).format("DD-MMM-YYYY")}
                  />
                  <RowTxt title={"Total Amount"} value={v?.SI_AMOUNT} />
                  <RowTxt
                    title={"Payment Terms"}
                    value={
                      v?.PAYMENT_TERMS == "1"
                        ? "Cash"
                        : v?.PAYMENT_TERMS == "2"
                        ? "Credit"
                        : ""
                    }
                  />
                  <RowTxt title={"Godown Address"} value={v?.GODOWN_NAME} />
                  {v?.PAYMENT_TERMS == "1" && (
                    <>
                      <RowTxt title={"Cash Recieved"} value={v?.CASH_AMOUNT} />
                      <RowTxt
                        title={"Online Recieved"}
                        value={v?.AMOUNT_RECEIVED_ONLINE}
                      />
                      <RowTxt
                        title={"Transaction Id"}
                        value={v?.TRANSACTION_ID || "NA"}
                      />
                      <RowTxt
                        title={"Cash Recieved From"}
                        // value={v?.SI_AMOUNT}
                        value={
                          v?.RECEIVED_FROM == "1"
                            ? "Phone Pay"
                            : v?.RECEIVED_FROM == "2"
                            ? "Google Pay"
                            : v?.RECEIVED_FROM == "3"
                            ? "Paytm"
                            : v?.RECEIVED_FROM == "4"
                            ? "Cred"
                            : v?.RECEIVED_FROM == "5"
                            ? "Others"
                            : "NA"
                        }
                      />
                      <RowTxt title={"Bank Account"} value={v?.BANK_ACC_NO} />
                    </>
                  )}
                </div>
                <div className="df jcsb mt10">
                  {v?.STATUS_FLAG == "0" ? (
                    <DeleteIcon
                      onClick={() => {
                        setId(v?.ID);
                        setOpen(true);
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    >
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData([]);
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button
                title={"CVO"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"EQUIPMENT"}
                back
                onClick={() => setEquipClick(!EquipClick)}
              />
              <Button
                title={"PRICE"}
                back
                onClick={() => setPriceClick(!PriceClick)}
              />
              <Button
                title={"BANK"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"STAFF"}
                back
                onClick={() => setStaffClick(!StaffClick)}
              />
              <Button
                title={"AREACODE"}
                back
                onClick={() => setAreaClick(!AreaClick)}
              />
            </div>
          }
          title={"DOMESTIC CYLINDER SALES"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={"SALES INVOICE DATE"}
                  type={"date"}
                  value={addDataHead?.inv_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        inv_date: e.target.value,
                      });
                      const resetData1 = EmptyDataHook(addData?.[0]);
                      setAddData([resetData1]);
                    }
                  }}
                />
                <Input
                  label={"INVOICE NUMBER"}
                  placeholder={"INVOICE NUMBER"}
                  value={addDataHead?.ref_no}
                  disabled={true}
                />

                <Dropdown
                  width2=""
                  label={"CUSTOMER NAME"}
                  value={
                    addDataHead?.customer_name == "CASH SALES / HOUSEHOLDS"
                      ? "1"
                      : addDataHead?.customer_name
                  }
                  onChange={(e) => {
                    const input = e.target.value;
                    const data = transition_complete_list?.Customer?.filter(
                      (v) => v?.ID == e.target.value
                    );
                    setAddDataHead({
                      ...addDataHead,
                      customer_name:
                        e.target.value == "1"
                          ? "CASH SALES / HOUSEHOLDS"
                          : e.target.value,
                      ref_no: "",
                      // customer_name: e.target.value,
                      payment_terms:
                        input == "1" ? "1" : addDataHead?.payment_terms || "",
                      dis_payment: input == "1" ? true : false,
                      cust_reg: data?.length > 0 ? data[0]?.IS_GST_REG : "NO",
                      c_type:
                        data?.length > 0
                          ? data?.[0]?.connection_type
                          : e.target.value == "1"
                          ? "lp"
                          : "",
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>CASH SALES / HOUSEHOLDS</option>
                      {console.log(
                        "transition_complete_list?.Customer ====",
                        transition_complete_list?.Customer
                      )}
                      {transition_complete_list?.Customer?.filter(
                        (v) => v?.cvo_name != "UJWALA" && v?.DOM == "1"
                      ).map((s, i) => (
                        <option key={i} value={s?.ID}>
                          {s?.cvo_name}
                        </option>
                      ))}
                    </>
                  }
                />
                <Dropdown
                  width2=""
                  disabled={addDataHead?.dis_payment}
                  label={"PAYMENT TERMS"}
                  value={addDataHead?.payment_terms}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      payment_terms: e.target.value,
                      cash_recieved: "",
                      cash_recieved_from: "",
                      online_recieved: "",
                      bank_account: "",
                    });
                    if (e.target.value == "2") {
                      addData?.forEach((val, ind) => {
                        setAddData((pre) =>
                          pre.map((v, i) =>
                            i == ind
                              ? {
                                  ...v,
                                  bank_account: "",
                                }
                              : { ...v }
                          )
                        );
                      });
                    }
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>CASH</option>
                      <option value={"2"}>CREDIT</option>
                    </>
                  }
                />

                <Input
                  label={"INV AMOUNT"}
                  placeholder={"INV AMOUNT"}
                  disabled={true}
                  value={addDataHead?.inv_amount}
                />
                {addDataHead?.payment_terms == "1" && (
                  <>
                    <Input
                      label={"CASH RECIEVED"}
                      placeholder={"CASH RECIEVED"}
                      value={addDataHead?.cash_recieved}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length <= 10 &&
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            )) ||
                          inputValue === "" ||
                          inputValue === "-" ||
                          !addDataHead?.cash_recieved
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            cash_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) || inputValue === ""
                                ? inputValue
                                : "",

                            online_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) ||
                              inputValue === "" ||
                              inputValue === "-"
                                ? Number(
                                    Number(addDataHead?.total_new || 0) -
                                      Number(
                                        inputValue == "-" ? 0 : inputValue || 0
                                      )
                                  )?.toFixed(2)
                                : "",
                          });
                        }
                      }}
                    />

                    <Input
                      label={"ONLINE RCVD AMOUNT"}
                      placeholder={"ONLINE RCVD AMOUNT"}
                      value={addDataHead?.online_recieved}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length <= 10 &&
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            )) ||
                          inputValue === "" ||
                          inputValue === "-" ||
                          !addDataHead?.online_recieved
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            online_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) || inputValue === ""
                                ? inputValue
                                : "",
                            cash_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) ||
                              inputValue === "" ||
                              inputValue === "-"
                                ? Number(
                                    Number(addDataHead?.total_new || 0) -
                                      Number(
                                        inputValue == "-" ? 0 : inputValue || 0
                                      )
                                  )?.toFixed(2)
                                : "",
                          });
                        }
                      }}
                    />
                    <Input
                      disabled={addDataHead?.online_recieved <= 0}
                      placeholder={"TRANSACTION ID"}
                      label={"TRANSACTION ID"}
                      onChange={(e) => {
                        const inputValue = e.target.value?.toUpperCase();
                        if (
                          inputValue.length <= 15 &&
                          (alfaNumericWithoutSpace.test(inputValue) ||
                            inputValue === "")
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            transaction_id: alfaNumericWithoutSpace.test(
                              inputValue
                            )
                              ? inputValue
                              : "",
                          });
                        }
                      }}
                      value={addDataHead?.transaction_id}
                    />
                    <Dropdown
                      disabled={addDataHead?.online_recieved <= 0}
                      width2=""
                      label={"CASH RECIVED FROM"}
                      value={addDataHead?.cash_recieved_from}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          cash_recieved_from: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          <option value="1">Phonepay</option>
                          <option value="2">GPay</option>
                          <option value="3">Paytm</option>
                          <option value="4">Cred</option>
                          <option value="5">Others</option>
                        </>
                      }
                    />
                    <Dropdown
                      disabled={addDataHead?.online_recieved <= 0}
                      width2=""
                      label={"BANK ACCOUNT"}
                      value={addDataHead?.bank_account}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          bank_account: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          {bank_master_list
                            ?.filter(
                              (v) =>
                                v?.BANK_CODE == "TAR ACCOUNT" ||
                                v?.BANK_CODE == "SAVINGS" ||
                                v?.BANK_CODE == "CURRENT" ||
                                v?.BANK_CODE == "OVER DRAFT"
                            )
                            ?.map((v, i) => (
                              <option key={i} value={v?.ID}>
                                {bankCode(v?.BANK_CODE)} -
                                {v?.BANK_ACC_NO == "NA"
                                  ? user_id
                                  : v?.BANK_ACC_NO}
                              </option>
                            ))}
                        </>
                      }
                    />
                  </>
                )}

                <Dropdown
                  width2=""
                  label={"DISPATCH GODOWN"}
                  value={addDataHead?.godown_id}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      godown_id: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {godown_address_list?.map((s, i) => (
                        <option key={i} value={s?.ID}>
                          {s?.UNIT_NAME}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={[
                    "PRODUCT",
                    "GST %	",
                    "UNIT PRICE",
                    "DISCOUNT ON UNIT PRICE",
                    "QUANTITY",
                    "PREPAID CYLINDERS",
                    "PSV QUANTITY",
                    "DELIVERED BY",
                    "BANK ACCOUNT",
                    "AREA CODE",
                    "TRANSPORT CHARGES",
                    "TRANSACTION AMOUNT",
                    "TOTAL AMOUNT",
                    "ONLINE RCVD AMOUNT",
                    "ACTIONS",
                  ]}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Dropdown
                            disabled={addData?.length > ind + 1}
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {refill_price_list
                                  .filter((t) => t?.cat_name == "DOMESTIC")
                                  .map((v, i) => (
                                    <option
                                      disabled={
                                        addData?.filter(
                                          (d) => d?.product_code == v?.pcode
                                        )?.length > 0
                                      }
                                      value={v?.pcode}
                                      key={i}
                                    >
                                      {v?.cat_desc}
                                    </option>
                                  ))}
                              </>
                            }
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        product_code: e.target.value,
                                        unit_rate: "",
                                        p_gst: "",
                                      }
                                    : { ...v }
                                )
                              )
                            }
                            value={val.product_code}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.p_gst}
                            placeholder={"GST %	"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.unit_rate}
                            placeholder={"UNIT PRICE"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.discount_price}
                            placeholder={"DISCOUNT ON UNIT PRICE"}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length < 10 &&
                                  /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                e.target.value == 0 ||
                                !val?.discount_price
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? {
                                          ...v,
                                          discount_price:
                                            /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          amount: "",
                                          transaction_amount: "",
                                          online_rcvd_amount: "",
                                          igst: "",
                                          sgst: "",
                                          cgst: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.quantity}
                            placeholder={"Quantity"}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length <= 4 &&
                                  /^\d{0,4}$/.test(inputValue)) ||
                                inputValue === "0" ||
                                !val?.quantity
                              ) {
                                setAddData((prev) =>
                                  prev.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          quantity: /^\d{0,4}$/.test(inputValue)
                                            ? inputValue
                                            : "",
                                          transport_charges:
                                            Number(
                                              (e.target.value || 1) -
                                                (val?.psv_quantity || 0) -
                                                (val?.prepaid_cylinder || 0)
                                            ) *
                                              Number(
                                                val?.unit_transport_charges || 0
                                              ) || "",
                                          amount: "",
                                          transaction_amount: "",
                                          online_rcvd_amount: "",
                                          igst: "",
                                          sgst: "",
                                          cgst: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.prepaid_cylinder}
                            placeholder={"PREPAID CYLINDERS"}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length <= 4 &&
                                  /^\d{0,4}$/.test(inputValue)) ||
                                inputValue === "0" ||
                                !val?.prepaid_cylinder
                              ) {
                                setAddData((prev) =>
                                  prev.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          prepaid_cylinder: /^\d{0,4}$/.test(
                                            inputValue
                                          )
                                            ? inputValue
                                            : "",
                                          transport_charges:
                                            Number(
                                              (val?.quantity || 1) -
                                                (val?.psv_quantity || 0) -
                                                (e.target.value || 0)
                                            ) *
                                              Number(
                                                val?.unit_transport_charges || 0
                                              ) || "0",
                                          amount: "",
                                          transaction_amount: "",
                                          online_rcvd_amount: "",
                                          igst: "",
                                          sgst: "",
                                          cgst: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.psv_quantity}
                            placeholder={"PSV QUANTITY"}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length <= 4 &&
                                  /^\d{0,4}$/.test(inputValue)) ||
                                inputValue === "0" ||
                                !val?.psv_quantity
                              ) {
                                setAddData((prev) =>
                                  prev.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          psv_quantity: /^\d{0,4}$/.test(
                                            inputValue
                                          )
                                            ? inputValue
                                            : "",
                                          transport_charges:
                                            Number(
                                              (val?.quantity || 1) -
                                                (e.target.value || 0) -
                                                (val?.prepaid_cylinder || 0)
                                            ) *
                                              Number(
                                                val?.unit_transport_charges || 0
                                              ) || "",
                                          amount: "",
                                          // transaction_amount: "",
                                          online_rcvd_amount: "",
                                          igst: "",
                                          sgst: "",
                                          cgst: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Dropdown
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {staff_list?.map((v, i) => (
                                  <option value={v.ID} key={i}>
                                    {v.EMP_NAME}
                                  </option>
                                ))}
                              </>
                            }
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        delivered_by: e.target.value,
                                      }
                                    : { ...v }
                                )
                              )
                            }
                            value={val.delivered_by}
                          />
                        }
                      </td>
                      <td>
                        <Dropdown
                          width2=""
                          value={val?.bank_account}
                          onChange={(e) => {
                            setAddData((pre) =>
                              pre.map((v, i) =>
                                i == ind
                                  ? {
                                      ...v,
                                      bank_account: e.target.value,
                                    }
                                  : { ...v }
                              )
                            );
                          }}
                          disabled={
                            addDataHead?.payment_terms == "2" ||
                            !val?.prepaid_cylinder ||
                            val?.prepaid_cylinder == 0
                          }
                          options={
                            <>
                              <option value={""}>SELECT</option>
                              {bank_master_list
                                ?.filter(
                                  (v) =>
                                    v?.BANK_CODE == "TAR ACCOUNT" ||
                                    v?.BANK_CODE == "SAVINGS" ||
                                    v?.BANK_CODE == "CURRENT"
                                )
                                ?.map((v, i) => (
                                  <option key={i} value={v?.ID}>
                                    {bankCode(v?.BANK_CODE)} -
                                    {v?.BANK_ACC_NO == "NA"
                                      ? user_id
                                      : v?.BANK_ACC_NO}
                                  </option>
                                ))}
                            </>
                          }
                        />
                      </td>
                      <td>
                        {
                          <Dropdown
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {area_code_list.map((item, i) => (
                                  <option value={item.ID} key={i}>
                                    {item.AREA_NAME}
                                  </option>
                                ))}
                              </>
                            }
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        area_code: e.target.value,
                                        amount: "",
                                        transaction_amount: "",
                                        online_rcvd_amount: "",
                                        igst: "",
                                        sgst: "",
                                        cgst: "",
                                      }
                                    : { ...v }
                                )
                              );
                              const data = area_code_list?.filter(
                                (v) => v?.ID == e.target.value
                              );
                              if (e.target.value != "" && data?.length > 0) {
                                fetchTransCharge(ind, data[0]?.AREA_CODE);
                              }
                            }}
                            value={val.area_code}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.transport_charges}
                            placeholder={"TRANSPORT CHARGES"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.transaction_amount}
                            placeholder={"TRANSACTION AMOUNT"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.amount}
                            placeholder={"Sale Amount"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.online_rcvd_amount}
                            placeholder={" Online RCVD Amount "}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                inv_amount: "",
                                cash_recieved: "",
                                online_recieved: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    validation_check() &&
                      setMess(
                        "Please enter all the values in current row,calculate and then add next row"
                      );
                    validation_check()
                      ? setOpen1(true)
                      : setAddData((pre) => [...pre, {}]);
                  }}
                />
                <Button
                  title={"FETCH INVOICE NUMBER"}
                  back
                  onClick={FetchInvoiceNumber}
                />
                <Button title={"FETCH PRICE"} back onClick={FetchGST} />
                <Button title={"CALCULATE"} back onClick={() => calculate()} />
                <SaveButton onClick={() => add_items("first")} />
              </div>
            </div>
          }
        ></Modal>
      )}

      {openView && (
        <Modal
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${
                viewItem?.cvo_name || "CASH SALES / HOUSEHOLDS"
              }-${viewItem?.SR_NO}`}
            />
          }
          onCancel={() => {
            setViewItem({});
          }}
          isOpen={openView}
          title={"DOMESTIC CYLINDER SALES"}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "DELIVERED BY	",
                  "AREA CODE",
                  "GST%",
                  "UNIT PRICE",
                  "DISCOUNT ON UNIT PRICE",
                  "QUANTITY",
                  "PREPAID CYLINDERS",
                  "PSV QUANTITY",
                  "TRANSPORT CHARGES",
                  "TRANSACTION AMOUNT",
                  "TOTAL AMOUNT",
                  "ONLINE RCVD AMOUNT",
                ]}
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        <td>{v?.CAT_NAME + "-" + v?.CAT_DESC}</td>
                        <td>{v?.EMP_NAME || "NA"}</td>
                        <td>{v?.AREA_CODE || "NA"}</td>
                        <td>{v?.GSTP}</td>
                        <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>
                        <td>{Number(v?.DISC_UNIT_RATE).toFixed(2)}</td>
                        <td>{v?.QUANTITY}</td>
                        <td>{v?.PRE_CYLS}</td>
                        <td>{v?.PSV_CYLS}</td>
                        <td>{Number(v?.TRANSPORT_CHARGES).toFixed(2)}</td>
                        <td>{Number(v?.SALE_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.TOTAL_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.AMOUNT_RCVD_ONLINE).toFixed(2)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={7} />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        TOTAL
                      </td>
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.TRANSPORT_CHARGES)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.SALE_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.TOTAL_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.AMOUNT_RCVD_ONLINE)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        />
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice3
          empty_row_height={180 - viewItem?.products?.length * 25}
          previewRef={previewRef}
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.SI_DATE,
            CVO_NAME: viewItem?.cvo_name
              ? viewItem?.cvo_name
              : "CASH SALES / HOUSEHOLDS",
            CVO_ADDRESS: viewItem?.CVO_ADDRESS,
            CVO_DISTRICT: viewItem?.B_DISTRICT,
            CVO_LOCATION: viewItem?.B_LOCATION,
            B_STATE_CODE: viewItem?.B_STATE,
            B_STATE_NAME: viewItem?.B_STATE_NAME,
            CVO_PINCODE: viewItem?.PINCODE,
            CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            SHIP_CVO_NAME: viewItem?.cvo_name
              ? viewItem?.cvo_name
              : "CASH SALES / HOUSEHOLDS",
            SHIP_CVO_ADDRESS: viewItem?.S_ADDRESS,
            SHIP_CVO_DISTRICT: viewItem?.S_DISTRICT,
            SHIP_CVO_LOCATION: viewItem?.S_LOCATION,
            SHIP_B_STATE: viewItem?.S_STATE,
            SHIP_B_STATE_NAME: viewItem?.S_STATE_NAME,
            SHIP_CVO_PINCODE: viewItem?.S_PINCODE,
            SHIP_CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
          }}
          table_body={
            <>
              {viewItem?.products?.map((v, i) => (
                <tr key={i}>
                  <td className="both">{i + 1}</td>
                  <td
                    className="both"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "12px",
                      minWidth: "250px",
                    }}
                  >{`${v?.CAT_NAME} - ${v?.CAT_DESC} `}</td>
                  <td className="both">{v?.HSNCODE}</td>
                  <td className="both">
                    {Number(v?.QUANTITY) - Number(v?.PSV_CYLS)}{" "}
                    {UnitFunction(v?.UNITS || v?.UOM, "title")}
                  </td>
                  <td className="both">{v?.UNIT_RATE}</td>
                  <td className="both">{v?.DISC_UNIT_RATE}</td>
                  <td className="both">{v?.GSTP} %</td>
                  <td className="both" style={{ textAlign: "right" }}>
                    {(
                      (Number(v?.QUANTITY) - Number(v?.PSV_CYLS)) *
                      (Number(v?.UNIT_RATE) - Number(v?.DISC_UNIT_RATE))
                    ).toFixed(2)}
                  </td>
                </tr>
              ))}
            </>
          }
          taxable_amt={ReduceFunction(viewItem?.invoice, "TAXABLE_AMOUNT")}
          Total_tax={ReduceFunction(viewItem?.hsn_groups, "TOTAL_AMOUNT")}
          igst={ReduceFunction(viewItem?.invoice, "IGST_AMOUNT")}
          cgst={ReduceFunction(viewItem?.invoice, "CGST_AMOUNT")}
          sgst={ReduceFunction(viewItem?.invoice, "SGST_AMOUNT")}
          other={(
            Number(ReduceFunction(viewItem?.invoice, "TRANSPORT_CHARGES")) +
            (Number(ReduceFunction(viewItem?.invoice, "TRANSPORT_CHARGES")) *
              5) /
              100
          ).toFixed(2)}
          net_total={Number(viewItem?.SI_AMOUNT).toFixed(2)}
        />
      </div>

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}

      {/* {open3 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open3}
          setIsOpen={setOpen3}
          mess={mess}
          okbtn={true}
        />
      )} */}

      {open4 && (
        <SmallModal
          bname={"Yes"}
          cname={"No"}
          title={"Confirm"}
          isOpen={open4}
          setIsOpen={setOpen4}
          mess={mess}
          onClick={() => {
            add_items();
          }}
        />
      )}

      {cvoClick && <CVOList cvoClick={cvoClick} setCvoClick={setCvoClick} />}
      {EquipClick && (
        <EquipmentList EquipClick={EquipClick} setEquipClick={setEquipClick} />
      )}
      {StaffClick && (
        <StaffList StaffClick={StaffClick} setStaffClick={setStaffClick} />
      )}
      {PriceClick && (
        <PriceList PriceClick={PriceClick} setPriceClick={setPriceClick} />
      )}
      {AreaClick && (
        <AreaCodeList AreaClick={AreaClick} setAreaClick={setAreaClick} />
      )}
      {loading && <Loader />}
    </div>
  );
};
