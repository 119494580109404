import { useMemo } from 'react';
var converter = require("number-to-words");

const useAmountToWords = (amt) => {
  return useMemo(() => {
    const total = amt;

    if (isNaN(total)) {
      return ""; // or any appropriate handling if total is not a number
    }

    const totalAmount = Number(total) || 0;

    const integerPart = Math.floor(totalAmount);
    const decimalPart = Math.round((totalAmount - integerPart) * 100);

    const integerWords = converter.toWords(integerPart);
    const decimalWords = converter.toWords(decimalPart);

    let result = `INR ${integerWords} rupees`;
    if (decimalPart > 0) {
      result += ` and ${decimalWords} paisa`;
    }

    return result?.toUpperCase();
  }, [amt]);
};

export default useAmountToWords;
