import React, { useMemo } from "react";
import styles from "./tax.module.css";
import Indanegas from "../../../assets/Indane.png";
import hpgas from "../../../assets/Hp.png";
import bharatgas from "../../../assets/Bharatgas.png";
import { useSelector } from "react-redux";
import moment from "moment";
import { bankDetails } from "../../../utils/data";
import EInvoice from "./EInvoice";
import useAmountToWords from "../../../utils/Hook/useAmountToWords";
import { CheckBox } from "../../../components/inputs/CheckBox";
var converter = require("number-to-words");

const inv_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  // { title:"Disc.% Cash",cols:false},
  { title: "Discount", cols: false },
  // { title: "Transport Charge" ,cols:false},
  { title: "Amount", cols: false },
];

const TaxInvoice3 = ({
  //   e_inv = false,
  previewRef,
  table_header,
  table_body,
  empty_arr_length = 8,
  listdata,
  net_total,
  irn = false,
  Total_tax,
  taxable_amt,
  igst,
  cgst,
  sgst,
  other,
  empty_row_height,
  view,
  doc_name = "Tax Invoice",
  doc_type = "",
  bank = true,
  other_data,
}) => {
  console.log("irn ===", irn);

  const { profile_data, seal_image, signature_image, upi_qr_image, upi_id } =
    useSelector((state) => state.profile);

  const { bank_master_list } = useSelector((state) => state.masterdata);
  const bank_data = bank_master_list?.filter((v) => v?.DA_STATUS == "1");

  const convertAmountToWords = useAmountToWords(
    Number(net_total || 0).toFixed(0)
  );
  const convertAmountToWords_Total_tax = useAmountToWords(Total_tax);
  const { omc_name, omc_data } = useSelector((state) => state.other);

  return (
    <div
      style={{ marginLeft: view ? 0 : "", width: view ? "100%" : "" }}
      className={styles.invoice_main}
      ref={previewRef}
    >
      {/* Same in all */}
      <>
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
            padding: "0 0",
            marginBottom: "1rem",
          }}
        >
          {doc_name}
        </div>
        <div
          style={{
            width: "100%",
            fontSize: "14px",
            textAlign: "right",
            padding: "0 2rem 5px 0",
            display: "flex",
            justifyContent: "flex-end",
            gap: "0.5rem", // Add some space between items
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <CheckBox /> Original
          </label>
          /
          <label style={{ display: "flex", alignItems: "center" }}>
            <CheckBox /> Duplicate
          </label>
          /
          <label style={{ display: "flex", alignItems: "center" }}>
            <CheckBox /> Triplicate
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img
              id="Aname"
              style={{ width: "170px" }}
              src={
                omc_name == "IOCL"
                  ? Indanegas
                  : omc_name == "HPCL"
                  ? hpgas
                  : omc_name == "BPCL"
                  ? bharatgas
                  : ""
              }
            />
          </div>

          <div
            style={{
              flex: 3,
              gap: ".25rem",
              marginLeft: !irn ? "8rem" : "",
              flexDirection: "column",
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              fontSize: "14px",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile_data?.name}
            </div>
            <div>
              {profile_data.ADDRESS}, {profile_data?.LOCATION},
            </div>
            <div>
              {profile_data.DISTRICT}, {profile_data.state_name} ,
              {profile_data?.PINCODE},
            </div>
            <div>
              PH:{profile_data?.mobile},{profile_data?.landline}
            </div>
            <div>E-Mail :{profile_data?.email}</div>
            <div> GSTIN/UIN: {profile_data?.gst}</div>
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {irn && (
              <img
                id="Aname"
                style={{ width: "150px", height: "150px" }}
                src={listdata?.irn_qr}
              />
            )}
          </div>
        </div>
      </>

      {doc_type == "CN" || doc_type == "DN" ? (
        <table className={styles.table_1}>
          <tr>
            <td>CN/DN NO : {listdata?.SR_NO}</td>
            <td>REF INV NO : {listdata?.REF_NUMBER || "NA"}</td>
            <td>TYPE : {listdata?.type || "NA"}</td>
          </tr>
          <tr>
            <td>
              CN/DN DATE : {moment(listdata?.SR_NO_DATE).format("DD-MMM-YYYY")}
            </td>
            <td>
              REF IN DATE :{" "}
              {listdata?.REF_DATE
                ? moment(listdata?.REF_DATE).format("DD-MMM-YYYY")
                : "NA"}
            </td>

            <td>
              POS : {listdata?.B_STATE_NAME ? listdata?.B_STATE_NAME : ""}
              {listdata?.B_STATE_CODE ? `- ${listdata?.B_STATE_CODE}` : ""}
            </td>
          </tr>
          <tr>
            <td style={{ borderBottom: "none" }} colSpan={3}>
              REASON FOR CN/DN :{" "}
            </td>
          </tr>
        </table>
      ) : doc_type == "DC" ? (
        <table className={styles.table_1}>
          <tr>
            <td colSpan={2}>DC NO. : {listdata?.SR_NO}</td>
            <td colSpan={2}>REF INV NO : {listdata?.REF_NUMBER || "NA"}</td>
            <td colSpan={2}>e-WAYBILL NO :{listdata?.EWAY_NO || "NA"}</td>
            <td colSpan={2}>PO NO :{listdata?.PO_REF || "NA"}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              DC DATE. : {moment(listdata?.SR_NO_DATE).format("DD-MMM-YYYY")}
            </td>
            <td colSpan={2}>
              REF INV DATE :{" "}
              {listdata?.REF_DATE
                ? moment(listdata?.REF_DATE).format("DD-MMM-YYYY")
                : "NA"}
            </td>
            <td colSpan={2}>
              e-WAYBILL DATE :{" "}
              {listdata?.EWAY_DATE
                ? moment(listdata?.EWAY_DATE).format("DD-MMM-YYYY")
                : "NA"}
            </td>
            <td colSpan={2}>
              PO DATE :{" "}
              {listdata?.PO_REF_DATE
                ? moment(listdata?.PO_REF_DATE).format("DD-MMM-YYYY")
                : "NA"}
            </td>
          </tr>

          <tr>
            <td style={{ borderBottom: "none" }} colSpan={4}>
              DATE & TIME OF DELIVERY :
            </td>
            <td style={{ borderBottom: "none" }} colSpan={2}>
              TRANSPORT MODE : ROAD
            </td>
            <td style={{ borderBottom: "none" }} colSpan={2}>
              DRIVER/SALES MAN NAME: {listdata?.DRIVER_NAME}
            </td>
          </tr>
        </table>
      ) : (
        <table className={styles.table_1}>
          <tr>
            <td>INV NO. : {listdata?.SR_NO}</td>
            <td>PO NUMBER : {listdata?.REF_NUMBER || "NA"}</td>
            <td>DC NO/DATE :{listdata?.dc_num || "NA"}</td>
          </tr>
          <tr>
            <td>
              INV DATE : {moment(listdata?.SR_NO_DATE).format("DD-MMM-YYYY")}
            </td>
            <td>
              PO DATE :
              {listdata?.REF_DATE
                ? moment(listdata?.REF_DATE).format("DD-MMM-YYYY")
                : "NA"}
            </td>

            <td>
              DC NO :{" "}
              {listdata?.dc_num_date
                ? moment(listdata?.dc_num_date).format("DD-MMM-YYYY")
                : "NA"}
            </td>
          </tr>
          <tr>
            <td style={{ borderBottom: "none" }}>RCM : YES/NO</td>
            <td style={{ borderBottom: "none" }}>
              POS : {listdata?.B_STATE_NAME ? listdata?.B_STATE_NAME : ""}
              {listdata?.B_STATE_CODE ? `- ${listdata?.B_STATE_CODE}` : ""}
            </td>
            <td style={{ borderBottom: "none" }}>
              E-WAY BILL : {listdata?.eway_bill || "NA"}
            </td>
          </tr>
        </table>
      )}
      <table>
        {irn && (
          <>
            <tr>
              <td style={{ textAlign: "left" }} colSpan={8}>
                E-INVOICE IRN NO : {irn}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                }}
                colSpan={doc_type == "" ? 4 : 8}
              >
                IRN DATE : {moment(listdata?.irn_dare).format("DD-MM-YYYY")}
              </td>
              {doc_type == "" && (
                <td style={{ width: "50%", borderBottom: "none" }} colSpan={4}>
                  E-WAY BILL DATE :{" "}
                  {moment(listdata?.eway_bill_date).format("DD-MM-YYYY")}
                </td>
              )}
            </tr>
          </>
        )}
      </table>
      <table className={styles.table_1}>
        <tr>
          <td
            colSpan={3}
            style={{
              textAlign: "left",
              borderBottom: "none",
              verticalAlign: "top",
              width: "50%",
            }}
          >
            <div
              style={{
                flex: 1,
                gap: ".25rem",
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div style={{ fontSize: "13px" }}>BILL TO</div>

              {listdata?.CVO_NAME && (
                <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                  {listdata?.CVO_NAME}
                </div>
              )}
              {listdata?.CVO_NAME !== "CASH SALES / HOUSEHOLDS" && (
                <>
                  {listdata?.CVO_ADDRESS && (
                    <div
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        maxWidth: "100%",
                      }}
                    >
                      {listdata.CVO_ADDRESS ? ` ${listdata.CVO_ADDRESS} ,` : ""}
                      {listdata.CVO_LOCATION
                        ? ` ${listdata.CVO_LOCATION} ,`
                        : ""}
                      {listdata.CVO_DISTRICT
                        ? ` ${listdata.CVO_DISTRICT} ,`
                        : ""}
                      {listdata.B_STATE_NAME
                        ? ` ${listdata.B_STATE_NAME} ,`
                        : ""}
                      {listdata.CVO_PINCODE ? ` ${listdata.CVO_PINCODE} ` : ""}
                    </div>
                  )}
                  {/* <div>
                    {listdata.CVO_DISTRICT ? `${listdata.CVO_DISTRICT},` : ""}
                    {listdata.B_STATE_NAME ? `${listdata.B_STATE_NAME},` : ""}
                    {listdata.CVO_PINCODE ? `${listdata.CVO_PINCODE},` : ""}
                  </div> */}
                  {/* <div>PH: {listdata?.MOBILE || "NA"}</div>
                  <div>Email: {listdata?.EMAIL || "NA"}</div>
                  <div>State Code : {listdata?.B_STATE_CODE || "NA"}</div>
                  <div>GSTIN/UIN: {listdata?.CVO_TIN || "NA"}</div> */}
                  {/* {listdata?.MOBILE && (
                    <div>PH: {listdata?.MOBILE || "NA"}</div>
                  )} */}
                  {(listdata?.MOBILE || listdata?.EMAIL) && (
                    <div>
                      {" "}
                      {listdata?.MOBILE
                        ? `PH:${listdata?.MOBILE || "NA"}`
                        : ""}{" "}
                      {listdata?.EMAIL
                        ? `Email:${listdata?.EMAIL || "NA"}`
                        : ""}{" "}
                    </div>
                  )}
                  {/* {listdata?.EMAIL  && (
                    <div>Email: {listdata?.EMAIL || "NA"}</div>
                  )} */}
                  {/* {listdata?.B_STATE_CODE && (
                    <div>State Code : {listdata?.B_STATE_CODE || "NA"}</div>
                  )} */}
                  {listdata?.CVO_TIN && (
                    <div>GSTIN/UIN: {listdata?.CVO_TIN || "NA"}</div>
                  )}
                </>
              )}
            </div>
          </td>

          <td
            colSpan={3}
            style={{
              textAlign: "left",
              borderBottom: "none",
              verticalAlign: "top",
              width: "50%",
            }}
          >
            <div
              style={{
                flex: 1,
                gap: ".25rem",
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div style={{ fontSize: "13px" }}>SHIP TO</div>

              {listdata?.SHIP_CVO_NAME && (
                <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                  {listdata?.SHIP_CVO_NAME}
                </div>
              )}
              {listdata?.CVO_NAME !== "CASH SALES / HOUSEHOLDS" && (
                <>
                  {listdata?.SHIP_CVO_ADDRESS && (
                    <div
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        maxWidth: "100%",
                      }}
                    >
                      {listdata.SHIP_CVO_ADDRESS
                        ? ` ${listdata.SHIP_CVO_ADDRESS} ,`
                        : ""}
                      {listdata.SHIP_CVO_LOCATION
                        ? ` ${listdata.SHIP_CVO_LOCATION} ,`
                        : ""}
                      {listdata.SHIP_CVO_DISTRICT
                        ? ` ${listdata.SHIP_CVO_DISTRICT} ,`
                        : ""}
                      {listdata.SHIP_B_STATE_NAME
                        ? ` ${listdata.SHIP_B_STATE_NAME} ,`
                        : ""}
                      {listdata.SHIP_CVO_PINCODE
                        ? ` ${listdata.SHIP_CVO_PINCODE} `
                        : ""}
                    </div>
                  )}
                  {/* <div>
                    {listdata.SHIP_CVO_DISTRICT
                      ? `${listdata.SHIP_CVO_DISTRICT},`
                      : ""}
                    {listdata.SHIP_B_STATE_NAME
                      ? `${listdata.SHIP_B_STATE_NAME},`
                      : ""}
                    {listdata.SHIP_CVO_PINCODE
                      ? `${listdata.SHIP_CVO_PINCODE},`
                      : ""}
                  </div> */}
                  {/* {listdata?.MOBILE && (
                    <div>PH: {listdata?.MOBILE || "NA"}</div>
                  )}
                  {listdata?.EMAIL && (
                    <div>Email: {listdata?.EMAIL || "NA"}</div>
                  )} */}
                  {(listdata?.MOBILE || listdata?.EMAIL) && (
                    <div>
                      {" "}
                      {listdata?.MOBILE
                        ? `PH:${listdata?.MOBILE || "NA"}`
                        : ""}{" "}
                      {listdata?.EMAIL
                        ? `Email:${listdata?.EMAIL || "NA"}`
                        : ""}{" "}
                    </div>
                  )}
                  {/* {listdata?.SHIP_B_STATE && (
                    <div>State Code : {listdata?.SHIP_B_STATE || "NA"}</div>
                  )} */}
                  {listdata?.CVO_TIN && (
                    <div>GSTIN/UIN: {listdata?.CVO_TIN || "NA"}</div>
                  )}
                </>
              )}
            </div>
          </td>
        </tr>
      </table>
      <table>
        {table_header ? (
          table_header
        ) : (
          <tr>
            <td> SL NO</td>
            <td> ITEM DESCRIPTION</td>
            <td> HSN</td>
            <td>QTY </td>
            <td> RATE/UNIT</td>
            <td>DISC/UNIT </td>
            <td>GST </td>
            <td style={{ textAlign: "right" }}> AMOUNT</td>
          </tr>
        )}

        {table_body}
        <tr style={{ height: `${empty_row_height}px` }}>
          {Array(empty_arr_length)
            .fill()
            .map(() => (
              <td className="both"></td>
            ))}
        </tr>
      </table>
      {other_data}
      {/* Same in All  */}
      <table>
        {doc_type == "DC" ? (
          <>
            <tr>
              <td
                rowSpan={7}
                colSpan={5}
                style={{
                  width: "40%",
                  textAlign: "center",
                  verticalAlign: "top",
                  borderBottom: "none",
                }}
              >
                RECEIPIENT STAMP & SIGNATURE
              </td>
              <td
                style={{
                  width: "25%",
                }}
              >
                ITEM RECEIVED BY
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td
                style={{
                  width: "25%",
                }}
              >
                MOB NO
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td
                style={{
                  width: "25%",
                }}
              >
                RECEIPT DATE & TIME
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td
                style={{
                  height: "100px",
                  textAlign: "center",
                  verticalAlign: "top",
                  borderBottom: "none",
                }}
                rowSpan={4}
                colSpan={3}
              >
                GATE ENTRY DETAILS:
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td colSpan={5}>
                TOTAL INV AMOUNT:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {convertAmountToWords}
                </span>
              </td>
              <td>AMOUNT BEFORE TAX</td>
              <td style={{ textAlign: "right" }}>{taxable_amt}</td>
            </tr>
            {doc_type == "CN" || doc_type == "DN" ? (
              <>
                <tr>
                  <td
                    colSpan={5}
                    rowSpan={7}
                    style={{ textAlign: "center" }}
                  ></td>
                  <td>IGST</td>
                  <td style={{ textAlign: "right" }}>{igst}</td>
                </tr>

                <tr>
                  <td>CGST</td>
                  <td style={{ textAlign: "right" }}>{cgst}</td>
                </tr>
                <tr>
                  <td>SGST</td>
                  <td style={{ textAlign: "right" }}>{sgst}</td>
                </tr>

                <tr>
                  <td>TOTA GST</td>
                  <td style={{ textAlign: "right" }}>{Total_tax}</td>
                </tr>
                <tr>
                  <td>ADDITIONAL CHARGES</td>
                  <td style={{ textAlign: "right" }}>{other}</td>
                </tr>
                <tr>
                  <td>TOTAL INV AMOUNT</td>
                  <td style={{ textAlign: "right" }}>{net_total}</td>
                </tr>

                <tr>
                  <td>ROUNDED OFF</td>
                  <td style={{ textAlign: "right" }}>
                    {(Number(net_total).toFixed(0) - Number(net_total)).toFixed(
                      2
                    )}
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td
                    colSpan={1}
                    rowSpan={6}
                    style={{
                      textAlign: "center",
                      width: !bank ? "250px" : "auto", // Set the max width for the td
                      overflow: "hidden", // Ensure content does not overflow
                    }}
                  >
                    {upi_qr_image && (
                      <img
                        id="Aname"
                        style={{
                          width: "100%", // Make sure the image fits within the td's width
                          height: "auto", // Keep the aspect ratio of the image
                          maxWidth: "100px", // Set a max width for the image if needed
                          maxHeight: "100px", // Set a max height for the image
                        }}
                        src={upi_qr_image}
                      />
                    )}
                  </td>
                  {!bank ? (
                    <td
                      colSpan={4}
                      rowSpan={7}
                      style={{
                        verticalAlign: "top",
                        textAlign: "left",
                        width: "200px",
                      }}
                    >
                      TERMS OF SUPPLY:
                    </td>
                  ) : (
                    <td colSpan={4}>
                      OUR BANK ACCOUNT NO : <b>{bank_data[0]?.BANK_ACC_NO}</b>
                    </td>
                  )}

                  <td>IGST</td>
                  <td style={{ textAlign: "right" }}>{igst}</td>
                </tr>
                <tr>
                  {bank && (
                    <td colSpan={4}>
                      IFSC CODE : <b>{bank_data[0]?.BANK_IFSC_CODE}</b>
                    </td>
                  )}

                  <td>CGST</td>
                  <td style={{ textAlign: "right" }}>{cgst}</td>
                </tr>
                <tr>
                  {bank && (
                    <td colSpan={4}>
                      BANK/BRANCH : <b>{bank_data[0]?.BANK_BRANCH}</b>
                    </td>
                  )}

                  <td>SGST</td>
                  <td style={{ textAlign: "right" }}>{sgst}</td>
                </tr>
                <tr>
                  {bank && (
                    <td
                      colSpan={4}
                      rowSpan={4}
                      style={{ verticalAlign: "top", textAlign: "left" }}
                    >
                      TERMS OF SUPPLY:
                    </td>
                  )}
                  <td>TOTA GST</td>
                  <td style={{ textAlign: "right" }}>{Total_tax}</td>
                </tr>
                <tr>
                  <td>ADDITIONAL CHARGES</td>
                  <td style={{ textAlign: "right" }}>{other}</td>
                </tr>
                <tr>
                  <td>TOTAL INV AMOUNT</td>
                  <td style={{ textAlign: "right" }}>{net_total}</td>
                </tr>
                <tr>
                  <td>
                    UPI ID : <b>{upi_id}</b>
                  </td>
                  <td>ROUNDED OFF</td>
                  <td style={{ textAlign: "right" }}>
                    {(Number(net_total).toFixed(0) - Number(net_total)).toFixed(
                      2
                    )}
                  </td>
                </tr>
              </>
            )}
          </>
        )}
      </table>
      <table>
        <tr>
          <td
            colSpan={5}
            rowSpan={4}
            style={{
              verticalAlign: "center",
              textAlign: "left",
              // borderTop: "none",
            }}
          >
            DECLARATION: THIS IS THE SYSTEM GENERATED INVOICE AND WE DECLARE
            THAT THIS INVOICE SHOWS THE ACTUAL PRICE OF THE GOODS DESCRIBED AND
            THAT ALL PARTICULARS ARE TRUE AND CORRECT
          </td>
          <td
            colSpan={3}
            style={{
              textAlign: "left",
              fontSize: "14px",
              // borderTop: "none"
            }}
          >
            <b>For: {profile_data?.name} </b>
          </td>
        </tr>
        <tr>
          <td
            colSpan={3}
            rowSpan={1}
            style={{
              height: "120px",
              verticalAlign: "center",
              textAlign: "center",
            }}
          >
            {seal_image && (
              <div style={{ position: "relative" }}>
                <img
                  src={seal_image}
                  alt=""
                  style={{ height: "100px", width: "100px" }}
                />
                <img
                  src={signature_image}
                  alt=""
                  style={{
                    position: "absolute",
                    height: "80px",
                    width: "80px",
                    top: "0%",
                    left: "35%",
                  }}
                />
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{ textAlign: "left" }}>
            AUTHORISED SIGNATORY
          </td>
        </tr>
      </table>
    </div>
  );
};

export default TaxInvoice3;
