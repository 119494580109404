import React from "react";

const PageHeading = ({ title, right, middle }) => {
  return (
    <div className="flex aic jcsb page-head">
      <div className="page-heading">{title}</div>
      <div className="">{middle}</div>
      <div className="flex aic jcsb gap10">{right}</div>
    </div>
  );
};

export default PageHeading;
