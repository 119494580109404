import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";
import moment from "moment";

const userIdString = localStorage.getItem("userId");
const userId = JSON.parse(userIdString)?.aid;

export const get_hsn_list = createAsyncThunk("hsnlist", async (obj) => {
  const body = new FormData();
  body.append("search", obj?.search);
  body.append("type", obj?.type);
  const response = await fetch(base_url + "hsn_search.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const get_effective_date = createAsyncThunk(
  "effective_list",
  async () => {
    // return JSON.parse(localStorage.getItem("userId"))?.date;
    return localStorage.getItem("eff_date");
  }
);
export const get_user_id = createAsyncThunk("userid", async () => {
  return JSON.parse(localStorage.getItem("userId"))?.aid;
});
export const get_omc = createAsyncThunk("OMC", async () => {
  return JSON.parse(localStorage.getItem("userId"))?.OMC;
});
export const get_omc_name = createAsyncThunk("OMC_NAME", async () => {
  return JSON.parse(localStorage.getItem("userId"))?.OMC_NAME;
});
// export const get_user_turnover = createAsyncThunk("turnover", async (data) => {
//   if (data) {
//     return data;
//   } else {
//     return JSON.parse(localStorage.getItem("userId"))?.turnover;
//   }
// });
export const get_today_date = createAsyncThunk("today", async () => {
  return moment().subtract(0, "days").format("DD/MM/YYYY");
});
export const get_eff_date = createAsyncThunk("eff_date", async (date) => {
  return moment(date).format("DD/MM/YYYY");
});

const initialState = {
  hsn_list: [],
  eff_date: "",
  user_id: "",
  omc_data: "",
  omc_name: "",
  // user_turnover: "",
  today: "",
};

const otherSlice = createSlice({
  name: "hsnlist",
  initialState: initialState,
  extraReducers: (builder) => {
    // HSN Code List
    builder.addCase(get_hsn_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.hsn_list = data?.result;
      } else {
        state.hsn_list = [];
      }
    });
    builder.addCase(get_effective_date.fulfilled, (state, action) => {
      const data = action.payload;
      state.eff_date = moment(data).format("DD/MM/YYYY") || "";
    });
    builder.addCase(get_user_id.fulfilled, (state, action) => {
      const data = action.payload;
      state.user_id = data || "";
    });
    builder.addCase(get_omc.fulfilled, (state, action) => {
      const data = action.payload;
      state.omc_data = data || "";
    });
    // builder.addCase(get_user_turnover.fulfilled, (state, action) => {
    //   const data = action.payload;
    //   state.user_turnover = data || "";
    // });
    builder.addCase(get_omc_name.fulfilled, (state, action) => {
      const data = action.payload;
      state.omc_name = data || "";
    });
    builder.addCase(get_today_date.fulfilled, (state, action) => {
      const data = action.payload;
      state.today = data || "";
    });
    builder.addCase(get_eff_date.fulfilled, (state, action) => {
      const data = action.payload;
      state.eff_date = data || "";
    });
  },
});

export default otherSlice.reducer;
