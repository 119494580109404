import React, { useState } from "react";
import Input from "./inputs/Input";
import { Button } from "./btn/Buttons";
import SmallModal from "./modal/SmallModal";

export const UploadFile = ({ handle_click }) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");

  const upload_data = async () => {
    if (!inputValue || !inputValue?.name) {
      setOpen(true);
      setMess("Excel File Required");
    } else {
      await handle_click(inputValue);
    }
  };

  return (
    <div className="pin-ver">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          color: "red",
          margin: "0 0 1rem 0",
          fontSize: "11px",
        }}
      >
        Please select your excel to upload cvo data
      </div>
      <div
        className="pin-ver-1st"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "70%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <label style={{ width: "30%", fontSize: "13px" }}>UPLOAD EXCEL</label>
          <div style={{ width: "70%" }}>
            <Input value={inputValue.name} placeholder={"Excel File"} />
          </div>
        </div>
        <div
          style={{
            width: "30%",
            padding: "0 1% ",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <label className="label">
            <input
              type="file"
              accept=".xlsx, .xls"
              required
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                if (selectedFile) {
                  const fileType = selectedFile.type;
                  if (
                    fileType ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    fileType === "application/vnd.ms-excel"
                  ) {
                    setInputValue(selectedFile);
                  } else {
                    alert(
                      "Please select a valid Excel file (either .xlsx or .xls)."
                    );
                    e.target.value = null;
                  }
                }
              }}
            />

            <span style={{ fontSize: "13px" }}>Browse</span>
          </label>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "fit-content", margin: "1.5rem 0" }}>
          <Button title={"UPLOAD"} onClick={upload_data} />
        </div>
      </div>

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alert"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
    </div>
  );
};
