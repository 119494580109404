import React, { useEffect, useState } from "react";
import PageHeading from "../../components/PageHeading";
import styles from "../Home/Home.module.css";
import "./Payroll.css";
import Dropdown from "../../components/inputs/Dropdown";
import {
  Button,
  EditButton,
  SaveButton,
  SubmitButton,
} from "../../components/btn/Buttons";
import Table from "../../components/table/Table";
import TableNew from "../../components/table/TableWithPagination";
import { useSelector } from "react-redux";
import moment from "moment";
import { DeleteIcon, base_url } from "../../utils/utils";
import { Pagination2 } from "../../components/pagination/pagination";
import PayRunsModal from "../../components/modal/PayRunsModal";
const PayRuns = () => {
  const [show, setshow] = useState(true);
  const [modalData, setModalData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [showData, setShowData] = useState(false);
  const [SerchInput, setSearchInput] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [action, setAction] = useState("fetchStaffData");
  const { staff_list, staff_loading } = useSelector(
    (state) => state.masterdata
  );
  const { user_id } = useSelector((state) => state.other);
  function clickChangePayrol() {
    setshow(true);
    if (action === "fetchStaffData") setAction("showGenerateSalarySheet");
    else setAction("fetchStaffData");
  }

  function clickChangeSalarySheet() {
    setshow(false);
  }

  const handleFetchData = async (month, year) => {
    try {
      const body = {
        AGENCY_ID: user_id,
        action: action,
        month: month,
        year: parseInt(year),
      };

      const response = await fetch(`${base_url}DOMSALES/PayRuns.php`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
      }
      const responseText = await response.text();
      const cleanedResponse = responseText.replace(/\r?\n|\r/g, "");
      const jsonResponse = cleanedResponse.substring(
        cleanedResponse.indexOf("{")
      );
      const resJson = await JSON.parse(jsonResponse);
      setStaffData(resJson?.data?.STAFF_DATA);
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"Pay Runs"}
        right={
          <div className="df gap10">
            {/* <div>
              <butto className="runPayroll-btn" onClick={clickChangePayrol}>
                Run payroll
              </butto>
            </div> */}
            <div>
              <button
                onClick={() => {
                  setAction("fetchStaffData");
                  setShowData(false);
                  clickChangePayrol();
                }}
                style={{
                  backgroundColor: show && " #2196f3",
                }}
                className="ReportButtons"
              >
                Pay roll
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setAction("showGenerateSalarySheet");
                  setShowData(false);
                  clickChangeSalarySheet();
                }}
                className="ReportButtons"
                style={{
                  backgroundColor: !show && " #2196f3",
                }}
              >
                Generate sallary sheet
              </button>
            </div>
          </div>
        }
      />

      {!showData ? (
        show ? (
          <div className="tb1">
            <p>Process Pay Run for : </p>
            <div className="MonYear">
              <p style={{ fontSize: "11px" }}>MONTH:</p>
              <div className="DR">
                <Dropdown
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  options={
                    <>
                      <option value="SELECT">SELECT</option>
                      <option value="01">JAN</option>
                      <option value="02">FEB</option>
                      <option value="03">MAR</option>
                      <option value="04">APR</option>
                      <option value="05">MAY</option>
                      <option value="06">JUN</option>
                      <option value="07">JUL</option>
                      <option value="08">AUG</option>
                      <option value="09">SEP</option>
                      <option value="10">OCT</option>
                      <option value="11">NOV</option>
                      <option value="12">DEC</option>
                    </>
                  }
                />
              </div>
              <p style={{ fontSize: "11px" }}>Year:</p>
              <div className="DR">
                <Dropdown
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                    </>
                  }
                />
              </div>
              <Button
                title="Submit"
                onClick={() => {
                  setShowData(true);
                  handleFetchData(month, year);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="tb1">
            <p>Process Pay Run for : </p>
            <div className="MonYear">
              <p style={{ fontSize: "11px" }}>MONTH:</p>
              <div className="DR">
                <Dropdown
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  options={
                    <>
                      <option value="SELECT">SELECT</option>
                      <option value="01">JAN</option>
                      <option value="02">FEB</option>
                      <option value="03">MAR</option>
                      <option value="04">APR</option>
                      <option value="05">MAY</option>
                      <option value="06">JUN</option>
                      <option value="07">JUL</option>
                      <option value="08">AUG</option>
                      <option value="09">SEP</option>
                      <option value="10">OCT</option>
                      <option value="11">NOV</option>
                      <option value="12">DEC</option>
                    </>
                  }
                />
              </div>
              <p style={{ fontSize: "11px" }}>Year:</p>
              <div className="DR">
                <Dropdown
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  options={
                    <>
                      <option>SELECT</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                    </>
                  }
                />
              </div>
              <Button
                title="Submit"
                onClick={() => {
                  setShowData(true);
                  handleFetchData(month, year);
                }}
              />
            </div>
          </div>
        )
      ) : (
        <></>
      )}
      {showData && (
        <>
          <div style={{ margin: "20px 0" }}>
            <div className={styles.desc}>
              PayRuns of month {month} - {year}
            </div>
            <Pagination2
              top={true}
              totalCount={10}
              SerchInput={SerchInput}
              setSearchInput={setSearchInput}
              pageNo={pageNo}
              setPageNo={setPageNo}
              entries={entries}
              setEntries={setEntries}
            />
          </div>
          <TableNew
            mt={5}
            nodata={staffData.length !== 0 && !staff_loading ? false : true}
            headData={[
              "EMPLOYEE CODE",
              "EMPLOYEE NAME",
              "PAID DAYS",
              "GROSS PAY",
              "NET PAY",
              "PAYMENT MODE",
              "BANK",
              "PAYMENT STATUS",
              "ACTION",
            ]}
            body={
              <>
                {staffData?.length != 0 &&
                  staffData?.map((item, i) => (
                    <tr key={i}>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModalData(item);
                          setIsOpen(true);
                        }}
                      >
                        {item.EMP_CODE}
                      </td>
                      <td>{item.EMP_NAME}</td>
                      <td>{item.P_MONTH}</td>
                      <td>{item.BASIC_PAY || "100000"}</td>
                      <td>{item.BASIC_PAY || "100000"}</td>
                      <td>{item.BASIC_PAY || "100000"}</td>
                      <td>{item.BASIC_PAY || "100000"}</td>
                      <td>{item.BASIC_PAY || "100000"}</td>
                      <td style={{ display: "flex", gap: "15px" }}>
                        <EditButton />
                        <SaveButton />
                      </td>
                    </tr>
                  ))}
              </>
            }
          />
        </>
      )}
      {isOpen && <PayRunsModal setIsOpen={setIsOpen} modalData={modalData} />}
    </div>
  );
};

export default PayRuns;
