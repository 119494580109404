import React, { useState } from "react";
import { FiChevronRight, FiUsers } from "react-icons/fi";
import { LuCalculator, LuCreditCard } from "react-icons/lu";
import { AnimatePresence, motion } from "framer-motion";
import CustomList from "./CustomList";
import { useNavigate } from "react-router-dom";
import { BsBox, BsBoxSeam, BsTruck } from "react-icons/bs";
import { GrStorage } from "react-icons/gr";
import { BiDockBottom, BiPurchaseTag, BiShield } from "react-icons/bi";
import store from "../../redux/store";
import { useSelector } from "react-redux";

const MasterDataList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);

  const navigate = useNavigate();
  const userIdString = localStorage.getItem("userId");
  const OMC = JSON.parse(userIdString)?.OMC;

  return (
    <>
      <div
        className="list-main mt10"
        onClick={() =>
          setFocused((pre) =>
            pre.find((v) => v == "masterdata")
              ? pre.filter((v) => v != "masterdata")
              : ["masterdata"]
          )
        }
        // onClick={() =>
        //   store.dispatch(
        //     setFocused(
        //       focused?.find((v) => v == "masterdata")
        //         ? focused.filter((v) => v != "masterdata")
        //         : ["masterdata"]
        //     )
        //   )
        // }
      >
        <div className="list-icon">
          <GrStorage size={18} color="#6e82a5" />
        </div>
        <div className="list-title fs15">Master Data</div>
        <div className="list-arrow">
          <FiChevronRight
            size={18}
            className={
              focused?.includes("masterdata") ? "rotate-down" : "rotate-right"
            }
          />
        </div>
      </div>

      <AnimatePresence initial={false}>
        {focused?.includes("masterdata") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <>
              <div
                className="list-main"
                onClick={
                  () =>
                    setFocused((pre) =>
                      pre.find((v) => v == "Product Master")
                        ? pre.filter((v) => v != "Product Master")
                        : [...pre, "Product Master"]
                    )
                  // store.dispatch(
                  //   setFocused(
                  //     focused.find((v) => v == "Product Master")
                  //       ? focused.filter((v) => v != "Product Master")
                  //       : [...focused, "Product Master"]
                  //   )
                  // )
                }
              >
                <div className="list-icon">
                  <BsBox size={15} color="#6e82a5" />
                </div>
                <div className="list-title fs14">Product Master</div>
                <div className="list-arrow">
                  <FiChevronRight
                    size={18}
                    className={
                      focused.includes("Product Master")
                        ? "rotate-down"
                        : "rotate-right"
                    }
                  />
                </div>
              </div>
              <AnimatePresence>
                {focused.includes("Product Master") && (
                  <motion.div
                    // onClick={() => setFocused1(!focused1)}
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <CustomList
                      title={"Equipment Master"}
                      style={{
                        backgroundColor:
                          path == "/MasterData/EquipmentMaster" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/MasterData/EquipmentMaster");
                        close();
                      }}
                    />
                    <CustomList
                      title={
                        (OMC == 1 && "NFR Master") ||
                        (OMC == 2 && "ARB Master") ||
                        (OMC == 3 && "BLPG Master")
                      }
                      style={{
                        backgroundColor:
                          path == "/MasterData/BLPG/ARB/NFR-Master"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/MasterData/BLPG/ARB/NFR-Master");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Services Master"}
                      style={{
                        backgroundColor:
                          path == "/MasterData/ServicesMaster" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/MasterData/ServicesMaster");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Area Code Master"}
                      style={{
                        backgroundColor:
                          path == "/MasterData/AreaCodeMaster" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/MasterData/AreaCodeMaster");
                        close();
                      }}
                    />
                    <CustomList
                      title={"BOM/NC Packaging"}
                      // title={"NC/DBC Packaging"}
                      style={{
                        backgroundColor:
                          path == "/MasterData/NC/DBC-Packaging" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/MasterData/NC/DBC-Packaging");
                        close();
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <>
              <div
                className="list-main"
                onClick={
                  () =>
                    setFocused((pre) =>
                      pre.find((v) => v == "Price Master")
                        ? pre.filter((v) => v != "Price Master")
                        : [...pre, "Price Master"]
                    )
                  // store.dispatch(
                  //   setFocused(
                  //     focused.find((v) => v == "Price Master")
                  //       ? focused.filter((v) => v != "Price Master")
                  //       : [...focused, "Price Master"]
                  //   )
                  // )
                }
              >
                <div className="list-icon">
                  <BiPurchaseTag size={15} color="#6e82a5" />
                </div>
                <div className="list-title fs14">Price Master</div>
                <div className="list-arrow">
                  <FiChevronRight
                    size={18}
                    className={
                      focused.includes("Price Master")
                        ? "rotate-down"
                        : "rotate-right"
                    }
                  />
                </div>
              </div>
              <AnimatePresence>
                {focused.includes("Price Master") && (
                  <motion.div
                    // onClick={() => setFocused1(!focused1)}
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <CustomList
                      title={"Refill Price"}
                      style={{
                        backgroundColor:
                          path == "/MasterData/Price-Refill" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/MasterData/Price-Refill");
                        close();
                      }}
                    />
                    <CustomList
                      title={
                        (OMC == 1 && "NFR Price") ||
                        (OMC == 2 && "ARB Price") ||
                        (OMC == 3 && "BLPG Price")
                      }
                      style={{
                        backgroundColor:
                          path == "/MasterData/BLPG/ARB/NFR-price"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/MasterData/BLPG/ARB/NFR-price");
                        close();
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/MasterData/custVendMaster" ? "white" : "",
              }}
            >
              <div className="list-icon">
                <BiDockBottom size={15} color="#6e82a5" />
              </div>
              <div
                className="list-title fs14"
                onClick={() => {
                  navigate("MasterData/custVendMaster");
                  close();
                }}
              >
                Cust/Vend Master
              </div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/MasterData/SetCreditLimit" ? "white" : "",
              }}
            >
              <div className="list-icon">
                <LuCreditCard size={15} color="#6e82a5" />
              </div>
              <div
                className="list-title fs14"
                onClick={() => {
                  navigate("MasterData/SetCreditLimit");
                  close();
                }}
              >
                Discount/Credit Limit
              </div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/MasterData/BankMaster" ? "white" : "",
              }}
            >
              <div className="list-icon">
                <BiShield size={15} color="#6e82a5" />
              </div>
              <div
                className="list-title fs14"
                onClick={() => {
                  navigate("MasterData/BankMaster");
                  close();
                }}
              >
                Bank Master
              </div>
            </div>
            <div
              style={{
                backgroundColor:
                  path == "/MasterData/VehicalMaster" ? "white" : "",
              }}
              className="list-main"
            >
              <div className="list-icon">
                <BsTruck size={15} color="#6e82a5" />
              </div>
              <div
                className="list-title fs14"
                onClick={() => {
                  navigate("MasterData/VehicalMaster");
                  close();
                }}
              >
                Vehicles Master
              </div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/MasterData/StaffMaster" ? "white" : "",
              }}
            >
              <div className="list-icon">
                <FiUsers size={15} color="#6e82a5" />
              </div>
              <div
                className="list-title fs14"
                onClick={() => {
                  navigate("MasterData/StaffMaster");
                  close();
                }}
              >
                Staff Master
              </div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/MasterData/StockAdjustMent" ? "white" : "",
              }}
            >
              <div className="list-icon">
                <BsBoxSeam size={15} color="#6e82a5" />
              </div>
              <div
                className="list-title fs14"
                onClick={() => {
                  navigate("MasterData/StockAdjustMent");
                  close();
                }}
              >
                Stock Adjustments Module
              </div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/MasterData/StatutoryMaster" ? "white" : "",
              }}
              onClick={() => {
                navigate("/MasterData/StatutoryMaster");
                close();
              }}
            >
              <div className="list-icon">
                <LuCalculator size={15} color="#6e82a5" />
              </div>
              <div className="list-title fs14">Set Alert</div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default MasterDataList;
