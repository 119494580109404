import React from "react";
import { ClipLoader, PulseLoader, SyncLoader } from "react-spinners";

const PageLoading = ({ loading }) => {
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingBlock: "10rem",
      }}
    >
      <PulseLoader
        color={"#1b64af"}
        loading={loading}
        cssOverride={override}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default PageLoading;
