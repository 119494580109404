import React, { useEffect, useMemo, useState } from "react";
import PageHeading from "../../components/PageHeading";
import RowTxt from "../../components/RowTxt";
import Button from "../../components/btn/Button";
import { MdAdd } from "react-icons/md";
import Modal from "../../components/modal/Modal";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import { TransactionType, dropdown } from "../../utils/data";
import { get_bank_master_list } from "../MasterData/masterdataSlice";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { BackButton, SaveButton } from "../../components/btn/Buttons";
import { get_bank_transaction_list } from "./cashBankSlice";
import PageLoading from "../../components/PageLoading";
import NoData from "../../components/NoData";
import {
  Pagination2,
  Pagination3,
} from "../../components/pagination/pagination";
import moment from "moment";
import { BankCodeFinder, DeleteIcon } from "../../utils/utils";
import { DateValidator2 } from "../../utils/validation";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import { bank_transaction_API } from "../../utils/apis";
import { toast } from "react-toastify";
import { Alerts } from "../../components/Alerts/Alerts";
import SearchApiHook from "../../CustomHooks/SearchApiHook";

const BankTransactions = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [entries, setEntries] = useState("5");
  const [showLoader, setShowLoader] = useState(false);
  const [addDataHead, setAddDataHead] = useState();
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };
  const { day_end_date } = useSelector((state) => state.profile);
  const { user_id, today } = useSelector((state) => state.other);

  const { bank_transaction_list, arb_list, bank_transaction_loading } =
    useSelector((state) => state.cashbanks);

  const { bank_master_list } = useSelector((state) => state.masterdata);

  const fetchData = () => {
    store.dispatch(get_bank_transaction_list(obj));
  };
  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  useEffect(() => {
    store.dispatch(get_bank_master_list());
  }, []);

  const onDelete_item = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", DeleteId);
      const response = await bank_transaction_API(body);
      setShowLoader(false);
      if (response?.success) {
        setPageNo(
          bank_transaction_list?.length < 2 && pageNo != 1 ? pageNo - 1 : pageNo
        );
        store.dispatch(
          get_bank_transaction_list({
            pageNo:
              bank_transaction_list?.length < 2 && pageNo != 1
                ? pageNo - 1
                : pageNo,
            entries: entries,
            setTotalCount: setTotalCount,
            setSearchCount: setSearchCount,
            search: SerchInput,
          })
        );
        toast(response?.message);
        setOpen(false);
      } else {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = () => {
    const data = [];
    if (
      !addDataHead?.date ||
      (addDataHead?.date && addDataHead?.date?.length != 10)
    ) {
      data.push("Please Enter Date");
    } else if (
      DateValidator2(
        moment(addDataHead?.date).format("DD/MM/YYYY"),
        today,
        day_end_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addDataHead?.date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          `Transaction date cant be before as day end Date`,
          `Transaction date cant be a future date`
        )
      );
    }
    if (
      !addDataHead?.trans_mode ||
      (addDataHead?.trans_mode && addDataHead?.trans_mode?.length == 0)
    ) {
      data.push("Please Select Mode of Transaction");
    }
    if (
      !addDataHead?.trans_amount ||
      (addDataHead?.trans_amount && addDataHead?.trans_amount?.length == 0)
    ) {
      data.push("Please Enter Transaction Amount ");
    } else if (addDataHead?.trans_amount < 0) {
      data.push("Transaction Amount cant be a Zero");
    }
    if (
      !addDataHead?.trans_type ||
      (addDataHead?.trans_type && addDataHead?.trans_type?.length == 0)
    ) {
      data.push("Please Select Transaction Type");
    }
    if (
      !addDataHead?.bank_account ||
      (addDataHead?.bank_account && addDataHead?.bank_account?.length == 0)
    ) {
      data.push("Please Select Bank Account");
    }
    if (
      TransactionType?.filter((v) => v?.id == addDataHead?.trans_type)?.[0]
        ?.label == "TRANSFER"
    ) {
      if (
        !addDataHead?.to_bank_account ||
        (addDataHead?.to_bank_account &&
          addDataHead?.to_bank_account?.length == 0)
      ) {
        data.push("Please Select To Bank Account");
      }
      if (
        !addDataHead?.mode_of_trans ||
        (addDataHead?.mode_of_trans && addDataHead?.mode_of_trans?.length == 0)
      ) {
        data.push("Mode of Transaction is Mandatory for Transfer.");
      } else if (
        addDataHead?.mode_of_trans >= 1 &&
        addDataHead?.mode_of_trans <= 4 &&
        Number(addDataHead?.trans_amount) > 100000
      ) {
        data.push("UPI-Maximum Limit Was 1,00,000 Per Day");
      }
    }

    if (
      !addDataHead?.charges ||
      (addDataHead?.charges && addDataHead?.charges?.length == 0)
    ) {
      data.push("Please Select Bank Charges");
    }
    if (
      !addDataHead?.txr_no ||
      (addDataHead?.txr_no && addDataHead?.txr_no?.length == 0)
    ) {
      data.push("Please Enter TXR Number");
    }
    if (
      !addDataHead?.narration ||
      (addDataHead?.narration && addDataHead?.narration?.length == 0)
    ) {
      data.push("Please Enter Narration");
    }
    return data;
  };

  const add_items = async () => {
    const data = validate();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("btdate", addDataHead?.date);
        body.append("bank_id", addDataHead?.bank_account);
        body.append("to_bank", addDataHead?.to_bank_account);
        body.append("trans_amount", addDataHead?.trans_amount);
        body.append("trans_charge", addDataHead?.charges);
        body.append("trans_type", addDataHead?.trans_type);
        body.append("trans_mode", addDataHead?.trans_mode);
        body.append("ins_detail", addDataHead?.txr_no);
        body.append("narration", addDataHead?.narration);
        body.append("MODE_TRANSACTION", addDataHead?.mode_of_trans);
        const responce = await bank_transaction_API(body);
        setShowLoader(false);
        if (responce?.success) {
          store.dispatch(get_bank_transaction_list(obj));
          setIsAdd(false);
          setAddDataHead({});
          toast(responce.message);
        } else if (!responce?.success) {
          setMess(responce.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };
  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);

  return (
    <div className="container">
      <PageHeading
        title={"BANK TRANSACTIONS"}
        right={
          <>
            <Button
              title={"ADD"}
              icon={<MdAdd size={20} color="#fff" />}
              onClick={() => {
                setIsAdd(true);
                setAddDataHead({ trans_mode: "2" });
              }}
            />
            <BackButton />
          </>
        }
      />

      <Pagination3
        top={true}
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {bank_transaction_loading ? (
          <PageLoading />
        ) : bank_transaction_list?.length == 0 ? (
          <NoData />
        ) : (
          <div className="grid">
            {bank_transaction_list?.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v?.BANK_ACC_NO} {v?.BANK_CODE ? ` - ${v.BANK_CODE}` : ""}
                  </div>
                  <RowTxt title={"Transaction No."} value={v?.SR_NO} />
                  {/* {v?.BTFLAG != "1" && (
                    <RowTxt
                      title={"Ref Transaction No."}
                      value={v?.TXN_REF_NO}
                    />
                  )} */}
                  <RowTxt
                    title={"Transaction Date"}
                    value={moment(Number(v?.BT_DATE)).format("DD-MMM-YYYY")}
                  />
                  <RowTxt title={"Transaction Amt."} value={v?.TRANS_AMOUNT} />
                  <RowTxt
                    title={"Mode of Transaction"}
                    value={
                      v?.TRANS_MODE == "1"
                        ? "CASH"
                        : v?.TRANS_MODE == "2"
                        ? "ONLINE"
                        : v?.TRANS_MODE == "3"
                        ? "CHEQUE"
                        : ""
                    }
                  />
                  <RowTxt
                    title={"Transaction Type"}
                    value={
                      TransactionType?.filter(
                        (s, i) => v?.TRANS_TYPE == s?.id
                      )?.[0]?.label
                    }
                  />
                  <RowTxt title={"Bank Charges "} value={v?.TRANS_CHARGES} />
                  {v?.TO_BANK_ACC_NO && (
                    <RowTxt title={"To Bank"} value={v?.TO_BANK_ACC_NO} />
                  )}
                  <RowTxt title={"TXR Number "} value={v?.INSTR_DETAILS} />
                  <RowTxt title={"Narration"} value={v?.NARRATION} />
                  {TransactionType?.filter(
                    (s, i) => v?.TRANS_TYPE == s?.id
                  )?.[0]?.label === "TRANSFER" && (
                    <RowTxt
                      title={"Transaction Mode"}
                      value={
                        v?.TRANSACTION_MODE == "1"
                          ? "Phonepay"
                          : v?.TRANSACTION_MODE == "2"
                          ? "GPay"
                          : v?.TRANSACTION_MODE == "3"
                          ? "Paytm"
                          : v?.TRANSACTION_MODE == "4"
                          ? "Cred"
                          : v?.TRANSACTION_MODE == "6"
                          ? "RTGS"
                          : v?.TRANSACTION_MODE == "7"
                          ? "NEFT"
                          : v?.TRANSACTION_MODE == "8"
                          ? "IMPS"
                          : v?.TRANSACTION_MODE == "5"
                          ? "Others"
                          : ""
                      }
                    />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  {v?.BTFLAG == 1 && v?.STATUS_FLAG == 0 ? (
                    <DeleteIcon
                      onClick={() => {
                        setOpen(true);
                        setDeleteId(v?.ID);
                      }}
                    />
                  ) : (
                    <div></div>
                  )}

                  {/* <motion.div
                    onClick={() => {
                      setViewItem(v);
                      setOpenView(true);
                    }}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div className="view-btn">View</div>
                    <HiOutlineArrowRight size={20} color="#1b64af" />
                  </motion.div> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button title={"BANK"} back />
            </div>
          }
          title={"BANK TRANSACTIONS"}
          body={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
              }}
            >
              <div className="flex aic gap10 credit-limit">
                <Input
                  label="TRANSACTION DATE"
                  type={"date"}
                  value={addDataHead?.date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        date: e.target.value,
                      });
                    }
                  }}
                />
                <Dropdown
                  width2=""
                  label="MODE OF TRANSACTION"
                  value={addDataHead?.trans_mode}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      trans_mode: e.target.value,
                    });
                  }}
                  options={
                    <>
                      {/* <option value="">SELECT</option> */}
                      {/* <option value="2">CHEQUE</option> */}
                      <option value="2">ONLINE</option>
                    </>
                  }
                />
                <Input
                  label="TRANSACTION AMOUNT"
                  placeholder={"TRANSACTION AMOUNT"}
                  value={addDataHead?.trans_amount}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (
                      (inputValue?.length <= 10 &&
                        /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(inputValue)) ||
                      inputValue == 0 ||
                      !addDataHead?.trans_amount
                    ) {
                      setAddDataHead({
                        ...addDataHead,
                        trans_amount: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                          inputValue
                        )
                          ? inputValue
                          : "",
                      });
                    }
                  }}
                />
                <Dropdown
                  width2=""
                  label="TRANSACTION TYPE"
                  value={addDataHead?.trans_type}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      trans_type: e.target.value,
                      bank_account: "",
                      to_bank_account: "",
                    });
                  }}
                  options={
                    <>
                      <option value="">SELECT</option>
                      {TransactionType?.filter((v) => v?.show)?.map((v, i) => (
                        <option key={i} value={v?.id}>
                          {v?.label}
                        </option>
                      ))}
                    </>
                  }
                />
                <Dropdown
                  width2=""
                  label="BANK ACCOUNT"
                  value={addDataHead?.bank_account}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      bank_account: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value="">SELECT</option>
                      {bank_master_list
                        // ?.filter((v) => v?.BANK_CODE != "STAR ACCOUNT")
                        // ?.filter((v) => v?.BANK_CODE != "OVER DRAFT")
                        // ?.filter((v) => v?.BANK_CODE != "TAR ACCOUNT")
                        ?.filter((v, i) => v?.BANK_CODE != "CASH ACCOUNT")
                        // ?.filter((v) => v?.BANK_CODE != "LOAN")
                        ?.map((v, i) => (
                          <option
                            disabled={
                              v?.ID == addDataHead?.to_bank_account &&
                              addDataHead?.trans_type == "5"
                            }
                            key={i}
                            value={v?.ID}
                          >
                            {bankCode(v?.BANK_CODE)} -
                            {v?.BANK_ACC_NO == "NA" ? user_id : v?.BANK_ACC_NO}
                          </option>
                        ))}
                    </>
                  }
                />
                {TransactionType?.filter(
                  (v) => v?.id == addDataHead?.trans_type
                )?.[0]?.label == "TRANSFER" && (
                  <>
                    <Dropdown
                      width2=""
                      label="TO BANK ACCOUNT"
                      value={addDataHead?.to_bank_account}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          to_bank_account: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          {bank_master_list
                            ?.filter((v, i) => v?.BANK_CODE != "CASH ACCOUNT")
                            ?.map((v, i) => (
                              <option
                                disabled={v?.ID == addDataHead?.bank_account}
                                key={i}
                                value={v?.ID}
                              >
                                {bankCode(v?.BANK_CODE)} -
                                {v?.BANK_ACC_NO == "NA"
                                  ? user_id
                                  : v?.BANK_ACC_NO}
                              </option>
                            ))}
                        </>
                      }
                    />
                    <Dropdown
                      width2=""
                      label={"MODE OF TRANSACTION"}
                      value={addDataHead?.mode_of_trans}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          mode_of_trans: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          <option disabled>---------UPI--------</option>
                          <option value="1">Phonepay</option>
                          <option value="2">GPay</option>
                          <option value="3">Paytm</option>
                          <option value="4">Cred</option>
                          <option disabled>-----Net Banking----</option>
                          <option value="6">RTGS</option>
                          <option value="7">NEFT</option>
                          <option value="8">IMPS</option>
                          <option value="5">Others</option>
                        </>
                      }
                    />
                  </>
                )}
                <Input
                  label="BANK CHARGES"
                  placeholder="BANK CHARGES"
                  value={addDataHead?.charges}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (
                      (inputValue?.length <= 10 &&
                        /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(inputValue)) ||
                      inputValue == 0 ||
                      !addDataHead?.charges
                    ) {
                      setAddDataHead({
                        ...addDataHead,
                        charges: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                          inputValue
                        )
                          ? inputValue
                          : "",
                      });
                    }
                  }}
                />
                <Input
                  label="INSTRUMENT DETAILS / TXR NO"
                  placeholder="INSTRUMENT DETAILS / TXR NO"
                  value={addDataHead?.txr_no}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      txr_no: e.target.value,
                    });
                  }}
                />
                <Input
                  label="NARRATION"
                  placeholder={"NARRATION"}
                  value={addDataHead?.narration}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      narration: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <SaveButton onClick={add_items} back />
              </div>
            </div>
          }
        ></Modal>
      )}
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};

export default BankTransactions;
