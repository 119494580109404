import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/inputs/Input";
import Button from "../../components/btn/Button";
import "./login.css";
import logoicon from "../../assets/logo512.png";
import logotxt from "../../assets/mylpgtxt.png";
import { BiLogIn } from "react-icons/bi";
import AuthBg from "../../components/authbg/AuthBg";
import { toast } from "react-toastify";
import { Text, base_url } from "../../utils/utils";
import Loader from "../../components/Loader";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FiArrowLeft } from "react-icons/fi";
import { EmailValidation } from "../../utils/validator";
import { forgot_password_email_api } from "../../utils/apis";

export const ForgotPass = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userData, setUserData] = useState({});

  const forgot_mail_send = async () => {
    if (!EmailValidation.test(userData?.email)) {
      setUserData({ ...userData, emailmess: "Please Enter Valid Mail" });
    } else {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("email", userData?.email);
        const responce = await forgot_password_email_api(body);
        setShowLoader(false);
        if (responce.success) {
          toast.success(responce.message);
          navigate("/login");
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <AuthBg>
      <div className="login-inner-div" style={{ maxWidth: "450px" }}>
        <div className="sidebar-logo aic jcc ">
          <img src={logoicon} className="s_logo" />
          <img src={logotxt} className="s_logotxt" />
        </div>
        <div className="login-text ">Forgot Password</div>
        <div className="forgot-content">
          Enter Your Registered Email Address And We Will Send You The Link To
          Reset Your Password
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: " 1rem 0",
          }}
        >
          <Input
            placeholder={"Enter Email"}
            value={userData.email}
            onChange={(e) =>
              setUserData({
                ...userData,
                email: e.target.value,
                emailmess: "",
                emailval: false,
              })
            }
            w={"80%"}
            error={userData.emailmess}
            bcolor={userData.emailval}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0 20px 0",
          }}
        >
          <Button
            title={"SEND PASSWORD RESET LINK"}
            onClick={forgot_mail_send}
          />
        </div>
        <hr />
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-start",
            gap: "10px",
            justifyContent: "flex-start",
            padding: "1rem 0 1rem 1rem",
          }}
          onClick={() => navigate("/login")}
        >
          <FiArrowLeft size={20} color="#000" />
          <div
            style={{
              color: "#000",
              fontSize: "16px",
            }}
          >
            Back to Login
          </div>
        </div>
      </div>
      {showLoader && <Loader loading={showLoader} />}
    </AuthBg>
  );
};
