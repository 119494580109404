import React, { useEffect, useState } from "react";
import PageHeading from "../../components/PageHeading";
import { FaTrashCan } from "react-icons/fa6";
import Input from "../../components/inputs/Input";
import Dropdown from "../../components/inputs/Dropdown";
import store from "../../redux/store";
import { get_statutory_list } from "./masterdataSlice";
import { useSelector } from "react-redux";
import { base_url } from "../../utils/utils";
import Loader from "../../components/Loader";
import PageLoading from "../../components/PageLoading";
import moment from "moment";
import SmallModal from "../../components/modal/SmallModal";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../components/btn/Buttons";
import Table from "../../components/table/Table";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import TableNew from "../../components/table/TableWithPagination";
import { DateValidator2 } from "../../utils/validation";
import NPInput from "../../components/inputs/NPInput";
import { Pagination2 } from "../../components/pagination/pagination";
import { Alerts } from "../../components/Alerts/Alerts";

const StatutoryMaster = () => {
  const [itemlist, setItemlist] = useState([]);
  const [addData, setAddData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [ID, setID] = useState("");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const { user_id, today } = useSelector((state) => state.other);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  const headData = [
    "ITEM",
    "REFERENCE NO",
    "VALID UPTO",
    "REMINDER",
    "REMARKS",
    "ACTIONS",
  ];

  const { statutory_loading, statutory_list } = useSelector(
    (state) => state.masterdata
  );
  const add_items = async () => {
    const currentDate = new Date();
    const futureDate = moment(
      new Date(currentDate.getTime() + 90 * 24 * 60 * 60 * 1000)
    ).format("DD/MM/YYYY");
    const arr = [];

    addData.forEach((v) => {
      // const duplicate_item = addData?.filter((val)=>val?.item == v?.item)
      // if (duplicate_item?.length > 1) {
      //   arr.push("You can't able to add duplicate Items");
      // }
      if (!v?.item || (v?.item && v?.item?.length == 0)) {
        arr.push("Please Select Item");
      }
      if (
        !v?.reference ||
        (v?.reference && v?.reference?.trim()?.length == 0)
      ) {
        arr.push("Please Enter Reference No.");
      }
      const data1 = DateValidator2(
        moment(addData?.date).format("DD/MM/YYYY"),
        futureDate,
        today,
        `Valid Upto Date cant be below as current date (${today})`,
        `Valid Upto Date valid upto 90 days `
      );
      if (!v?.date || (v?.date && v?.date?.length == 0)) {
        arr.push("Please Select valid upto Date");
      } else if (data1 != true) {
        arr.push(...data1);
      }
      if (!v?.reminder || (v?.reminder && v?.reminder?.length == 0)) {
        arr.push("Please Select Reminder");
      }
      if (!v?.remark || (v?.remark && v?.remark?.trim()?.length == 0)) {
        arr.push("Please Enter Remark");
      }
    });

    if (arr?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        addData.forEach((v) => body.append("item[]", v?.item));
        addData.forEach((v) => body.append("refrence[]", v?.reference?.trim()));
        addData.forEach((v) => body.append("remind[]", v?.reminder));
        addData.forEach((v) => body.append("remark[]", v?.remark?.trim()));
        addData.forEach((v) => body.append("valid[]", v?.date));
        const responce = await fetch(base_url + "statutory_data.php", {
          method: "POST",
          body: body,
        });
        const res = await responce.json();
        setLoading(false);
        if (res.success) {
          store.dispatch(get_statutory_list(obj));
          setAddData([]);
          toast(res.message);
        } else {
          setMess(res.message);
          setOpen1(true);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setMess(<Alerts data={arr} />);
      setOpen1(true);
    }
  };
  const get_items = async () => {
    const responce = await fetch(base_url + "itemlistapi.php", {
      method: "GET",
    });
    const res = await responce.json();
    if (res.status == 200) {
      setItemlist(res.record);
    } else {
    }
  };
  const delete_items = async () => {
    try {
      setOpen(false);
      setLoading(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", ID);
      const responce = await fetch(base_url + "statutory_data.php", {
        method: "POST",
        body: body,
      });
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        toast(res.message);
        if (statutory_list?.length < 2) {
          setPageNo(pageNo - 1);
          store.dispatch(
            get_statutory_list({
              pageNo: pageNo - 1,
              entries: entries,
              setTotalCount: setTotalCount,
            })
          );
        } else {
          store.dispatch(get_statutory_list(obj));
        }
      } else {
        toast(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    get_items();
  }, []);

  useEffect(() => {
    store.dispatch(get_statutory_list(obj));
  }, [pageNo, entries]);

  return (
    <div className="container">
      {loading && <Loader loading={loading} />}
      <PageHeading
        title={"DISTRIBUTOR STATUTORY DATA AND RENEWAL REMINDER"}
        right={
          <>
            <AddButton
              onClick={() => {
                if (
                  addData.find(
                    (v) =>
                      !v.item ||
                      (v.item && v.item.length == 0) ||
                      !v.reference ||
                      (v.reference && v.reference.length == 0) ||
                      !v.date ||
                      (v.date && v.date.length == 0) ||
                      !v.reminder ||
                      (v.reminder && v.reminder.length == 0) ||
                      !v.remark ||
                      (v.remark && v.remark.length == 0)
                  )
                ) {
                  setMess(
                    "Please enter all the values in current row and then add next row"
                  );
                  setOpen1(true);
                  return;
                }
                setAddData((pre) => [...pre, { reference: "NA" }]);
              }}
            />
            {addData.length > 0 && <SaveButton onClick={() => add_items()} />}
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/A971FsmllM4",
                "https://youtu.be/v5AAVwSlxqs",
              ]}
            />
          </>
        }
      />
      {addData.length > 0 && (
        <Table
          headData={headData}
          body={addData.map((val, ind) => (
            <tr key={ind}>
              <td>
                <Dropdown
                  value={val.item}
                  onChange={(e) =>
                    setAddData((pre) =>
                      pre.map((v, i) =>
                        i == ind ? { ...v, item: e.target.value } : { ...v }
                      )
                    )
                  }
                  options={[<option value={""}>{"SELECT"}</option>].concat(
                    itemlist
                      .filter((v) => v.STATUS == 1)
                      .map((v, i) => (
                        <option
                          disabled={
                            addData.filter((item) => item?.item == v.ID)
                              .length > 0
                              ? true
                              : false
                          }
                          value={v.ID}
                          key={i}
                        >
                          {v.ITEM}
                        </option>
                      ))
                  )}
                />
              </td>
              <td>
                <Input
                  value={val.reference}
                  onChange={(e) => {
                    if (e.target.value.length <= 20) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind
                            ? { ...v, reference: e.target.value }
                            : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Input
                  type="date"
                  value={val.date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind ? { ...v, date: e.target.value } : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Dropdown
                  value={val.reminder}
                  onChange={(e) =>
                    setAddData((pre) =>
                      pre.map((v, i) =>
                        i == ind ? { ...v, reminder: e.target.value } : { ...v }
                      )
                    )
                  }
                  options={
                    <>
                      <option value={"0"}>SELECT</option>
                      <option value={"1"}>7 DAYS</option>
                      <option value={"2"}>15 DAYS</option>
                      <option value={"3"}>30 DAYS</option>
                      <option value={"4"}>60 DAYS</option>
                    </>
                  }
                />
              </td>
              <td>
                <Input
                  placeholder={"Enter Remarks"}
                  value={val.remark}
                  onChange={(e) => {
                    if (e.target.value.length <= 40) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind ? { ...v, remark: e.target.value } : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <FaTrashCan
                  color="#f00"
                  size={20}
                  cursor={"pointer"}
                  onClick={() =>
                    setAddData((pre) => pre.filter((v, i) => i != ind))
                  }
                />
              </td>
            </tr>
          ))}
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          top={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {statutory_loading ? (
        <PageLoading />
      ) : (
        <TableNew
          nodata={statutory_list?.length == 0 ? true : false}
          headData={headData}
          body={statutory_list.map((v, i) => (
            <tr key={i}>
              <td>{v.ITEM}</td>
              <td>{v.ITEM_REF_NO}</td>
              <td>{moment(v.VALID_UPTO).format("DD-MMM-YYYY")}</td>
              <td>
                {v.REMIND_BEFORE == 1
                  ? "7 DAYS"
                  : v.REMIND_BEFORE == 2
                  ? "15 DAYS"
                  : v.REMIND_BEFORE == 3
                  ? "30 DAYS"
                  : "60 DAYS"}
              </td>
              <td>{v.REMARKS}</td>
              <td>
                <FaTrashCan
                  color="#f00"
                  size={20}
                  cursor={"pointer"}
                  onClick={() => {
                    setID(v.ID);
                    setOpen(true);
                  }}
                />
              </td>
            </tr>
          ))}
        />
      )}

      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => delete_items()}
        />
      )}
      {open1 && (
        <SmallModal
          okbtn={true}
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
        />
      )}
    </div>
  );
};

export default StatutoryMaster;
