import { useMemo } from 'react';

const useLastFourFinancialYears = (year) => {
  const currentYear = new Date().getFullYear();

  const options = useMemo(() => {
    const yearOptions = [];
    for (let i = 0; i < year; i++) {
      yearOptions.push(
        <option key={currentYear - i} value={currentYear - i}>
          {currentYear - i}
        </option>
      );
    }
    return yearOptions;
  }, [currentYear]);

  return options;
};

export default useLastFourFinancialYears;
