import React from "react";
import ReactToPrint from "react-to-print";

const ReactPrint = ({ setViewItem, v, documentTitle, previewRef, num }) => {
  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <div
            onMouseEnter={() => {
              setViewItem(v);
            }}
            onTouchStart={() => {
              setViewItem(v);
            }}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setViewItem(v);
            }}
          >
            {num}
          </div>
        )}
        content={() => previewRef.current}
        documentTitle={documentTitle}
      />
    </div>
  );
};

export default ReactPrint;
