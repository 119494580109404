import React, { useState } from "react";
import PageHeading from "../../components/PageHeading";
import { BackButton, Button, HelpButton } from "../../components/btn/Buttons";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import "./Gst.css";
import { useSelector } from "react-redux";
import SmallModal from "../../components/modal/SmallModal";
import { Alerts } from "../../components/Alerts/Alerts";
import { gstr_3_API } from "../../utils/apis";
import Loader from "../../components/Loader";
import Table from "../../components/table/Table";
import NoData from "../../components/NoData";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";

import excelData from "../../assets/GSTR3.xlsx";

import { YearData, monthData } from "../../utils/data";
import axios from "axios";
import { base_url } from "../../utils/utils";
const GSTR3 = () => {
  const [addData, setAddData] = useState({});
  const [showData, setShowData] = useState(false);
  const [addDataPre, setAddDataPre] = useState({});
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [list, setList] = useState([]);
  const [calculationData, setCalculationData] = useState({});
  const [loading, setLoading] = useState(false);

  const [url, setUrl] = useState("");

  const { profile_data, day_end_date } = useSelector((state) => state.profile);

  const { user_id, today } = useSelector((state) => state.other);

  const validate = () => {
    const data = [];
    if (!addData?.month || (addData?.month && addData?.month?.length == 0)) {
      data.push("Please select Month");
    }
    if (!addData?.year || (addData?.year && addData?.year?.length == 0)) {
      data.push("Please select Year");
    }
    return data;
  };
  const fetchGSTR3 = async () => {
    const data = validate();
    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("agencyid", user_id);
        body.append("action", "generate");
        body.append("month", addData?.month);
        body.append("year", addData?.year);
        body.append("C_IGST", addData?.igst || "");
        body.append("C_SGST", addData?.sgst || "");
        body.append("C_CGST", addData?.cgst || "");
        const res = await gstr_3_API(body);
        setLoading(false);
        if (res.success) {
          setAddDataPre(addData);
          setAddData({
            month: "",
            year: "",
            igst: "",
            cgst: "",
            sgst: "",
          });
          setUrl(res.file);
          setList(res?.data);
          setShowData(true);
          setCalculationData(res?.calculation);
        } else if (!res.success) {
          setOpen(true);
          setMess(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen(true);
      setMess(<Alerts data={data} />);
    }
  };

  const handleExport = async () => {
    try {
      // const response = await axios.get(excelData, {
      //   responseType: "arraybuffer",
      // });
      // const workbook = new ExcelJS.Workbook();
      // await workbook.xlsx.load(response.data);
      // const worksheet = workbook.getWorksheet(1);

      // const updateCell = (cell, value) => {
      //   const excelCell = worksheet.getCell(cell);
      //   excelCell.value = value;
      // };

      // updateCell("C5:D5",profile_data?.gst);
      // updateCell("G5",addDataPre?.year);
      // updateCell("C6:D6",profile_data?.name);
      // updateCell("G6",monthData?.filter((v)=>v?.id == addDataPre?.month)?.[0]?.fullName);

      // // Please add here remaining Data ...

      const base_url_new = base_url;
      // // Save the updated workbook
      // const buffer = await workbook.xlsx.writeBuffer();
      // const blob = new Blob([buffer], { type: "application/octet-stream" });
      // const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const fullUrl = base_url_new + url;

      // Open the URL in a new window
      const newWindow = window.open(fullUrl, "_blank");

      // Download the file
      newWindow.onload = function () {
        const downloadLink = newWindow.document.createElement("a");
        downloadLink.href = fullUrl;
        downloadLink.download = "GSTR-3B-Filled.xlsx";
        downloadLink.click();
        newWindow.close();
      };
    } catch (error) {
      console.error("Error loading the template:", error);
    }
  };

  return (
    <div className="container">
      <div className="ReportsMain">
        <PageHeading
          title={"GSTR3 REPORT :"}
          right={
            <div className="ReportMenu-btn">
              <div>
                <BackButton />
              </div>
              <div>
                <HelpButton
                  link={[
                    "https://youtu.be/A971FsmllM4",
                    "https://youtu.be/v5AAVwSlxqs",
                  ]}
                />
              </div>
            </div>
          }
        />
        <div style={{ height: "30px" }}></div>
        <p style={{ fontSize: "11px" }}>
          Please Enter Your Monthly Carry forwards of GST in Creditors IGST,
          CGST and SGST :
        </p>

        <div>
          <div style={{ height: "10px" }}></div>
          <div className="tb1">
            <div className="GSTR1Mian">
              <div className="DR">
                <Dropdown
                  label={"MONTH"}
                  value={addData?.month}
                  onChange={(e) => {
                    const input = e.target.value;
                    setAddData({ ...addData, month: input });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {monthData.map((v, i) => (
                        <option value={v?.id} key={i}>
                          {v?.label}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>

              <div className="DR">
                <Dropdown
                  label={"YEAR"}
                  value={addData?.year}
                  onChange={(e) => {
                    const input = e.target.value;
                    setAddData({ ...addData, year: input });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {YearData.map((v, i) => (
                        <option value={v?.id} key={i}>
                          {v?.lable}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <div className="df gap10">
                  <Input
                    value={addData?.igst}
                    onChange={(e) => {
                      const input = e.target.value;
                      setAddData({ ...addData, igst: input });
                    }}
                    label={"Creditors IGST"}
                    type={"text"}
                    placeholder={"IGST"}
                  />
                  <Input
                    value={addData?.cgst}
                    onChange={(e) => {
                      const input = e.target.value;
                      setAddData({ ...addData, cgst: input });
                    }}
                    label={"Creditors CGST"}
                    type={"text"}
                    placeholder={"CGST"}
                  />
                  <Input
                    value={addData?.sgst}
                    onChange={(e) => {
                      const input = e.target.value;
                      setAddData({ ...addData, sgst: input });
                    }}
                    label={"Creditors SGST"}
                    type={"text"}
                    placeholder={"SGST"}
                  />
                </div>
                <button className="GST-btn" onClick={fetchGSTR3}>
                  FETCH GSTR3
                </button>
                <button
                  className="GST-btn"
                  onClick={() => {
                    if (showData) {
                      handleExport();
                    }
                  }}
                >
                  GENERATE GSTR3
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showData && (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "16px" }}>Month :</span>
            <p className="FDATE">
              {monthData?.filter((v) => v?.id == addDataPre?.month)?.[0]?.label}
            </p>
            <span style={{ fontSize: "16px" }}>Year :</span>
            <p className="FDATE">{addDataPre?.year}</p>
          </div>

          {list?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <>
              <Table
                headers={
                  <thead>
                    <tr>
                      <th rowspan="2">
                        <font>GST RATE</font>
                      </th>
                      <th rowspan="2">
                        <font>TAXABLE</font>
                      </th>
                      <th colspan="4">
                        <font>PURCHASE GST</font>
                      </th>
                      <th rowspan="2">
                        <font>TAXABLE</font>
                      </th>
                      <th colspan="4">
                        <font>SALE GST</font>
                      </th>
                      <th rowspan="2">
                        <font>NET GST</font>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <font>IGST</font>
                      </th>
                      <th>
                        <font>CGST</font>
                      </th>
                      <th>
                        <font>SGST</font>
                      </th>
                      <th>
                        <font>TOTAL</font>
                      </th>
                      <th>
                        <font>IGST</font>
                      </th>
                      <th>
                        <font>CGST</font>
                      </th>
                      <th>
                        <font>SGST</font>
                      </th>
                      <th>
                        <font>TOTAL</font>
                      </th>
                    </tr>
                  </thead>
                }
                body={
                  <>
                    {list?.map((v, i) => (
                      <tr key={i}>
                        <td>{v?.["GST RATE"]}</td>
                        <td>{v?.["PURCHASE TAXABLE"]}</td>
                        <td>{v?.["PURCHASE IGST"]}</td>
                        <td>{v?.["PURCHASE CGST"]}</td>
                        <td>{v?.["PURCHASE SGST"]}</td>
                        <td>{v?.["TOTAL PURCHASE GST"]}</td>
                        <td>{v?.["SALES TAXABLE"]}</td>
                        <td>{v?.["SALES IGST"]}</td>
                        <td>{v?.["SALES CGST"]}</td>
                        <td>{v?.["SALES SGST"]}</td>
                        <td>{v?.["TOTAL SALES GST"]}</td>
                        <td>{v?.["NET GST"]}</td>
                      </tr>
                    ))}
                  </>
                }
              />
              <div style={{ marginTop: "2rem" }} className="ReportMenu-btn">
                <Button
                  disabled={true}
                  bgColor={"#0797ad"}
                  title={"SAVE GST AMOUNTS"}
                />
                <Button
                  disabled={true}
                  bgColor={"#0797ad"}
                  title={"CACULATE"}
                />
                <Button disabled={true} bgColor={"#0797ad"} title={"SET OFF"} />
                <Button
                  disabled={true}
                  bgColor={"#0797ad"}
                  title={"PAY OFF GST AMOUNT"}
                />
              </div>
            </>
          )}
        </>
      )}
      {open && (
        <SmallModal
          title={"Alerts"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={mess}
          okbtn={true}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default GSTR3;
