import React from "react";
import "./button.css";

const Button = ({ title, icon, back, onClick, disable, width }) => {
  return (
    <div
      className={`btn  ${
        disable ? "btn-info1" : back ? "btn-info" : "btn-success"
      }`}
      style={{
        cursor: disable && "default",
        backgroundColor: disable ? "gray" : "",
        border: disable ? "none" : "",
        width: width,
      }}
      role="button"
      onClick={() => {
        if (!disable && onClick) {
          onClick();
        }
      }}
    >
      {icon}
      {title}
    </div>
  );
};

export default Button;
