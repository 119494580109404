import React from "react";
import "./list.css";
import { BsCircle } from "react-icons/bs";

const CustomList = ({ icon, title, onClick, style, fd = "row" }) => {
  return (
    <div
      className="list-main-single"
      style={{ ...style, flexDirection: fd ? fd : "row" }}
      onClick={onClick}
    >
      <div className="list-icon">
        {icon ? icon : <div className="circle"></div>}
      </div>
      <div className="list-title fs12">{title}</div>
    </div>
  );
};

export default CustomList;
