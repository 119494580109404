import React from "react";
import "./input.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const Input = ({
  label,
  placeholder,
  error,
  mess,
  type,
  onChange,
  value,
  disabled,
  onBlur,
  mw,
  w,
  onFocus,
  onKeyDown,
  setShowPass,
  showPass,
  pass = false,
  max,
}) => {
  return (
    <div className="inp-main" style={{ width: w,maxWidth:mw }}>
      {label && <div className="inp-label">{label}</div>}
     <div className="inp-wrap" style={{ position: "relative" }}>
        <input
          placeholder={placeholder?.toUpperCase()}
          value={value}
          onChange={onChange}
          className="inp"
          type={type}
          max={max}
          disabled={disabled}
          onBlur={onBlur} // it is used for input type date only
          onFocus={onFocus} // it is Used for input type data and select both
          onKeyDown={onKeyDown} // it is Used for input type data and select both
        />
        {pass && (
          <div className="pass-show-eye">
            {showPass ? (
              <BsEyeSlash onClick={() => setShowPass(!showPass)} />
            ) : (
              <BsEye onClick={() => setShowPass(!showPass)} />
            )}
          </div>
        )}
      </div>
      {mess && <div className="inp-mess">{mess}</div>}
      {error && <div className="inp-error">{error}</div>}
    </div>
  );
};

export default Input;
