import React from "react";

const RowTxt = ({ title, value }) => {
  return (
    <div className="rowtxt">
      <div className="card-label card-label-width">{title}</div>
      <div className="card-label">:</div>
      <div className="card-title" style={{textWrap:"wrap"}}>{value}</div>
    </div>
  );
};

export default RowTxt;
