import React from "react";
import styles from "./tax.module.css";
import { CheckBox } from "../../../components/inputs/CheckBox";
import { useSelector } from "react-redux";
import Indanegas from "../../../assets/Indane.png";
import hpgas from "../../../assets/Hp.png";
import bharatgas from "../../../assets/Bharatgas.png";
import useAmountToWords from "../../../utils/Hook/useAmountToWords";
import moment from "moment";

const TaxInvoice4 = ({ doc_name, previewRef, listdata, net_total }) => {
  const { profile_data, seal_image, signature_image, upi_qr_image, upi_id } =
    useSelector((state) => state.profile);
  const { omc_name, omc_data } = useSelector((state) => state.other);
  const convertAmountToWords = useAmountToWords(
    Number(net_total || 0).toFixed(0)
  );

  return (
    <div ref={previewRef}>
      {Array(2)
        .fill()
        .map((v, i) => (
          <div
            key={i}
            style={{
              marginLeft: 0,
              borderTop: i % 2 == 1 ? "1px solid black" : "",
            }}
          >
            <div
              className={styles.invoice_main}
              style={{ marginLeft: "60px", marginBottom: 0 }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",

                  padding: "0 0",
                  marginBottom: "1rem",
                }}
              >
                {doc_name}
              </div>
              <div
                style={{
                  width: "100%",
                  fontSize: "14px",
                  textAlign: "right",
                  padding: "0 2rem 5px 0",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0.5rem", // Add some space between items
                }}
              >
                <label style={{ display: "flex", alignItems: "center" }}>
                  <CheckBox /> Original
                </label>
                /
                <label style={{ display: "flex", alignItems: "center" }}>
                  <CheckBox /> Duplicate
                </label>
                /
                <label style={{ display: "flex", alignItems: "center" }}>
                  <CheckBox /> Triplicate
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <img
                    id="Aname"
                    style={{ width: "170px" }}
                    src={
                      omc_name == "IOCL"
                        ? Indanegas
                        : omc_name == "HPCL"
                        ? hpgas
                        : omc_name == "BPCL"
                        ? bharatgas
                        : ""
                    }
                  />
                </div>

                <div
                  style={{
                    flex: 3,
                    gap: ".25rem",
                    marginLeft: "8rem",
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {profile_data?.name}
                  </div>
                  <div>
                    {profile_data.ADDRESS}, {profile_data?.LOCATION},
                  </div>
                  <div>
                    {profile_data.DISTRICT}, {profile_data.state_name} ,
                    {profile_data?.PINCODE},
                  </div>
                  <div>
                    PH:{profile_data?.mobile},{profile_data?.landline}
                  </div>
                  <div>E-Mail :{profile_data?.email}</div>
                  <div> GSTIN/UIN: {profile_data?.gst}</div>
                </div>
              </div>
              <table className={styles.table_1}>
                <tr>
                  <td colSpan={2}>VOUCHER NO. : {listdata?.SR_NO}</td>
                  <td colSpan={2}>
                    VOUCHER DATE :{" "}
                    {moment(listdata?.SR_NO_DATE).format("DD-MMM-YYYY")}
                  </td>
                  <td colSpan={2}>
                    TIME OF RECEIPT :{listdata?.dc_num || "NA"}
                  </td>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <div
                      style={{
                        flex: 1,
                        gap: ".25rem",
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ fontSize: "13px" }}>Payer Details</div>

                      {listdata?.CVO_NAME && (
                        <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                          {listdata?.CVO_NAME}
                        </div>
                      )}
                      {listdata?.CVO_NAME !== "CASH SALES / HOUSEHOLDS" && (
                        <>
                          {listdata?.CVO_ADDRESS && (
                            <div
                              style={{
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                maxWidth: "100%",
                              }}
                            >
                              {listdata.CVO_ADDRESS
                                ? ` ${listdata.CVO_ADDRESS} ,`
                                : ""}
                              {listdata.CVO_LOCATION
                                ? ` ${listdata.CVO_LOCATION} ,`
                                : ""}
                              {listdata.CVO_DISTRICT
                                ? ` ${listdata.CVO_DISTRICT} ,`
                                : ""}
                              {listdata.B_STATE_NAME
                                ? ` ${listdata.B_STATE_NAME} ,`
                                : ""}
                              {listdata.CVO_PINCODE
                                ? ` ${listdata.CVO_PINCODE} `
                                : ""}
                            </div>
                          )}
                          {(listdata?.MOBILE || listdata?.EMAIL) && (
                            <div>
                              {" "}
                              {listdata?.MOBILE
                                ? `PH:${listdata?.MOBILE || "NA"}`
                                : ""}{" "}
                              {listdata?.EMAIL
                                ? `Email:${listdata?.EMAIL || "NA"}`
                                : ""}{" "}
                            </div>
                          )}
                          {listdata?.CVO_TIN && (
                            <div>GSTIN/UIN: {listdata?.CVO_TIN || "NA"}</div>
                          )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    Mode of Receipt : {listdata?.make_of_payment || "NA"}
                  </td>
                  <td colSpan={3}>
                    Cheque Number : {listdata?.cheque_number || "NA"}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    Instrument/TXN Details. :{" "}
                    {listdata?.intrument_details || "NA"}
                  </td>
                  <td colSpan={3}>
                    Transaction Mode : {listdata?.tr_mode || "NA"}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>AMOUNT : {listdata?.AMOUNT}</td>
                  <td colSpan={3}>Narration : {listdata?.NARRATION}</td>
                </tr>
                <tr>
                  <td
                    colSpan={6}
                    style={{ height: "100px", verticalAlign: "center" }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: "100%",
                        gap: ".25rem",
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          gap: ".25rem",
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <div style={{ fontSize: "13px" }}>
                          TOTAL INVOICE AMOUNT (In Words) :{" "}
                          {convertAmountToWords}
                        </div>

                        <div style={{ fontSize: "13px" }}>
                          DECLARATION : This is system generated.Hence no stamp
                          and signature is required.
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          height: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          margin: "0 1rem 1rem 0 ",
                        }}
                      >
                        STAMP AND SIGNATURE
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TaxInvoice4;
