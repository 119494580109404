import React, { useState } from "react";
import Table from "../../components/table/Table";
import Modal from "../../components/modal/Modal";
import PageHeading from "../../components/PageHeading";
import { AddButton, BackButton, SaveButton } from "../../components/btn/Buttons";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import { useSelector } from "react-redux";
import { StaffMaster } from "../MasterData/StaffMaster/StaffMaster";

// const StafMaster = () => {
//   const [showEdit, setShowEdit] = useState(false);
//   const [EditItem, setEditItem] = useState({
//     AADHAR:'',
//     PAN:'',
//     BANK_ACC_NO:'',
//     ESIC:'',
//     CONTACT_NO:'',
//     PF_NAME:'',
//     UAN_NUM:'',
//     BLOOD_GROUP:'',
//     EMERGENCY_CONTACT_NO:'',
//     EMAIL_ID:'',
//     ADDRESS:'',
//     ESIC_ELIGIBLE:'',
//     PF_ELIGIBLE:'',
//     BASIC_PAY:'',
//     HOUSE_RENTAL_ALLOWANCE:'',
//     CONVEYANCE_ALLOWANCE:'',
//     MEDICAL_ALLOWANCE:'',
//     OTHER_ALLOWANCE:'',
//     OUTSTANDING_LOANS_ADVANCES:''
//   });
//   const { staff_list, staff_loading } = useSelector(
//     (state) => state.masterdata
//   );
//   return (
//     <div className="container ">
//       <PageHeading
//         title={"STAFF MASTER"}
//         right={
//           <>
//             <AddButton />
//             <BackButton />
//           </>
//         }
//       />

//       <div style={{ height: "20px" }}></div>

//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           flexWrap: "wrap",
//         }}
//       >
//         <div className="epss-btn">
//           <button style={{ padding: "3px" }}>Show 5 rows</button>
//         </div>
//         <div className="search-btn">
//           <span style={{ fontSize: "12px" }}>search : </span>
//           <input
//             type="search"
//             style={{ height: "40px", width: "200px", borderColor: "#edf1f" }}
//           />
//         </div>
//       </div>
//       <Table
//         headData={[
//           "CUSTOMER NAME",
//           "< 30 DAYS DATE",
//           "31-60 DAYS",
//           "C> 60 DAYS",
//           "BALANCE",
//         ]}
//         body={
//           <>
//             {[0, 0, 0, 0, 0].map((item) => <tr>
//               <td style={{ cursor: 'pointer' }} onClick={() => setShowEdit(true)}>cust yes</td>
//               <td>0.00</td>
//               <td>0.00</td>
//               <td>53500</td>
//               <td>53500</td>
//             </tr>)}
//           </>
//         }
//       />
//       <div style={{ height: "10px" }}></div>

//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginBottom: "0%",
//         }}
//         className="mt10"
//       >
//         <div>
//           <p style={{ fontSize: "12px" }}> Showing 1 to 1 entries</p>
//         </div>
//         <div className="df">
//           <button
//             style={{
//               padding: "10px",
//               fontSize: "10px",
//               borderRadius: "5px",
//               backgroundColor: "white",
//               border: "0.5px solid  #2cbfca",
//             }}
//           >
//             previous
//           </button>
//           <button
//             style={{
//               backgroundColor: "blue",
//               padding: "10px",
//               fontSize: "10px",
//               border: "0.5px solid",
//             }}
//           >
//             1
//           </button>
//           <button
//             style={{
//               padding: "10px",
//               fontSize: "10px",
//               borderRadius: "5px",
//               backgroundColor: "white",
//               border: "0.5px solid  #2cbfca",
//             }}
//           >
//             Next
//           </button>
//         </div>
//       </div>
//       {showEdit && (
//         <Modal
//           isOpen={showEdit}
//           setIsOpen={setShowEdit}
//           title={"STAFF DETAILS"}
//           body={
//             <div
//               style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
//             >
//               <div className="staff-heading">
//                 <div style={{ color: "#145272", fontWeight: "600" }}>
//                   PERSONAL INFO :
//                 </div>
//                 <div style={{ color: "#0c08d8", textWrap: "wrap" }}>
//                   {EditItem?.EMP_CODE} - {EditItem?.EMP_NAME}
//                 </div>
//               </div>
//               <div className="credit-limit">
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={
//                       <span>
//                         AADHAR NUMBER<span style={{ color: "red" }}>*</span>
//                       </span>
//                     }
//                     placeholder={"AADHAR NUMBER"}
//                     // value={EditItem?.AADHAR}
//                     disabled={
//                       staff_list?.filter((v) => v?.ID == EditItem?.ID)[0]
//                         ?.AADHAR
//                     }
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, ""); // Remove non-digits
//                       const limitedInput = sanitizedInput.substring(0, 12); // Limit to 17 digits
//                       setEditItem({
//                         ...EditItem,
//                         AADHAR: limitedInput,
//                       });
//                     }}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={"PAN NUMBER"}
//                     placeholder={"PAN NUMBER"}
//                     // value={EditItem?.PAN}
//                     onChange={(e) => {
//                       if (e.target.value?.length < 11) {
//                         setEditItem({
//                           ...EditItem,
//                           PAN: e.target.value.toUpperCase(),
//                         });
//                       }
//                     }}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={"BANK ACCOUNT"}
//                     placeholder={"BANK ACCOUNT"}
//                     value={EditItem?.BANK_ACC_NO}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 20);
//                       setEditItem({
//                         ...EditItem,
//                         BANK_ACC_NO: limitedInput,
//                       });
//                     }}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={"ESIC NUMBER"}
//                     placeholder={"ESIC NUMBER"}
//                     // value={EditItem?.ESIC}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 20);
//                       setEditItem({
//                         ...EditItem,
//                         ESIC: limitedInput,
//                       });
//                     }}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={
//                       <span>
//                         CONTACT NUMBER<span style={{ color: "red" }}>*</span>
//                       </span>
//                     }
//                     // value={EditItem?.CONTACT_NO}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 10);
//                       setEditItem({
//                         ...EditItem,
//                         CONTACT_NO: limitedInput,
//                       });
//                     }}
//                     placeholder={"CONTACT NUMBER"}
//                   />
//                 </div>

//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={"PF NUMBER"}
//                     placeholder={"PF NUMBER"}
//                     value={EditItem?.BANK_ACC_NO}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 20);
//                       setEditItem({
//                         ...EditItem,
//                         PF_NAME: limitedInput,
//                       });
//                     }}
//                   />
//                 </div>

//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={"UAN NUMBER"}
//                     placeholder={"UAN NUMBER"}
//                     // value={EditItem?.UAN_NUM}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 12);
//                       setEditItem({ ...EditItem, UAN_NUM: limitedInput });
//                     }}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Dropdown
//                     label="BLOOD GROUP"
//                     // value={EditItem?.BLOOD_GROUP}
//                     onChange={(e) => {
//                       setEditItem({ ...EditItem, BLOOD_GROUP: e.target.value });
//                     }}
//                     options={
//                       <>
//                         <option hidden value={"0"}>
//                           SELECT
//                         </option>
//                         <option value="1"> A + </option>
//                         <option value="2"> A - </option>
//                         <option value="3"> B + </option>
//                         <option value="4"> B - </option>
//                         <option value="5"> O + </option>
//                         <option value="6"> O - </option>
//                         <option value="7"> AB + </option>
//                         <option value="8"> AB - </option>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     // value={EditItem?.EMERGENCY_CONTACT_NO}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 10);
//                       setEditItem({
//                         ...EditItem,
//                         EMERGENCY_CONTACT_NO: limitedInput,
//                       });
//                     }}
//                     label={"EMERGENCY NUMBER"}
//                     placeholder={"EMERGENCY NUMBER"}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     // value={EditItem?.EMAIL_ID}
//                     onChange={(e) => {
//                       setEditItem({ ...EditItem, EMAIL_ID: e.target.value });
//                     }}
//                     label={"EMAIL ID"}
//                     placeholder={"EMAIL ID"}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     // value={EditItem?.ADDRESS}
//                     onChange={(e) => {
//                       setEditItem({ ...EditItem, ADDRESS: e.target.value });
//                     }}
//                     label={
//                       <span>
//                         ADDRESS <span style={{ color: "red" }}>*</span>
//                       </span>
//                     }
//                     placeholder={"ADDRESS"}
//                   />
//                 </div>
//                 <div
//                   style={{
//                     width: "240px",
//                     padding: " .15rem .5rem",
//                     display: "flex",
//                     flexDirection: "column",
//                     gap: "1rem",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-evenly",
//                       gap: "1rem",
//                     }}
//                   >
//                     <span>ESIC ELIGIBLE</span>
//                     <span>
//                       <input
//                         style={{ cursor: "pointer" }}
//                         checked={
//                           Number(EditItem?.ESIC_ELIGIBLE) == 0 ? false : true
//                         }
//                         onChange={(e) => {
//                           setEditItem({
//                             ...EditItem,
//                             ESIC_ELIGIBLE:
//                               Number(EditItem?.ESIC_ELIGIBLE) == 0 ? 1 : 0,
//                           });
//                         }}
//                         type="checkbox"
//                       />
//                     </span>
//                   </div>
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-evenly",
//                       gap: "1rem",
//                     }}
//                   >
//                     <span>PF ELIGIBLE</span>
//                     <span style={{ paddingLeft: ".81rem" }}>
//                       <input
//                         style={{ cursor: "pointer" }}
//                         checked={
//                           Number(EditItem?.PF_ELIGIBLE) == 0 ? false : true
//                         }
//                         onChange={(e) => {
//                           setEditItem({
//                             ...EditItem,
//                             PF_ELIGIBLE:
//                               Number(EditItem?.PF_ELIGIBLE) == 0 ? 1 : 0,
//                           });
//                         }}
//                         type="checkbox"
//                       />
//                     </span>
//                   </div>
//                 </div>
//               </div>

//               <div className="staff-heading">
//                 <div style={{ color: "#145272", fontWeight: "600" }}>
//                   SALARY DETAILS
//                 </div>
//                 <div style={{ color: "#0c08d8", textWrap: "wrap" }}>
//                   {EditItem?.EMP_CODE} - {EditItem?.EMP_NAME}
//                 </div>
//               </div>
//               <div className="credit-limit credit-limit1">
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={<span>
//                       BASIC PAY<span style={{ color: "red" }}>*</span>
//                     </span>}
//                     placeholder={"BASIC PAY"}
//                     // value={EditItem?.BASIC_PAY}
//                     onChange={(e) => {
//                       if (e.target.value?.length < 11) {
//                         setEditItem({
//                           ...EditItem,
//                           BASIC_PAY: e.target.value.toUpperCase(),
//                         });
//                       }
//                     }}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={<span>
//                       HOUSE RENTAL ALLOWANCE<span style={{ color: "red" }}>*</span>
//                     </span>
//                     }
//                     placeholder={"HOUSE RENTAL ALLOWANCE"}
//                     // value={EditItem?.HOUSE_RENTAL_ALLOWANCE}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 20);
//                       setEditItem({
//                         ...EditItem,
//                         HOUSE_RENTAL_ALLOWANCE: limitedInput,
//                       });
//                     }}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={<span>
//                       CONVEYANCE ALLOWANCE<span style={{ color: "red" }}>*</span>
//                     </span>}
//                     placeholder={"CONVEYANCE ALLOWANCE"}
//                     // value={EditItem?.CONVEYANCE_ALLOWANCE}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 20);
//                       setEditItem({
//                         ...EditItem,
//                         CONVEYANCE_ALLOWANCE: limitedInput,
//                       });
//                     }}
//                   />
//                 </div>
//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={
//                       <span>
//                         MEDICAL ALLOWANCE<span style={{ color: "red" }}>*</span>
//                       </span>
//                     }
//                     // value={EditItem?.MEDICAL_ALLOWANCE}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 10);
//                       setEditItem({
//                         ...EditItem,
//                         MEDICAL_ALLOWANCE: limitedInput,
//                       });
//                     }}
//                     placeholder={"MEDICAL ALLOWANCE"}
//                   />
//                 </div>

//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={<span>
//                       OTHER ALLOWANCE<span style={{ color: "red" }}>*</span>
//                     </span>}
//                     placeholder={"OTHER ALLOWANCE"}
//                     // value={EditItem?.OTHER_ALLOWANCE}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 20);
//                       setEditItem({
//                         ...EditItem,
//                         OTHER_ALLOWANCE: limitedInput,
//                       });
//                     }}
//                   />
//                 </div>

//                 <div style={{ width: "240px", padding: " .15rem .5rem" }}>
//                   <Input
//                     label={<span>
//                       OUTSTANDING LOANS/ ADVANCES<span style={{ color: "red" }}>*</span>
//                     </span>}
//                     placeholder={"OUTSTANDING LOANS/ ADVANCES"}
//                     // value={EditItem?.OUTSTANDING_LOANS_ADVANCES}
//                     onChange={(e) => {
//                       const input = e.target.value;
//                       const sanitizedInput = input.replace(/\D/g, "");
//                       const limitedInput = sanitizedInput.substring(0, 12);
//                       setEditItem({ ...EditItem, OUTSTANDING_LOANS_ADVANCES: limitedInput });
//                     }}
//                   />
//                 </div>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "flex-end",
//                   fontSize: "11px",
//                   marginTop: "1rem",
//                   gap: ".5rem",
//                 }}
//               >
//                 <SaveButton onClick={() => { }} />
//               </div>
//             </div>
//           }
//         />
//       )}
//     </div>
//   );
// };

// export default StafMaster;

const StafMaster = ()=>{
  return(
    <StaffMaster data={true} />
  )
}

 export default StafMaster;