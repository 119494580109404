import React, { useEffect, useRef, useState } from "react";
import "./menu.css";
import { AnimatePresence, motion } from "framer-motion";
import { FaCaretDown } from "react-icons/fa";
import { BiSolidUserCircle } from "react-icons/bi";
import { IoMdLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SmallModal from "../modal/SmallModal";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { logout, logoutAllTabs } from "../../utils/Hook/useBroadcastChannel";
// import { get_profile_data } from "../../screens/profile/UsersSlice";

const MenuBar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const Name = JSON.parse(userIdString)?.Name;

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFocused(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const { img_url, contact_person_name } = useSelector(
    (state) => state.profile
  );
  // const handleLogout = () => {
  //   // Clear user session (e.g., remove token from local storage)
  //   localStorage.removeItem('userId');

  //   // Broadcast the logout event
  //   const channel = new BroadcastChannel('auth-channel');
  //   channel.postMessage('logout');

  //   // Close the channel
  //   channel.close();

  //   // Redirect to login page or perform any other logout related actions
  //   window.location.href = '/login';
  // };

  return (
    <div ref={wrapperRef}>
      <div className="menu-list-main" onClick={() => setFocused(true)}>
        {img_url ? (
          <img
            src={img_url}
            style={{
              height: 35,
              width: 35,
              borderRadius: 30,
              objectFit: "cover",
            }}
          />
        ) : (
          <BiSolidUserCircle color="#6e82a5" size={"35px"} />
        )}
        <div className="menu-user-id">{userId}</div>
        <FaCaretDown className="menu-down-arr" size={20} />
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {focused && (
          <motion.div
            style={{ zIndex: 3 }}
            className="sub-menu"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, bounce: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="menu-header">
              {img_url ? (
                <img src={img_url} className="menu-header-img" />
              ) : (
                <BiSolidUserCircle color="#6e82a5" size={"35px"} />
              )}

              <div className="menu-header-title" style={{ width: "200px" }}>
                {contact_person_name?.toUpperCase() || Name}
              </div>
            </div>
            <div
              className="submenu-div"
              onClick={() => {
                navigate("/profile/Ainfo");
              }}
            >
              <BiSolidUserCircle size={30} color="#6e82a5" />
              <div className="submenu-title">Profile</div>
            </div>
            <div
              className="submenu-div"
              onClick={() => {
                setFocused(false);
                setOpen(true);
              }}
            >
              <IoMdLogOut size={30} color="#6e82a5" />
              <div className="submenu-title">Sign Out</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {open && (
        <SmallModal
          title={"Log Out"}
          mess={"Are you sure? You want to Sign out?"}
          isOpen={open}
          setIsOpen={setOpen}
          bname={"SIGN OUT"}
          onClick={logout}
        />
      )}
    </div>
  );
};

export default MenuBar;
